import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
///////////////////////

import {
  selectProductSearch,
  productVariationSearch,
} from "../product/productAsyncThunk";

import {
  catalogListBrand,
  selectCatalogListBrand,
} from "../catalog/catalogAsyncThunk";

///////////////////////

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: 999,
    // border: "none",
  },
  select: {
    borderRadius: 999,
    border: "none",
    fontSize: "15px",
    "& .MuiSelect-select": {
      borderRadius: 999,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)", // Add the desired hover effect
      },
    },
  },
  menuItem: {
    fontSize: "15px",
  },
});

///////////////////////

const FilterBar = (criteria) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: "12px",
        marginTop: "8px",
      },
    },
  };

  /////////////////////// brand information
  const catalogListBrandStore = useSelector(selectCatalogListBrand);
  const {
    isLoading: brandListLoading,
    isSuccess: brandListSuccess,
    payload: brandArray,
  } = catalogListBrandStore;

  const brandList = brandArray.map((brand) => {
    return {
      key: brand.id,
      label: brand.name,
    };
  });

  const [chipData, setChipData] = useState(["All Brands"]);

  ///////////////////////

  return (
    <div>
      <FormControl
        className={classes.formControl}
        size={"small"}
        // hiddenLabel={true}
      >
        {/* <InputLabel id="brandSelect" className={classes.InputLabel}>
          All Brand
        </InputLabel> */}
        <Select
          className={classes.select}
          autoWidth={true}
          // labelId="demo-multiple-chip-label"
          id="brandSelect"
          defaultValue={brandList.label}
          multiple
          disableUnderline
          value={chipData}
          //   onChange={handleChange}
          input={<Input id="select-multiple-brand" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem value={brandList.label} className={classes.menuItem}>
            <b>All Brands</b>
          </MenuItem>
          {brandList.map((brand) => (
            <MenuItem
              className={classes.menuItem}
              key={brand.key}
              value={brand.label}
              //   style={getStyles(brand.label, chipData, theme)}
            >
              {brand.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterBar;
