import * as React from "react";

const OrderSampleIcon = (props) => (
  <svg
    id="Layer_2"
    width="26"
    height="26"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.76 25.29"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.2px}"
        }
      </style>
    </defs>
    <g id="Layer_1-2">
      <path
        className="cls-1"
        d="M12.87.6v9.79M8.22 9.01l4.66 4.65 4.66-4.65M20.05 13.66h5.11v11.03H.6V13.66h7.13v.21c0 2.84 2.3 5.13 5.13 5.13h0c2.84 0 5.13-2.3 5.13-5.13v-.21"
      />
    </g>
  </svg>
);

export default OrderSampleIcon;
