import React, { useEffect, useState } from "react";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./components/styles/materialUIStyles";
import {
  authUserInfo,
  authPreflight,
  authUserLocation,
  selectAuthLogin,
  selectAuthUserInfo,
} from "./features/authentication/authAsyncThunk";
import {
  selectFavoriteCreate,
  favoriteCreateReset,
} from "./features/favorite/favoriteAsyncThunk";
import { orderCartDecode } from "./features/order/orderAsyncThunk";
import axios from "axios";
import BottomAppBar from "./components/BottomAppBar";
import CookiesSnackbar from "./components/CookiesSnackbar";
import FeedbackSnackbar from "./components/FeedbackSnackbar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ComingSoon from "./components/ComingSoon";
import Error404 from "./screens/Error404";
import LandingPage from "./screens/LandingPage";
import LoginPage from "./screens/LoginPage";
import CatalogPage from "./screens/CatalogPage";
import SearchPage from "./screens/SearchPage";
import SearchMaterialPage from "./screens/SearchMaterialPage";
import OrderPage from "./screens/OrderPage";
import ProductPage from "./screens/ProductPage";
import BrandPage from "./screens/BrandPage";
import BrandDisplayPage from "./screens/BrandDisplayPage";
import CartPage from "./screens/CartPage";
import SuperPage from "./screens/SuperPage";
import OrderCreate from "./features/order/OrderCreate";
import OrderGet from "./features/order/OrderGet";
import RegConfirmRegistration from "./features/registration/RegConfirmRegistration";
import RegConfirmSubscription from "./features/registration/RegConfirmSubscription";
import UserForgetPassword from "./features/userChange/UserForgetPassword";
import UserConfirmRecovery from "./features/userChange/UserConfirmRecovery";
import UserFederatedInfo from "./features/userChange/UserFederatedInfo";
import SpacePage from "./screens/SpacePage";
import ProjectPage from "./screens/ProjectPage";
import SpaceProductPage from "./screens/SpaceProductPage";
import Layout from "./layouts/Layout";
import BrandContentTable from "./components/BrandContentTable";
import MatterBoard from "./screens/MatterBoard";
import MatterboardDetailPage from "./screens/MatterboardDetailPage";
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const dispatch = useDispatch();
  const authLogin = useSelector(selectAuthLogin);
  const { successCode } = authLogin;
  const { csrfToken, ip, payload: userInfo } = useSelector(selectAuthUserInfo);
  const { role, isSuper, isInfoSupplemented } = userInfo;
  const { isBulkCreateSuccess: favoriteCreateBulkSuccess } =
    useSelector(selectFavoriteCreate);
  const [productInfo, setProductInfo] = useState(null);
  const handleProductInfo = (productInfo) => {
    setProductInfo(productInfo);
  };
  useEffect(() => {
    axios.defaults.withCredentials = true;
    dispatch(authPreflight());
    const cartToken = sessionStorage.getItem("cart");
    if (Boolean(cartToken) === true) dispatch(orderCartDecode({ cartToken }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (Boolean(csrfToken) === true) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrfToken]);
  useEffect(() => {
    const countryCode = sessionStorage.getItem("countryCode");
    if (Boolean(ip) === true && Boolean(countryCode) === false) {
      dispatch(authUserLocation(ip));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ip]);
  useEffect(() => {
    if (successCode === 200) {
      dispatch(authUserInfo());
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCode]);

  useEffect(() => {
    // if (Boolean(csrfToken) === true) {
    //   const { id } = userInfo;
    //   const productVariationID =
    //     JSON.parse(localStorage.getItem("productVariationID")) || [];
    //   if (Boolean(id) === true) {
    //     if (Array.isArray(productVariationID))
    //       dispatch(favoriteCreateBulk({ productVariationID }));
    //     dispatch(favoriteList());
    //   } else {
    //     dispatch(favoriteListBulk(productVariationID));
    //   }
    // }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, csrfToken]);
  useEffect(() => {
    if (favoriteCreateBulkSuccess) {
      localStorage.removeItem("productVariationID");
      dispatch(favoriteCreateReset());
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteCreateBulkSuccess]);

  useEffect(() => {
    let select = document.getElementById("svgUploader");
    if (select) {
      select.onChange(alert("hello"));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <div className="App">
        <StyledEngineProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Layout productInfo={productInfo}>
              {role === 2 && isInfoSupplemented === false ? (
                <Switch>
                  <Route component={UserFederatedInfo} />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path="/confirmRegistration/:token"
                    component={RegConfirmRegistration}
                  />
                  <Route
                    path="/confirmSubscription/:token"
                    component={RegConfirmSubscription}
                  />
                  <Route path="/recovery" component={UserForgetPassword} />
                  <Route
                    path="/confirmRecovery/:token"
                    component={UserConfirmRecovery}
                  />
                  <ProtectedRoute
                    role={role}
                    roles={[0, 1, 2]}
                    path="/account"
                    component={ProjectPage}
                  />
                  <ProtectedRoute
                    role={role}
                    roles={[0]}
                    exact
                    path="/catalog"
                    component={CatalogPage}
                  />
                  {/* <Route path="/favorite" component={ProjectPage} /> */}
                  <Route path="/search" component={SearchPage} />
                  <Route path="/order" component={OrderPage} />
                  <Route path="/product">
                    <ProductPage handleProductInfo={handleProductInfo} />
                  </Route>
                  <Route path="/brand" component={BrandPage} />
                  <Route path="/brands" component={BrandContentTable} />
                  <Route path="/brand234" component={BrandDisplayPage} />
                  <ProtectedRoute
                    role={role}
                    roles={[0, 1, 2]}
                    path="/cart"
                    component={CartPage}
                  />
                  <ProtectedRoute
                    role={role}
                    roles={[0, 1, 2]}
                    path="/confirmOrder"
                    component={OrderCreate}
                  />
                  <Route exact path="/matterboard" component={MatterBoard} />
                  <Route
                    exact
                    path="/matterboard/:id"
                    component={MatterboardDetailPage}
                  />
                  <ProtectedRoute
                    role={role}
                    roles={[0, 1, 2]}
                    path="/order/:id"
                    component={OrderGet}
                  />
                  <Route path="/login" component={LoginPage} />
                  <Route path="/comingSoon" component={ComingSoon} />
                  <Route exact path="/" component={LandingPage} />
                  {isSuper && <Route path="/super" component={SuperPage} />}
                  <ProtectedRoute
                    role={role}
                    roles={[0, 1, 2]}
                    path="/project/:id"
                    component={SpacePage}
                  />
                  <ProtectedRoute
                    role={role}
                    roles={[0, 1, 2]}
                    path="/space/:id"
                    component={SpaceProductPage}
                  />
                  <Route component={Error404} />
                </Switch>
              )}
            </Layout>
            <CookiesSnackbar />
            <FeedbackSnackbar />
            <BottomAppBar />
          </BrowserRouter>
          {/* </ThemeProvider> */}
        </StyledEngineProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
