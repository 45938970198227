import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@mui/material";
import { BlackButton } from "../../components/CustomButtons";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, StyledDialogTitle } from "../../components/CustomComponents";
import { selectSpaceMove, spaceMove } from "./spaceAsyncThunk";
import { useParams } from "react-router-dom";

export default function SpaceProductMoveModal({
  open,
  onClose,
  projects,
  isLoading,
  spaceId,
  handleFetch,
  setSpaceId,
  setSpaceMoveToModalOpen,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showHint, setShowHint] = React.useState(false);
  const { favoriteProductIds } = useSelector(selectSpaceMove);
  const [nextSpaceId, setNextSpaceId] = React.useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const handleMove = async () => {
    await dispatch(
      spaceMove({ prevSpaceId: +spaceId, nextSpaceId, favoriteProductIds })
    );
    await handleFetch();
    window.location.href = `/project/${params.id}`;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="space-dialog-title"
      >
        <StyledDialogTitle id="space-dialog-title" onClose={onClose}>
          Select Project
        </StyledDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 3,
            width: { md: 512, sm: "100%" },
          }}
        >
          {isLoading ? (
            <Backdrop
              open
              sx={{
                zIndex: (theme) => theme.zIndex.modal + 1,
                color: "common.white",
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            projects
              .filter((item) => item.id !== params.id)
              .map((project, index) => {
                return (
                  <Box
                    sx={{
                      mb: 2,
                      cursor: "pointer",
                      backgroundColor:
                        +nextSpaceId === project.space_id
                          ? "#F2F2F2"
                          : "transparent",
                    }}
                    key={`select-space-${index}`}
                    onClick={() => {
                      setNextSpaceId(project.space_id);
                      setShowHint(false);
                    }}
                  >
                    <Typography
                      sx={{
                        mx: +nextSpaceId === project.space_id ? "10px" : "0",
                      }}
                    >
                      {project.space_id === +spaceId
                        ? null
                        : project.project_name}
                    </Typography>
                  </Box>
                );
              })
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <BlackButton
            sx={{
              width: "100%",
              height: 55,
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (Boolean(spaceId) === false) {
                setShowHint(true);
                return;
              }
              handleMove();
              onClose();
              setSpaceId("");
              setNextSpaceId("");
            }}
          >
            Select Project
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </div>
  );
}
