import React, { useState, useEffect } from "react";
import orderStatusTypeColumns from "../../components/types/orderStatusType";
import CatalogCreateOrderStatusType from "./CatalogCreateOrderStatusType";
import CatalogManageOrderStatusType from "./CatalogManageOrderStatusType";
import CatalogDeleteOrderStatusType from "./CatalogDeleteOrderStatusType";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListOrderStatusType,
  selectCatalogCreateOrderStatusType,
  selectCatalogListOrderStatusType,
  selectCatalogManageOrderStatusType,
  selectCatalogDeleteOrderStatusType,
} from "./catalogAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function OrderStatusTypeGrid() {
  const dispatch = useDispatch();
  const [orderStatusTypeList, setOrderStatusTypeList] = useState([]);
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
      color: "",
      created_at: new Date(),
      updated_at: new Date(),
    }
  });
  const catalogListOrderStatusTypeStore = useSelector(selectCatalogListOrderStatusType);
  const { payload } = catalogListOrderStatusTypeStore;
  const catalogManageOrderStatusTypeStore = useSelector(selectCatalogManageOrderStatusType);
  const { isSuccess: catalogManageOrderStatusTypeSuccess } = catalogManageOrderStatusTypeStore;
  const catalogCreateOrderStatusTypeStore = useSelector(selectCatalogCreateOrderStatusType);
  const { isSuccess: catalogCreateOrderStatusTypeSuccess } = catalogCreateOrderStatusTypeStore;
  const catalogDeleteOrderStatusTypeStore = useSelector(selectCatalogDeleteOrderStatusType);
  const { isSuccess: catalogDeleteOrderStatusTypeSuccess } = catalogDeleteOrderStatusTypeStore;
  
  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = orderStatusTypeList.findIndex(x => x.id === selected[0]);
      setEditButtonProps({ isSelected: true, data: { ...orderStatusTypeList[index] } });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
          color: "",
          created_at: new Date(),
          updated_at: new Date(),
        }
      });
    };
  };

  useEffect(() => {
    dispatch(catalogListOrderStatusType());
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogManageOrderStatusTypeSuccess, catalogCreateOrderStatusTypeSuccess, catalogDeleteOrderStatusTypeSuccess]);

  useEffect(() => {
    let orderStatusType = [...payload];
    let list = [];
    if (orderStatusType.length > 0) {
      for (let i = 0; i < orderStatusType.length; i++) {
        let item = {
          ...orderStatusType[i],
          created_at: new Date(orderStatusType[i].created_at),
          updated_at: new Date(orderStatusType[i].updated_at),
        }
        list.push(item);
      };
      setOrderStatusTypeList(list);
    };
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ flexGrow: 1, mt: 1, bgcolor: "background.paper" }}>
      <Typography>Order Status Type Management</Typography>
      <CatalogCreateOrderStatusType />
      {
        orderStatusTypeList[0] &&
        <CatalogManageOrderStatusType {...editButtonProps} />
      }
      { orderStatusTypeList[0] && <CatalogDeleteOrderStatusType {...editButtonProps} /> }
      {
        orderStatusTypeList[0] &&
        <Box sx={{ height: 400, width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={orderStatusTypeList}
                columns={orderStatusTypeColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      }
    </Box>
  );
}
