import { createSlice } from "@reduxjs/toolkit";
import { spaceProductsCreate } from "./spaceProductsAsyncThunk";

const spaceProductCreateSlice = createSlice({
  name: "spaceProduct",
  initialState: {
    isLoading: false,
    isSuccess: false,
    failedCode: 0,
  },
  reducers: {},
  extraReducers: {
    [spaceProductsCreate.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failedCode = 0;
    },
    [spaceProductsCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [spaceProductsCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.failedCode = action.payload.status;
    },
  },
});

export default spaceProductCreateSlice.reducer;
