import React from "react";
import { Box, Typography } from "@mui/material";

const AutoCompleteOption = ({ name, ...props }) => {
  return (
    <Box
      sx={{
        py: "12px!important",
        pl: "24px!important",
        cursor: "pointer!important",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
      {...props}
    >
      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
        {name}
      </Typography>
    </Box>
  );
};

export default AutoCompleteOption;
