import React from "react";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

const ImgStyled = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: theme.spacing(8),
  objectFit: "cover",
}));

const ProductOptionCard = ({
  title,
  subTitle,
  onClick,
  options,
  imageUrl,
  isModal,
}) => {
  return (
    <Box
      sx={{
        transition: "box-shadow 50ms ease-in-out",
        borderRadius: "16px",
        Width: "100%",
        // minWidth: "200px",

        // maxHeight: (theme) => theme.spacing("118.5px"),
        // paddingTop: "100%",
        // position: "relative",
        "&:hover": {
          boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.05)",
          transitionDelay: "100ms",
        },
      }}
    >
      <Box
        sx={{
          mt: 1.75,
          backgroundColor: "white",
          py: 1.5,
          pl: 1.5,
          display: "flex",
          pr: 1.5,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: { xs: "100%", lg: isModal ? 432 : "100%" },
          cursor: "pointer",
          transition: "border 50ms ease-in-out",
          border: 1.5,
          borderColor: "white",
          "&:hover": {
            border: 1.5,
            borderColor: "rgba(0, 0, 0, 0.25)",
            transitionDelay: "100ms",
          },
          borderRadius: "16px",
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ImgStyled src={imageUrl} alt={title} sx={{ borderRadius: "8px" }} />
          <Box sx={{ pl: 1.5 }}>
            <Box>
              <Typography
                variant="title_secondary_black"
                sx={{
                  display: "block",
                  pb: 0.5,
                  fontSize: 15,
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="title_secondary_grey"
                sx={{
                  display: "block",
                  pt: 0.5,
                  fontSize: 15,
                }}
              >
                {subTitle}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="title_secondary_black"
            sx={{
              pr: 1,
              fontSize: 15,
              display: { xs: "none", md: "flex" },
            }}
          >
            {options} options
          </Typography>
          <ArrowForwardIos
            fontSize="inherit"
            sx={{ color: "rgb(0,0,0,0.56)" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductOptionCard;
