import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { regSignup } from "./regAsyncThunk";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, StyledTextField } from "../../components/CustomComponents";
import {
  BlackButton,
  RBlackButton,
  BlueButton,
  WhiteButton,
  LightBlueButton,
} from "../../components/CustomButtons";
import { useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(type) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyType: "",
    jobTitle: "",
    companyLocation: "",
    companyWebsite: "",
    isSubscribe: true,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!Boolean(showPassword));
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!Boolean(showConfirmPassword));
  };

  const handlePointerDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (values.password.length < 9) {
      setShowHint(true);
      return;
    }
    if (confirmPassword !== values.password) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.jobTitle) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyWebsite) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(regSignup(values));
  };

  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {type.onlyMobile === "yes" ? (
        <WhiteButton
          variant="contained"
          color="primary"
          sx={{
            m: 1,
            ml: 0,
            borderRadius: 999,
            fontSize: "15px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "36px",
            paddingRight: "36px",
            border: 1,
            borderColor: "rgba(255,255,255,0)",
            backgroundColor: "rgba(0,0,0,0.2)",
            backdropFilter: "blur(8px)",
            color: "white",
          }}
          onClick={() =>
            handleVariant("New user sign up will be available soon.")
          }
        >
          Register
        </WhiteButton>
      ) : (
        <>
          {" "}
          {type.type === 1 ? (
            <MenuItem
              onClick={() =>
                handleVariant("New user sign up will be available soon.")
              }
            >
              <IconButton
                aria-label="register"
                aria-controls="primary-search-register-menu"
                aria-haspopup="true"
                color="inherit"
                size="small"
              ></IconButton>
              <p
                style={{
                  background: "#fff",
                  color: "#000",
                  padding: "10px 20px",
                  borderRadius: "30px",
                }}
              >
                Register
              </p>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                handleVariant("New user sign up will be available soon.")
              }
            >
              <IconButton
                aria-label="register"
                aria-controls="primary-search-register-menu"
                aria-haspopup="true"
                color="inherit"
                size="large"
              >
                <AccountCircle />
              </IconButton>
              <p>Register</p>
            </MenuItem>
          )}
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
                  New Account
                </Typography>
                <Button autoFocus color="inherit" onClick={handleSubmit}>
                  register
                </Button>
              </Toolbar>
            </AppBar> */}
        <Box sx={{ p: "10px 10px 5px 2px", display: "flex", align: "center" }}>
          <Typography
            variant="h6"
            sx={{ ml: 2, flex: 1, mt: 1, fontWeight: "bold" }}
          >
            Register
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem sx={{ flexDirection: "column" }}>
            {showHint && (
              <ListItemText
                sx={{ color: "red" }}
                primary="Oops! Something is still wrong below."
              />
            )}
            <StyledTextField
              autoFocus
              required
              error={isUserActionMade && Boolean(values.firstName) === false}
              defaultValue={values.firstName}
              onChange={handleValues("firstName")}
              variant="outlined"
              margin="dense"
              size="small"
              id="name"
              placeholder="First Name"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.lastName) === false}
              defaultValue={values.lastName}
              onChange={handleValues("lastName")}
              variant="outlined"
              margin="dense"
              size="small"
              id="lastname"
              placeholder="Last Name"
              fullWidth
            />
            <StyledTextField
              required
              error={
                isUserActionMade &&
                Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
              }
              defaultValue={values.email}
              onChange={handleValues("email")}
              variant="outlined"
              margin="dense"
              size="small"
              id="email"
              placeholder="Email Address"
              type="email"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && values.password.length < 9}
              defaultValue={values.password}
              onChange={handleValues("password")}
              variant="outlined"
              margin="dense"
              size="small"
              autoComplete="off"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              helperText="At least 9-character long"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onPointerDown={handlePointerDownPassword}
                      edge="end"
                      sx={{ marginRight: "0px!important" }}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              required
              error={isUserActionMade && confirmPassword !== values.password}
              defaultValue={confirmPassword}
              onChange={(e) => {
                setIsUserActionMade(true);
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              margin="dense"
              size="small"
              autoComplete="off"
              placeholder="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onPointerDown={handlePointerDownPassword}
                      edge="end"
                      size="large"
                      sx={{ marginRight: "0px!important" }}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.companyType) === false}
              defaultValue={values.companyType}
              onChange={handleValues("companyType")}
              variant="outlined"
              margin="dense"
              size="small"
              id="company-type"
              placeholder="Company Type"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.jobTitle) === false}
              defaultValue={values.jobTitle}
              onChange={handleValues("jobTitle")}
              variant="outlined"
              margin="dense"
              size="small"
              id="job-title"
              placeholder="Job Title"
              fullWidth
            />
            <StyledTextField
              required
              error={
                isUserActionMade && Boolean(values.companyLocation) === false
              }
              defaultValue={values.companyLocation}
              onChange={handleValues("companyLocation")}
              variant="outlined"
              margin="dense"
              size="small"
              id="company-location"
              placeholder="Company Location"
              helperText="You can enter multiple rows here."
              fullWidth
              multiline
            />
            <StyledTextField
              required
              error={
                isUserActionMade && Boolean(values.companyWebsite) === false
              }
              defaultValue={values.companyWebsite}
              onChange={handleValues("companyWebsite")}
              variant="outlined"
              margin="dense"
              size="small"
              id="company-website"
              placeholder="Company Website"
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isSubscribe}
                  onChange={(e) =>
                    setValues({ ...values, isSubscribe: e.target.checked })
                  }
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20, mr: 1 } }}
                  name="isSubscribe"
                  color="primary"
                  inputProps={{
                    "aria-label": "Subscribe to our weekly newsletter",
                  }}
                />
              }
              placeholder={
                <Typography sx={{ fontSize: "14px", mr: 4 }}>
                  Subscribe to our weekly newsletter
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Box>
          <Typography variant="body2" sx={{ fontSize: "10px", p: 2 }}>
            By registering with Matterbase you agree to our Terms and Conditions
            and Privacy Statement
          </Typography>
        </Box>
        <Box sx={{ p: 2, width: "100%" }}>
          <RBlackButton autoFocus color="inherit" onClick={handleSubmit}>
            register
          </RBlackButton>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
