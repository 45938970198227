import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import SampleOrderCard from "../SampleOrderCard";
import SampleOrderProductCard from "../SampleOrderProductCard";
import SampleOrderModal from "../SampleOrderModal";

import { selectAuthUserInfo } from "../../features/authentication/authAsyncThunk";
import {
  orderCancel,
  orderListAdmin,
  orderListSelf,
  orderStatusUpdate,
  selectOrderList,
  selectOrderStatusUpdate,
} from "../../features/order/orderAsyncThunk";

import { ORDER_OPTIONS } from "../../constants/orders.constants";

const SampleOrdersTab = () => {
  const [orderId, setOrderId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderStatusId, setOrderStatusId] = useState(1);

  const authUserInfo = useSelector(selectAuthUserInfo);
  const { payload: userInfo, csrfToken } = authUserInfo;
  const dispatch = useDispatch();
  const orderListStore = useSelector(selectOrderList);
  const { payload: ordersList, isLoading: ordersLoading } = orderListStore;
  const orderUpdateStatus = useSelector(selectOrderStatusUpdate);
  const { isLoading: orderUpdateStatusLoading } = orderUpdateStatus;

  useEffect(() => {
    // if (crsfToken) {
    if (userInfo.role === 0) return dispatch(orderListAdmin());
    if (csrfToken) return dispatch(orderListSelf());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, csrfToken]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (userInfo.role === 0) {
      setAnchorEl(event.currentTarget);
    }
    return;
  };

  const handleOpen = (index) => {
    if (
      selectedSampleOrders?.courier &&
      selectedSampleOrders?.expectedDelivery &&
      selectedSampleOrders?.trackingNumber &&
      (index === 0 || index === 1 || index === 2)
    ) {
      dispatch(
        orderStatusUpdate({
          id: orderId,
          courier: index === 0 ? "" : selectedSampleOrders?.courier,
          expectedDelivery:
            index === 0 ? "" : selectedSampleOrders?.expectedDelivery,
          trackingNumber:
            index === 0 ? "" : selectedSampleOrders?.trackingNumber,
          orderStatusId: index === 0 ? 2 : index === 1 ? 3 : 4,
        })
      );
      window.location.href = `/account`;
      return;
    }
    if (index === 0) {
      return;
    }
    setModalOpen(!modalOpen);
  };

  const handleMenuItemClick = (_, index) => {
    if (index === 0) {
      setOrderStatusId(1);
    }
    if (index === 1) {
      setOrderStatusId(3);
    }
    if (index === 2) {
      setOrderStatusId(4);
    }
    setSelectedIndex(index);
    setAnchorEl(null);
    handleOpen(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedSampleOrders = useMemo(() => {
    const orders = ordersList?.find(({ id }) => id === orderId);
    setSelectedIndex(
      ORDER_OPTIONS.findIndex((option) => orders?.orderStatus?.includes(option))
    );
    return orders;
  }, [orderId, ordersList]);

  const handleHeading = (id) => {
    if (id === 1 || id === 2) {
      return "Your Matterbox is being prepared...";
    }
    if (id === 3) {
      return "Your Matterbox is on its way!";
    }
    return "Your Matterbox is delivered.";
  };

  const handleBackgroundColor = (id) => {
    if (id === 1 || id === 2) {
      return "#D1D1D1";
    }
    if (id === 3) {
      return "#F8E399";
    }
    return "#C0EF83";
  };

  const handleChangeText = (id) => {
    if (id === 1 || id === 2) {
      return "Preparing Matterbox";
    }
    if (id === 3) {
      return "Matterbox Shipped";
    }
    return "Matterbox Delivered";
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {ordersLoading || orderUpdateStatusLoading ? (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: "common.white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>
          {orderId ? (
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    cursor: "pointer",
                    "&:hover": {
                      borderBottom: 1,
                    },
                  }}
                  fontWeight={300}
                  noWrap
                  onClick={() => setOrderId(null)}
                >
                  Sample Orders
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    mx: 1.5,
                  }}
                  fontWeight={300}
                >
                  /
                </Typography>
                <Typography sx={{ fontSize: 15 }} fontWeight={300}>
                  {selectedSampleOrders?.matterboxNumber}
                </Typography>
              </Box>
              <Box sx={{ pt: 1.5, pb: 4 }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: { xs: 2, sm: 3 },
                    mb: 2,
                    borderRadius: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                      justifyContent: { xs: "flex-start", sm: "space-between" },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { xs: 20, lg: 24 },
                          mb: { xs: 2, sm: 0 },
                        }}
                        fontWeight={600}
                      >
                        {handleHeading(selectedSampleOrders?.order_status_id)}
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          px: 2,
                          py: 0.5,
                          borderRadius: 10,
                          backgroundColor: handleBackgroundColor(
                            selectedSampleOrders?.order_status_id
                          ),
                          letterSpacing: 0.5,
                          cursor: "pointer",
                        }}
                        onClick={handleClick}
                      >
                        <Typography sx={{ fontSize: 15, fontWeight: "600" }}>
                          {handleChangeText(
                            selectedSampleOrders?.order_status_id
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography sx={{ fontSize: 15, mt: 1.5 }} fontWeight={300}>
                    Matterbox Number: {selectedSampleOrders?.matterboxNumber}
                  </Typography>
                  <Typography sx={{ fontSize: 15, my: 1.5 }} fontWeight={300}>
                    Placed on:{" "}
                    {moment(selectedSampleOrders?.created_at).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                  <Typography sx={{ fontSize: 15, my: 1.5 }} fontWeight={300}>
                    Courier: {selectedSampleOrders?.courier || "-"}
                  </Typography>
                  <Typography sx={{ fontSize: 15, my: 1.5 }} fontWeight={300}>
                    Tracking Number:{" "}
                    {selectedSampleOrders?.trackingNumber || "-"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, mt: 1.5, mb: { xs: 3, sm: 1.5 } }}
                    fontWeight={300}
                  >
                    Expected Delivery:{" "}
                    {selectedSampleOrders?.expectedDelivery
                      ? moment(selectedSampleOrders?.expectedDelivery).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, mt: 1.5, mb: { xs: 3, sm: 1.5 } }}
                    fontWeight={300}
                  >
                    Shipping to: {selectedSampleOrders?.deliveryLocation || "-"}
                  </Typography>
                  {(selectedSampleOrders?.order_status_id === 1 ||
                    selectedSampleOrders?.order_status_id === 2) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: { xs: "flex-start", sm: "flex-end" },
                        justifyContent: { xs: "flex-start", sm: "flex-end" },
                      }}
                      onClick={() => {
                        dispatch(orderCancel(selectedSampleOrders?.id));
                        window.location.href = "/account";
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 15,
                          borderBottom: 1,
                          cursor: "pointer",
                        }}
                        noWrap
                        fontWeight={300}
                      >
                        Cancel Matterbox Order
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={{ mt: 6 }}>
                  {selectedSampleOrders?.product_variation_objects?.map(
                    (product, index) => (
                      <React.Fragment key={`order-products-${index}`}>
                        <SampleOrderProductCard
                          product={product}
                          orderId={orderId}
                          productCheck={
                            selectedSampleOrders?.product_variation_objects
                              ?.length > 1
                              ? true
                              : false
                          }
                          orderIdCheck={
                            selectedSampleOrders?.order_status_id === 1 ||
                            selectedSampleOrders?.order_status_id === 2
                              ? true
                              : false
                          }
                        />
                      </React.Fragment>
                    )
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Typography
                sx={{ fontSize: { xs: 16, sm: 20, md: 24 } }}
                fontWeight={600}
              >
                Your Matterbox
              </Typography>
              <Box sx={{ pt: 1.5, pb: 4 }}>
                {ordersList?.length > 0 &&
                  ordersList?.map((order, index) => {
                    return (
                      <Box key={`sample-order-${index}`}>
                        <SampleOrderCard
                          order={order}
                          setOrderId={setOrderId}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {ORDER_OPTIONS.map((option, index) => (
              <MenuItem
                key={option}
                disabled={index === selectedIndex}
                selected={option === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
          <SampleOrderModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            orderStatusId={orderStatusId}
            id={orderId}
          />
        </Box>
      )}
    </LocalizationProvider>
  );
};

export default SampleOrdersTab;
