import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const matterboardCreate = createAsyncThunk(
  "matterBoard/create",
  async (payload, thunkAPI) => {
    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/matterBoard/`,
        payload
      );
      return { data, status };
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const matterboardImageUpload = createAsyncThunk(
  "image/auth",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/image/auth`
      );
      //   if (data) {
      //     let params = {
      //       file: "https://s3.amazonaws.com/images.seroundtable.com/google-click-1530532593.jpg",
      //       expire: data?.expire,
      //       signature: data?.signature,
      //       token: data?.token,
      //       fileName: "text.svg",
      //       publicKey: "public_MHSO7cNWT6A6ZG/Yk/YoY+1rW9M=",
      //       isPrivateFile: false,
      //     };
      //     try {
      //       let imageInfo = axios({
      //         method: "post",
      //         url: `https://upload.imagekit.io/api/v1/files/upload`,
      //         allowedHeaders: ["Content-Type"],
      //         data: params,
      //       })
      //         .then((response) => response)
      //         .catch((err) => err.response);
      //     } catch (err) {
      //       const { data, status } = err.response;
      //       return thunkAPI.rejectWithValue({ data, status });
      //     }
      //   }
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);
export const imageCreateMatterboard = createAsyncThunk(
  "matterboard/image/create",
  async (payload, thunkAPI) => {
    const { label, ...values } = payload;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/image/`,
        values
      );
      return { ...data, label };
    } catch (err) {
      const data = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const matterboardList = createAsyncThunk(
  "matterboard/list",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/matterBoard`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const matterboardDeleteByID = createAsyncThunk(
  "matterboard/deleteByID",
  async (id, thunkAPI) => {
    try {
      const data = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/matterBoard/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const matterboardDetailsByID = createAsyncThunk(
  "matterboard/details",
  async (id, thunkAPI) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/matterBoard/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);
export const editMatterBoard = createAsyncThunk(
  "matterboard/edit",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/matterBoard`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const similarCategoryProducts = createAsyncThunk(
  "matterboard/similarCategoryProducts",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/matterBoard/similarCategoryProducts`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const selectMatterboardCreate = (state) => state.matterboardCreate;
export const selectmatterboardImageUpload = (state) =>
  state.matterboardImageUpload;
export const selectCreateImage = (state) => state.imageCreateMatterboard;
export const selectMatterboardList = (state) => state.matterboardList;
export const selectMatterboardDeleteByID = (state) =>
  state.matterboardDeleteByID;
export const selectMatterboardDetailsByID = (state) =>
  state.matterboardDetailsByID;
export const selectEditMatterboard = (state) => state.editMatterBoard;
export const selectSimilarCategoryProducts = (state) =>
  state.similarCategoryProducts;
