import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  imageCreate,
  imageCreateReset,
  selectImageCreate,
} from "../image/imageAsyncThunk";
import { IKContext, IKUpload } from "imagekitio-react";
import ImageKit from "imagekit";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function ImageCreate({ svg }) {
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleSubmit = () => {
    const imagekit = new ImageKit({
      publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY,
      urlEndpoint: process.env.REACT_APP_IMAGEKIT_URL,
      privateKey: "private_/qJqcsmQLSw4Gt0+1Tkq0mTm8AE=",
    });
    imagekit
      .upload({
        file: `data:image/svg+xml;base64,PHN2ZyBpZD0iY29sbGFnZSIgd2lkdGg9IjUwMCIgaGVpZ2h0PSI3MDAiIHZpZXdCb3g9IjAgMCAxMDE0IDEyNzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJpbWFnMSIgdGl0bGU9ImRkZGQiIGQ9Ik02OCAxMjcxLjVWMTA3Ny41SDE3NFY5NDIuNUgyNjVWMTE0MUg4NTFWMTI3MS41SDY4WiIgZmlsbD0iIzU1M0EyNSIvPgo8cGF0aCBpZD0iaW1hZzIiIHRpdGxlPSJkZGRkIiBkPSJNNjM4IDU4Ni41SDYxMFY3MjMuNUg2MzhWNjI0LjVIODQ2LjVWNjAySDYzOFY1ODYuNVoiIGZpbGw9IiM1NTNBMjUiLz4KPHBhdGggaWQ9ImltYWczIiB0aXRsZT0iZGRkZCIgZD0iTTEwMTQgNzI3SDk2Ni41VjkzOS41SDYzOFY3MjdINjExVjkzOS41SDI2NC41VjExNDAuNUgxMDE0VjcyN1oiIGZpbGw9IiMxQzQwMkYiIGZpbGwtb3BhY2l0eT0iMC45OSIvPgo8cGF0aCBpZD0iaW1hZzQiIHRpdGxlPSJkZGRkIiBkPSJNNjcgMzkyLjVWNjMzLjVIMTcxVjkzOEg2MDkuNVYzOTIuNUg2N1oiIGZpbGw9IiM3MUM0RkYiLz4KPHBhdGggaWQ9ImltYWc1IiB0aXRsZT0iZGRkZCIgZD0iTTk2OCA2MjQuNUg2MzkuNVY5MzYuNUg5NjhWNjI0LjVaIiBmaWxsPSIjMTg2NDQxIiBmaWxsLW9wYWNpdHk9IjAuOTkiLz4KPHBhdGggaWQ9ImltYWc2IiB0aXRsZT0iZGRkZCIgZD0iTTk2MiAyODRWNjAwLjVINjQyVjI4NEg5NjJaIiBmaWxsPSIjREVCN0IzIi8+CjxwYXRoIGlkPSJpbWFnNyIgdGl0bGU9ImRkZGQiIGQ9Ik0zNTguNSAzOTBWMzI5LjVIMFY3NzkuNUg2Mi41VjM5MEgzNTguNVoiIGZpbGw9IiMyNzUyMzgiLz4KPHBhdGggaWQ9ImltYWc4IiB0aXRsZT0iZGRkZCIgZD0iTTkyOSAwVjI4Mi41SDY0MlY1NjYuNUg2MTIuNVYzODkuNUgzNTguNVYwSDkyOVoiIGZpbGw9IiNEOUQ5RDkiLz4KPHBhdGggaWQ9ImltYWc5IiB0aXRsZT0iZGRkZCIgZD0iTTE3MS41IDYzMy41SDY1LjVWMTA3NS41SDE3MS41VjYzMy41WiIgZmlsbD0iIzhGNTgyRCIvPgo8L3N2Zz4=`,
        fileName: "image.svg",
      })
      .then((response) => {
        setImageUrl(response.url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleSubmit();
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <button type="submit">Upload Image</button>
      {imageUrl && <img src={imageUrl} alt="Uploaded image" />}
    </form>
  );
}
ImageCreate.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
};

export default ImageCreate;
