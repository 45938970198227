import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userUpdateFederated } from "./userAsyncThunk";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";

import Container from "@mui/material/Container";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlackButton } from "../../components/CustomButtons";

export default function UserFederatedInfo() {
  const dispatch = useDispatch();
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo, isLoggedOut } = authUserInfoStore;
  const history = useHistory();
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    companyType: "",
    jobTitle: "",
    companyLocation: "",
    companyWebsite: "",
  });
  const [showHint, setShowHint] = useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.jobTitle) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyWebsite) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(userUpdateFederated(values));
  };

  useEffect(() => {
    if (isLoggedOut) {
      return history.push("/");
    }
    if (Boolean(userInfo.id) === false) {
      return history.push("/login");
    }
    userInfo.telephone
      ? setValues({ ...values, ...userInfo })
      : setValues({ ...values, ...userInfo, telephone: "" });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isLoggedOut]);

  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        aria-labelledby="form-dialog-title"
      >
        <StyledDialogTitle id="form-dialog-title">
          1 Last Step! Let Us Know You More
        </StyledDialogTitle>
        <DialogContent>
          <DialogContentText>Account Details</DialogContentText>
          <StyledTextField
            autoFocus
            required
            disabled={Boolean(userInfo.firstName)}
            error={isUserActionMade && Boolean(values.firstName) === false}
            value={values.firstName}
            onChange={handleValues("firstName")}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            placeholder="First Name"
            fullWidth
          />
          <StyledTextField
            required
            disabled={Boolean(userInfo.lastName)}
            error={isUserActionMade && Boolean(values.lastName) === false}
            value={values.lastName}
            onChange={handleValues("lastName")}
            variant="outlined"
            margin="dense"
            size="small"
            id="lastname"
            placeholder="Last Name"
            fullWidth
          />
          <StyledTextField
            required
            disabled={Boolean(userInfo.email)}
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            value={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            size="small"
            id="email"
            placeholder="Email Address"
            type="email"
            fullWidth
            autoComplete="off"
          />
          <StyledTextField
            value={values.telephone}
            onChange={handleValues("telephone")}
            variant="outlined"
            margin="dense"
            size="small"
            id="telephone"
            placeholder="Telephone number"
            fullWidth
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Work Details</DialogContentText>
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.companyType) === false}
            value={values.companyType}
            onChange={handleValues("companyType")}
            variant="outlined"
            margin="dense"
            size="small"
            id="company-type"
            placeholder="Company Type"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.jobTitle) === false}
            value={values.jobTitle}
            onChange={handleValues("jobTitle")}
            variant="outlined"
            margin="dense"
            size="small"
            id="job-title"
            placeholder="Job Title"
            fullWidth
          />
          <StyledTextField
            required
            error={
              isUserActionMade && Boolean(values.companyLocation) === false
            }
            value={values.companyLocation}
            onChange={handleValues("companyLocation")}
            variant="outlined"
            margin="dense"
            size="small"
            id="company-location"
            placeholder="Company Location"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.companyWebsite) === false}
            value={values.companyWebsite}
            onChange={handleValues("companyWebsite")}
            variant="outlined"
            margin="dense"
            size="small"
            id="company-website"
            placeholder="Company Website"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "70%",
              margin: (theme) => theme.spacing(1, 1, 3, 1),
            }}
            onClick={handleSubmit}
          >
            Update Account
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 1, 1, 1),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Container>
    </React.Fragment>
  );
}
