import React, { useState, useEffect } from "react";
import matterboardColumns from "../../components/types/matterBoard";
import MatterboardCreate from "./CreateMatterBoard";
import {
  matterboardList,
  selectMatterboardList,
  selectMatterboardCreate,
} from "./matterboardAsyncThunk";
// import BrandEdit from "./BrandEdit";
// import BrandPreview from "./BrandPreview";
import { useSelector, useDispatch } from "react-redux";
import {
  brandList,
  selectBrandList,
  selectBrandCreate,
  selectBrandEdit,
} from "../brand/brandAsyncThunk";

import { BlueButton, BlackButton } from "../../components/CustomButtons";
import MatterBoardDelete from "./matterboardDelete";
import EditMatterboard from "./editMatterboard";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export const loadingData = [...Array(10)].map((id, key) => ({ id: key }));

export default function BrandManageGrid() {
  const dispatch = useDispatch();
  const [matterBoardFullList, setMatterBoardFullList] = useState([]);
  const [isEditScreen, setIsEditScreen] = useState(false);
  const [isCreateScreen, setIscreateScreen] = useState(false);
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
    },
  });
  const matterBoardListStore = useSelector(selectMatterboardList);
  const { payload } = matterBoardListStore;

  const handleToggle = () => {
    setIscreateScreen(!isCreateScreen);
    setIsEditScreen(false);
  };
  const toggleEdit = () => {
    setIsEditScreen(!isEditScreen);
  };
  useEffect(() => {
    setMatterBoardFullList(payload);
  }, [payload]);
  useEffect(() => {
    dispatch(matterboardList());
  }, []);

  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = matterBoardFullList.findIndex((x) => x.id === selected[0]);
      setEditButtonProps({
        isSelected: true,
        data: {
          id: matterBoardFullList[index]?.id,
          name: matterBoardFullList[index]?.title,
        },
      });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
        },
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 1, bgcolor: "background.paper" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
        }}
      >
        {!isCreateScreen ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography>Matterboard Management</Typography>
              {/* <BlackButton
                disabled={editButtonProps.data?.name ? false : true}
                onClick={toggleEdit}
                color="primary"
              >
                Edit
              </BlackButton> */}
              <BlackButton
                variant="contained"
                color="primary"
                disabled={editButtonProps.data?.name ? false : true}
                onClick={toggleEdit}
              >
                Edit
              </BlackButton>

              {!isEditScreen && matterBoardFullList[0] && (
                <MatterBoardDelete {...editButtonProps} />
              )}
            </Box>
            {isEditScreen ? (
              <BlueButton onClick={toggleEdit}>Back</BlueButton>
            ) : (
              <BlueButton onClick={handleToggle}>Create Matterboard</BlueButton>
            )}
          </>
        ) : (
          <>
            <Typography>Matterboard Management</Typography>
            <BlueButton onClick={handleToggle}>Back</BlueButton>
          </>
        )}
      </Box>
      <Box>
        {isEditScreen ? (
          <EditMatterboard
            handleToggle={toggleEdit}
            details={editButtonProps}
          />
        ) : (
          <>
            {!isCreateScreen ? (
              <Box sx={{ height: 400, width: "100%" }}>
                <Box sx={{ display: "flex", height: "100%" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <DataGrid
                      rows={matterBoardFullList}
                      columns={matterboardColumns}
                      pageSize={25}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      onSelectionModelChange={handleSelection}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <MatterboardCreate handleToggle={handleToggle} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
