import React, { useMemo, useState } from "react";

import { Box, Grid, SwipeableDrawer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { StyledTextField } from "./CustomComponents";

const StyledImg = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
}));

export const ImgVariationImage = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const VariationSideModal = ({
  anchor,
  open,
  onOpen,
  onClose,
  variations,
  setSelectedVariation,
  handleVariationSelect,
}) => {
  const [searchText, setSearchText] = useState("");

  const filteredVariations = useMemo(() => {
    if (searchText) {
      return variations?.filter((item) =>
        item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
    }
    return variations;
  }, [searchText, variations]);

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box sx={{ py: 2, px: 3, width: { xs: 300, sm: 560 } }}>
        <Box
          sx={{
            display: "flex",
            marginTop: 3,
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }} onClick={onClose}>
            <ArrowBackIosOutlinedIcon
              fontSize="medium"
              color="#181818"
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: 12, lg: 20 },
              fontWeight: 600,
              marginLeft: 1,
            }}
          >
            Select Variations
          </Typography>
        </Box>
        <StyledTextField
          sx={{ my: 2.5, width: "100%" }}
          value={searchText}
          label="Search Variations"
          onChange={(e) => setSearchText(e.target.value)}
          placeholder=""
        />
        <Grid container spacing={1.5}>
          {filteredVariations?.map((item, index) => {
            return (
              <React.Fragment key={`finishing-${index}`}>
                <Grid item xs={4} sm={3}>
                  <Box
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedVariation(item);
                      handleVariationSelect(index);
                      onClose();
                    }}
                  >
                    <Box
                      sx={{
                        transition: "box-shadow 50ms ease-in-out",
                        borderRadius: "8px",
                        Width: "100%",
                        // maxHeight: (theme) => theme.spacing("118.5px"),
                        // paddingTop: "100%",
                        // position: "relative",
                        "&:hover": {
                          boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.05)",
                          transitionDelay: "100ms",
                        },
                      }}
                    >
                      <ImgVariationImage
                        alt={item.name}
                        src={item.thumbnailURL}
                        sx={{
                          border: 1.5,
                          borderColor: "white",
                          borderRadius: "8px",
                          transition: "border 50ms ease-in-out",
                          "&:hover": {
                            border: 1.5,
                            borderColor: "rgba(0, 0, 0, 0.25)",

                            transitionDelay: "100ms",
                          },
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: 15, fontWeight: 300, mt: 1 }}>
                      {item.name}
                    </Typography>
                  </Box>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

export default VariationSideModal;
