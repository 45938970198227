import React, { useEffect } from "react";
import {
  regVerifySubscription,
  selectRegVerifySubscription,
} from "./regAsyncThunk";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function RegConfirmSubscription() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const regSignup = useSelector(selectRegVerifySubscription);
  const { isLoading, isSuccess, isFail } = regSignup;

  useEffect(() => {
    if (token) dispatch(regVerifySubscription({ token }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <Container maxWidth="sm">
        <Box sx={{ width: "100%", maxWidth: 500, my: { xs: 20, md: 15 } }}>
          <Typography variant="h5" gutterBottom>
            Subscription Confirmation
          </Typography>
          {isLoading && (
            <React.Fragment>
              <Typography variant="h2" gutterBottom>
                Loading...
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                We are currently processing your request
              </Typography>
            </React.Fragment>
          )}
          {isSuccess && (
            <React.Fragment>
              <Typography variant="h2" gutterBottom>
                Congratulations
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                You will receive our subscription from now on
              </Typography>
            </React.Fragment>
          )}
          {isFail && (
            <React.Fragment>
              <Typography variant="h2" gutterBottom>
                Oops...
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                We are unable to process your request currently
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Please try again later
              </Typography>
            </React.Fragment>
          )}
          <Typography variant="body1" gutterBottom>
            <Link href="/" color="inherit" underline="hover">
              Back to Main Page
            </Link>
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}
