import React, { useState, useEffect } from "react";
import productVariationColumns from "../../components/types/productVariation";
import ProductVariationEdit from "./ProductVariationEdit";
import ProductVariationDelete from "./ProductVariationDelete";
import { useSelector, useDispatch } from "react-redux";
import {
  productVariationList,
  selectProductVariationCreate,
  selectProductVariationList,
  selectProductVariationEdit,
  selectProductVariationDelete,
} from "./productAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProductVariationManageGrid() {
  const dispatch = useDispatch();
  const [productVariationFullList, setProductVariationFullList] = useState([]);
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      product: "",
      product_id: 0,
      name: "",
      imageURL: "",
      thumbnailURL: "",
      isDisabled: false,
      created_at: new Date(),
      updated_at: new Date(),
    },
  });
  const productVariationListStore = useSelector(selectProductVariationList);
  const { payload } = productVariationListStore;
  const productVariationCreateStore = useSelector(selectProductVariationCreate);
  const { isSuccess: productVariationCreateSuccess } =
    productVariationCreateStore;
  const productVariationEditStore = useSelector(selectProductVariationEdit);
  const { isSuccess: productVariationEditSuccess } = productVariationEditStore;
  const productVariationDeleteStore = useSelector(selectProductVariationDelete);
  const { isSuccess: productVariationDeleteSuccess } =
    productVariationDeleteStore;

  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = productVariationFullList.findIndex(
        (x) => x.id === selected[0]
      );
      setEditButtonProps({
        isSelected: true,
        data: { ...productVariationFullList[index] },
      });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          product: "",
          product_id: 0,
          name: "",
          imageURL: "",
          thumbnailURL: "",
          isDisabled: false,
          created_at: new Date(),
          updated_at: new Date(),
        },
      });
    }
  };

  useEffect(() => {
    dispatch(productVariationList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productVariationCreateSuccess,
    productVariationDeleteSuccess,
    productVariationEditSuccess,
  ]);

  useEffect(() => {
    let productVariation = [...payload];
    let list = [];
    if (productVariation.length > 0) {
      for (let i = 0; i < productVariation.length; i++) {
        let item = {
          ...productVariation[i],
          created_at: new Date(productVariation[i].created_at),
          updated_at: new Date(productVariation[i].updated_at),
        };
        list.push(item);
      }
      setProductVariationFullList(list);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Typography>Product Variation Management</Typography>
      <ProductVariationEdit {...editButtonProps} />
      <ProductVariationDelete {...editButtonProps} />
      {productVariationFullList[0] && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={productVariationFullList}
                columns={productVariationColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
