import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  productGetByID,
  productParams,
  selectProductGet,
  selectProductParams,
} from "../features/product/productAsyncThunk";

import { bodyFont } from "../components/styles/materialUIStyles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ProductGet from "../features/product/ProductGet";
import BrandGet from "../features/brand/BrandGet";
import CategoryRelevant from "../features/category/CategoryRelevant";
import BrandRelevant from "../features/brand/BrandRelevant";
import Error404 from "./Error404";
import ProductNotFound from "../components/ProductNotFound";
import { favoriteCreateReset } from "../features/favorite/favoriteAsyncThunk";

export default function ProductPage({ handleProductInfo }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [variationId, setVariationId] = useState(null);
  const { isLoading, isSuccess, failCode, brand_id, category_id, name } =
    useSelector(selectProductParams);
  const productGetStore = useSelector(selectProductGet);
  const { payload } = productGetStore;
  const url = window.location.href;

  useEffect(() => {
    const queryString = url?.split('?')[1];
    const queryParams = {};
  
    if (queryString) {
      const params = queryString?.split('&');
  
      params.forEach(param => {
        const [key, value] = param?.split('=');
        queryParams[key] = decodeURIComponent(value);
      });
    }
  
    const varValue = queryParams['var'];
    setVariationId(+varValue?.split('/')[0])
  }, [url])

  const { pathname, search } = location;
  const searchTerm = "-";
  const params = pathname
    .toLowerCase()
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "product")
    .map((x) => x.split(searchTerm))
    .flat();
  const expectedID = Number(params[params.length - 1]);
  const expectedName = params.slice(0, params.length - 1).join(" ");
  const args = search
    .slice(1)
    .toLowerCase()
    .split("&")
    .filter((x) => x.includes("var="))
    .map((x) => x.slice(x.indexOf("=") + 1));
  const expectedVariationID = args.length > 0 ? Number(args[0]) : undefined;

  useEffect(() => {
    if (isNaN(expectedID) === false) dispatch(productParams(expectedID));
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectedID]);

  useEffect(() => {
    if (expectedID) {
      dispatch(productGetByID(expectedID));
    }
    return () => {
      dispatch(favoriteCreateReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectedID]);

  useEffect(() => {
    if (payload) {
      handleProductInfo(payload.category_object);
    }
    // Fetch data and update the state in App.js

    // Clean up the effect by setting the fetched data to null
    // return handleProductInfo(null);
  }, [handleProductInfo, payload]);

  return (
    <React.Fragment>
      {isNaN(expectedID) === true && <ProductNotFound />}
      {isLoading && (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: "common.white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {isSuccess &&
        name.toLowerCase().replaceAll("-", " ") === expectedName && (
          <React.Fragment>
            <ProductGet id={expectedID} variationID={expectedVariationID || variationId} />
            <BrandGet id={brand_id} />
            <BrandRelevant product_id={expectedID} brand_id={brand_id} />
            <CategoryRelevant
              product_id={expectedID}
              category_id={category_id}
              categoryURL={`/search/category/${payload?.category_object?.fullname}`}
            />
          </React.Fragment>
        )}
      {isSuccess &&
        name.toLowerCase().replaceAll("-", " ") !== expectedName && (
          <ProductNotFound />
        )}
      {failCode === 406 && <Error404 />}
    </React.Fragment>
  );
}
