import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  productPropGet,
  selectProductPropGet,
} from "../features/productProp/productPropAsyncThunk";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import LinearProgress from "@mui/material/LinearProgress";
import SearchRefiner from "../features/search/SearchRefiner";
import SearchGrid from "../components/SearchGrid";
import MoreCriteriaPlease from "../components/MoreCriteriaPlease";
import ImageSearch from "../features/image/ImageSearch";
import { landing_page_background_colour } from "../components/styles/materialUIStyles";

export default function SearchPage() {
  const dispatch = useDispatch();
  const productPropGetStore = useSelector(selectProductPropGet);
  const { payload, isSuccess } = productPropGetStore;
  const [criteria, setCriteria] = useState({});
  const [searchRefinerHeight, setSearchRefinerHeight] = useState("500px");
  const location = useLocation();

  const { pathname } = location;
  const params = pathname
    .toLowerCase()
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "search");
  const expectedMethod = params[0];
  const expectedKeyword = params.slice(1).join(" ");

  useEffect(() => {
    dispatch(productPropGet());
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    payload.length > 0
      ? setCriteria(Object.fromEntries(payload.map((x) => [x.key, x.values])))
      : setCriteria({});
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <React.Fragment>
      {expectedMethod === "image" ? (
        <ImageSearch />
      ) : Boolean(expectedMethod) === false ||
        Boolean(expectedKeyword) === false ? (
        <MoreCriteriaPlease />
      ) : (
        <div>
          <CssBaseline enableColorScheme />
          {/* <Container maxWidth={false} sx={{ px: { lg: "48px !important" } }}> */}
          <Box
            sx={{
              display: "flex",
              backgroundColor: landing_page_background_colour,
              mt: { xs: "56px", sm: "64px" },
            }}
          >
            <Box
              sx={{
                width: { xs: "0px", md: "20%" },
                maxWidth: "300px",
                minWidth: { xs: "0px", md: "220px" },
                paddingLeft: { xs: "0px", md: "24px" },
                paddingRight: { xs: "0px", md: "24px" },
                paddingTop: "12px",
                // backgroundColor: "white",
                height: "100%",
                // display: { xs: "none", md: "block" },
              }}
            >
              {
                // Object.keys(criteria).length > 0 ? (
                isSuccess && Object.keys(criteria).length > 0 ? (
                  <Box
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <SearchRefiner criteria={criteria} />
                  </Box>
                ) : (
                  <LinearProgress sx={{ my: 20 }} />
                )
              }
            </Box>
            <Box
              sx={{
                width: "100%",
                paddingRight: { xs: "16px", sm: "32px", md: "48px" },
                paddingLeft: { xs: "16px", sm: "32px", md: "24px" },
                paddingTop: "12px",
              }}
            >
              <SearchGrid setSearchRefinerHeight={setSearchRefinerHeight} />
            </Box>
          </Box>
          {/* <Box></Box> */}
          {/* </Container> */}
          {/* <SearchRefiner criteria={criteria} /> */}
        </div>
      )}
    </React.Fragment>
  );
}
