import React from "react";

const MatterBaseLogo3 = (props) => {
  const { fill = "#000000", ...otherProps } = props;
  return (
    <svg
      {...otherProps}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 675"
    >
      <g fill={fill}>
        <path
          d="M 256 0
          C 256 0 710 0 710 0
          C 710 0 686.37 33 686.37 33
          C 686.37 33 651.88 80 651.88 80
          C 651.88 80 545.12 226 545.12 226
          C 545.12 226 484 310 484 310
          C 478.09 306.95 467.15 290.2 462.71 284
          C 462.71 284 421 227 421 227
          C 421 227 306.85 71 306.85 71
          C 306.85 71 274.63 27 274.63 27
          C 270.1 20.94 258.37 6.09 256 0
          Z
          M 0 159
          C 0 159 247 159 247 159
          C 247 159 303 159 303 159
          C 306.48 159 318.99 158.08 320.98 160.6
          C 322.23 162.19 322 166.01 322 168
          C 322 168 322 481 322 481
          C 322 481 0 481 0 481
          C 0 481 0 159 0 159
          Z
          M 505 353.42
          C 522.24 351.32 548.46 355.28 565 360.67
          C 587.95 368.15 607.02 378.93 625 395.17
          C 689.77 453.66 693.65 554.51 637.57 620
          C 625.36 634.26 608.62 647.44 592 656.22
          C 564.51 670.73 539.78 675.36 509 675
          C 497.38 674.86 481.23 671.63 470 668.42
          C 406.23 650.23 360.72 592.76 355.91 527
          C 355.91 527 355 517 355 517
          C 354.76 495.96 359.14 471.38 367.43 452
          C 385.16 410.52 417.49 378.64 460 363.05
          C 476.6 356.97 487.97 355.84 505 353.42
          Z"
          stroke="red"
          strokeWidth={1}
        />
      </g>
    </svg>
  );
};

export default MatterBaseLogo3;
