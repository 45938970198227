import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  categoryList,
  selectCategoryList,
} from "../category/categoryAsyncThunk";
import {
  productSearch,
  productVariationSearch,
} from "../product/productAsyncThunk";
import {
  catalogListBrand,
  selectCatalogListBrand,
} from "../catalog/catalogAsyncThunk";
import { styled } from "@mui/material/styles";
import MuiAccordion, { accordionClasses } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import MuiCheckbox, { checkboxClasses } from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import MuiFormControlLabel, {
  formControlLabelClasses,
} from "@mui/material/FormControlLabel";
import MuiFormControl from "@mui/material/FormControl";
import MuiFormLabel, { formLabelClasses } from "@mui/material/FormLabel";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircleIcon from "@mui/icons-material/Circle";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import MuiPaper from "@mui/material/Paper";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import MuiListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Dialog, StyledTextField } from "../../components/CustomComponents";
import {
  TextButton,
  BlackButton,
  HighlightButton,
  HighlightFab,
} from "../../components/CustomButtons";
import { landing_page_background_colour } from "../../components/styles/materialUIStyles";
import AutoCompleteOption from "../../components/AutoCompleteOption";
import FilterPreferenceGroup from "../../components/FilterPreferenceGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// sticky element
function useWindowScroll() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollPosition;
}
//

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionRight = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const BoxConfirm = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: `25%`,
  left: `25%`,
  width: `50%`,
  zIndex: theme.zIndex.appBar - 1,
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "70vh",
}));

const FormControl = styled(MuiFormControl)({
  width: "100%",
});

const FormLabel = styled(MuiFormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "13px",
  fontWeight: 600,
  [`&.${formLabelClasses["root"]}.${formLabelClasses["focused"]}`]: {
    color: theme.palette.text.primary,
  },
}));

const FormControlLabel = styled(MuiFormControlLabel)({
  justifyContent: "space-between",
  marginLeft: 4,
  marginRight: -4,
  [`& .${formControlLabelClasses["label"]}`]: {
    display: "inline-grid",
    justifyContent: "flex-start",
    width: "75%",
  },
});

const Tooltip = styled(MuiTooltip)({
  [`& .${tooltipClasses["tooltip"]}`]: {
    textTransform: "capitalize",
  },
});

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${accordionClasses["root"]}`]: {
    borderTop: `1px solid ${theme.palette.primary.secondary}`,
    backgroundColor: "transparent",
    width: "100%",
    boxShadow: "none",
    "&:first-of-type": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    "&:last-of-type": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  [`& .${accordionClasses["root"]}`]: {
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    [`&.${accordionClasses["expanded"]}`]: {
      margin: "auto",
    },
  },
  [`& .${accordionClasses["expanded"]}`]: {
    // width: "100%",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)({
  [`& .${accordionSummaryClasses["root"]}`]: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,

    "&$expanded": {
      minHeight: 56,
    },
  },
  [`& .${accordionSummaryClasses["content"]}`]: {
    textTransform: "uppercase",
    "&$expanded": {
      // margin: "12px 0",
    },
  },
  [`& .${accordionSummaryClasses["expanded"]}`]: {},
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  // textTransform: "capitalize",
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between" }}
      {...other}
    >
      <Typography variant="h6" component="span" sx={{ alignSelf: "center" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{ color: "grey.500" }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
}));

const CapitalStyledTextField = styled(StyledTextField)({
  textTransform: "capitalize",
  padding: "50px 50px",
});

const ListItem = styled(MuiListItem)({
  flexDirection: "column",
  alignItems: "baseline",
  "& a": {
    alignSelf: "center",
  },
});

const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  [`&.${checkboxClasses["root"]}.${checkboxClasses["checked"]}`]: {
    color: "theme.palette.text.primary",
  },
}));

function SearchRefiner({ criteria }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const catalogListBrandStore = useSelector(selectCatalogListBrand);
  const {
    isLoading: brandListLoading,
    isSuccess: brandListSuccess,
    payload: brandArray,
  } = catalogListBrandStore;
  const categoryListStore = useSelector(selectCategoryList);
  const { payload: categoryArray, isSuccess: categoryListSuccess } =
    categoryListStore;
  const brandMap = useRef(new Map());
  const categoryMap = useRef(new Map());
  const [legend, setLegend] = useState([]);
  const [choice, setChoice] = useState([]);
  const [preference, setPreference] = useState([]);
  const [isExpanded, setIsExpanded] = useState([]);
  const [autoCompleteKey, setAutoCompleteKey] = useState([]);
  const [query, setQuery] = useState({});
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isShowVariation, setIsShowVariation] = useState(true);
  const [isReadyToShip, setIsReadyToShip] = useState(false);
  const [isBrandExpanded, setIsBrandExpanded] = useState(true);
  const [brandKey, setBrandKey] = useState(
    `Brand${Math.random().toString().substring(15)}`
  );
  const [showMore, setShowMore] = useState(false);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isProductFetched, setIsProductFetched] = useState(false);

  const displayLimit = 6;

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  //sticky element
  // const scrollPosition = useWindowScroll();
  // const windowHeight = window.innerHeight;
  // const documentHeight = document.documentElement.scrollHeight;
  // const maxBottomDistance = 600; // Replace with the desired distance from the bottom

  // const scrollableHeight = documentHeight - windowHeight;
  // const distanceFromBottom = scrollableHeight - scrollPosition;
  // const bottom = Math.max(0, maxBottomDistance - distanceFromBottom);
  //

  const [brand, setBrand] = useState([]);
  const choiceThreshold = 16;

  const { pathname, search } = location;
  const params = pathname
    .toLowerCase()
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "search");
  const expectedMethod = params[0];
  const expectedKeyword = params.slice(1).join(" ");

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMobileClickOpen = () => {
    setMobileOpen(true);
  };
  const handleMobileClose = () => {
    setMobileOpen(false);
  };

  const handleBrand = (event, value) => {
    // handleClickOpen();
    setBrand([...value]);
    if (!isMdDown) {
      handleSubmit(event, value);
    }
  };

  const handleChange = (index) => (event) => {
    // handleClickOpen();
    const newPreference = [...preference];
    newPreference[index] = {
      ...preference[index],
      [event.target.name]: event.target.checked,
    };
    setPreference(newPreference);
    const newQuery = {};
    const queryValues = newPreference.map((preferenceObject) => {
      const filteredItem = Object.entries(preferenceObject).filter(
        ([item, isSelected]) => isSelected
      );
      return filteredItem.map((array) => array[0]);
    });
    for (let i = 0; i < queryValues.length; i++) {
      if (queryValues[i].length > 0) newQuery[legend[i]] = [...queryValues[i]];
    }
    for (let i = 0; i < legend.length; i++) {
      if (choice[i].length > choiceThreshold)
        if (Array.isArray(query[legend[i]]))
          newQuery[legend[i]] = [...query[legend[i]]];
    }
    setQuery(newQuery);
    if (!isMdDown) {
      handleSubmit(event, [], newQuery);
    }
  };

  const handleAutocomplete = (event, { key, value }) => {
    // handleClickOpen();
    const optionSelected = { ...query };
    if (Array.isArray(value)) {
      value.length > 0
        ? typeof value[0] === "string"
          ? (optionSelected[key] = [...value])
          : (optionSelected[key] = [...value.map((x) => x.value)])
        : delete optionSelected[key];
    }
    setQuery(optionSelected);
    handleSubmit(event, [], optionSelected);
  };

  const handleExpand = (panel) => (event) => {
    // let newIsExpanded = [...isExpanded];
    let newIsExpanded = isExpanded.map((_, i) => {
      return panel === i ? isExpanded[i] : false;
    });
    newIsExpanded[panel] = !newIsExpanded[panel];
    setIsExpanded(newIsExpanded);
  };

  const handleClear = (e) => {
    const key = [...legend];
    const option = Object.values(criteria);
    const manipulatedKey = key.map((x) => {
      return `${x}${Math.random().toString().substring(15)}`;
    });
    const manipulatedPreference = option.map((x) => {
      const obj = {};
      for (let i = 0; i < x.length; i++) {
        obj[x[i].value] = false;
      }
      return obj;
    });
    setIsShowVariation(true);
    setIsReadyToShip(false);
    sessionStorage.setItem("isShowVariation", true);
    sessionStorage.setItem("isReadyToShip", false);
    setBrandKey(`Brand${Math.random().toString().substring(15)}`);
    setBrand([]);
    setAutoCompleteKey(manipulatedKey);
    setChoice(option);
    setPreference(manipulatedPreference);
    setQuery({});
    handleSubmit(e, [], {});
  };

  const handleSubmit = (event, brand, query) => {
    sessionStorage.setItem("isShowVariation", isShowVariation);
    sessionStorage.setItem("isReadyToShip", isReadyToShip);
    const manipulatedQuery = { ...query, isReadyToShip };
    if (brand.length > 0) {
      manipulatedQuery.brand_id = brand.map((x) => x.id);
    }
    switch (expectedMethod) {
      case "keyword":
        const key_word = expectedKeyword;
        isShowVariation
          ? dispatch(productVariationSearch({ ...manipulatedQuery, key_word }))
          : dispatch(productSearch({ ...manipulatedQuery, key_word }));
        break;
      case "brand":
        const brand_id = [brandMap.current.get(expectedKeyword)];
        isShowVariation
          ? dispatch(productVariationSearch({ ...manipulatedQuery, brand_id }))
          : dispatch(productSearch({ ...manipulatedQuery, brand_id }));
        break;
      default: // "category"
        const category_id = categoryMap.current.get(expectedKeyword);
        isShowVariation
          ? dispatch(
              productVariationSearch({ ...manipulatedQuery, category_id })
            )
          : dispatch(productSearch({ ...manipulatedQuery, category_id }));
        break;
    }
    setOpen(false);
    setMobileOpen(false);
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView();
    }
  };

  useEffect(() => {
    // dispatch(categoryList());
    // dispatch(catalogListBrand());
    sessionStorage.setItem("isShowVariation", true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brandArray[0]) {
      brandMap.current = new Map();
      for (let i = 0; i < brandArray.length; i++) {
        brandMap.current.set(
          brandArray[i].name.toLowerCase(),
          brandArray[i].id
        );
      }
      const queryString = new URLSearchParams(search);
      setBrand(
        Number(queryString.get("brand")) > 0
          ? brandArray.filter((x) => x.id === Number(queryString.get("brand")))
          : []
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandArray]);

  useEffect(() => {
    if (categoryArray[0]) {
      categoryMap.current = new Map();
      for (let i = 0; i < categoryArray.length; i++) {
        categoryMap.current.set(
          categoryArray[i].fullname.toLowerCase(),
          categoryArray[i].id
        );
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryArray]);

  const fetchProducts = () => {
    const isReadyToShip =
      sessionStorage.getItem("isReadyToShip") === "true" ? true : false;
    const manipulatedQuery = { isReadyToShip };

    if (brand.length > 0) {
      manipulatedQuery.brand_id = brand.map((x) => x.id);
    }

    // if (brandListSuccess && categoryListSuccess) {
    switch (expectedMethod) {
      case "keyword":
        const key_word = expectedKeyword;
        // console.log("keyword fired");
        sessionStorage.getItem("isShowVariation") === "true"
          ? dispatch(productVariationSearch({ ...manipulatedQuery, key_word }))
          : dispatch(productSearch({ ...manipulatedQuery, key_word }));
        break;
      case "brand":
        const brand_id = [brandMap.current.get(expectedKeyword)];
        // console.log("brand fired");

        sessionStorage.getItem("isShowVariation") === "true"
          ? dispatch(productVariationSearch({ ...manipulatedQuery, brand_id }))
          : dispatch(productSearch({ ...manipulatedQuery, brand_id }));
        break;
      default: // "category"
        const category_id = categoryMap.current.get(expectedKeyword);
        // console.log("category fired");

        sessionStorage.getItem("isShowVariation") === "true"
          ? dispatch(
              productVariationSearch({ ...manipulatedQuery, category_id })
            )
          : dispatch(productSearch({ ...manipulatedQuery, category_id }));
        break;
    }
    // }
  };

  useEffect(() => {
    const key = Object.keys(criteria);
    const option = Object.values(criteria);
    const manipulatedKey = key.map((x) => {
      return `${x}${Math.random().toString().substring(15)}`;
    });
    const manipulatedPreference = option.map((x) => {
      const obj = {};
      for (let i = 0; i < x.length; i++) {
        obj[x[i].value] = false;
      }
      return obj;
    });
    // const expanded = key.map((_) => {
    //   return true;
    // });
    const expanded = key.map((_, i) => {
      return i === 0;
    });
    setIsShowVariation(
      sessionStorage.getItem("isShowVariation") === "true" ? true : false
    );
    setIsReadyToShip(
      sessionStorage.getItem("isReadyToShip") === "true" ? true : false
    );
    setBrandKey(`Brand${Math.random().toString().substring(15)}`);
    setLegend(key);
    setAutoCompleteKey(manipulatedKey);
    setChoice(option);
    setPreference(manipulatedPreference);
    setIsExpanded(expanded);
    setQuery({});
    fetchProducts();
    setIsProductFetched(true);
    // }
    // console.log("useEffect fired");
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isProductFetched && brand.length !== 0) {
      fetchProducts();
    }
    return () => {};
  }, [brand]);

  console.log(brand);
  // useEffect(() => {
  //   console.log("Location changed:", location);
  // }, [location]);

  // useEffect(() => {
  //   console.log("CategoryListSuccess changed:", categoryListSuccess);
  // }, [categoryListSuccess]);

  // useEffect(() => {
  //   console.log("BrandListSuccess changed:", brandListSuccess);
  // }, [brandListSuccess]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid black",
            backgroundColor: "transparent",
          }}
        >
          {/* <FormControl component="fieldset">
          <Accordion expanded>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  label={
                    <Tooltip
                      title="Display All Color and Variations"
                      placement="left"
                    >
                      <Typography variant="caption" component="p" noWrap>
                        Display All Color and Variations
                      </Typography>
                    </Tooltip>
                  }
                  control={
                    <Checkbox
                      checked={isShowVariation}
                      onChange={handleShowVariation}
                      name="Display All Color and Variations"
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                    />
                  }
                />
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={isReadyToShip}
                      onChange={handleReadyToShip}
                      name="Ready to ship"
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                    />
                  }
                  label={
                    <Tooltip title="Ready to ship" placement="left">
                      <Typography variant="caption" component="p" noWrap>
                        Ready to ship
                      </Typography>
                    </Tooltip>
                  }
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </FormControl> */}
          {expectedMethod !== "brand" && (
            <FormControl component="fieldset">
              <Accordion
                defaultExpanded="true"
                // expanded={isBrandExpanded}
                onChange={() => setIsBrandExpanded(!isBrandExpanded)}
              >
                <AccordionSummary
                  aria-controls="Brand"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <FormLabel component="legend">Brand</FormLabel>
                </AccordionSummary>
                <AccordionDetails>
                  <Autocomplete
                    key={brandKey}
                    multiple
                    fullWidth
                    options={brandArray}
                    loading={brandListLoading}
                    value={brand}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionDisabled={(option) => option.isDisabled}
                    disableCloseOnSelect
                    size="small"
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <span style={{ textTransform: "capitalize" }}>
                          {option.name}
                        </span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <StyledTextField {...params} placeholder="Brands" />
                    )}
                    onChange={handleBrand}
                  />
                </AccordionDetails>
              </Accordion>
            </FormControl>
          )}
          {legend[0] ? (
            legend.map((_, i) => (
              <div key={i}>
                <FormControl component="fieldset">
                  <Accordion onChange={handleExpand(i)} defaultExpanded="true">
                    <AccordionSummary
                      aria-controls={legend[i]}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <FormLabel component="legend">{legend[i]}</FormLabel>
                    </AccordionSummary>
                    <AccordionDetails>
                      {choice[i].length > choiceThreshold ? (
                        <Autocomplete
                          key={autoCompleteKey[i]}
                          multiple
                          fullWidth
                          options={choice[i]}
                          getOptionLabel={(x) => x.value || ""}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) =>
                              Boolean(option.rgb) === true ? (
                                <Chip
                                  label={option.value}
                                  sx={{ textTransform: "capitalize" }}
                                  {...getTagProps({ index })}
                                  avatar={
                                    <Avatar
                                      sx={{
                                        bgcolor: option.rgb,
                                      }}
                                    >
                                      &nbsp;
                                    </Avatar>
                                  }
                                />
                              ) : (
                                <Chip
                                  label={option.value}
                                  sx={{ textTransform: "capitalize" }}
                                  {...getTagProps({ index })}
                                />
                              )
                            )
                          }
                          disableCloseOnSelect
                          size="small"
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {Boolean(option.rgb) === true && (
                                <CircleIcon
                                  sx={{ color: option.rgb }}
                                  style={{ marginRight: 4 }}
                                />
                              )}
                              <span style={{ textTransform: "capitalize" }}>
                                {option.value}
                              </span>
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              placeholder={legend[i]}
                            />
                          )}
                          onChange={(event, value) =>
                            handleAutocomplete(event, { key: legend[i], value })
                          }
                        />
                      ) : (
                        <FilterPreferenceGroup
                          preference={preference}
                          i={i}
                          displayLimit={displayLimit}
                          choice={choice}
                          handleChange={handleChange}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </div>
            ))
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Box>
        {brand.length !== 0 || Object.keys(query).length !== 0 ? (
          <Box
            sx={{
              position: "sticky",
              bottom: "0",
              // height: "80px",
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              zIndex: 1,
              background:
                "linear-gradient(to top, rgba(245,245,245,1), rgba(245,245,245,1) 60%,rgba(245,245,245,0) 100%)",
            }}
          >
            <BlackButton
              onClick={handleClear}
              color="primary"
              sx={{
                borderRadius: 999,
                mt: 2,
                px: 3,
                mb: 4,
                py: 1,
              }}
            >
              Clear All Criteria
            </BlackButton>
          </Box>
        ) : null}

        {/* <Fade in={open} timeout={350}>
          <BoxConfirm>
            <Paper elevation={4}>
              <DialogTitle onClose={handleClose}>Confirm?</DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  Display All Color and Variations:{" "}
                  {isShowVariation ? "Yes" : "No"}
                </Typography>
                <Typography gutterBottom>
                  Ready to ship: {isReadyToShip ? "Yes" : "Doesn't Matter"}
                </Typography>
                {brand.length > 0 && (
                  <Typography gutterBottom>
                    Brand: {brand.map((x) => x.name).join(", ")}
                  </Typography>
                )}
                {Object.entries(query).length > 0 &&
                  Object.entries(query).map(([key, value]) => (
                    <Typography gutterBottom key={key}>
                      {key}: {value.join(", ")}
                    </Typography>
                  ))}
              </DialogContent>
              <DialogActions>
                <TextButton autoFocus onClick={handleClear} color="primary">
                  Clear All Criteria
                </TextButton>
                <HighlightButton
                  autoFocus
                  onClick={handleSubmit}
                  color="primary"
                >
                  Apply
                </HighlightButton>
              </DialogActions>
            </Paper>
          </BoxConfirm>
        </Fade> */}
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <HighlightFab
          aria-label="criteria"
          variant="extended"
          // color="primary"
          sx={{
            borderRadius: 999,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            backdropFilter: "blur(8px)",
            height: "40px",
          }}
          onClick={handleMobileClickOpen}
        >
          <FilterListIcon />
          &nbsp;Filter
        </HighlightFab>
        <Dialog
          // fullScreen={isXs}
          open={mobileOpen}
          onClose={handleMobileClose}
          TransitionComponent={isXs ? Transition : TransitionRight}
          PaperProps={{
            style: {
              borderRadius: 0,
              position: "fixed",
              left: 0,
              top: isXs ? "56px" : "64px",
              bottom: 0,
              width: isXs ? "100%" : "70%",
              minHeight: isXs ? "calc(100% - 56px)" : "calc (100% - 64px)",
              margin: 0,
            },
          }}
          sx={{ display: { xs: "block", md: "none" } }}
          // sx={{
          //   display: { xs: "none", md: "block" },
          //   borderBottom: "1px solid black",
          //   backgroundColor: "transparent",
          // }}
        >
          <Box
            sx={{
              position: "fixed",
              left: 0,
              top: 0,
              height: isXs ? "56px" : "64px",
              boxShadow: "none !important",
              width: isXs ? "100%" : "70%",
              backgroundColor: "#282828",
              maxWidth: "600px",
            }}
          >
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                color: "#FFF",
                height: "100%",
                px: 2,
              }}
            >
              <Typography
                variant="title_secondary_grey"
                sx={{ flex: 1, color: "#FFF" }}
              >
                Filter Search
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleMobileClose}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
              {/* <Button
                sx={{ fontSize: "15px", fontWeight: "600", py: 1.5 }}
                autoFocus
                color="inherit"
                onClick={handleSubmit}
              >
                Submit
              </Button> */}
            </Box>
          </Box>
          {expectedMethod !== "brand" && (
            <FormControl
              component="fieldset"
              sx={{
                px: 2,
              }}
            >
              <Accordion
                defaultExpanded="true"
                sx={{
                  borderTop: "0px !important",
                }}
                // expanded={isBrandExpanded}
                onChange={() => setIsBrandExpanded(!isBrandExpanded)}
              >
                <AccordionSummary
                  aria-controls="Brand"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <FormLabel component="legend">Brand</FormLabel>
                </AccordionSummary>
                <AccordionDetails>
                  <Autocomplete
                    key={brandKey}
                    multiple
                    fullWidth
                    options={brandArray}
                    loading={brandListLoading}
                    value={brand}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionDisabled={(option) => option.isDisabled}
                    disableCloseOnSelect
                    size="small"
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <span style={{ textTransform: "capitalize" }}>
                          {option.name}
                        </span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <StyledTextField {...params} placeholder="Brands" />
                    )}
                    onChange={handleBrand}
                  />
                </AccordionDetails>
              </Accordion>
            </FormControl>
          )}
          {
            // legend[0] ? (
            legend.map((_, i) => (
              <div key={i}>
                <FormControl
                  component="fieldset"
                  sx={{
                    px: 2,
                  }}
                >
                  <Accordion
                    defaultExpanded="true"
                    // expanded={isExpanded[i]}
                    onChange={handleExpand(i)}
                  >
                    <AccordionSummary
                      aria-controls={legend[i]}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <FormLabel component="legend">{legend[i]}</FormLabel>
                    </AccordionSummary>
                    <AccordionDetails>
                      {choice[i].length > choiceThreshold ? (
                        <Autocomplete
                          key={autoCompleteKey[i]}
                          multiple
                          fullWidth
                          options={choice[i]}
                          getOptionLabel={(x) => x.value || ""}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) =>
                              Boolean(option.rgb) === true ? (
                                <Chip
                                  label={option.value}
                                  sx={{ textTransform: "capitalize" }}
                                  {...getTagProps({ index })}
                                  avatar={
                                    <Avatar
                                      sx={{
                                        bgcolor: option.rgb,
                                      }}
                                    >
                                      &nbsp;
                                    </Avatar>
                                  }
                                />
                              ) : (
                                <Chip
                                  label={option.value}
                                  sx={{ textTransform: "capitalize" }}
                                  {...getTagProps({ index })}
                                />
                              )
                            )
                          }
                          disableCloseOnSelect
                          size="small"
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {Boolean(option.rgb) === true && (
                                <CircleIcon
                                  sx={{ color: option.rgb }}
                                  style={{ marginRight: 4 }}
                                />
                              )}
                              <span style={{ textTransform: "capitalize" }}>
                                {option.value}
                              </span>
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              placeholder={legend[i]}
                            />
                          )}
                          onChange={(event, value) =>
                            handleAutocomplete(event, { key: legend[i], value })
                          }
                        />
                      ) : (
                        <FilterPreferenceGroup
                          preference={preference}
                          i={i}
                          displayLimit={displayLimit}
                          choice={choice}
                          handleChange={handleChange}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </div>
            ))
            // ) : (
            //   <CircularProgress color="inherit" />
            // )}
          }
          {brand.length !== 0 || Object.keys(query).length !== 0 ? (
            <Box
              sx={{
                position: "sticky",
                bottom: "0",
                height: "80px",
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-around",
                zIndex: 1,
                background:
                  "linear-gradient(to top, rgba(245,245,245,1), rgba(245,245,245,1) 60%,rgba(245,245,245,0) 100%)",
              }}
            >
              <TextButton
                onClick={handleClear}
                color="primary"
                sx={{
                  height: "40px",

                  borderRadius: 999,
                  px: 2,
                  mb: 2,
                }}
              >
                Clear All Criteria
              </TextButton>
              <TextButton
                onClick={(e) => handleSubmit(e, brand, query)}
                sx={{
                  borderRadius: 999,
                  height: "40px",
                  px: 2,
                  color: "#FFF",
                  backgroundColor: "#282828",
                  mb: 2,
                }}
              >
                Apply
              </TextButton>
            </Box>
          ) : null}
          {/* <Fade in={open} timeout={350}>
            <BoxConfirm>
              <Paper elevation={4}>
                <DialogTitle onClose={handleClose}>Confirm?</DialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>
                    Display All Color and Variations:{" "}
                    {isShowVariation ? "Yes" : "No"}
                  </Typography>
                  <Typography gutterBottom>
                    Ready to ship: {isReadyToShip ? "Yes" : "Doesn't Matter"}
                  </Typography>
                  {brand.length > 0 && (
                    <Typography gutterBottom>
                      Brand: {brand.map((x) => x.name).join(", ")}
                    </Typography>
                  )}
                  {Object.entries(query).length > 0 &&
                    Object.entries(query).map(([key, value]) => (
                      <Typography gutterBottom key={key}>
                        {key}: {value.join(", ")}
                      </Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                  <TextButton autoFocus onClick={handleClear} color="primary">
                    Clear All Criteria
                  </TextButton>
                  <HighlightButton
                    autoFocus
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Apply
                  </HighlightButton>
                </DialogActions>
              </Paper>
            </BoxConfirm>
          </Fade> */}
        </Dialog>
      </Box>
    </React.Fragment>
  );
}

SearchRefiner.propTypes = {
  criteria: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        rgb: PropTypes.string,
      })
    )
  ).isRequired,
};

export default SearchRefiner;
