import { createSlice } from "@reduxjs/toolkit";
import { spaceMove } from "./spaceAsyncThunk";

const spaceMoveSlice = createSlice({
  name: "space",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
    favoriteProductIds: [],
  },
  reducers: {
    setFavoriteProductIds(state, action) {
      state.favoriteProductIds = [...action.payload];
    },
  },
  extraReducers: {
    [spaceMove.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [spaceMove.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [spaceMove.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export const { setFavoriteProductIds } = spaceMoveSlice.actions;

export default spaceMoveSlice.reducer;
