import { createSlice } from '@reduxjs/toolkit';
import { imageSearch, imageSearchReset, } from './imageAsyncThunk';

const imageSearchSlice = createSlice({
  name: "image",
  initialState: {
    count: 0,
    list: [],
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [imageSearch.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [imageSearch.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.failCode = 0;
      state.count = action.payload.count;
      state.list = action.payload.list;
    },
    [imageSearch.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.failCode = action.payload.status;
    },
    [imageSearchReset]: (state, action) => {
        state.count = 0;
        state.list = [];
        state.isLoading = false;
        state.isSuccess = false;
        state.failCode = 0;
      },
  },
});

export default imageSearchSlice.reducer;