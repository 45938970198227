import { createSlice } from '@reduxjs/toolkit';
import { brandCreate } from './brandAsyncThunk';

const brandCreateSlice = createSlice({
  name: "brand",
  initialState: {
    isLoading: false,
    isSuccess: false,
    failCode: 0,
    failMessage: "",
  },
  reducers: {},
  extraReducers: {
    [brandCreate.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [brandCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [brandCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
      state.failMessage = action?.payload?.data?.message;
    },
  },
});

export default brandCreateSlice.reducer;