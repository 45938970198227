import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  orderCartEncode,
  orderCartDecode,
  selectOrderCart,
  selectOrderCartToken,
} from "./orderAsyncThunk";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import ClearIcon from '@mui/icons-material/Clear';

const StyledImg = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "cover",
}));

function OrderCartPaper({ variation }) {
  const dispatch = useDispatch();
  const orderCartStore = useSelector(selectOrderCart);
  const { payload: cartInfo } = orderCartStore;
  const orderCartTokenStore = useSelector(selectOrderCartToken);
  const { token: cartToken } = orderCartTokenStore;
  const [cart, setCart] = useState(cartInfo);
    
  const handleRemove = () => {
    const newCart = [...cart];
    const index = newCart.findIndex(x => x.id === variation.id);
    if (index > -1) newCart.splice(index, 1);
    setCart(newCart);
    dispatch(orderCartEncode({ cart: newCart }));
  };

  useEffect(() => {
    if (Boolean(cartToken) === true) {
      sessionStorage.setItem("cart", cartToken);
      dispatch(orderCartDecode({ cartToken }));
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartToken]);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Paper sx={{
          padding: { xs: 1, sm: 2 },
          textAlign: "left",
          borderRadius: 0,
        }}>
          <Grid container direction="column">
            <Grid item xs sx={{ borderBottom: "1px solid" }}>
              <Typography gutterBottom variant="h6">
                { variation.productName }
              </Typography>
            </Grid>
            <Grid item xs sx={{ height: 1, mt: 1 }}>
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "center", sm: "flex-start" }}
                sx={{ height: 1 }}
              >
                <Grid item xs sx={{ height: 1 }}>
                  <Link href={variation.link} underline="hover" target="_blank" rel="opener noreferrer">
                    <ButtonBase sx={{
                      width: "100%",
                      height: "100%",
                    }}>
                      <StyledImg
                        alt={variation.name}
                        src={variation.image}
                      />
                    </ButtonBase>
                  </Link>
                </Grid>
                <Grid item xs>
                  <Typography sx={{ ml: { xs: 0, sm: 3 } }} variant="h6" gutterBottom>
                    { variation.name }
                  </Typography>
                  <Typography sx={{ ml: { xs: 0, sm: 3 } }} variant="body2" gutterBottom>
                    { variation.productName }
                  </Typography>
                  <Typography sx={{ ml: { xs: 0, sm: 3 } }} variant="body2" gutterBottom>
                    { variation.brandName }
                  </Typography>
                  <Typography sx={{ ml: { xs: 0, sm: 3 } }} variant="body2" gutterBottom>
                    Sample Size: { variation.productSize }
                  </Typography>
                </Grid>
                <Grid item xs
                  sx={{ alignSelf: "flex-end", cursor: "pointer" }}
                  onClick={handleRemove}
                >
                  <Typography variant="body2" align="right" gutterBottom>
                    <ClearIcon sx={{ fontSize: 12, mr: 1 }}/>Remove
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
}

OrderCartPaper.propTypes = {
  variation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    productName: PropTypes.string,
    productSize: PropTypes.string,
    availability: PropTypes.string,
    brandName: PropTypes.string,
    image: PropTypes.string,
    thumbnail: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default OrderCartPaper;