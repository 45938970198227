import { createSlice } from '@reduxjs/toolkit';
import { productGroupManage } from './productGroupAsyncThunk';

const productGroupManageSlice = createSlice({
  name: "productGroup",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [productGroupManage.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [productGroupManage.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [productGroupManage.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default productGroupManageSlice.reducer;