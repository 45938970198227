import React, { useState, useEffect } from "react";
import productGalleryTitleColumns from "../../components/types/productGalleryTitle";
import ProductGalleryTitleCreate from "./ProductGalleryTitleCreate";
import ProductGalleryTitleManage from "./ProductGalleryTitleManage";
import ProductGalleryTitleDelete from "./ProductGalleryTitleDelete";
import { useSelector, useDispatch } from "react-redux";
import {
  productGalleryTitleList,
  selectProductGalleryTitleCreate,
  selectProductGalleryTitleList,
  selectProductGalleryTitleManage,
  selectProductGalleryTitleDelete,
} from "./productGalleryTitleAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProductGalleryTitleManageGrid() {
  const dispatch = useDispatch();
  const [productGalleryTitleArray, setProductGalleryTitleArray] = useState([]);
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
  });
  const productGalleryTitleListStore = useSelector(
    selectProductGalleryTitleList
  );
  const { payload } = productGalleryTitleListStore;
  const productGalleryTitleManageStore = useSelector(
    selectProductGalleryTitleManage
  );
  const { isSuccess: productGalleryTitleManageSuccess } =
    productGalleryTitleManageStore;
  const productGalleryTitleCreateStore = useSelector(
    selectProductGalleryTitleCreate
  );
  const { isSuccess: productGalleryTitleCreateSuccess } =
    productGalleryTitleCreateStore;
  const productGalleryTitleDeleteStore = useSelector(
    selectProductGalleryTitleDelete
  );
  const { isSuccess: productGalleryTitleDeleteSuccess } =
    productGalleryTitleDeleteStore;

  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = productGalleryTitleArray.findIndex(
        (x) => x.id === selected[0]
      );
      setEditButtonProps({
        isSelected: true,
        data: { ...productGalleryTitleArray[index] },
      });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
          created_at: new Date(),
          updated_at: new Date(),
        },
      });
    }
  };

  useEffect(() => {
    dispatch(productGalleryTitleList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productGalleryTitleManageSuccess,
    productGalleryTitleCreateSuccess,
    productGalleryTitleDeleteSuccess,
  ]);

  useEffect(() => {
    let productGalleryTitle = [...payload];
    let list = [];
    if (productGalleryTitle.length > 0) {
      for (let i = 0; i < productGalleryTitle.length; i++) {
        let item = {
          ...productGalleryTitle[i],
          created_at: new Date(productGalleryTitle[i].created_at),
          updated_at: new Date(productGalleryTitle[i].updated_at),
        };
        list.push(item);
      }
      setProductGalleryTitleArray(list);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Typography>Product Gallery Title Management</Typography>
      <ProductGalleryTitleCreate />
      {productGalleryTitleArray[0] && (
        <ProductGalleryTitleManage {...editButtonProps} />
      )}
      {productGalleryTitleArray[0] && (
        <ProductGalleryTitleDelete {...editButtonProps} />
      )}
      {productGalleryTitleArray[0] && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={productGalleryTitleArray}
                columns={productGalleryTitleColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
