export const PROJECT_OWNER_DETAILS_TAB = [
  {
    title: "Material Samples",
    value: 0,
  },
  {
    title: "Schedules",
    value: 1,
  },
  {
    title: "Settings",
    value: 2,
  },
];

export const PROJECT_READ_DETAILS_TAB = [
  {
    title: "Material Samples",
    value: 0,
  },
  {
    title: "Schedules",
    value: 1,
  },
];

export const PROJECTS_TAB = [
  { title: "Projects", value: 0 },
  { title: "Archive", value: 1 },
  { title: "Your Orders", value: 2 },
  { title: "Settings", value: 3 },
];

export const REQUESTS_DATA = [
  {
    brandName: "Brand 1",
    products: [
      {
        productImageURL: "",
        productName: "Material Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Material Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Material Name",
        productVariationName: "Variation name",
        spaceRoomName: "Space 1",
        productStatus: "Product Status",
      },
    ],
  },
  {
    brandName: "Brand 2",
    products: [
      {
        productImageURL: "",
        productName: "Furniture Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Furniture Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Furniture Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Furniture Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Furniture Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
    ],
  },
  {
    brandName: "Brand 3",
    products: [
      {
        productImageURL: "",
        productName: "Material Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Hardware Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Hardware Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
      {
        productImageURL: "",
        productName: "Hardware Name",
        productVariationName: "Variation name",
        spaceRoomName: "room",
        productStatus: "Product Status",
      },
    ],
  },
];

export const PROJECT_FORM_DATA = [
  {
    type: "input",
    label: "Project Name",
    value: "projectName",
    id: "project-name",
  },
  {
    type: "input",
    label: "Project Location",
    value: "projectLocation",
    id: "project-location",
  },
  {
    type: "autocomplete",
    label: "Project Type",
    value: "projectType",
    id: "project-type",
  },
  {
    type: "input",
    label: "Project Size",
    value: "projectSize",
    id: "project-size",
  },
  {
    type: "input",
    label: "Project Description",
    value: "projectDescription",
    id: "project-description",
  },
];

export const ACCOUNT_FORM_DATA = [
  {
    label: "First name",
    value: "firstName",
    id: "first-name",
    type: "input",
  },
  {
    label: "Last name",
    value: "lastName",
    id: "last-name",
    type: "input",
  },
  {
    label: "Email",
    value: "email",
    id: "email",
    type: "input",
  },
];
