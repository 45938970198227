import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userCreateAdmin } from "./userAsyncThunk";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlueButton, BlackButton } from "../../components/CustomButtons";

export default function UserCreateAdmin() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    remarks: "",
    isSuper: false,
    isDisabled: false,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!Boolean(showPassword));
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!Boolean(showConfirmPassword));
  };

  const handlePointerDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (values.password.length < 9) {
      setShowHint(true);
      return;
    }
    if (confirmPassword !== values.password) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(userCreateAdmin(values));
  };

  return (
    <React.Fragment>
      <BlueButton
        variant="contained"
        color="primary"
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Create Admin
      </BlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-admin"
        scroll="body"
      >
        <StyledDialogTitle id="form-dialog-create-admin" onClose={handleClose}>
          Create New Admin
        </StyledDialogTitle>
        <DialogContent sx={{ px: 3, pt: 3, pb: 0 }}>
          <DialogContentText>Personal Details</DialogContentText>
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.firstName) === false}
            defaultValue={values.firstName}
            onChange={handleValues("firstName")}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            placeholder="First Name"
            sx={{ mt: 0, mb: 1.5 }}
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.lastName) === false}
            defaultValue={values.lastName}
            onChange={handleValues("lastName")}
            variant="outlined"
            margin="dense"
            sx={{ mt: 0, mb: 1.5 }}
            size="small"
            id="lastname"
            placeholder="Last Name"
            fullWidth
          />
          <StyledTextField
            required
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            defaultValue={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            size="small"
            id="email"
            placeholder="Email Address"
            sx={{ mt: 0, mb: 1.5 }}
            type="email"
            fullWidth
            autoComplete="off"
          />
        </DialogContent>
        <DialogContent sx={{ px: 3, pt: 0, pb: 3 }}>
          <DialogContentText>Password</DialogContentText>
          <StyledTextField
            required
            error={isUserActionMade && values.password.length < 9}
            defaultValue={values.password}
            onChange={handleValues("password")}
            variant="outlined"
            margin="dense"
            size="small"
            autoComplete="new-password"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            helperText="At least 9-character long"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onPointerDown={handlePointerDownPassword}
                    sx={{ marginRight: "0px!important" }}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            required
            error={isUserActionMade && confirmPassword !== values.password}
            defaultValue={confirmPassword}
            onChange={(e) => {
              setIsUserActionMade(true);
              setConfirmPassword(e.target.value);
            }}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            autoComplete="off"
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onPointerDown={handlePointerDownPassword}
                    sx={{ marginRight: "0px!important" }}
                    edge="end"
                    size="large"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogContent sx={{ px: 3, py: 0 }}>
          <DialogContentText>Remarks</DialogContentText>
          <StyledTextField
            defaultValue={values.remarks}
            onChange={handleValues("remarks")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            id="remarks"
            placeholder="Remarks"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isSuper}
                onChange={(e) =>
                  setValues({ ...values, isSuper: e.target.checked })
                }
                name="isSuper"
                color="primary"
                inputProps={{
                  "aria-label": "Super Admin (Able to Disable Other Admin)",
                }}
              />
            }
            label="Super Admin (Able to Disable Other Admin)"
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Create Account
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}
