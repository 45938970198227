import React, { useState, useEffect } from "react";
import { Lightbox } from "react-modal-image";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { selectProductGet } from "./productAsyncThunk";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import { activeProjectList } from "../project/projectAsyncThunk";
import ProductDetailSection from "../../components/ProductDetailSection";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";
import GallerySlider from "../../components/GallerySlider";
import ProductGallerySlider from "../../components/ProductGallerySlider";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

function ProductGet({ id, variationID }) {
  const productGetStore = useSelector(selectProductGet);
  const { csrfToken } = useSelector(selectAuthUserInfo);
  const { payload } = productGetStore;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [characteristicsArray, setCharArray] = useState([]);

  useEffect(() => {
    if (csrfToken) {
      dispatch(activeProjectList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrfToken]);
  const handleChangeGalleryImage = (gallery) => {
    setOpen(!open);
    setSelectedGallery(gallery);
  };

  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <Container
        maxWidth={false}
        sx={{
          bgcolor: "#F6F6F6",
          mt: { xs: "56px", sm: "64px" },
          py: { xs: 1, md: 1.5 },
          px: { xs: 2, sm: 4, md: 6 },
          textAlign: "left",
        }}
      >
        {payload && payload?.supplier_object ? (
          <Grid container spacing={{ xs: 1, md: 1.5 }}>
            <Grid item xs={12}>
              <Breadcrumbs
                aria-label="breadcrumb"
                sx={{
                  "& .MuiBreadcrumbs-ol > *": {
                    fontSize: { xs: "13px", md: "15px" },
                  },
                }}
              >
                {payload?.category_object?.fullname
                  ?.split("->")
                  ?.map((x, i) => (
                    <Link
                      component={RouterLink}
                      key={i}
                      color="inherit"
                      to={`/search/category/${payload?.category_object?.fullname
                        .split("->")
                        .slice(0, i + 1)
                        .join("->")}`}
                      underline="hover"
                    >
                      {x}
                    </Link>
                  ))}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <ProductDetailSection
                variationID={variationID}
                id={id}
                selectedGallery={selectedGallery}
                setCharArray={setCharArray}
                setSelectedGallery={setSelectedGallery}
                handleChangeGalleryImage={handleChangeGalleryImage}
                isModal={false}
                onClose={() => {}}
              />
            </Grid>
          </Grid>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Container>
      <ProductGallerySlider
        gallery={payload?.gallery?.length > 0 ? payload?.gallery : []}
        handleClick={handleChangeGalleryImage}
      />
      {/* {payload?.gallery?.length > 0 && (
        <GallerySlider
          gallery={payload?.gallery?.length > 0 ? payload?.gallery : []}
          handleClick={handleChangeGalleryImage}
        />
      )} */}

      <Container
        maxWidth={false}
        sx={{
          bgColor: "rgb(245, 245, 245)",
          padding: {
            xs: "16px !important",
            sm: "32px !important",
            md: "48px !important",
          },
          textAlign: "left",
        }}
      >
        {payload && payload.general ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="title_primary_black" sx={{ mb: 3 }}>
                Technical Specifications
              </Typography>
              {Object.entries(payload.general).map(([key, value], i) => (
                <Typography
                  variant="body_secondary_grey"
                  key={i}
                  sx={{ my: 1, fontSize: "16px", display: "block" }}
                >
                  {key}: {value.join(", ")}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="title_primary_black" sx={{ mb: 3 }}>
                Characteristics
              </Typography>
              {characteristicsArray.map(([key, value], i) =>
                Boolean(value) === false ? (
                  <Typography
                    variant="body_secondary_grey"
                    key={i}
                    sx={{
                      mt: i === 0 ? 0 : 3,
                      mb: 3,
                      display: "block",
                      fontSize: "16px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {key}
                  </Typography>
                ) : value.indexOf("\n") > -1 ? (
                  <Typography
                    variant="body_secondary_grey"
                    key={i}
                    sx={{
                      my: 1,
                      display: "block",
                      fontSize: "16px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {key}:
                    <br />
                    {value}
                  </Typography>
                ) : (
                  <Typography
                    variant="body_secondary_grey"
                    key={i}
                    sx={{
                      my: 1,
                      display: "block",
                      fontSize: "16px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {value}
                  </Typography>
                )
              )}
              <Typography
                variant="title_primary_black"
                sx={{ display: "block", mt: 3 }}
              >
                Download
              </Typography>
              {Object.entries(payload.download).map(([title, url], i) => (
                <Typography
                  variant="body_secondary_grey"
                  key={i}
                  sx={{ my: 1, fontSize: "16px", display: "block" }}
                >
                  <Link
                    color="inherit"
                    href={url}
                    target="_blank"
                    rel="opener noreferrer"
                    underline="hover"
                    underlineColor="text.secondary"
                  >
                    {title}
                  </Link>
                </Typography>
              ))}
            </Grid>
          </Grid>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Container>
    </React.Fragment>
  );
}

ProductGet.propTypes = {
  id: PropTypes.number.isRequired,
  variationID: PropTypes.number,
};

export default ProductGet;
