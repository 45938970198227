import React from "react";

const NewProjectIcon = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.625 61.25C47.5387 61.25 61.25 47.5387 61.25 30.625C61.25 13.7113 47.5387 0 30.625 0C13.7113 0 0 13.7113 0 30.625C0 47.5387 13.7113 61.25 30.625 61.25ZM28.4375 19.6875C28.4375 19.1073 28.668 18.5509 29.0782 18.1407C29.4884 17.7305 30.0448 17.5 30.625 17.5C31.2052 17.5 31.7616 17.7305 32.1718 18.1407C32.582 18.5509 32.8125 19.1073 32.8125 19.6875V28.4375H41.5625C42.1427 28.4375 42.6991 28.668 43.1093 29.0782C43.5195 29.4884 43.75 30.0448 43.75 30.625C43.75 31.2052 43.5195 31.7616 43.1093 32.1718C42.6991 32.582 42.1427 32.8125 41.5625 32.8125H32.8125V41.5625C32.8125 42.1427 32.582 42.6991 32.1718 43.1093C31.7616 43.5195 31.2052 43.75 30.625 43.75C30.0448 43.75 29.4884 43.5195 29.0782 43.1093C28.668 42.6991 28.4375 42.1427 28.4375 41.5625V32.8125H19.6875C19.1073 32.8125 18.5509 32.582 18.1407 32.1718C17.7305 31.7616 17.5 31.2052 17.5 30.625C17.5 30.0448 17.7305 29.4884 18.1407 29.0782C18.5509 28.668 19.1073 28.4375 19.6875 28.4375H28.4375V19.6875Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export default NewProjectIcon;
