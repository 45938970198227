import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./styles/materialUIStyles.js";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";

const ImgZoomModal = ({ open, setOpen, selectedGallery }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="product image zoom modal"
      aria-describedby="product image zoom modal"
    >
      <Box>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            color: "white",
            display: { xs: "flex", sm: "none" },
            backgroundColor: "rgba(0, 0, 0, 0.45)",
            backdropFilter: "blur(8px)",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              top: 0,
              right: "-48px",
              color: "white",
              display: { xs: "none", sm: "flex" },
              backgroundColor: "rgba(0, 0, 0, 0.45)",
              backdropFilter: "blur(8px)",

              "&:hover": {
                backgroundColor: "#282828",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedGallery?.url}
            alt={selectedGallery?.title}
            style={{
              objectFit: "contain",
              maxHeight: "85vh",
              maxWidth: "85vw",
            }}
          />
          <Box
            component="figcaption"
            sx={{
              width: "auto",
              textAlign: "center",
              color: "white",
              padding: "0.5rem",
            }}
          >
            <Typography
              variant="title_secondary_grey"
              sx={{
                fontSize: { xs: 15, sm: 18, md: 20 },
                color: "white",
                fontWeight: 500,
              }}
            >
              {selectedGallery?.title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImgZoomModal;
