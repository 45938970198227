import { createSlice } from "@reduxjs/toolkit";
import { downloadProductRequestSheet } from "./spaceAsyncThunk";

const spaceProductRequestsDownloadSlice = createSlice({
  name: "space",
  initialState: {
    message: "",
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [downloadProductRequestSheet.pending]: (state, action) => {
      state.isSuccess = false;
      state.isFail = false;
    },
    [downloadProductRequestSheet.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action.message;
    },
    [downloadProductRequestSheet.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.message = "";
    },
  },
});

export default spaceProductRequestsDownloadSlice.reducer;
