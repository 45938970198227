import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectArchiveProjectList } from "../../features/project/projectAsyncThunk";
import ArchiveProjectCard from "../ArchiveProjectCard";

const ArchiveTab = () => {
  const { payload: archiveProjectsList } = useSelector(
    selectArchiveProjectList
  );

  return (
    <Box>
      <Typography
        sx={{ fontSize: { xs: 16, sm: 20, md: 24 } }}
        fontWeight={600}
      >
        Archived projects
      </Typography>
      <Box sx={{ pt: 1.5, pb: 4 }}>
        <Grid container spacing={2}>
          {archiveProjectsList.map((project, index) => {
            return (
              <React.Fragment>
                <Grid
                  key={`project-${index}`}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={12 / 5}
                >
                  <ArchiveProjectCard project={project} />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ArchiveTab;
