import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  productPropPrecedenceList,
  selectProductPropPrecedenceList,
  productPropPrecedenceEdit,
} from './productPropAsyncThunk';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { LightBlueButton } from "../../components/CustomButtons";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList() {
  const dispatch = useDispatch();
  const productPropPrecedenceListStore = useSelector(selectProductPropPrecedenceList);
  const { payload } = productPropPrecedenceListStore;
  const keyMap = useRef(new Map());
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([0, 1, 2, 3]);
  const [right, setRight] = useState([4, 5, 6, 7]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(productPropPrecedenceEdit({ order: left }));
  };
    
  useEffect(() => {
    dispatch(productPropPrecedenceList());
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
  useEffect(() => {
    if (payload[0]) {
      keyMap.current = new Map();
      for (let i = 0; i < payload.length; i++) {
        keyMap.current.set(payload[i].id, payload[i].key);
      };
      setLeft(payload.filter(x => typeof x.precedence === "number").map(x => x.id));
      setRight(payload.filter(x => typeof x.precedence !== "number").map(x => x.id));
    };
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const customList = (items) => (
    <Paper elevation={4} sx={{ width: 200, height: 430, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={keyMap.current.get(value)} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ margin: "auto" }}
    >
      <Grid item>
        <Typography>
          Precedence
        </Typography>
        {payload[0] ? customList(left) : <CircularProgress color="inherit" />}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            sx={{ my: 0.5 }}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ my: 0.5 }}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ my: 0.5 }}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ my: 0.5 }}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          Available Property
        </Typography>
        {payload[0]? customList(right) : <CircularProgress color="inherit" />}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" alignItems="center">
          <LightBlueButton
            variant="contained"
            color="primary"
            sx={{ width: "50%", mt: 2 }}
            onClick={handleSubmit}
          >
            Save
          </LightBlueButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
