import React, { useState, useEffect, useRef } from "react";
import styled from "@mui/material/styles/styled";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import MaterialsIcon from "@mui/icons-material/Gavel";
import HardwareIcon from "@mui/icons-material/House";
import FurnitureIcon from "@mui/icons-material/Deck";
import ProjectsIcon from "@mui/icons-material/InsertPhoto";
import StarIcon from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import MatterBaseLogo4 from "../components/img/MatterbaseLogo4";
import { Typography, Badge, Divider, TextField, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AuthMobile from "../features/authentication/AuthMobile";
import RegMobile from "../features/registration/RegMobile";
import { Category } from "../layouts/DummyData";
import MatterbaseLogoMobileWhite from "../components/img/matterbase_logo_mobile_white.svg";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

// import TreeItem from '@mui/lab/TreeItem';
import { SidebarTreeItemMobile } from "../components/CustomComponents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import CategoryListDialog from "./sidebarMenu/CategoryListDialog";

import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BrandDialog from "./BrandDialog";

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  overflowY: "hidden",
  flexShrink: 0,
  [theme.breakpoints.up("md")]: {
    display: "none",
  },

  [`& .${drawerClasses["paper"]}`]: {
    overflowY: "hidden",
    boxSizing: "border-box",
    height:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",

    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },

    [theme.breakpoints.up("sm")]: {
      width: "40vw",
    },
  },
}));

export default function SwipeableTemporaryDrawer({
  hierarchyCategoryList,
  setSelected,
  selected,
}) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const history = useHistory();
  const [materialsOpen, setMaterialsOpen] = useState(false);
  const [hardwareOpen, setHardwareOpen] = useState(false);
  const [furnitureOpen, setFurnitureOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [matterBoard, setMatterBoard] = useState(false);
  // const [categoryListArr, setCategoryListArr] = useState([]);
  const [brandOpen, setBrandOpen] = useState(false);
  const [expandedNode, setExpandedNode] = useState("");
  const location = useLocation();
  const [text, setText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (item) => {
    history.push(`/search/category/${item.fullname}`);
  };

  useEffect(() => {
    // set selected and expanded when page is refreshed

    const pathArray = location.pathname.split("/");
    const searchCategoryIndex = pathArray.findIndex(
      (element) => element === "category" && pathArray.includes("search")
    );
    const productCategoryIndex = pathArray.findIndex(
      (element) => element === "product"
    );

    if (location.pathname.startsWith("/search/category/")) {
      const valueAfterCategory = pathArray[searchCategoryIndex + 1];
      const valueBeforeArrow = valueAfterCategory.split("->")[0];
      const valueAfterArrow = valueAfterCategory.split("->")[1];
      setSelected(valueAfterArrow);
      setExpandedNode(valueBeforeArrow);
    } else {
      setExpandedNode([]);
      console.log("Value after 'category/' not found");
    }
  }, [location.pathname]);

  const openMenuButtonRef = useRef();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsFocused(false);
  };

  const onRedireactClick = () => {
    history.push("/");
    setOpen(false);
  };
  const closeDrawer = () => {
    setBrandOpen(!brandOpen);
  };

  const handleExpandClick = (item) => {
    handleClick(item);
    setExpandedNode(item.name);
    setSelected(item.name);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && Boolean(text) === true) {
      history.push(`/search/keyword/${text}`);
      setText("");
      if (openMenuButtonRef.current) {
        openMenuButtonRef.current.blur();
      }
      handleClose();
    }
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSearchClick = () => {
    setIsFocused(true);
  };

  const MobileInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses["input"]}`]: {
      // padding: theme.spacing(2),
      textAlign: "left",
      fontSize: "16px",
    },
  }));

  const renderTreeItems = (items, handleExpandClick) => {
    return items.map((item) => {
      const hasChildren = item.children && item.children.length > 0;

      return (
        <SidebarTreeItemMobile
          nodeId={item.name}
          label={item.name}
          key={item.id}
          onClick={() => {
            handleClick(item);
            handleClose();
          }}
        ></SidebarTreeItemMobile>
      );
    });
  };

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        // onClick={toggleDrawer("left", true)}
        onClick={handleOpen}
        ref={openMenuButtonRef}
        size="large"
      >
        <MenuRoundedIcon
          sx={{
            fontSize: { xs: "24px", sm: "28px" },
            color: "#palette.text.primary",
          }}
        />
      </IconButton>

      <StyledDrawer
        variant="temporary"
        open={open}
        ModalProps={{
          onBackdropClick: handleClose,
        }}
      >
        <Box
          sx={{
            background: "#282828",
            color: "#fff",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                px: 2,
                py: { xs: 2, sm: 2.5 },
              }}
            >
              <Box
                component="img"
                src={MatterbaseLogoMobileWhite}
                alt="matterbase-logo-mobile"
                sx={{
                  width: { xs: 150, sm: 170 },
                  display: "block",
                }}
                onClick={() => onRedireactClick()}
              />
              {/* <Badge onClick={handleClose} color="warning" sx={{ cursor: 'pointer', mr: 2 }} overlap="circular" badgeContent=" ">
            </Badge> */}
              <IconButton onClick={handleClose} sx={{ color: "#fff", p: 0 }}>
                <ClearIcon />
              </IconButton>
            </Box>
            <Divider
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: 1,
                px: 2,
              }}
            >
              <SearchIcon
                sx={{
                  width: 28,
                  pr: 1,
                  color: "rgba(255, 255, 255, 0.2)",
                }}
              />
              <MobileInput
                aria-describedby={"search input"}
                autoFocus={isFocused}
                fullWidth
                inputProps={{ "aria-label": "search" }}
                value={text}
                onChange={handleChange}
                onClick={handleSearchClick}
                onKeyPress={handleKeyPress}
                sx={{
                  color: "#FFF",
                }}
              />
            </Box>
            <Divider
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
            />

            <Box
              sx={{
                height: "100%",
                // justifyContent: "flex-start",
              }}
            >
              <TreeView
                aria-label="file system navigator"
                sx={{ flexGrow: 1, minWidth: 200 }}
                expanded={expandedNode ? [expandedNode] : []}
                selected={selected}
              >
                {hierarchyCategoryList.length > 0 &&
                  hierarchyCategoryList.map((item) => {
                    const hasChildren =
                      item.children && item.children.length > 0;

                    const collapseIcon = (
                      <img
                        src={item.imageURL}
                        alt="category icon"
                        style={{
                          width: "18px",
                        }}
                      />
                    );
                    const expandIcon = (
                      <img
                        src={item.imageURL}
                        alt="category icon"
                        style={{
                          width: "18px",
                          filter: "brightness(0.5)",
                        }}
                      />
                    );

                    return (
                      <SidebarTreeItemMobile
                        nodeId={item.name}
                        label={item.name}
                        key={item.id}
                        onClick={() => handleExpandClick(item)}
                        sx={{}}
                        collapseIcon={collapseIcon}
                        expandIcon={expandIcon}
                      >
                        {hasChildren ? (
                          <Box
                            sx={{
                              height: {
                                xs: "calc(100vh - 355px)",
                                sm: "calc(100vh - 363px)",
                              },
                            }}
                          >
                            {renderTreeItems(item.children, handleClick)}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              height: {
                                xs: "calc(100vh - 355px)",
                                sm: "calc(100vh - 363px)",
                              },
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant="title_primary_grey"
                              sx={{
                                fontSize: "15px",
                                color: "#5f5f5f",
                                textAlign: "left",
                                fontWeight: "500",
                                paddingTop: "15px",
                                paddingLeft: "12px",
                              }}
                            >
                              Coming soon...
                            </Typography>
                          </Box>
                        )}
                      </SidebarTreeItemMobile>
                    );
                  })}
              </TreeView>
            </Box>
          </Box>
          <Box sx={{ padding: "0px, 12px", justifyContent: "flex-end" }}>
            <Divider
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
            />

            <Link component={RouterLink} to={"/account"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "56px",
                }}
                onClick={() => handleClose()}
              >
                <Typography
                  variant="title_primary_grey"
                  sx={{
                    lineHeight: "42px",
                    fontSize: "15px",
                    paddingLeft: "16px",
                    color: "#fff",
                  }}
                >
                  Projects
                </Typography>
                <ChevronRightIcon
                  sx={{
                    color: "#fff",
                  }}
                />
              </Box>
            </Link>
          </Box>
        </Box>
      </StyledDrawer>
      <BrandDialog onClose={closeDrawer} open={brandOpen} mobileView={1} />
    </div>
  );
}
