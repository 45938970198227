import { useState, useRef, useCallback, useEffect } from "react";
import { throttle } from "lodash";

function useThrottledHover(items, throttleDuration) {
  const [hoverStates, setHoverStates] = useState({});
  const leaveHandlers = useRef({});

  useEffect(() => {
    items?.forEach((item) => {
      leaveHandlers.current[item] = throttle(() => {
        setHoverStates((prevState) => ({
          ...prevState,
          [item]: false,
        }));
      }, throttleDuration);
    });

    return () => {
      Object.values(leaveHandlers.current).forEach((handler) =>
        handler.cancel()
      );
    };
  }, [items, throttleDuration]);

  const handleMouseEnter = useCallback((id) => {
    leaveHandlers?.current[id].cancel();
    setHoverStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  }, []);

  const handleMouseLeave = useCallback((id) => {
    if (leaveHandlers?.current[id]) {
      leaveHandlers.current[id]();
    }
  }, []);

  return {
    hoverStates,
    handleMouseEnter,
    handleMouseLeave,
  };
}

export default useThrottledHover;
