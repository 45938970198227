import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  productPropCreate,
  productPropGet,
  selectProductPropCreate,
  selectProductPropGet,
} from "./productPropAsyncThunk";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlueButton, BlackButton } from "../../components/CustomButtons";
import { AirlineSeatIndividualSuiteSharp } from "@mui/icons-material";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const filter = createFilterOptions();

export default function ProductPropCreate() {
  const dispatch = useDispatch();
  const productPropCreateStore = useSelector(selectProductPropCreate);
  const { isSuccess: productPropCreateSuccess } = productPropCreateStore;
  const productPropGetStore = useSelector(selectProductPropGet);
  const { payload, isLoading: productPropGetLoading } = productPropGetStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    key: "",
    value: "",
    rgb: "",
  });
  const [showHint, setShowHint] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [valueList, setValueList] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    if (
      String(newValue).startsWith(`Create "`) &&
      String(newValue).endsWith(`"`)
    ) {
      newValue = newValue.slice(8, -1);
    }
    const newObj = { ...values, [prop]: newValue };
    if (prop === "key") {
      setValueList(
        payload.filter((x) => x.key === newValue)[0]
          ? [
              ...payload
                .filter((x) => x.key === newValue)[0]
                .values.map((y) => y.value),
            ]
          : []
      );
      newObj.value = "";
    }
    if (prop === "value") {
      newObj.rgb = payload.filter((x) => x.key === values.key)[0].values[0]
        ? payload
            .filter((x) => x.key === values.key)[0]
            .values.filter((y) => y.value === newValue)[0]
          ? payload
              .filter((x) => x.key === values.key)[0]
              .values.filter((y) => y.value === newValue)[0].rgb
          : ""
        : "";
    }
    setValues(newObj);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.key) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.value) === false) {
      setShowHint(true);
      return;
    }
    if (
      Boolean(values.rgb) === true &&
      String(values.rgb).startsWith("#") === false
    ) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(productPropCreate(values));
  };

  useEffect(() => {
    dispatch(productPropGet());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropCreateSuccess]);

  useEffect(() => {
    setKeyList(payload.map((x) => x.key));
    if (payload[0]) {
      setValueList([...payload[0].values.map((y) => y.value)]);
      setValues({ ...values, key: payload[0].key });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <React.Fragment>
      <BlueButton
        variant="contained"
        color="primary"
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Create / Change Product Property
      </BlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-product-prop"
        scroll="body"
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle
          id="form-dialog-create-product-prop"
          onClose={handleClose}
        >
          Create / Override Current Product Property
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Autocomplete
            id="product_key"
            options={keyList}
            loading={productPropGetLoading}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option} />;
            }}
            value={values.key}
            onChange={handleAutoCompleteValues("key")}
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "")
                filtered.push(`Create "${params.inputValue}"`);
              return filtered;
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label="Key"
                margin="dense"
                sx={{ mt: 0, mb: 1.5 }}
                size="small"
                variant="outlined"
                required
                error={isUserActionMade && Boolean(values.key) === false}
              />
            )}
          />
          <Autocomplete
            id="product_value"
            options={valueList}
            loading={productPropGetLoading}
            isOptionEqualToValue={(option, value) => option === value}
            value={values.value}
            onChange={handleAutoCompleteValues("value")}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option} />;
            }}
            fullWidth
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "")
                filtered.push(`Create "${params.inputValue}"`);
              return filtered;
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Value"
                margin="dense"
                sx={{ mt: 0, mb: 1.5 }}
                size="small"
                variant="outlined"
                required
                error={isUserActionMade && Boolean(values.value) === false}
              />
            )}
          />
          <StyledTextField
            value={values.rgb}
            error={
              Boolean(values.rgb) === true &&
              String(values.rgb).startsWith("#") === false
            }
            onChange={handleValues("rgb")}
            variant="outlined"
            margin="dense"
            size="small"
            id="rgb"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="RGB Value (Optional)"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Create / Override Product Property
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}
