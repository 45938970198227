import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const catalogCreateCompanyType = createAsyncThunk(
  "catalog/createCompanyType",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/companyType/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const catalogListCompanyType = createAsyncThunk(
  "catalog/listCompanyType",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/companyType/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogManageCompanyType = createAsyncThunk(
  "catalog/manageCompanyType",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/companyType/`,
        payload
        );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogListCountry = createAsyncThunk(
  "catalog/listCountry",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/country/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogListSupplier = createAsyncThunk(
  "catalog/listSupplier",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/supplier/dropdown/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogListBrand = createAsyncThunk(
  "catalog/listBrand",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/dropdown/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogListCategory = createAsyncThunk(
  "catalog/listCategory",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/category/dropdown/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogListProduct = createAsyncThunk(
  "catalog/listProduct",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/dropdown/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogListProductVariation = createAsyncThunk(
  "catalog/listProductVariation",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/variationDropdown/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogCreateProjectType = createAsyncThunk(
  "catalog/createProjectType",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/projectType/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const catalogListProjectType = createAsyncThunk(
  "catalog/listProjectType",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/projectType/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogManageProjectType = createAsyncThunk(
  "catalog/manageProjectType",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/projectType/`,
        payload
        );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogDeleteProjectType = createAsyncThunk(
  "catalog/deleteProjectType",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/projectType/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const catalogCreateOrderStatusType = createAsyncThunk(
  "catalog/createOrderStatusType",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/orderStatusType/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const catalogListOrderStatusType = createAsyncThunk(
  "catalog/listOrderStatusType",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/orderStatusType/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogManageOrderStatusType = createAsyncThunk(
  "catalog/manageOrderStatusType",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/orderStatusType/`,
        payload
        );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const catalogDeleteOrderStatusType = createAsyncThunk(
  "catalog/deleteOrderStatusType",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/orderStatusType/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const selectCatalogCreateCompanyType = (state) => state.catalogCreateCompanyType;
export const selectCatalogListCompanyType = (state) => state.catalogListCompanyType;
export const selectCatalogManageCompanyType = (state) => state.catalogManageCompanyType;
export const selectCatalogListCountry = (state) => state.catalogListCountry;
export const selectCatalogListSupplier = (state) => state.catalogListSupplier;
export const selectCatalogListBrand = (state) => state.catalogListBrand;
export const selectCatalogListCategory = (state) => state.catalogListCategory;
export const selectCatalogListProduct = (state) => state.catalogListProduct;
export const selectCatalogListProductVariation = (state) => state.catalogListProductVariation;
export const selectCatalogCreateProjectType = (state) => state.catalogCreateProjectType;
export const selectCatalogListProjectType = (state) => state.catalogListProjectType;
export const selectCatalogManageProjectType = (state) => state.catalogManageProjectType;
export const selectCatalogDeleteProjectType = (state) => state.catalogDeleteProjectType;
export const selectCatalogCreateOrderStatusType = (state) => state.catalogCreateOrderStatusType;
export const selectCatalogListOrderStatusType = (state) => state.catalogListOrderStatusType;
export const selectCatalogManageOrderStatusType = (state) => state.catalogManageOrderStatusType;
export const selectCatalogDeleteOrderStatusType = (state) => state.catalogDeleteOrderStatusType;