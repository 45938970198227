import { createSlice } from "@reduxjs/toolkit";
import { orderListAdmin, orderListSelf } from "./orderAsyncThunk";

const orderListSlice = createSlice({
  name: "order",
  initialState: {
    payload: [],
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {
    setOrderListUser(state, action) {
      state.payload = action.payload;
    },
  },
  extraReducers: {
    [orderListAdmin.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [orderListAdmin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = [...action.payload.list];
    },
    [orderListAdmin.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
    [orderListSelf.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [orderListSelf.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = [...action.payload.list];
    },
    [orderListSelf.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export const { setOrderListUser } = orderListSlice.actions;

export default orderListSlice.reducer;
