import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectOrderCart } from "../features/order/orderAsyncThunk";
import OrderCartPaper from "../features/order/OrderCartPaper";

import { bodyFont } from "../components/styles/materialUIStyles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { BlackButton } from "../components/CustomButtons";
import NoItemFound from "../components/NoItemFound";

export default function CartPage() {
  const history = useHistory();
  const orderCartStore = useSelector(selectOrderCart);
  const { payload: cartInfo, availability } = orderCartStore;

  return (
    <React.Fragment>
      {Array.isArray(cartInfo) && cartInfo.length > 0 ? (
        <Box sx={{ my: { xs: 3, md: 10 }, mx: 3 }}>
          <Container maxWidth="md">
            <Grid sx={{ mt: 1 }} container spacing={2} alignItems="flex-end">
              {cartInfo.map((item, i) => (
                <Grid key={i} item xs={12}>
                  <OrderCartPaper variation={item} />
                </Grid>
              ))}
              <Grid item xs={12} sx={{ textAlign: "start" }}>
                {availability}
              </Grid>
            </Grid>
            <BlackButton
              variant="contained"
              color="primary"
              sx={{
                width: "50%",
                margin: (theme) => theme.spacing(5, 1),
              }}
              onClick={() => history.push(`/confirmOrder`)}
            >
              Order
            </BlackButton>
          </Container>
        </Box>
      ) : (
        <NoItemFound />
      )}
    </React.Fragment>
  );
}
