import React, { useState } from "react";
import PropTypes from "prop-types";
import ProductGet from "./ProductGet";

import { GreyButton } from "../../components/CustomButtons";
import { Dialog } from "../../components/CustomComponents";

function ProductPreview({ isSelected, id }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <GreyButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Preview
      </GreyButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-preview-product"
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <ProductGet id={id} />
      </Dialog>
    </React.Fragment>
  );
}

ProductPreview.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

export default ProductPreview;
