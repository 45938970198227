import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ServiceLogo from "../../components/img/file_icon_blank.png";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import SearchPaper from "../../components/SearchPaper";
import ProductNotFound from "../../components/ProductNotFound";
import { StyledTextField } from "../../components/CustomComponents";
import { landing_page_background_colour } from "../../components/styles/materialUIStyles";
import { useSelector, useDispatch } from "react-redux";
import { imageSearch, selectImageSearch } from "./imageAsyncThunk";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const StyledImg = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  objectFit: "cover",
}));

export default function ImageSearch() {
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const imageSearchStore = useSelector(selectImageSearch);
  const {
    isLoading: imageSearchLoading,
    isSuccess: imageSearchSuccess,
    failCode: imageSearchFail,
    count,
    list: readOnlyList,
  } = imageSearchStore;
  const material = [...readOnlyList];
  const resultPerPage = 24;
  const numOfPage = Math.ceil(count / resultPerPage);
  const [page, setPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState((page - 1) * resultPerPage);
  const [lastIndex, setLastIndex] = useState(
    Math.min(page * resultPerPage - 1, count - 1)
  );
  const [list, setList] = useState([]);

  const sortOption = [
    {
      id: 0,
      title: "Sort By Date (New)",
    },
    {
      id: 1,
      title: "Sort By Date (Old)",
    },
    {
      id: 2,
      title: "Sort By Name (A-Z)",
    },
    {
      id: 3,
      title: "Sort By Name (Z-A)",
    },
    // {
    //   id: 4,
    //   title: "Sort By Price ($-$$$)",
    // },
    // {
    //   id: 5,
    //   title: "Sort By Price ($$$-$)",
    // },
  ];
  const sortByDateAsc = (a, b) => {
    var nameA = a.created_at.toUpperCase(); // ignore upper and lowercase
    var nameB = b.created_at.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // dates must be equal
    return 0;
  };
  const sortByDateDesc = (a, b) => {
    var nameA = a.created_at.toUpperCase(); // ignore upper and lowercase
    var nameB = b.created_at.toUpperCase(); // ignore upper and lowercase
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    // dates must be equal
    return 0;
  };
  const sortByNameAsc = (a, b) => {
    var nameA = a.subtitle.toUpperCase(); // ignore upper and lowercase
    var nameB = b.subtitle.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  };
  const sortByNameDesc = (a, b) => {
    var nameA = a.subtitle.toUpperCase(); // ignore upper and lowercase
    var nameB = b.subtitle.toUpperCase(); // ignore upper and lowercase
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  };
  const sortByPriceAsc = (a, b) => {
    if (Boolean(a.price) && Boolean(b.price) === true) {
      // Both a.price and b.price are not null
      return a.price.join(",").localeCompare(b.price.join(","));
    } else {
      if (Boolean(a.price) === false) {
        // if a.price is null
        return -1; // swap b in front of a
      } else {
        return 1; // swap a in front of b
      }
    }
  };
  const sortByPriceDesc = (a, b) => {
    if (Boolean(a.price) && Boolean(b.price) === true) {
      // Both a.price and b.price are not null
      return a.price.join(",").localeCompare(b.price.join(",")) * -1;
    } else {
      if (Boolean(a.price) === false) {
        // if a.price is null
        return -1; // swap b in front of a
      } else {
        return 1; // swap a in front of b
      }
    }
  };
  const sortID = Number(sessionStorage.getItem("sortID"));
  const [sort, setSort] = useState(sortOption[sortID]);

  const handleSort = (_, value) => {
    setSort(value);
    sessionStorage.setItem("sortID", value.id);
  };

  const handleChange = (event, value) => {
    setPage(value);
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView();
    }
  };

  useEffect(() => {
    switch (sort.id) {
      case 0:
        Array.isArray(material) && material.sort(sortByDateDesc);
        break;
      case 1:
        Array.isArray(material) && material.sort(sortByDateAsc);
        break;
      case 2:
        Array.isArray(material) && material.sort(sortByNameAsc);
        break;
      case 3:
        Array.isArray(material) && material.sort(sortByNameDesc);
        break;
      case 4:
        Array.isArray(material) && material.sort(sortByPriceAsc);
        break;
      case 5:
        Array.isArray(material) && material.sort(sortByPriceDesc);
        break;
      default:
        break;
    }
    const firstInd = (page - 1) * resultPerPage;
    const lastInd = Math.min(page * resultPerPage - 1, count - 1);
    setFirstIndex(firstInd);
    setLastIndex(lastInd);
    setList(
      Array.isArray(material)
        ? material.filter((_, i) => i >= firstInd && i <= lastInd)
        : []
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, imageSearchSuccess, count]);

  const handleDragEnter = (e) => {
    e.preventDefault();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    let file = "";
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...e.dataTransfer.items]
        .find((item) => item.kind === "file")
        .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = e.dataTransfer.files[0];
    }
    dispatch(imageSearch(file));
  };

  const handleInputChange = (e) => {
    dispatch(imageSearch(e.target.files[0]));
  };

  useEffect(() => {
    let div = fileInput.current;
    div.addEventListener("dragenter", handleDragEnter);
    div.addEventListener("dragleave", handleDragLeave);
    div.addEventListener("dragover", handleDragOver);
    div.addEventListener("drop", handleDrop);
    return () => {
      div.removeEventListener("dragenter", handleDragEnter);
      div.removeEventListener("dragleave", handleDragLeave);
      div.removeEventListener("dragover", handleDragOver);
      div.removeEventListener("drop", handleDrop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {imageSearchLoading === false &&
        imageSearchSuccess === false &&
        imageSearchFail === 0 && (
          <Container maxWidth="lg">
            <Box
              ref={fileInput}
              sx={{
                width: "100%",
                height: "100%",
                my: { xs: 20, md: 15 },
                mx: 0,
                border: "2px dashed lightgray",
              }}
            >
              <label
                htmlFor={"file-browser-input"}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ mt: 2 }}>
                  <StyledImg alt="Select a File..." src={ServiceLogo} />
                </Box>
                <Typography sx={{ mt: 2 }} variant="subtitle1" gutterBottom>
                  Click the icon above
                </Typography>
                <Typography sx={{ mb: 2 }} variant="subtitle1" gutterBottom>
                  or drag and drop to upload an image.
                </Typography>
              </label>
              <input
                type="file"
                id="file-browser-input"
                accept="image/png, image/jpeg"
                onChange={handleInputChange}
                style={{ display: "none" }}
              />
            </Box>
          </Container>
        )}
      {imageSearchLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {Boolean(imageSearchFail) === true && <ProductNotFound />}
      {imageSearchSuccess && count < 1 && <ProductNotFound />}
      {imageSearchSuccess && count > 0 && (
        <div>
          <CssBaseline enableColorScheme />
          <Box
            sx={{
              backgroundColor: landing_page_background_colour,
              display: "flex",
              alignItems: "center",
              py: { xs: 1, sm: 3, md: 5 },
              px: { xs: 3, sm: 5, md: 0 },
            }}
          >
            <Container maxWidth="lg">
              <Grid container direction="column">
                <Grid item xs sx={{ mt: 1 }}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4} sx={{ textAlign: "start" }}>
                      <Typography
                        variant="h4"
                        sx={{ mx: 1, textTransform: "capitalize" }}
                      >
                        Image Search Result
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        disableClearable
                        size="small"
                        sx={{ mx: 1 }}
                        options={sortOption}
                        getOptionLabel={(option) => option.title || ""}
                        value={sort}
                        renderOption={(props, option) => {
                          return (
                            <AutoCompleteOption
                              {...props}
                              name={option.title}
                            />
                          );
                        }}
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                        onChange={handleSort}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs sx={{ my: 1 }}>
                  <Grid
                    container
                    alignItems="flex-end"
                    spacing={1}
                    sx={{ my: 1 }}
                  >
                    {Array.isArray(list) &&
                      list.map((item, i) => (
                        <Grid
                          key={i}
                          item
                          xs={12}
                          sm={item.isPremium ? 12 : 6}
                          md={item.isPremium ? 4 : 2}
                        >
                          <SearchPaper material={item} />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid item xs sx={{ my: 1 }}>
                  <Grid
                    container
                    alignItems="flex-end"
                    spacing={1}
                    sx={{ my: 1 }}
                  >
                    <Grid item xs sx={{ mt: 3, mb: 1, flexBasis: "100%" }}>
                      <Typography>
                        Showing {firstIndex + 1} to {lastIndex + 1} of {count}
                      </Typography>
                      <Pagination
                        sx={{ display: "inline-flex" }}
                        count={numOfPage}
                        page={page}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      )}
    </React.Fragment>
  );
}
