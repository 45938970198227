import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  brandGetLeadCapturePage,
  selectBrandGetLeadCapturePage,
} from "./brandAsyncThunk";
import SupplierContact from "../supplier/SupplierContactBrand";
import { selectSupplierContact } from "../supplier/supplierAsyncThunk";
import ImgMediaCardSwiper from "../../components/ImgMediaCardSwiper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { landing_page_background_colour } from "../../components/styles/materialUIStyles";
import DOMPurify from "dompurify";
import "aos/dist/aos.css";
import SupplierContactBrandName from "../supplier/SupplierContactBrandName";
import { SendIcon } from "../../components/img/SendIcon";
import { useHistory } from "react-router-dom";
import AspectRatioBox from "../../components/AspectRatioBox";

const DivLandingPage = styled("div")(({ theme }) => ({
  position: "relative",
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    // minHeight: theme.spacing(112.5),
  },
  textAlign: "center",
  ":after": {
    content: "''",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(to top, rgba(0,0,0,0.35), rgba(0,0,0,0.3)12%, rgba(0,0,0,0)25%)",

    zIndex: 1,
  },
}));

const StyledImgs = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "cover",
  [theme.breakpoints.down("sm")]: { paddingBottom: "12px" },
}));
const StyledSingleImg = styled("img")(({ theme }) => ({
  width: "100%",
  height: "40vh",
  objectFit: "cover",
}));
const StyledBrandBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  display: "flex",
  zIndex: theme.zIndex.mobileStepper - 1,
  color: theme.palette.text.primary,
  backgroundColor: "rgba(255, 0, 0, 0.01)",
}));

function BrandGetLeadCapturePage({ id }) {
  const dispatch = useDispatch();
  const brandGetLeadCapturePageStore = useSelector(
    selectBrandGetLeadCapturePage
  );
  const { payload } = brandGetLeadCapturePageStore;
  const supplierContactStore = useSelector(selectSupplierContact);
  const { isSuccess: supplierContactSuccess } = supplierContactStore;
  const [contactBtnKey, setContactBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [brandCategory, setBrandCategory] = useState([]);
  const [brandProducts, setBrandProducts] = useState([]);
  const theme = useTheme();

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(brandGetLeadCapturePage(id));
    sessionStorage.setItem("brandID", id);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const { id, categoryList, name, product } = payload;
    if (Array.isArray(categoryList) && categoryList.length > 0) {
      // const items = categoryList.map((unit) => {
      //   if (product.map(() => ele?.category_id))
      // })
      const items = categoryList.map((unit) =>
        Object.assign(
          {},
          {
            title: unit.fullname.split("->")[0],
            subtitle: unit.name,
            imageURL: unit.imageURL,
            url: `/search/category/${unit.fullname}?brand=${id}`,
            category_name: unit.fullname.split("->").at(-1),
            brand_name: name,
          }
        )
      );
      setBrandCategory(items);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    const { id, categoryList, product, name } = payload;
    if (
      Array.isArray(categoryList) &&
      categoryList.length > 0 &&
      Array.isArray(product) &&
      product.length > 0
    ) {
      const items = categoryList.map((unit) => {
        return {
          brand: {
            title: unit.fullname.split("->")[0],
            subtitle: unit.name,
            imageURL: unit.imageURL,
            url: `/search/category/${unit.fullname}?brand=${id}`,
            category_name: unit.fullname.split("->").at(-1),
            brand_name: name,
          },
          name: unit?.name,
          products: product.filter((ele) => ele?.category_id === unit?.id),
        };
      });
      const brandItems = items
        .map((item) => {
          if (item.products.length > 0) {
            return {
              title: item.brand.title,
              subtitle: item.brand.subtitle,
              imageURL: item.brand.imageURL,
              url: item.brand.url,
              category_name: item.brand.category_name,
              brand_name: item.brand.brand_name,
            };
          }
          return;
        })
        .filter((ele) => ele !== undefined);
      setBrandCategory(brandItems);
      setBrandProducts(items);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    setContactBtnKey(
      `supplier-contact-${Math.random().toString().substring(14)}`
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierContactSuccess]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: landing_page_background_colour,
        }}
      >
        <AspectRatioBox
          ratio={2 / 1}
          style={{
            maxHeight: "720px",
            minHeight: "400px",
          }}
        >
          <DivLandingPage
            sx={{
              backgroundImage: `url(${payload.bannerURL})`,
              backgroundSize: "cover",
              backgroundPositionX: "right",
              backgroundPositionY: "center",
            }}
          >
            <StyledBrandBox
              sx={{
                bottom: 0,
                mb: { xs: 2, sm: 2, md: 3 },
                mx: { xs: 3, sm: 4, md: 6 },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    cursor: "pointer",
                    marginRight: { xs: 1, md: 2 },
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <SendIcon />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography
                    color="white"
                    sx={{
                      fontSize: { xs: 28, md: 30, lg: 32 },
                      fontWeight: "600",
                    }}
                    noWrap
                  >
                    {payload?.name}
                  </Typography>
                </Box>
              </Box>
            </StyledBrandBox>
          </DivLandingPage>
        </AspectRatioBox>
        {payload.categoryList && payload.categoryList[0] && (
          <>
            <Box
              sx={{
                // backgroundColor: landing_page_background_colour,
                pt: { xs: 3, sm: 4, md: 6 },
                px: { xs: 3, sm: 4, md: 6 },
                width: { xs: "auto", md: "900px" },
              }}
            >
              <Box>
                <Box>
                  <Box
                    sx={{
                      maxWidth: "804px",
                    }}
                  >
                    <Typography
                      display="block"
                      sx={{
                        whiteSpace: "break-spaces",
                        textAlign: "left",
                        "& p": {
                          marginTop: "0px",
                          marginBottom: { xs: "12px", sm: "16px", md: "24px" },
                        },
                      }}
                      color="inherit"
                    >
                      <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(
                          payload.leftIntro
                        )}
                      ></div>
                    </Typography>
                    <Box sx={{ display: "block", width: "100%" }}>
                      {payload.image_objects && (
                        <Box
                          sx={{
                            display: { xs: "block", sm: "flex" },
                            gap: "12px",
                            mb: { sm: "16px", md: "24px" },
                          }}
                        >
                          <AspectRatioBox
                            ratio={isSmDown ? 1 / 1 : 2 / 3}
                            style={{
                              maxHeight: "591px",
                              // minHeight: "320px",
                              width: "100%",
                            }}
                          >
                            <StyledImgs
                              src={payload?.image_objects?.[0]?.url ?? ""}
                            />
                          </AspectRatioBox>

                          <AspectRatioBox
                            ratio={isSmDown ? 1 / 1 : 2 / 3}
                            style={{
                              maxHeight: "591px",
                              // minHeight: "320px",
                              width: "100%",
                            }}
                          >
                            <StyledImgs
                              src={payload?.image_objects?.[1]?.url ?? ""}
                            />
                          </AspectRatioBox>
                        </Box>
                      )}
                    </Box>
                    <Typography
                      display="block"
                      sx={{
                        whiteSpace: "break-spaces",
                        textAlign: "left",
                        "& p": {
                          marginTop: "0px",
                          marginBottom: { xs: "12px", sm: "16px", md: "24px" },
                        },
                      }}
                      color="inherit"
                    >
                      <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(
                          payload.rightIntro
                        )}
                      ></div>
                    </Typography>

                    <AspectRatioBox
                      ratio={isSmDown ? 1 / 1 : 16 / 9}
                      style={{
                        // maxHeight: "591px",
                        // minHeight: "320px",
                        width: "100%",
                      }}
                    >
                      {payload.image_objects && (
                        <StyledSingleImg
                          src={
                            payload.image_objects[2]
                              ? payload.image_objects[2].url
                              : ""
                          }
                        />
                      )}
                    </AspectRatioBox>
                  </Box>
                </Box>
              </Box>

              {/* {payload.supplier_object && payload.supplier_object.id && (
                    <Box sx={{ textAlign: "center" }}>
                      <SupplierContact
                        sx={{ alignSelf: "flex-end" }}
                        key={contactBtnKey}
                        supplierInfo={payload.supplier_object}
                        productInfo={{}}
                        outlinedButton
                      />
                    </Box>
                  )} */}
            </Box>
            {/* {payload.categoryList && payload.categoryList[0] && (
              <>
                <Box
                  sx={{
                    pl: { xs: 2, sm: 4, md: 6 },
                    pt: {
                      xs: "20px",
                      md: 3,
                    },
                    pb: {
                      xs: 1,
                      md: 2,
                    },
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    color="primary.main"
                    // variant="h5"
                    align="left"
                    noWrap
                    sx={{
                      fontSize: { xs: "18px", md: "20px" },
                      // cursor: "pointer",
                      // "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    <b>{payload.name}'s Products</b>
                  </Typography>
                </Box>
                <ImgMediaCardSwiper items={brandCategory} sx={{ mt: 1 }} />
              </>
            )} */}
            {brandProducts.length > 0 &&
              brandProducts.map((item, index) => {
                if (item?.products.length > 0) {
                  return (
                    <Box
                      sx={{
                        backgroundColor: landing_page_background_colour,
                        // py: { xs: 1, sm: 3, md: 5 },
                      }}
                      key={`brand-products-${index}`}
                    >
                      <Box
                        sx={{
                          pl: { xs: 2, sm: 4, md: 6 },
                          pt: {
                            xs: 4,
                            md: 6,
                          },
                          pb: {
                            xs: 1,
                            md: 2,
                          },
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Link
                          component={RouterLink}
                          to={`/search/category/Materials->${item?.name}`}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            color="primary.main"
                            fontSize="20px"
                            align="left"
                            noWrap
                            sx={{
                              cursor: "pointer",
                              marginLeft: "6px",
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            <b>{item.name}</b>
                          </Typography>

                          <ChevronRightIcon
                            sx={{
                              fontSize: { xs: "28px", md: "32px" },
                              color: theme.palette.text.secondary,
                            }}
                          />
                        </Link>
                      </Box>

                      <ImgMediaCardSwiper
                        items={item?.products}
                        sx={{ mt: 1 }}
                      />
                    </Box>
                  );
                }
              })}
          </>
        )}
      </Box>
      {open && (
        <SupplierContactBrandName
          open={open}
          onClose={() => setOpen(false)}
          supplierInfo={payload.supplier_object}
          productInfo={{}}
        />
      )}
    </React.Fragment>
  );
}

BrandGetLeadCapturePage.propTypes = {
  id: PropTypes.number.isRequired,
};

export default BrandGetLeadCapturePage;
