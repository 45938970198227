import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const orderCreate = createAsyncThunk(
  "order/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const orderListAdmin = createAsyncThunk(
  "order/listAdmin",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/admin`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderListSelf = createAsyncThunk(
  "order/listSelf",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderEdit = createAsyncThunk(
  "order/edit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const orderGetByID = createAsyncThunk(
  "order/getByID",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderCartEncode = createAsyncThunk(
  "order/cartEncode",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/cart/encode`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderCartDecode = createAsyncThunk(
  "order/cartDecode",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cart/decode`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderCancel = createAsyncThunk(
  "order/cancel",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/cancel/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderProductRemove = createAsyncThunk(
  "order/productRemove",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/product-remove/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderStatusUpdate = createAsyncThunk(
  "order/orderStatus",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/status/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const orderCartReset = createAction("order/cart");
export const orderCreateReset = createAction("order/create");

export const selectOrderCreate = (state) => state.orderCreate;
export const selectOrderList = (state) => state.orderList;
export const selectOrderEdit = (state) => state.orderEdit;
export const selectOrderGet = (state) => state.orderGet;
export const selectOrderCart = (state) => state.orderCart;
export const selectOrderCartToken = (state) => state.orderCartToken;
export const selectOrderCancel = (state) => state.orderCancel;
export const selectOrderProductRemove = (state) => state.orderProductRemove;
export const selectOrderStatusUpdate = (state) => state.orderStatusUpdate;
