import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  productParams,
  selectProductParams,
} from "../features/product/productAsyncThunk";

import { bodyFont } from "../components/styles/materialUIStyles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ProductGet from "../features/product/ProductGet";
import BrandGet from "../features/brand/BrandGet";
import CategoryRelevant from "../features/category/CategoryRelevant";
import BrandRelevant from "../features/brand/BrandRelevant";
import Error404 from "./Error404";
import ProductNotFound from "../components/ProductNotFound";

export default function ProductPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading, isSuccess, failCode, brand_id, category_id, name } =
    useSelector(selectProductParams);

  const { pathname } = location;
  const searchTerm = "-";
  const params = pathname
    .toLowerCase()
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "product")
    .map((x) => x.split(searchTerm))
    .flat();
  const expectedID = Number(params[params.length - 1]);
  const expectedName = params.slice(0, params.length - 1).join(" ");

  useEffect(() => {
    if (isNaN(expectedID) === false) dispatch(productParams(expectedID));
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectedID]);

  return <React.Fragment></React.Fragment>;
}
