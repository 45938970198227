import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CompanyTypeGrid from "../features/catalog/CompanyTypeGrid";
import SupplierManageGrid from "../features/supplier/SupplierManageGrid";
import BrandManageGrid from "../features/brand/BrandManageGrid";
import CategoryManageGrid from "../features/category/CategoryManageGrid";
import ProductPropManageGrid from "../features/productProp/ProductPropManageGrid";
import ProductPropPrecedenceEdit from "../features/productProp/ProductPropPrecedenceEdit";
import ProductManageGrid from "../features/product/ProductManageGrid";
import ProductVariationManageGrid from "../features/product/ProductVariationManageGrid";
import MatterboardGrid from "../features/matterboard/MatterboardGrid";
import ProductGroupManageGrid from "../features/productGroup/ProductGroupManageGrid";
import ProductGalleryTitleManageGrid from "../features/productGalleryTitle/ProductGalleryTitleManageGrid";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& *": {
    textTransform: "none",
  },
}));

const StyledTabPanel = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          sx={{
            height: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </StyledTabPanel>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function CatalogPage() {
  const theme = useTheme();
  const vertical = useMediaQuery(theme.breakpoints.up("lg"));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const disableFooter = document.getElementById("footer");
    if (disableFooter) {
      disableFooter.style.display = "none";
    }
  }, []);
  return (
    <Box
      sx={{
        // flexGrow: 1,
        m: 0,
        p: 0,
        height: "100vh",
        bgcolor: "background.paper",
        // display: "flex",
        // flexDirection: { xs: "column", lg: "row" },
        // textAlign: "left",
        // justifyContent: "left",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          pt: { xs: "56px", md: "64px" },
          pb: { xs: "16px", md: "48px" },
          // bgcolor: "background.paper",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          textAlign: "left",
          justifyContent: "left",
          height: { xs: "calc(100vh - 56px)", md: "calc(100vh - 64px)" },
        }}
      >
        <StyledTabs
          orientation={vertical ? "vertical" : "horizontal"}
          variant="scrollable"
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="Catalog"
        >
          <Tab label="Supplier" {...a11yProps(0)} />
          <Tab label="Company Type" {...a11yProps(1)} />
          <Tab label="Brand" {...a11yProps(2)} />
          <Tab label="Item Category" {...a11yProps(3)} />
          <Tab label="Product Property" {...a11yProps(4)} />
          <Tab label="Precedence of Search Criteria" {...a11yProps(5)} />
          <Tab label="Product" {...a11yProps(6)} />
          <Tab label="Product Variation" {...a11yProps(7)} />
          {/* <Tab label="Matterboard" {...a11yProps(8)} /> */}
          <Tab label="Product Group" {...a11yProps(9)} />
          <Tab label="Product Gallery Title" {...a11yProps(10)} />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <SupplierManageGrid />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CompanyTypeGrid />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BrandManageGrid />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CategoryManageGrid />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ProductPropManageGrid />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <ProductPropPrecedenceEdit />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ProductManageGrid />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <ProductVariationManageGrid />
        </TabPanel>
        {/* <TabPanel value={value} index={8}>
        <MatterboardGrid />
      </TabPanel> */}
        <TabPanel value={value} index={9}>
          <ProductGroupManageGrid />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <ProductGalleryTitleManageGrid />
        </TabPanel>
      </Box>
    </Box>
  );
}
