import React from "react";

const WhitePlustIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88598 12.9453V0.881778H7.62293V12.9453H4.88598ZM0.222689 8.28202V5.54507H12.2862V8.28202H0.222689Z"
        fill="white"
      />
    </svg>
  );
};

export default WhitePlustIcon;
