import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  catalogListProjectType,
  selectCatalogListProjectType,
} from "../catalog/catalogAsyncThunk";
import {
  orderCreate,
  orderCreateReset,
  orderCartReset,
  selectOrderCart,
  selectOrderCreate,
} from "./orderAsyncThunk";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";
import OrderCartPaper from "./OrderCartPaper";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Timeline from "@mui/lab/Timeline";
import BaseTimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { StyledTextField } from "../../components/CustomComponents";
import { BlackButton } from "../../components/CustomButtons";
import MoreSamplePlease from "../../components/MoreSamplePlease";
import {
  activeProjectList,
  selectProjectList,
} from "../project/projectAsyncThunk";
import { selectSpaceList, spaceList } from "../space/spaceAsyncThunk";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const TimelineItem = styled(BaseTimelineItem)(({ theme }) => ({
  "&:before": {
    flex: 0,
  },
}));

export default function OrderCreate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;
  const orderCartStore = useSelector(selectOrderCart);
  const {
    payload: cartInfo,
    availability,
    projectId,
    spaceId,
  } = orderCartStore;
  const orderCreateStore = useSelector(selectOrderCreate);
  const { csrfToken } = useSelector(selectAuthUserInfo);

  const { successCode: orderCreateSuccess } = orderCreateStore;
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const { payload: projectsList, isLoading: projectListLoading } =
    useSelector(selectProjectList);
  const { payload: spacesList, isLoading: spacesListLoading } =
    useSelector(selectSpaceList);
  const [values, setValues] = useState({
    project_id: null,
    project: {},
    space: {},
    space_id: null,
    deliveryLocation: userInfo.companyLocation || "",
    telephone: userInfo.telephone || "",
    order_status_id: 1,
  });

  useEffect(() => {
    if (projectId && projectsList.length > 0) {
      const selectedProject = projectsList.find(
        (project) => project.id === projectId
      );
      setValues({
        ...values,
        project_id: projectId,
        project: selectedProject,
      });
    }
  }, [projectId, projectsList]);

  useEffect(() => {
    if (spaceId && spacesList.length > 0) {
      const selectedSpace = spacesList.find((space) => space.id === +spaceId);
      setValues({
        ...values,
        space: selectedSpace,
        space_id: spaceId,
      });
    }
  }, [spacesList, spaceId]);

  const [showHint, setShowHint] = useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    if (prop === "project") newObj.project_id = newValue.id;
    if (prop === "space") newObj.space_id = newValue.id;
    setValues(newObj);
  };

  useMemo(() => {
    if (values?.project_id) {
      dispatch(spaceList(values?.project_id));
    }
  }, [values?.project_id, dispatch]);

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.project_id) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.deliveryLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.telephone) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(
      orderCreate(
        Object.assign(
          {
            project_id: values.project_id,
            deliveryLocation: values?.deliveryLocation,
            telephone: values.telephone,
            order_status_id: values.order_status_id,
            space_id: values.space_id,
          },
          {
            product_variation_ids: cartInfo.map((x) => x.id),
          }
        )
      )
    );
  };

  useEffect(() => {
    dispatch(catalogListProjectType());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (csrfToken) {
      dispatch(activeProjectList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, csrfToken]);

  useEffect(() => {
    if (Boolean(userInfo.id) === false) {
      return history.push(`/login?from=${location.pathname}`);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (projectsList[0])
      setValues({ ...values, project: { ...projectsList[0] } });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList]);

  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  useEffect(() => {
    if (Boolean(orderCreateSuccess) === true) {
      sessionStorage.removeItem("cart");
      dispatch(orderCartReset());
      handleVariant(`Your order requests is submitted`, "success");
      dispatch(orderCreateReset());
      return history.push(`/account`);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCreateSuccess]);

  return (
    <React.Fragment>
      {Array.isArray(cartInfo) && cartInfo.length > 0 ? (
        <Container maxWidth="md">
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <HomeWorkIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Project Information
                </Typography>
                <Autocomplete
                  id="projects"
                  options={projectsList}
                  loading={projectListLoading}
                  getOptionLabel={(option) => option.project_name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <AutoCompleteOption
                        {...props}
                        name={option.project_name}
                      />
                    );
                  }}
                  value={values.project}
                  onChange={handleAutoCompleteValues("project")}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Projects"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  sx={{ marginTop: 2 }}
                  id="spaces"
                  options={spacesList}
                  loading={spacesListLoading}
                  getOptionLabel={(option) => option.space_name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={values.space}
                  onChange={handleAutoCompleteValues("space")}
                  renderOption={(props, option) => {
                    return (
                      <AutoCompleteOption {...props} name={option.space_name} />
                    );
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Spaces"
                      variant="outlined"
                    />
                  )}
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <EmojiTransportationIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Delivery Details
                </Typography>
                <StyledTextField
                  required
                  error={
                    isUserActionMade &&
                    Boolean(values.deliveryLocation) === false
                  }
                  defaultValue={values.deliveryLocation}
                  onChange={handleValues("deliveryLocation")}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="deliverylocation"
                  placeholder="Delivery Location"
                  helperText="You can enter multiple rows here."
                  fullWidth
                  multiline
                />
                <StyledTextField
                  required
                  error={
                    isUserActionMade && Boolean(values.telephone) === false
                  }
                  defaultValue={values.telephone}
                  onChange={handleValues("telephone")}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="telephone"
                  placeholder="Telephone"
                  fullWidth
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <FactCheckIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Verify Sample List
                </Typography>
                <Grid
                  sx={{ mt: 1 }}
                  container
                  spacing={2}
                  alignItems="flex-end"
                >
                  {cartInfo.map((item, i) => (
                    <Grid key={i} item xs={12}>
                      <OrderCartPaper variation={item} />
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ textAlign: "start" }}>
                    {availability}
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <ThumbUpOffAltIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <DialogActions
                  sx={{
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <BlackButton
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "70%",
                      margin: (theme) => theme.spacing(1, 1, 3, 1),
                    }}
                    onClick={handleSubmit}
                  >
                    Create Order
                  </BlackButton>
                </DialogActions>
                {showHint && (
                  <DialogContentText
                    sx={{
                      margin: (theme) => theme.spacing(0, 1, 1, 1),
                      textAlign: "center",
                    }}
                  >
                    Oops! Something is still wrong above.
                  </DialogContentText>
                )}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Container>
      ) : (
        <MoreSamplePlease />
      )}
    </React.Fragment>
  );
}
