import React, { useState, useEffect } from "react";
import {
  authUserInfo,
  selectAuthUserInfo,
} from "../authentication/authAsyncThunk";
import {
  userVerifyRecovery,
  selectUserVerifyRecovery,
  userFinishRecovery,
  selectUserFinishRecovery,
} from "./userAsyncThunk";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { StyledTextField } from "../../components/CustomComponents";
import { BlackButton } from "../../components/CustomButtons";

export default function UserConfirmRecovery() {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { csrfToken } = useSelector(selectAuthUserInfo);
  const userVerifyRecoveryStore = useSelector(selectUserVerifyRecovery);
  const {
    isLoading: userVerifyRecoveryLoading,
    isSuccess: userVerifyRecoverySuccess,
    failCode: userVerifyRecoveryFailCode,
  } = userVerifyRecoveryStore;
  const userFinishRecoveryStore = useSelector(selectUserFinishRecovery);
  const { isSuccess: userFinishRecoverySuccess } = userFinishRecoveryStore;
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!Boolean(showPassword));
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!Boolean(showConfirmPassword));
  };

  const handlePointerDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (values.password.length > 0 && values.password.length < 9) {
      setShowHint(true);
      return;
    }
    if (confirmPassword !== values.password) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(userFinishRecovery(values));
  };

  useEffect(() => {
    if (Boolean(csrfToken) === true) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
      dispatch(userVerifyRecovery({ token }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrfToken]);

  useEffect(() => {
    if (userFinishRecoverySuccess) {
      dispatch(authUserInfo());
      history.replace("/");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFinishRecoverySuccess]);

  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <Container maxWidth="sm">
        <Box sx={{ width: "100%", maxWidth: 500, my: { xs: 20, md: 15 } }}>
          <Typography variant="h5" gutterBottom>
            Password Recovery
          </Typography>
          {userVerifyRecoveryLoading && (
            <Box>
              <Typography variant="h2" gutterBottom>
                Loading...
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                We are currently processing your request
              </Typography>
            </Box>
          )}
          {userVerifyRecoverySuccess && (
            <Box>
              <DialogContent>
                <StyledTextField
                  error={
                    isUserActionMade &&
                    values.password.length > 0 &&
                    values.password.length < 9
                  }
                  defaultValue={values.password}
                  onChange={handleValues("password")}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="new-password"
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  helperText="At least 9-character long"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          sx={{ marginRight: "0px!important" }}
                          onClick={handleClickShowPassword}
                          onPointerDown={handlePointerDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  error={
                    isUserActionMade && confirmPassword !== values.password
                  }
                  defaultValue={confirmPassword}
                  onChange={(e) => {
                    setIsUserActionMade(true);
                    setConfirmPassword(e.target.value);
                  }}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  placeholder="Retype New Password"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onPointerDown={handlePointerDownPassword}
                          sx={{ marginRight: "0px!important" }}
                          edge="end"
                          size="large"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <BlackButton
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "70%",
                    margin: (theme) => theme.spacing(1, 1, 3, 1),
                  }}
                  onClick={handleSubmit}
                >
                  Update Account
                </BlackButton>
              </DialogActions>
              {showHint && (
                <DialogContentText
                  sx={{
                    margin: (theme) => theme.spacing(0, 1, 1, 1),
                    textAlign: "center",
                  }}
                >
                  Oops! Something is still wrong above.
                </DialogContentText>
              )}
            </Box>
          )}
          {userVerifyRecoveryFailCode === 401 && (
            <Box>
              <Typography variant="h2" gutterBottom>
                Oops...
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                We are unable to process your request currently
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Please try again later
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link href="/" color="inherit" underline="hover">
                  Back to Main Page
                </Link>
              </Typography>
            </Box>
          )}
          {userVerifyRecoveryFailCode === 406 && (
            <Box>
              <Typography variant="h2" gutterBottom>
                Oops...
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                The requested resource has been expired.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Please create a new request.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link href="/" color="inherit" underline="hover">
                  Back to Main Page
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}
