import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListBrand,
  selectCatalogListBrand,
} from "../features/catalog/catalogAsyncThunk";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { modal_background_colour } from "./styles/materialUIStyles";
import Grid from "@mui/material/Grid";
import * as _ from "lodash";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";
const MuiTreeItem = styled(TreeItem)(
  (theme, props) => (theme.label = { fontWeight: 500 })
);

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <Box mx={6}>
      <MuiDialogTitle
        sx={{
          m: 0,
          p: 2,
          background: modal_background_colour,
        }}
        {...other}
      >
        <Typography variant="h4" component="span">
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: (theme) => theme.spacing(6),
              top: (theme) => theme.spacing(1),
              color: "grey.500",
            }}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </Box>
  );
};

BrandDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
};

export default function BrandDialog(props) {
  const dispatch = useDispatch();
  const catalogListBrandStore = useSelector(selectCatalogListBrand);
  const { payload } = catalogListBrandStore;
  const { onClose, selectedValue, open } = props;
  const [brand, setBrand] = useState([[], [], [], []]);
  const [activeSelection, setActiveSelection] = useState(null);
  const [newArr, setNewArr] = useState([]);
  const history = useHistory();

  const handleClose = () => {
    onClose(selectedValue);
  };
  const onRedireactClick = (path) => {
    if (path) {
      history.push(path);
      handleClose();
    }
  };

  const handleActiveSelection = (value) => {
    if (Boolean(activeSelection) === false) {
      setActiveSelection({ id: "1234555", name: value, count: 5 });
      return;
    }
    if (value === activeSelection.name) {
      setActiveSelection(null);
      return;
    }
    if (value.endsWith("More...")) {
      const newActiveSelection = {
        ...activeSelection,
        count: activeSelection.count + 5,
      };
      setActiveSelection(newActiveSelection);
      return;
    }
    if (value !== activeSelection.name) {
      setActiveSelection({ name: value, count: 5, id: "1234555" });
      return;
    }
  };

  useEffect(() => {
    // dispatch(catalogListBrand());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selections = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    selections.unshift("1");
    selections.unshift("&");
    let master = selections.map((x, index) =>
      Object.assign(
        {},
        {
          id: index * 13365 + 17,
          url: null,
          logoURL: null,
          name: x,
          // name2: [{ id: index * 1870, name: "No Brands" }],
        }
      )
    );
    if (payload.length > 0) {
      const manipulate = payload.map((x) => {
        return { ...x, url: `/brand/${x.name.replaceAll(" ", "-")}-${x.id}` };
      });
      _.forEach(master, function (value, index) {
        let result = [];
        let numberArr = [];
        let spclCharArr = [];
        _.forEach(manipulate, function (value2) {
          if (value.name === "&") {
            let filteredArr = value2.name.match(/^[~!@#$%^&*()_=?]/);
            let newSpclCharArr = manipulate.find(
              (item) => item.name === filteredArr?.input
            );
            if (newSpclCharArr) {
              newSpclCharArr.id = Math.floor(Math.random() * 100000000000000);
              spclCharArr = [...numberArr, newSpclCharArr];
              master[index].name2 = numberArr;
            }
          }

          if (value.name === "1") {
            let filteredArr = value2.name.match(/[0-9]/);
            let newArrOfNumbers = manipulate.find(
              (item) => item.name === filteredArr?.input
            );
            if (newArrOfNumbers) {
              newArrOfNumbers.id = Math.floor(Math.random() * 1000000000000000);
              numberArr = [...numberArr, newArrOfNumbers];
              master[index].name2 = numberArr;
            }
          } else if (
            value.name.charAt(0).toLocaleLowerCase() ===
            value2.name.charAt(0).toLocaleLowerCase()
          ) {
            result = [...result, value2];
            value.name2 = result;
            setNewArr(master);
          }
        });
        // console.log("master", master);
        // setNewArr(master);
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, activeSelection]);
  useEffect(() => {
    const selections = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    selections.push("0-9");
    selections.push("&@#");
    let master = selections.map((x) =>
      Object.assign({}, { id: -1, url: null, logoURL: null, name: x })
    );
    if (payload.length > 0) {
      const manipulate = payload
        .filter((a) => a.isDisabled === false && a.isProductExists === true)
        .map((x) => {
          return { ...x, url: `/brand/${x.name.replaceAll(" ", "-")}-${x.id}` };
        });
      if (Boolean(activeSelection) === true) {
        const filteredBrands = manipulate.filter((x) => {
          if (activeSelection.name === "0-9") {
            return x.name.match(/^\d/);
          }
          if (activeSelection.name === "&@#") {
            return x.name.match(/^[~!@#$%^&*()_=?]/);
          }
          return x.name
            .toLowerCase()
            .startsWith(activeSelection.name.toLowerCase());
        });
        const position = master
          .map((x) => x.name)
          .indexOf(activeSelection.name);
        if (filteredBrands.length === 0) {
          master.splice(
            position + 1,
            0,
            Object.assign(
              {},
              {
                id: -1,
                isDisabled: true,
                url: null,
                logoURL: null,
                name: "No Brand Found",
              }
            )
          );
        } else {
          if (activeSelection.count < filteredBrands.length) {
            master.splice(
              position + 1,
              0,
              Object.assign(
                {},
                { id: -1, url: null, logoURL: null, name: "More..." }
              )
            );
          }
          master.splice(
            position + 1,
            0,
            ...filteredBrands.slice(
              0,
              Math.min(activeSelection.count, filteredBrands.length)
            )
          );
        }
      }
      let trim = [[], [], [], []];
      trim[0] = master.filter(
        (_, i) => i < master.map((x) => x.name).indexOf("H")
      );
      trim[1] = master.filter(
        (_, i) =>
          i >= master.map((x) => x.name).indexOf("H") &&
          i < master.map((x) => x.name).indexOf("O")
      );
      trim[2] = master.filter(
        (_, i) =>
          i >= master.map((x) => x.name).indexOf("O") &&
          i < master.map((x) => x.name).indexOf("V")
      );
      trim[3] = master.filter(
        (_, i) => i >= master.map((x) => x.name).indexOf("V")
      );
      // for (let i = 1; i < 4; i++) {
      //   while (trim[i].length > 0 && trim[i][0].parent_id !== 1) {
      //     trim[i - 1].push(trim[i].shift());
      //   }
      // };
      setBrand([...trim]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, activeSelection]);

  const fn = _.spread(_.union);

  const result = fn(brand);

  let filterBrands = result.filter((item) => item.id !== -1);

  return (
    <MuiDrawer
      anchor={"right"}
      open={open}
      hideBackdrop={true}
      onEscapeKeyDown={true}
      onBackdropClick={true}
      onClose={false}
      variant="temporary"
      PaperProps={{
        sx: {
          width: props.mobileView === 1 ? "100%" : "95%",
          mt: 8,
          backgroundColor: "#F6F6F6",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#F6F6F6" }} onClose={handleClose}>
        Brand
      </DialogTitle>
      <DialogContent
        sx={{
          background: modal_background_colour,
          height: "65vh",
          padding: "20px 48px",
          backgroundColor: "#F6F6F6",
        }}
      >
        <Box my={2} sx={{ pb: 10 }}>
          <Grid container spacing={11}>
            {newArr.length > 0 ? (
              <>
                {!activeSelection ? (
                  <Grid item xs={props.mobileView === 1 ? 9 : 11}>
                    <TreeView
                      aria-label="file system navigator"
                      defaultExpanded={newArr.map((item) => item.id.toString())}
                      sx={{
                        flexGrow: 1,
                        minWidth: 200,
                        overflowY: "auto",
                      }}
                    >
                      {newArr.length > 0 &&
                        newArr.map((item, index) => (
                          <>
                            <MuiTreeItem
                              nodeId={item.id.toString()}
                              label={item.name}
                              key={index}
                              // sx={{
                              //   "& .MuiTreeItem-label": {
                              //     fontWeight: newArr.map((item, index) =>
                              //       item.name2 !== undefined
                              //         ? "bold"
                              //         : "initial"
                              //     ),
                              //   },
                              // }}
                            >
                              <Divider />
                              {item.name2 && (
                                <div>
                                  {item.name2.map((item2) => (
                                    <>
                                      <TreeItem
                                        nodeId={item2.id.toString()}
                                        label={item2.name}
                                        key={item2.id}
                                        onClick={(e) =>
                                          onRedireactClick(item2.url)
                                        }
                                      />
                                    </>
                                  ))}
                                </div>
                              )}
                            </MuiTreeItem>
                          </>
                        ))}
                    </TreeView>
                  </Grid>
                ) : (
                  <Grid item xs={props.mobileView === 1 ? 9 : 11}>
                    <TreeView
                      aria-label="file system navigator"
                      defaultExpanded={[activeSelection.id]}
                      sx={{ flexGrow: 1, minWidth: 200 }}
                    >
                      <TreeItem
                        nodeId={activeSelection.id}
                        label={activeSelection.name}
                        key={activeSelection.name}
                      >
                        {filterBrands.length > 0 ? (
                          filterBrands.map((item) => (
                            <TreeItem
                              nodeId={item.id}
                              label={item.name}
                              onClick={(e) => onRedireactClick(item.url)}
                              key={item.id}
                            />
                          ))
                        ) : (
                          <TreeItem
                            nodeId={"89899786657575"}
                            label={"No Brands"}
                            key={65363}
                          />
                        )}
                      </TreeItem>
                    </TreeView>
                  </Grid>
                )}
                <Grid item xs={1}>
                  {newArr.map((item) => (
                    <Typography
                      variant="inherit"
                      onClick={() => handleActiveSelection(item.name)}
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: "bold",
                        lineHeight: 1.5,
                      }}
                    >
                      {item.name}
                    </Typography>
                  ))}{" "}
                </Grid>
              </>
            ) : (
              <Box
                sx={{
                  height: props.mobileView === 1 ? "10vh" : "50vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  No Categories found !
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </DialogContent>
    </MuiDrawer>
  );
}
