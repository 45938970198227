import React from "react";

export const SendIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0013 2.66797C17.7523 2.66797 19.4861 3.01285 21.1037 3.68291C22.7214 4.35297 24.1913 5.3351 25.4294 6.57321C26.6675 7.81133 27.6496 9.28118 28.3197 10.8989C28.9898 12.5165 29.3346 14.2503 29.3346 16.0013C29.3346 19.5375 27.9299 22.9289 25.4294 25.4294C22.9289 27.9299 19.5375 29.3346 16.0013 29.3346C14.2503 29.3346 12.5165 28.9898 10.8989 28.3197C9.28118 27.6496 7.81133 26.6675 6.57321 25.4294C4.07273 22.9289 2.66797 19.5375 2.66797 16.0013C2.66797 12.4651 4.07273 9.0737 6.57321 6.57321C9.0737 4.07273 12.4651 2.66797 16.0013 2.66797ZM10.668 10.2813V14.7346L20.188 16.0013L10.668 17.268V21.7213L24.0013 16.0013L10.668 10.2813Z"
        fill="white"
      />
    </svg>
  );
};
