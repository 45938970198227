import React, { useState, useEffect } from "react";
import productPropColumns from "../../components/types/productProp";
import ProductPropCreate from "./ProductPropCreate";
import ProductPropDelete from "./ProductPropDelete";
import { useSelector, useDispatch } from "react-redux";
import {
  productPropList,
  selectProductPropList,
  selectProductPropCreate,
  selectProductPropDelete,
} from "./productPropAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProductPropManageGrid() {
  const dispatch = useDispatch();
  const [productPropFullList, setProductPropFullList] = useState([]);
  const [createBtnKey, setCreateBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const [deleteButtonProps, setDeleteButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      key: "",
      value: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
  });
  const productPropListStore = useSelector(selectProductPropList);
  const { payload } = productPropListStore;
  const productPropDeleteStore = useSelector(selectProductPropDelete);
  const { isSuccess: productPropDeleteSuccess } = productPropDeleteStore;
  const productPropCreateStore = useSelector(selectProductPropCreate);
  const { isSuccess: productPropCreateSuccess } = productPropCreateStore;

  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = productPropFullList.findIndex((x) => x.id === selected[0]);
      setDeleteButtonProps({
        isSelected: true,
        data: { ...productPropFullList[index] },
      });
    } else {
      setDeleteButtonProps({
        isSelected: false,
        data: {
          id: 0,
          key: "",
          value: "",
          created_at: new Date(),
          updated_at: new Date(),
        },
      });
    }
  };

  useEffect(() => {
    dispatch(productPropList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropDeleteSuccess, productPropCreateSuccess]);

  useEffect(() => {
    setCreateBtnKey(
      `productProp-create-${Math.random().toString().substring(14)}`
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropCreateSuccess]);

  useEffect(() => {
    let productProp = [...payload];
    let list = [];
    if (productProp.length > 0) {
      for (let i = 0; i < productProp.length; i++) {
        let item = {
          ...productProp[i],
          created_at: new Date(productProp[i].created_at),
          updated_at: new Date(productProp[i].updated_at),
        };
        list.push(item);
      }
      setProductPropFullList(list);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Typography>
        Product Property Treated As Criteria on Search Page
      </Typography>
      <ProductPropCreate key={createBtnKey} />
      {productPropFullList[0] && <ProductPropDelete {...deleteButtonProps} />}
      {productPropFullList[0] && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={productPropFullList}
                columns={productPropColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
