import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import { brandEdit } from "../../features/brand/brandAsyncThunk";

const GridCellExpand = React.memo(({ params }) => {
  const dispatch = useDispatch();
  const onDataEdit = async (value) => {
    console.log('value', value);
    await dispatch(brandEdit({ ...value, isEOL: !value?.isEOL }));
  };
  return (
    <div>
      <IconButton onClick={() => onDataEdit(params?.row)}>
        {params?.row?.isEOL ? <CheckIcon /> : <CloseIcon />}
      </IconButton>
    </div>
  );
});

const renderBrandISEOLCell = (params) => {
  return <GridCellExpand params={params} />;
};

export default renderBrandISEOLCell;
