import React, { useState, useEffect } from "react";
import productColumns from "../../components/types/product";
import ProductCreate from "./ProductCreate";
import ProductCopy from "./ProductCopy";
import ProductEdit from "./ProductEdit";
import ProductPreview from "./ProductPreview";
import ProductVariationCreate from "./ProductVariationCreate";
import { useSelector, useDispatch } from "react-redux";
import {
  productList,
  selectProductList,
  selectProductCreate,
  selectProductEdit,
  selectProductVariationCreate,
} from "./productAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProductManageGrid() {
  const dispatch = useDispatch();
  const [productFullList, setProductFullList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [createBtnKey, setCreateBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const [editBtnKey, setEditBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
      size: "",
      sku: "",
      tradePrice: "",
      brand: "",
      category: "",
      general: {},
      characteristics: [],
      download: {},
      isEOL: false,
      created_at: new Date(),
      updated_at: new Date(),
    },
  });
  const [previewButtonProps, setPreviewButtonProps] = useState({
    isSelected: false,
    id: 0,
  });
  const [variationButtonProps, setVariationButtonProps] = useState({
    isSelected: false,
    data: {
      product_id: 0,
      product_name: "",
    },
  });
  const productListStore = useSelector(selectProductList);
  const { payload } = productListStore;
  const productCreateStore = useSelector(selectProductCreate);
  const { isSuccess: productCreateSuccess } = productCreateStore;
  const productEditStore = useSelector(selectProductEdit);
  const { isSuccess: productEditSuccess } = productEditStore;
  const productVariationCreateStore = useSelector(selectProductVariationCreate);
  const { isSuccess: productVariationCreateSuccess } =
    productVariationCreateStore;

  const handleSelection = (selected) => {
    setSelectionModel(selected);
    if (selected[0]) {
      const index = productFullList.findIndex((x) => x.id === selected[0]);
      setEditButtonProps({
        isSelected: true,
        data: { ...productFullList[index] },
      });
      setPreviewButtonProps({ isSelected: true, id: selected[0] });
      setVariationButtonProps({
        isSelected: true,
        data: {
          product_id: selected[0],
          product_name: productFullList[index].name,
        },
      });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
          size: "",
          sku: "",
          tradePrice: "",
          brand: "",
          category: "",
          general: {},
          characteristics: [],
          download: {},
          isEOL: false,
          created_at: new Date(),
          updated_at: new Date(),
        },
      });
      setPreviewButtonProps({
        isSelected: false,
        id: 0,
      });
      setVariationButtonProps({
        isSelected: false,
        data: { product_id: 0, product_name: "" },
      });
    }
  };

  useEffect(() => {
    dispatch(productList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEditSuccess, productCreateSuccess]);

  useEffect(() => {
    handleSelection([]);
    setCreateBtnKey(`product-create-${Math.random().toString().substring(14)}`);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCreateSuccess, productVariationCreateSuccess]);

  useEffect(() => {
    handleSelection([]);
    setEditBtnKey(`product-edit-${Math.random().toString().substring(14)}`);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEditSuccess]);

  useEffect(() => {
    let product = [...payload];
    let list = [];
    if (product.length > 0) {
      for (let i = 0; i < product.length; i++) {
        let item = {
          ...product[i],
          created_at: new Date(product[i].created_at),
          updated_at: new Date(product[i].updated_at),
        };
        list.push(item);
      }
      setProductFullList(list);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Typography>Product Management</Typography>
      <ProductCreate key={createBtnKey} />
      <ProductCopy key={createBtnKey + "1"} {...editButtonProps} />
      {productFullList[0] && (
        <ProductEdit key={editBtnKey} {...editButtonProps} />
      )}
      {productFullList[0] && <ProductPreview {...previewButtonProps} />}
      {productFullList[0] && (
        <ProductVariationCreate
          key={createBtnKey + "2"}
          {...variationButtonProps}
        />
      )}
      {productFullList[0] && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={productFullList}
                columns={productColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
                selectionModel={selectionModel}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
