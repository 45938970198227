import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { formControlClasses } from "@mui/material/FormControl";
import { inputClasses } from "@mui/material/Input";
import { filledInputClasses } from "@mui/material/FilledInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { formHelperTextClasses } from "@mui/material/FormHelperText";
import { formLabelClasses } from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import MuiDialog, { dialogClasses } from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent, {
  dialogContentClasses,
} from "@mui/material/DialogContent";
import MuiDialogActions, {
  dialogActionsClasses,
} from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Badge, { badgeClasses } from "@mui/material/Badge";
import MuiTreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import MuiPopover, { popoverClasses } from "@mui/material/Popover";
import MuiAutocomplete, {
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../components/img/close.svg";

export const DialogTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: "rgba(0, 0, 0, 1)",
  },
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#3f51b5",
  },
  "& input + fieldset": {
    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderRadius: 0,
  },
  "& input + * + fieldset": {
    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderRadius: 0,
  },
  "& textarea + fieldset": {
    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderRadius: 0,
  },
  "& textarea + * + fieldset": {
    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderRadius: 0,
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    // paddingTop: "12px!important",
    // paddingBottom: "12px!important",
    // paddingLeft: "24px!important",
    // paddingRight: "24px!important",
    fontSize: "15px",
    fontWeight: 500,
  },
  [`& *`]: {
    fontSize: "15px",
  },
  [`& button svg`]: {
    fontSize: "1.5rem",
  },
  [`& .${formControlClasses["root"]}`]: {
    borderWidth: 2,
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
  },
  [`& .${inputClasses["root"]}`]: {
    borderWidth: 2,
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
  },
  [`& .${filledInputClasses["root"]}`]: {
    borderWidth: 2,
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
  },
  [`& .${inputLabelClasses["root"]}`]: {
    borderWidth: 2,
    borderRadius: "4px",
    // lineHeight: "1",
    backgroundColor: theme.palette.common.white,
  },
  [`& .${formLabelClasses["root"]}.${formLabelClasses["focused"]}`]: {
    color: theme.palette.common.black,
  },
  [`& .${outlinedInputClasses["root"]}`]: {
    borderWidth: 2,
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
    // padding: "0px!important",
  },

  [`& .${outlinedInputClasses["notchedOutline"]}`]: {
    borderWidth: 1,
    borderColor: theme.palette.primary.secondary,
  },
  [`& .${outlinedInputClasses["root"]}:hover .${outlinedInputClasses["notchedOutline"]}`]:
    {
      borderColor: theme.palette.primary.secondary,
    },
  [`& .${outlinedInputClasses["root"]}.${outlinedInputClasses["focused"]} .${outlinedInputClasses["notchedOutline"]}`]:
    {
      borderColor: theme.palette.primary.main,
    },
  [`& .${formHelperTextClasses["root"]}`]: {
    borderWidth: 2,
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
  },
}));

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${dialogClasses["paper"]}`]: {
    borderRadius: "12px",
  },
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: "32px 48px",
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  flex: "1 1 auto",
  WebkitOverflowScrolling: "touch",
  // Add iOS momentum scrolling.
  overflowY: "auto",
  padding: "16px 48px",
  "&:first-of-type": {
    // dialog without title
    paddingTop: 40,
  },
  [`& .${dialogContentClasses["dividers"]}`]: {
    padding: "32px 48px",
    borderTop: "2px solid ".concat(theme.palette.divider),
    borderBottom: "2px solid ".concat(theme.palette.divider),
  },
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: 16,
  [`& .${dialogActionsClasses["spacing"]}`]: {
    "& > :not(:first-of-type)": {
      marginLeft: 16,
    },
  },
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses["badge"]}`]: {
    right: 12,
    top: 13,
    color: theme.palette.common.white,
    padding: "0 4px",
  },
}));

export const StyledDialogTitle = (props) => {
  const { children, onClose, textStyle, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{ p: 3, display: "flex", alignItems: "center" }}
      {...other}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "600" }}
        style={textStyle}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "10px",
            top: (theme) => theme.spacing(2),
            color: "black",
          }}
          onClick={onClose}
          size="large"
        >
          <img src={CloseIcon} style={{ width: 14, height: 14 }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

StyledDialogTitle.propTypes = {
  onClose: PropTypes.func,
};

export const StyledDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1.5),
  })
);

export const AppBarDialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${dialogClasses["paper"]}`]: {
    borderRadius: 0,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: "100%",
    width: "100%",
  },
}));
export const SidebarTreeItem = styled(MuiTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses["content"]} .${treeItemClasses["label"]}`]: {
    lineHeight: "42px",
    fontSize: "15px",
    // paddingLeft: "12px",
  },

  // [`& .${treeItemClasses["root"]}`]: {
  //   backgroundColor: "#181818",
  // },

  [`& .${treeItemClasses["content"]}`]: {
    flexDirection: "row-reverse",
    color: theme.palette.grey[500],
    padding: "4px 16px",
  },
  [`& .${treeItemClasses["content"]}.${treeItemClasses["selected"]}`]: {
    color: theme.palette.grey[50],
  },
  [`& .${treeItemClasses["content"]}.${treeItemClasses["selected"]} *`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${treeItemClasses["content"]}.${treeItemClasses["expanded"]}`]: {
    // backgroundColor: "none",
    // px: 20,
  },

  [`& .${treeItemClasses["group"]}`]: {
    marginLeft: 0,
    paddingLeft: 12,
    // backgroundColor: theme.palette.grey[900],
    overflow: "overlay",
    overflowY: "auto",
  },

  [`& .${treeItemClasses["content"]}.${treeItemClasses["expanded"]} *`]: {
    color: theme.palette.grey[50],
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const SidebarTreeItemMobile = styled(MuiTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses["content"]} .${treeItemClasses["label"]}`]: {
    lineHeight: "42px",
    fontSize: "15px",
    paddingLeft: "0px",
  },

  // [`& .${treeItemClasses["root"]}`]: {
  //   backgroundColor: "#181818",
  // },

  [`& .${treeItemClasses["content"]}`]: {
    flexDirection: "row-reverse",
    color: theme.palette.grey[500],
    padding: "4px 16px",
  },
  [`& .${treeItemClasses["content"]}.${treeItemClasses["selected"]}`]: {
    color: theme.palette.grey[50],
  },
  [`& .${treeItemClasses["content"]}.${treeItemClasses["selected"]} *`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${treeItemClasses["content"]}.${treeItemClasses["expanded"]}`]: {
    backgroundColor: "#181818",
  },

  [`& .${treeItemClasses["group"]}`]: {
    marginLeft: 0,
    paddingLeft: 16,
    backgroundColor: theme.palette.grey[900],
    overflow: "overlay",
    overflowY: "auto",
  },

  [`& .${treeItemClasses["content"]}.${treeItemClasses["expanded"]} *`]: {
    color: theme.palette.grey[50],
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const SidebarPopover = styled(MuiPopover)(({ theme }) => ({
  [`& .${popoverClasses["paper"]}`]: {
    borderRadius: 0,
    height: "100% !important",
    maxHeight: "100% !important",
    width: "16%",
    minWidth: "220px",
    maxWidth: "300px",
    overflow: "hidden",
  },
  borderRadius: "0px !important",
  transition: "transition: width 2s",
  overflow: "hidden !important",
}));

const TempAutocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  [`& .${autocompleteClasses["focused"]}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const StyledAutocomplete = (props) => (
  <TempAutocomplete
    {...props}
    ListboxProps={{
      sx: {
        [`& .${autocompleteClasses["option"]}[aria-selected="true"]`]: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          color: "common.white",
        },
        [`& .${autocompleteClasses["option"]}[aria-selected="true"].${autocompleteClasses["focused"]}`]:
          {
            backgroundColor: "common.black",
            color: "common.white",
          },
        [`& .${autocompleteClasses["option"]}.${autocompleteClasses["focused"]}`]:
          {
            backgroundColor: "rgba(0, 0, 0, 0)",
            border: "0.5px solid black",
          },
      },
    }}
  />
);
