import React, { useEffect, useMemo, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BlackButton } from "./CustomButtons";
import ProductOptionCard from "./ProductOptionCard";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import SupplierContact from "../features/supplier/SupplierContact";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";
import { StyledTextField } from "./CustomComponents";
import {
  productGetByID,
  selectProductGet,
} from "../features/product/productAsyncThunk";
import SelectProjectModal from "./SelectProjectModal";
import {
  activeProjectList,
  selectProjectList,
} from "../features/project/projectAsyncThunk";
import { favoriteCreateReset } from "../features/favorite/favoriteAsyncThunk";
import { selectSupplierContact } from "../features/supplier/supplierAsyncThunk";
import ProductSingleLineImageList from "./ProductSingleLineImageList";
import VariationSideModal, { ImgVariationImage } from "./VariationSideModal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../components/img/close.svg";
import { TextField } from "@mui/material";
import OrderSampleIcon from "./img/OrderSampleIcon";

const ImgProductImage = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "cover",
  height: "100%",
  width: "100%",
}));

const ProductDetailSection = ({
  variationID,
  setCharArray,
  id,
  isModal,
  onClose,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const productGetStore = useSelector(selectProductGet);
  const { payload: userInfo } = authUserInfoStore;
  const { payload } = productGetStore;
  const { payload: projects, isLoading: projectListLoading } =
    useSelector(selectProjectList);
  const supplierContactStore = useSelector(selectSupplierContact);
  const { isSuccess: supplierContactSuccess } = supplierContactStore;

  const variations = useRef(new Map());
  const images = useRef(new Map());

  const [productVariantId, setProductVariantId] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [spaceId, setSpaceId] = useState(0);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [variationSideModal, setVariationSideModal] = useState(false);
  const [openSelectProjectModal, setOpenSelectProjectModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [productInfo, setProductInfo] = useState({});
  const [supplierInfo, setSupplierInfo] = useState({});
  const [contactBtnKey, setContactBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const [isProductAdded, setIsProductAdded] = useState(false);
  const [galleryArray, setGalleryArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [variationsData, setVarationsData] = useState([]);
  const [variationModal, setVariationModal] = useState(false);

  useEffect(() => {
    dispatch(productGetByID(id));
    return () => {
      dispatch(favoriteCreateReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(activeProjectList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContactBtnKey(
      `supplier-contact-${Math.random().toString().substring(14)}`
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierContactSuccess]);

  const handleGetVariations = () => {
    const data =
      payload?.variation_object?.Color.length > 0
        ? [...payload?.variation_object?.Color]
        : payload?.variation_object?.Finishing?.length > 0
        ? [...payload?.variation_object?.Finishing]
        : [
            ...payload?.variation_object?.Color,
            ...payload?.variation_object?.Finishing,
          ];
    const uniqueArray = data.filter((value, index, arr) => {
      return arr.findIndex((v2) => v2.id === value.id) === index;
    });
    setVarationsData(uniqueArray);
  };

  useEffect(() => {
    if (
      payload?.variation_object?.Color.length > 0 ||
      payload?.variation_object?.Finishing.length > 0
    ) {
      handleGetVariations();
    }
  }, [payload]);

  useEffect(() => {
    if (
      Array.isArray(payload?.variation_object) &&
      Boolean(payload?.variation_object[0]) === true
    ) {
      images.current.clear();
      variations.current.clear();
      let count = 0;
      let matchedIndex = undefined;
      for (let i = 0; i < payload.variation_object.length; i++) {
        if (payload.variation_object[i].id === variationID) {
          matchedIndex = count;
        }
        for (let j = 0; j < payload.variation_object[i].imageURL.length; j++) {
          images.current.set(count, {
            id: payload.variation_object[i].id,
            name: payload.variation_object[i].name,
            image: payload.variation_object[i].imageURL[j],
            thumbnail: payload.variation_object[i].thumbnailURL[j],
          });
          variations.current.set(count, {
            id: payload.variation_object[i].id,
            name: payload.variation_object[i].name,
            productName: payload.name,
            productSize: payload.size,
            availability: payload.general.hasOwnProperty("Availability")
              ? payload.general["Availability"][0]
              : "Made to Order",
            brandName: payload.brand_object.name,
            image: payload.variation_object[i].imageURL[j],
            thumbnail: payload.variation_object[i].thumbnailURL[j],
            link: location.pathname,
          });
          count += 1;
        }
      }
      setProductInfo({
        name: payload.name,
        variation: images.current.get(matchedIndex || 0).name,
        thumbnailURL: images.current.get(matchedIndex || 0).thumbnail,
      });
      setSelectedIndex(matchedIndex);
    } else if (
      typeof payload.variation_object === "object" &&
      !Array.isArray(payload.variation_object) &&
      payload.variation_object !== null
    ) {
      const flatVariationArray = Object.values(payload.variation_object).flat();
      const keys = ["id"];
      const uniqueVariationArray = flatVariationArray.filter(
        (
          (s) => (o) =>
            ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
        )(new Set())
      );
      images.current.clear();
      variations.current.clear();
      let count = 0;
      let matchedIndex = undefined;
      for (let i = 0; i < uniqueVariationArray.length; i++) {
        if (uniqueVariationArray[i].id === variationID) {
          matchedIndex = count;
        }
        for (let j = 0; j < uniqueVariationArray[i].imageURL.length; j++) {
          images.current.set(count, {
            id: uniqueVariationArray[i].id,
            name: uniqueVariationArray[i].name,
            image: uniqueVariationArray[i].imageURL[j],
            thumbnail: uniqueVariationArray[i].thumbnailURL[j],
          });
          variations.current.set(count, {
            id: uniqueVariationArray[i].id,
            name: uniqueVariationArray[i].name,
            productName: payload.name,
            productSize: payload.size,
            availability: payload.general.hasOwnProperty("Availability")
              ? payload.general["Availability"][0]
              : "Made to Order",
            brandName: payload.brand_object.name,
            image: uniqueVariationArray[i].imageURL[j],
            thumbnail: uniqueVariationArray[i].thumbnailURL[j],
            link: location.pathname,
          });
          count += 1;
        }
      }
      setProductInfo({
        name: payload.name,
        variation: images.current.get(matchedIndex || 0).name,
        thumbnailURL: images.current.get(matchedIndex || 0).thumbnail,
      });
      setSelectedIndex(matchedIndex);
    } else {
      images.current.clear();
      variations.current.clear();
      setProductInfo({ name: payload.name });
      setSelectedIndex(undefined);
    }
    if (
      Array.isArray(payload.image_objects) &&
      Boolean(payload.image_objects[0]) === true
    ) {
      setGalleryArray(
        payload.image_objects.map((x, i) =>
          Object.assign(
            { ...x },
            {
              onClick: () => {
                setProductInfo({ name: payload.name });
                setSelectedIndex(undefined);
              },
            }
          )
        )
      );
    } else {
      setGalleryArray([]);
    }
    setSupplierInfo(payload.supplier_object);
    setCharArray(
      Boolean(payload.characteristics) === true
        ? Array.isArray(payload.characteristics)
          ? payload.characteristics
          : Object.entries(payload.characteristics)
        : []
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const handleVariationSelect = (index) => {
    setSelectedIndex(index);
    setProductInfo({
      ...productInfo,
      variation: images.current.get(index).name,
      thumbnailURL: images.current.get(index).thumbnail,
    });
    dispatch(favoriteCreateReset());
  };

  const handleSelectProject = async (id) => {
    setSpaceId(id);
  };

  useEffect(() => {
    if (variationsData.length > 0) {
      setSelectedVariation(
        variationsData.find((item) => item.id === variationID)
      );
    }
  }, [variationsData, variationID]);

  const filteredVariations = useMemo(() => {
    if (searchText) {
      return variationsData?.filter((item) =>
        item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
    }
    return variationsData;
  }, [searchText, variationsData]);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={{ xs: 1.5, md: 3 }}
        sx={{
          position: "relative",
          // flexDirection: "row-reverse",
        }}
      >
        {isModal && (
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(2.5),
              color: "black",
            }}
            onClick={() => {
              if (variationModal) {
                setVariationModal(false);
                return;
              }
              onClose();
            }}
            size="large"
          >
            <img src={CloseIcon} style={{ width: 14, height: 14 }} />
          </IconButton>
        )}
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          lg={6}
          sx={{ textAlign: "center", marginBottom: { xs: "4px", sm: "68px" } }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingBottom: "100%",
            }}
          >
            {!selectedVariation ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  maxWidth: "100%",
                  position: "absolute",
                  height: "100%",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              />
            ) : (
              <ImgProductImage
                src={selectedVariation?.imageURL}
                alt={selectedVariation?.name}
                sx={{
                  maxWidth: "100%",
                  position: "absolute",
                  // marginBottom: "68px",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={6} sx={{ minHeight: "434px" }}>
          {isModal && variationModal ? (
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: 12, lg: 20 },
                  fontWeight: 600,
                }}
              >
                Select Variations
              </Typography>
              {/* <DialogTextField
                required
                error={
                  isUserActionMade && Boolean(values.projectName) === false
                }
                defaultValue={values.projectName}
                onChange={handleValues("projectName")}
                variant="outlined"
                sx={{ mt: 0, mb: 1.5 }}
                margin="dense"
                size="small"
                label="Project Name"
                fullWidth
              /> */}
              <StyledTextField
                sx={{ my: 2.5, width: "100%" }}
                placeholder=""
                value={searchText}
                label="Search Variations"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Grid container spacing={1.5}>
                {filteredVariations?.map((item, index) => {
                  return (
                    <React.Fragment key={`color-${index}`}>
                      <Grid item xs={4} sm={3}>
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedVariation(item);
                            handleVariationSelect(index);
                            setVariationModal(false);
                          }}
                        >
                          <ImgVariationImage
                            alt={item.name}
                            src={item.thumbnailURL[0]}
                            sx={{
                              borderRadius: "6px",
                              "&:hover": {
                                border: 1,
                              },
                            }}
                          />
                          <Typography sx={{ fontSize: 15, fontWeight: 300 }}>
                            {item?.name?.replace(/_/g, "/")}
                          </Typography>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                height: "100%",
                width: "100%",
                maxWidth: "600px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(
                      `/product/${payload?.name?.replaceAll(" ", "-")}-${
                        payload?.id
                      }/?var=${selectedVariation.id}`
                    )
                  }
                >
                  <Typography
                    sx={{
                      mb: { xs: 0.5, lg: 1 },
                      fontSize: isModal ? 28 : { xs: 28, md: 40, lg: 48 },
                      fontWeight: 600,
                    }}
                  >
                    {payload.name}
                  </Typography>
                </Box>
                <Box
                  sx={{ cursor: "pointer", mb: { xs: 1, lg: 3 } }}
                  onClick={() =>
                    history.push(
                      `/brand/${payload?.brand_object.name?.replaceAll(
                        " ",
                        "-"
                      )}-${payload?.brand_object?.id}`
                    )
                  }
                >
                  <Typography
                    variant="title_primary_grey"
                    sx={{
                      fontSize: isModal ? 20 : { xs: 16, md: 20, lg: 24 },
                      "&:hover": {
                        // textDecoration: "underline",
                      },
                    }}
                  >
                    {payload?.brand_object?.name}
                  </Typography>
                </Box>
                <ProductOptionCard
                  title={"Variations"}
                  isModal={isModal}
                  subTitle={selectedVariation?.name}
                  imageUrl={selectedVariation?.thumbnailURL[0]}
                  options={variationsData.length}
                  onClick={() => {
                    if (isModal) {
                      setVariationModal(!variationModal);
                      return;
                    }
                    setVariationSideModal(!variationSideModal);
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <BlackButton
                    sx={{
                      width: { xs: "100%", lg: isModal ? 432 : "100%" },
                      my: 1,
                      backgroundColor: isProductAdded
                        ? "white"
                        : "primary.main",
                      color: isProductAdded ? "black" : "white",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="contained"
                    onClick={() => {
                      if (isProductAdded) {
                        return;
                      }
                      if (Boolean(userInfo.id) === false) {
                        return history.push(`/login?from=${location.pathname}`);
                      }
                      if (images.current.size > 0) {
                        setProductVariantId(
                          images.current.get(selectedIndex || 0).id
                        );
                        setOpenSelectProjectModal(!openSelectProjectModal);
                        return;
                      }
                      return;
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: { md: "48px", sm: "32px", xs: "16px" },
                        // paddingRight: "180px",
                      }}
                    >
                      <OrderSampleIcon />
                    </Box>
                    <Box
                    // sx={{
                    //   display: "flex",
                    //   width: "100%",
                    //   justifyContent: "center",
                    // }}
                    >
                      {isProductAdded ? "Added" : "Order Sample"}
                    </Box>
                  </BlackButton>
                </Box>
                {!isModal && (
                  <Box sx={{ width: "100%" }}>
                    {supplierInfo &&
                      supplierInfo.id &&
                      productInfo &&
                      productInfo.name && (
                        <SupplierContact
                          key={contactBtnKey}
                          supplierInfo={supplierInfo}
                          productInfo={productInfo}
                          outlinedButton
                          isModal={isModal}
                        />
                      )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      {/* {Array.isArray(payload.image_objects) &&
        Boolean(payload.image_objects[0]) === true && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <ProductSingleLineImageList gallery_array={galleryArray} />
            </Grid>
          </Grid>
        )} */}
      <SelectProjectModal
        open={openSelectProjectModal}
        onClose={() => setOpenSelectProjectModal(false)}
        setProjectId={setProjectId}
        handleSelectProject={handleSelectProject}
        projectId={projectId}
        projects={projects}
        isLoading={projectListLoading}
        productVariantId={productVariantId}
        spaceId={spaceId}
        setSpaceId={setSpaceId}
        setIsProductAdded={setIsProductAdded}
      />
      <VariationSideModal
        open={variationSideModal}
        anchor={"right"}
        variations={variationsData}
        onClose={() => setVariationSideModal(false)}
        setSelectedVariation={setSelectedVariation}
        handleVariationSelect={handleVariationSelect}
      />
    </React.Fragment>
  );
};
export default ProductDetailSection;
