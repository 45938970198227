import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const spaceProductsCreate = createAsyncThunk(
  "spaceProduct/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/`,
        payload
      );
      return data;
    } catch (error) {
      const { data, status } = error.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const spaceProductsList = createAsyncThunk(
  "spaceProduct/list",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/${id}`
      );
      return data?.list?.map((val) => ({ ...val, spaceId: id }));
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const spaceProductsDelete = createAsyncThunk(
  "spaceProduct/delete",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/delete/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const selectSpaceProductCreate = (state) => state.spaceProductCreate;
export const selectSpaceProductList = (state) => state.spaceProductsList;
export const selectSpaceProductDelete = (state) => state.spaceProductsDelete;
