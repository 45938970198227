import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function ProductFilterNotFound() {
  return (
    <Container sx={{ px: "0!important" }} maxWidth={false}>
      <Box
        sx={{
          width: 1,
          mx: 0,
        }}
      >
        <Typography
          sx={{
            mt: { xs: "16px", sm: "32px", md: "48px" },
            fontSize: { xs: "20px", sm: "28px", md: "32px" },
            fontWeight: "bold",
          }}
        >
          Sorry, no matches were found.
        </Typography>
        <Typography
          sx={{
            mt: { xs: "16px", sm: "32px", md: "48px" },
            fontSize: { xs: "13px", sm: "15px", md: "20px" },
            fontWeight: "400",
            pb: { xs: "16px", sm: "32px", md: "48px" },
          }}
        >
          Please try changing the filters or broadening your search.
        </Typography>
      </Box>
    </Container>
  );
}
