import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import RequestCard from "../RequestCard";

import {
  downloadProductRequestSheet,
  selectSpaceRequestProductList,
  spaceRequestProductList,
} from "../../features/space/spaceAsyncThunk";
import { selectAuthUserInfo } from "../../features/authentication/authAsyncThunk";
import { selectProductDeleteByOrderId } from "../../features/project/projectAsyncThunk";

const RequestsTab = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const { csrfToken } = authUserInfo;
  const { payload: requestsList } = useSelector(selectSpaceRequestProductList);
  const { isSuccess: productDeleteByOrderIdSuccess } = useSelector(
    selectProductDeleteByOrderId
  );

  useEffect(() => {
    if (csrfToken) {
      if (params?.id) {
        dispatch(spaceRequestProductList(params?.id));
      }
    }
  }, [params, csrfToken, productDeleteByOrderIdSuccess]);

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(downloadProductRequestSheet(params?.id));
          }}
        >
          <Typography
            sx={{
              textDecoration: "underline",
              fontSize: 18,
              fontWeight: "600",
            }}
          >
            Download spreadsheet
          </Typography>
        </Box>
      </Box>
      {requestsList?.length > 0 &&
        requestsList?.map(({ category_name, products }, index) => {
          return (
            <RequestCard
              key={`request-${index}`}
              categoryName={category_name}
              products={products}
            />
          );
        })}
    </Box>
  );
};

export default RequestsTab;
