import TreeItem from "@mui/lab/TreeItem/TreeItem";
import TreeView from "@mui/lab/TreeView/TreeView";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useEffect, useState, useCallback, memo } from "react";
import { SidebarTreeItem } from "../CustomComponents";
import { useHistory, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { setSelectedCategoryId } from "../../features/category/categoryListSlice";
import { useDispatch, useSelector } from "react-redux";

const RecursiveSidebarTreeItem = memo(({ item, handleClick }) => {
  const handleItemClick = (e) => {
    e.stopPropagation();
    handleClick(item);
  };

  return (
    <SidebarTreeItem
      nodeId={item.fullname}
      label={item.name}
      key={item.id}
      onClick={handleItemClick}
    >
      {item.children &&
        item.children.map((childItem) => (
          <RecursiveSidebarTreeItem
            item={childItem}
            handleClick={handleClick}
            key={childItem.id}
          />
        ))}
    </SidebarTreeItem>
  );
});

const CategoryTreeView = memo(
  ({ newArr, handleItemClick, selected, expanded, handleNodeToggle }) => (
    <TreeView
      aria-label="file system"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
      expanded={expanded}
      onNodeToggle={handleNodeToggle}
      sx={{
        // flexGrow: 1,
        maxWidth: "100%",
        overflowY: "auto",
        // marginRight: "3px",
        " & .MuiTreeItem-content:hover": {
          backgroundColor: "#181818 !important",
          color: "#fff",
          borderRadius: "5px",
          paddingLeft: "5px !important",
        },
        " & .MuiTreeItem-content": {
          padding: "0px !important",
          transition: "0.2s ease-in-out",
        },
        " & .MuiTreeItem-label:hover": {
          fontWeight: "500 !important",
        },
      }}
    >
      {newArr.map((item, index) => (
        <RecursiveSidebarTreeItem
          item={item}
          handleClick={handleItemClick}
          key={index}
        />
      ))}
    </TreeView>
  )
);
const CategoryListDialog = ({
  category,
  hierarchyCategoryList,
  selectedCategory,
  setSelected,
  selected,
}) => {
  const history = useHistory();
  const [newArr, setNewArr] = useState([]);
  const location = useLocation();
  const [expanded, setExpanded] = useState("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   //get Hierarchy Category List at launch
  //   const createHierarchy = (data) => {
  //     const isImmediateChild = (parent, item) => {
  //       const parentPathParts = parent.path.split(".");
  //       const itemPathParts = item.path.split(".");
  //       return (
  //         itemPathParts.length === parentPathParts.length + 1 &&
  //         item.path.startsWith(parent.path)
  //       );
  //     };

  //     const findChildren = (parent) => {
  //       let children = data.filter((item) => isImmediateChild(parent, item));

  //       if (children.length > 0) {
  //         return {
  //           ...parent,
  //           children: children.map((child) => findChildren(child)),
  //         };
  //       } else {
  //         return parent;
  //       }
  //     };
  //     const topLevelItems = data.filter((item) => !item.path.includes("."));
  //     const result = topLevelItems.map((item) => findChildren(item));

  //     return result;
  //   };
  //   if (category) {
  //     const list = createHierarchy(category);
  //     setHierarchyCategoryList(list);
  //   }
  // }, [category]);

  useEffect(() => {
    // set selected and expanded when page is refreshed

    const pathArray = location.pathname.split("/");
    const searchCategoryIndex = pathArray.findIndex(
      (element) => element === "category" && pathArray.includes("search")
    );
    const productCategoryIndex = pathArray.findIndex(
      (element) => element === "product"
    );
    if (location.pathname.startsWith("/search/category/")) {
      const valueAfterCategory = pathArray[searchCategoryIndex + 1];
      setSelected(valueAfterCategory);

      const splitArray = valueAfterCategory.split("->");
      const resultArray = splitArray.map((_, index) => {
        return splitArray.slice(0, index + 1).join("->");
      });

      setExpanded(resultArray);
      console.log("in search");
    } else if (location.pathname.startsWith("/product") && selectedCategory) {
      setSelected(selectedCategory.fullname);

      const splitArray = selectedCategory.fullname.split("->");
      const resultArray = splitArray.map((_, index) => {
        return splitArray.slice(0, index + 1).join("->");
      });
      setExpanded(resultArray);
    } else {
      console.log("Value after 'category/' not found");
    }
  }, [location.pathname, selectedCategory]);

  useEffect(() => {
    // render category list when selectedCategory is changed

    const selectedHierarchyCategory = hierarchyCategoryList?.find(
      (category) => category?.name === selectedCategory?.name
    );
    const subCategories = selectedHierarchyCategory
      ? selectedHierarchyCategory.children
      : [];

    const updateUrl = (arr) => {
      return arr.map((x) => {
        const updatedItem = {
          ...x,
          url: `/search/category/${x.fullname}`,
        };
        if (x.children) {
          return {
            ...updatedItem,
            children: updateUrl(x.children),
          };
        } else {
          return updatedItem;
        }
      });
    };

    if (subCategories) {
      const manipulate = updateUrl(subCategories);
      setNewArr(manipulate);
    }
  }, [selectedCategory, hierarchyCategoryList]);

  function getParents(fullname) {
    const parts = fullname.split("->");

    return parts.slice(0, -1).map((_, i) => parts.slice(0, i + 1).join("->"));
  }

  const onRedireactClick = (path) => {
    history.push(path);
  };

  const handleItemClick = useCallback(
    (item) => {
      onRedireactClick(item.url);
      setSelected(item.fullname);
      dispatch(setSelectedCategoryId(item.id));

      if (expanded.includes(item.fullname)) {
        setExpanded(expanded.filter((id) => id !== item.fullname));
      } else {
        const parents = getParents(item.fullname);
        const newExpanded = [...parents, item.fullname].filter(
          (id, index, self) => self.indexOf(id) === index
        );
        setExpanded(newExpanded);
        // console.log(newExpanded);
      }
      // console.log(selectedCategory);
      // console.log(item);
    },

    [onRedireactClick, setSelected]
  );
  // console.log(selectedCategory);
  const handleNodeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  // console.log("selected", selected);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "0px !important",
        paddingTop: "12px !important",
        paddingLeft: "16px !important",
        paddingRight: "12px !important",
        background: "#282828",
        color: "#f2f2f2",
      }}
    >
      <Box
        sx={{
          minHeight: "calc(100% - 78%)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="title_primary_black"
          sx={{ color: "white", fontSize: "26px" }}
        >
          {selectedCategory && selectedCategory.name}
        </Typography>
        <Box
          id="style-2"
          sx={{
            marginTop: "16px",
            overflowX: "hidden",
            height: "100%",
          }}
        >
          {newArr.length > 0 ? (
            <CategoryTreeView
              newArr={newArr}
              handleItemClick={handleItemClick}
              selected={selected}
              expanded={expanded}
              handleNodeToggle={handleNodeToggle}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="title_primary_black"
                sx={{
                  fontSize: "15px",
                  color: "#5f5f5f",
                  textAlign: "left",
                  fontWeight: "500",
                  paddingTop: "12px",
                }}
              >
                Coming soon...
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          py: "24px",
        }}
      >
        {newArr.length > 0 ? (
          <Typography
            variant="title_secondary_black"
            sx={{
              color: "white",
              cursor: "pointer",
              fontWeight: "500",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              onRedireactClick("/brands");
            }}
          >
            All Brands
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};
export default CategoryListDialog;
