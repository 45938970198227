import { createSlice } from '@reduxjs/toolkit';
import { favoriteList, favoriteListBulk, } from './favoriteAsyncThunk';

const favoriteListSlice = createSlice({
  name: "favorite",
  initialState: {
    payload: [],
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [favoriteList.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [favoriteList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = [...action.payload.list];
    },
    [favoriteList.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
    [favoriteListBulk.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [favoriteListBulk.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = [...action.payload.list];
    },
    [favoriteListBulk.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
  },
});

export default favoriteListSlice.reducer;