import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Backdrop,
  Box,
  CircularProgress,
  DialogContentText,
  Typography,
} from "@mui/material";
import { BlackButton } from "./CustomButtons";
import { Dialog, StyledDialogTitle } from "./CustomComponents";
import { useDispatch, useSelector } from "react-redux";
import { selectProjectCreate } from "../features/project/projectAsyncThunk";
import ProjectCreate from "../features/project/ProjectCreate";
import { spaceProductsCreate } from "../features/spaceproducts/spaceProductsAsyncThunk";

export default function SelectProjectModal({
  open,
  onClose,
  handleSelectProject,
  setProjectId,
  spaceId,
  productVariantId,
  projectId,
  setSpaceId,
  projects,
  isLoading,
  setIsProductAdded
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showHint, setShowHint] = React.useState(false);
  const { isSuccess } = useSelector(selectProjectCreate);
  const [submitted, setSubmitted] = React.useState(false);
  const [openProjectModal, setOpenProjectModal] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isSuccess) {
      setSubmitted(true);
    }
  }, [isSuccess]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="space-dialog-title"
      >
        {submitted ? (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { md: 512, sm: "100%" },
            }}
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: "600", marginBottom: "1rem" }}
            >
              Added to your project
            </Typography>
          </DialogContent>
        ) : (
          <>
            <StyledDialogTitle id="space-dialog-title" onClose={onClose}>
              Select Project
            </StyledDialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: 512, sm: "100%" },
                p: 3,
              }}
            >
              {isLoading ? (
                <Backdrop
                  open
                  sx={{
                    zIndex: (theme) => theme.zIndex.modal + 1,
                    color: "common.white",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                projects.map((project, index) => {
                  return (
                    <Box
                      sx={{
                        mb: 2,
                        paddingLeft: "1rem",
                        cursor: "pointer",
                        backgroundColor:
                          projectId === project?.id ? "#F2F2F2" : "transparent",
                      }}
                      key={`select-project-${index}`}
                      onClick={() => {
                        setProjectId(project?.id);
                        handleSelectProject(project?.space_id);
                        setShowHint(false);
                      }}
                    >
                      <Typography
                        sx={{
                          mx: projectId === project?.id ? "10px" : "0",
                        }}
                      >
                        {project.project_name}
                      </Typography>
                    </Box>
                  );
                })
              )}
              <Box
                sx={{ marginTop: "1rem", width: "90px", cursor: "pointer" }}
                onClick={() => {
                  setOpenProjectModal(true);
                  onClose();
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#181818",
                    borderBottom: 0.5,
                    borderColor: "#181818",
                    whiteSpace: "nowrap",
                  }}
                >
                  New project
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ p: 0 }}>
              <BlackButton
                sx={{
                  width: "100%",
                  height: 55,
                  margin: (theme) => theme.spacing(0, 3, 3, 3),
                }}
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (Boolean(spaceId) === false) {
                    setShowHint(true);
                    return;
                  }
                  await dispatch(
                    spaceProductsCreate({
                      productVariationID: productVariantId,
                      projectSpaceId: spaceId,
                    })
                  );
                  onClose();
                  setProjectId(0);
                  setSpaceId(0);
                  setIsProductAdded(true);
                }}
              >
                Add to project
              </BlackButton>
            </DialogActions>
            {showHint && (
              <DialogContentText
                sx={{
                  margin: (theme) => theme.spacing(0, 3, 3, 3),
                  textAlign: "center",
                }}
              >
                Oops! Something is still wrong above.
              </DialogContentText>
            )}
          </>
        )}
      </Dialog>
      {openProjectModal && (
        <ProjectCreate showModal onClose={() => setOpenProjectModal(false)} />
      )}
    </div>
  );
}
