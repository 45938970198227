import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { categoryRelevant, selectCategoryRelevant } from "./categoryAsyncThunk";

import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Slider from "../../components/Slider";
import { useHistory } from "react-router-dom";

function CategoryRelevant({ product_id, category_id, categoryURL }) {
  const dispatch = useDispatch();
  const categoryRelevantStore = useSelector(selectCategoryRelevant);
  const { payload } = categoryRelevantStore;
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    if (Boolean(product_id) === true && Boolean(category_id) === true)
      dispatch(categoryRelevant({ product_id, category_id }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id, category_id]);

  return (
    <React.Fragment>
      {Array.isArray(payload) && payload.length > 0 && (
        <Box sx={{ background: "#F6F6F6", my: 0 }}>
          <Box
            sx={{
              // mb: 2,
              pl: { xs: 2, sm: 4, md: 6 },
              pt: { xs: "20px", md: 3 },
              pb: { xs: "8px", md: "16px" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              component={RouterLink}
              to={categoryURL}
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <Typography
                color="primary.main"
                fontSize="20px"
                align="left"
                noWrap
                sx={{
                  cursor: "pointer",
                  marginLeft: "6px",
                  // "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => history.push(categoryURL)}
              >
                <b>More {payload[0]?.category_name}</b>
              </Typography>
              <ChevronRightIcon
                sx={{
                  fontSize: { xs: "28px", md: "32px" },
                  color: theme.palette.text.secondary,
                }}
              />
            </Link>
          </Box>
          <Box>
            <Slider product={payload} />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

CategoryRelevant.propTypes = {
  product_id: PropTypes.number.isRequired,
  category_id: PropTypes.number.isRequired,
};

export default CategoryRelevant;
