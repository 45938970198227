import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const projectCreate = createAsyncThunk(
  "project/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/`,
        payload
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const activeProjectList = createAsyncThunk(
  "project/list",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/active`,
        { withCredentials: true }
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const archiveProjectList = createAsyncThunk(
  "project/archive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/archive`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const projectEdit = createAsyncThunk(
  "project/edit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/`,
        payload
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const projectDelete = createAsyncThunk(
  "project/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${id}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const updateProjectStatus = createAsyncThunk(
  "project/status",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/status`,
        payload
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const projectCoWorkerCreate = createAsyncThunk(
  "project/create-co-worker",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-role/`,
        payload
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const projectCoWorkersList = createAsyncThunk(
  "project/list-co-worker",
  async (projectId, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-role/${projectId}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productDeleteByOrderId = createAsyncThunk(
  "project/deleteByOrderId",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/delete/${id}`
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const selectProjectCreate = (state) => state.projectCreate;
export const selectProjectList = (state) => state.projectList;
export const selectProjectEdit = (state) => state.projectEdit;
export const selectProjectDelete = (state) => state.projectDelete;
export const selectArchiveProjectList = (state) => state.archiveProjectList;
export const selectUpdateProjectStatus = (state) => state.updateProjectStatus;
export const selectProjectCoWorkerCreate = (state) =>
  state.projectCoWorkerCreate;
export const selectProjectCoWorkersList = (state) => state.projectCoWorkersList;
export const selectProductDeleteByOrderId = (state) =>
  state.productDeleteByOrderId;
