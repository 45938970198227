import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { WhiteButton, PrimaryRoundButton } from "./CustomButtons";
import { red, grey, lightBlue, common } from "@mui/material/colors";
import RegDesktop from "../features/registration/RegDesktop";
import RegMobile from "../features/registration/RegMobile";
import { Paper } from "@mui/material";
import { useHistory } from "react-router-dom";

const customWhite = { ...grey, 100: common.white };

export const DivLandingPage = styled("div")(({ theme }) => ({
  position: "relative",
  height: "90vh",
  [theme.breakpoints.up("sm")]: {
    // minHeight: theme.spacing(112.5),
  },
}));

const TypographyText = styled(Typography)(({ theme }) => ({
  // fontSize: "48px",
  width: "100%",
  gutter: "12px",
  textAlign: "left",
  whiteSpace: "normal",
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
    width: "100%",
    textAlign: "left",
    // marginTop: "center",
  },
  [theme.breakpoints.up("sm")]: { fontSize: 30, width: "90%" },
  [theme.breakpoints.up("md")]: { fontSize: 36, width: "80%" },
  [theme.breakpoints.up("xl")]: { fontSize: 48, width: "70%" },
}));

export const LandingStyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  zIndex: theme.zIndex.mobileStepper - 1,
  color: theme.palette.text.primary,
}));

export default function LandingPageBanner() {
  const theme = useTheme();
  const history = useHistory();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const smOnly = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        flexGrow: 1,
        color: "common.black",
        // mb: { xs: 1, md: 3 },
        // height: "auto",
      }}
    >
      <DivLandingPage
        theme={theme}
        sx={{
          backgroundImage: `url("https://ik.imagekit.io/fylhgvwjvzq/landingpage/175895002_l_normal_none_3K_HO58ecJJt.jpg")`,
          backgroundSize: "cover",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
        }}
      >
        <Paper
          sx={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.20)30%, rgba(0,0,0,0)50%)",
            height: "100%",
          }}
        >
          <LandingStyledBox
            theme={theme}
            sx={{
              mx: { xs: 2, sm: 4, md: 6 },
              alignItems: "left",
            }}
          >
            <TypographyText
              theme={theme}
              sx={{
                color: "white",
                lineHeight: 1.2,
                marginBottom: 4,
              }}
            >
              <b>
                The new way for interior <br></br>professionals to source
                materials
              </b>
            </TypographyText>
            <Box
              component="span"
              sx={{
                display: { sm: "flex" },
              }}
            >
              <PrimaryRoundButton
                onClick={() => {
                  history.push("/search/category/Materials");
                }}
                sx={{
                  border: 1,
                  display: { xs: "block", sm: "flex" },
                  margin: "8px",
                  ml: 0,
                  borderColor: "transparent",
                  "&:hover": {
                    backgroundColor: customWhite[200],
                  },
                }}
              >
                Browse Materials
              </PrimaryRoundButton>
              {sm && <RegDesktop type={1}></RegDesktop>}
              {smOnly && <RegMobile onlyMobile={"yes"} />}
            </Box>
          </LandingStyledBox>
        </Paper>
      </DivLandingPage>
    </Box>
  );
}
