import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PrimarySearchAppBar from "./TopBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tooltip, Typography } from "@mui/material";
import { SidebarPopover } from "../components/CustomComponents";
import CategoryListDialog from "../components/sidebarMenu/CategoryListDialog";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCategoryList } from "../features/category/categoryAsyncThunk";
import MatterBaseLogo4 from "../components/img/MatterbaseLogo4";
import { setSelectedCategoryId } from "../features/category/categoryListSlice";
import "../App.css";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  background: "#282828",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "#282828",
  overflowX: "hidden",
  width: "120px",
  [theme.breakpoints.up("sm")]: {
    width: `70px`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout({ children, productInfo }) {
  const [open, setOpen] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState("");
  const [categoryListArr, setCategoryListArr] = useState([]);
  const categoryListStore = useSelector(selectCategoryList);
  const { payload: category } = categoryListStore;
  const [brandOpen, setBrandOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [sortedCategoryArr, setSortedCategoryArr] = useState([]);
  const [filteredSubCategoryArr, setFilteredSubCategoryArr] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hierarchyCategoryList, setHierarchyCategoryList] = useState([]);

  const xs = useMediaQuery("(max-width:450px)");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open2 = Boolean(anchorEl);

  const popoverRef1 = useRef(null);
  const popoverRef2 = useRef(null);
  const topBarRef = useRef(null);

  function getFirstInteger(str) {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  useEffect(() => {
    let sortedCategoryArr = [];
    if (category) {
      let CategoryArr = category.filter((item) => item.parent_id === null);
      sortedCategoryArr = CategoryArr.sort(function (a, b) {
        return a.id - b.id;
      });
      setSortedCategoryArr(sortedCategoryArr);

      let sortedSubCategoryArr = [];
      let subCategoryArr = category.filter((item) => item.parent_id !== null);
      sortedSubCategoryArr = subCategoryArr.sort(function (a, b) {
        return a.id - b.id;
      });
      setCategoryListArr(sortedCategoryArr);

      const filteredArr =
        selectedCategory &&
        sortedSubCategoryArr.filter(
          (item) => getFirstInteger(item.path) === selectedCategory.id
        );
      setFilteredSubCategoryArr(filteredArr);
    }
  }, [category]);

  useEffect(() => {
    //search url for category when in search page
    if (location.pathname.includes("search") && sortedCategoryArr) {
      const pathArray = location.pathname.split("/");
      const searchCategoryIndex = pathArray.findIndex(
        (element) => element === "category" && pathArray.includes("search")
      );
      const selectedCategoryName =
        searchCategoryIndex !== -1 && pathArray[searchCategoryIndex + 1]
          ? pathArray[searchCategoryIndex + 1].split("->")[0]
          : "";
      const selectedCategory = sortedCategoryArr.find(
        (item) => item.name.toLowerCase() === selectedCategoryName.toLowerCase()
      );
      setSelectedCategory(selectedCategory);
      setCategoryName(selectedCategoryName);

      // console.log(selectedCategory, "selectedCategory");
    } else if (
      //search url for category when in product page
      location.pathname.includes("product") &&
      productInfo &&
      sortedCategoryArr
    ) {
      const input = productInfo.fullname;
      const delimiter = "->";
      const category = input.split(delimiter)[0];

      const updatedProductInfo = { ...productInfo, name: category };

      setSelectedCategory(updatedProductInfo);
      setCategoryName(category);
    } else {
      setSelectedCategory("");
      setCategoryName("");
      setAnchorEl(null);
    }
  }, [sortedCategoryArr, productInfo, location.pathname]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(setSelectedCategoryId(selectedCategory.id));
    }
  }, [selectedCategory]);

  useEffect(() => {
    //get Hierarchy Category List at launch
    const createHierarchy = (data) => {
      const isImmediateChild = (parent, item) => {
        const parentPathParts = parent.path.split(".");
        const itemPathParts = item.path.split(".");
        return (
          itemPathParts.length === parentPathParts.length + 1 &&
          item.path.startsWith(parent.path)
        );
      };

      const findChildren = (parent) => {
        let children = data.filter((item) => isImmediateChild(parent, item));

        if (children.length > 0) {
          return {
            ...parent,
            children: children.map((child) => findChildren(child)),
          };
        } else {
          return parent;
        }
      };
      const topLevelItems = data.filter((item) => !item.path.includes("."));
      const result = topLevelItems.map((item) => findChildren(item));

      return result;
    };

    const list = createHierarchy(category);

    const sortedArray = list.sort((a, b) => {
      if (a.name === "Materials") return -1;
      if (b.name === "Materials") return 1;
      return a.name.localeCompare(b.name);
    });

    setHierarchyCategoryList(sortedArray);
  }, [category]);

  const handleCloseOnMouseLeave = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };
  document.addEventListener("mouseleave", handleCloseOnMouseLeave);

  const handleChange = (event, newValue) => {
    if (newValue) {
      setCategoryName(newValue);

      history.push(`/search/category/${newValue}`);
    } else {
      return;
    }
  };

  const handleClick = (item) => {
    setSelectedCategory(item);
    dispatch(setSelectedCategoryId(item.id));
    history.push(`/search/category/${item.name}`);
  };

  // console.log(selectedCategory, "selectedCategory");
  const handleOpenPopover = (event) => {
    let id = event.currentTarget.id;
    let menuIds = id ? id.split("T-")[1] : "";

    setCategoryName(menuIds);

    setAnchorEl(event.currentTarget);
  };

  const handleOpen = (e) => {
    if (
      location.pathname.startsWith("/search/category") ||
      location.pathname.startsWith("/product")
    ) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeDrawer = () => {
    setAnchorEl(null);

    setBrandOpen(!brandOpen);
  };

  const isMouseInsidePopover = (mouseX, mouseY, ref) => {
    const popoverElement = ref.current;
    if (!popoverElement) return false;

    const rect = popoverElement.getBoundingClientRect();
    return (
      mouseX >= rect.left &&
      mouseX <= rect.right &&
      mouseY >= rect.top &&
      mouseY <= rect.bottom
    );
  };

  const isMouseInsideAnyComponent = (mouseX, mouseY) => {
    return (
      isMouseInsidePopover(mouseX, mouseY, popoverRef1) ||
      isMouseInsidePopover(mouseX, mouseY, popoverRef2)
    );
  };

  const handleMouseEnter = (event) => {
    if (!isMouseInsideAnyComponent(event.clientX, event.clientY)) {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (open2) {
      document.addEventListener("mouseover", handleMouseEnter);
    } else {
      document.removeEventListener("mouseover", handleMouseEnter);
    }
    return () => {
      document.removeEventListener("mouseover", handleMouseEnter);
    };
  }, [open2]);

  useEffect(() => {
    if (open2) {
      document.body.classList.add("no-scroll");
      if (topBarRef.current) {
        topBarRef.current.classList.add("top-bar-padding");
      }
    } else {
      document.body.classList.remove("no-scroll");
      if (topBarRef.current) {
        topBarRef.current.classList.remove("top-bar-padding");
      }
    }

    return () => {
      document.body.classList.remove("no-scroll");
      if (topBarRef.current) {
        topBarRef.current.classList.remove("top-bar-padding");
      }
    };
  }, [open2]);

  return (
    <Box sx={{ display: "flex" }}>
      <TabContext value={categoryName}>
        <Drawer ref={popoverRef2} variant="permanent" onMouseOver={handleOpen}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pt: 1.5,
            }}
          >
            <IconButton
              onClick={() => history.push("/")}
              sx={{
                display: "flex",
                transition: "1s ease all",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MatterBaseLogo4 width="35px" height="27px" />
            </IconButton>
          </Box>
          <TabList
            orientation="vertical"
            variant="scrollable"
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              color: "#fff",
              marginTop: "10px",
              "& .MuiButtonBase-root-MuiTab-root": {
                minWidth: "none !important",
              },
            }}
            TabIndicatorProps={{
              style: {
                left: 3,
                borderLeft: "3.5px solid #fff",
              },
            }}
          >
            {categoryListArr.length > 0 &&
              categoryListArr.map((item, index) => (
                <Tab
                  sx={{
                    minWidth: 0,
                    my: "4px",
                    p: 0,
                  }}
                  key={index}
                  onClick={(e) => {
                    handleOpenPopover(e);
                    handleClick(item);
                    // history.push(`/search/category/${selectedCategory.name}`);
                  }}
                  icon={
                    <Tooltip
                      title={
                        <Typography fontSize="12px">{item.name}</Typography>
                      }
                      placement="right"
                      key={index}
                      arrow
                      componentsProps={{
                        popper: {
                          sx: {
                            // paddingLeft: "4px",
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              backdropFilter: "blur(8px)",
                            },
                            "& .MuiTooltip-arrow": {
                              color: "rgba(0, 0, 0, 0.5)",
                              backdropFilter: "blur(8px)",
                            },
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: "12px 16px !important",
                          "&:hover .category-menu-icons": {
                            filter: "brightness(200%)",
                          },
                        }}
                      >
                        <Box
                          as="img"
                          className="category-menu-icons"
                          src={item.imageURL}
                          sx={{
                            filter:
                              categoryName === item.name
                                ? "brightness(200%)"
                                : "brightness(50%)",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  }
                  value={item.name}
                />
              ))}
          </TabList>
        </Drawer>
        {/* <Box onMouseLeave={handleClose}> */}
        <Box
          ref={popoverRef1}
          sx={{
            borderLeft: "2px solid #4f4e4e !important",
            borderRadius: "0px !important",
            left: "69px !important",
            transition: "width 0s !important",
            overflow: "hidden !important",
            height: "100%",
            width: "16%",
            minWidth: "220px",
            maxWidth: "300px",
            display: { xs: "none", md: open2 ? "block" : "none" },
            position: "fixed",
            zIndex: 1500,

            "& .MuiBackdrop-root": {
              marginLeft: "8% !important",
            },
            "& .MuiBackdrop-invisible": { width: "0% !important" },
          }}
          onMouseLeave={handleClose}
        >
          {categoryListArr.length > 0 &&
            categoryListArr.map((item, index) => (
              <TabPanel
                key={index}
                value={item.name}
                sx={{
                  p: 0,
                  m: 0,
                }}
              >
                <CategoryListDialog
                  category={category}
                  selectedCategory={selectedCategory}
                  selected={selected}
                  setSelected={setSelected}
                  hierarchyCategoryList={hierarchyCategoryList}
                />
              </TabPanel>
            ))}
          {/* </Box> */}
          {/* <SidebarPopover
            anchorReference="anchorPosition"
            open={open2}
            marginThreshold={0}
            onClose={handleClose}
            disableRestoreFocus
            // disableTransition
            // PaperProps={{ onMouseLeave: handleClose }}
            transitionDuration={{ enter: 0, exit: 0 }}
            anchorPosition={{ top: 0, left: 0 }}
            transformOrigin={{ horizontal: "left", vertical: "center" }}
            sx={{
              borderLeft: "2px solid #4f4e4e !important",
              borderRadius: "0px !important",
              left: "69px !important",
              transition: "width 0s !important",
              overflow: "hidden !important",
              // width: "100% !important",
              height: "100%",
              display: { xs: "none", md: open2 ? "block" : "none" },

              "& .MuiBackdrop-root": {
                marginLeft: "8% !important",
              },
              "& .MuiBackdrop-invisible": { width: "0% !important" },
            }}
          >
            {categoryListArr.length > 0 &&
              categoryListArr.map((item, index) => (
                <TabPanel
                  key={index}
                  value={item.name}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                >
                  <CategoryListDialog
                    category={category}
                    selectedCategory={selectedCategory}
                    selected={selected}
                    setSelected={setSelected}
                    hierarchyCategoryList={hierarchyCategoryList}
                  />
                </TabPanel>
              ))}
          </SidebarPopover> */}
        </Box>
      </TabContext>
      <Box component="main" sx={{ flexGrow: 1, background: "#F6F6F6" }}>
        <PrimarySearchAppBar
          hierarchyCategoryList={hierarchyCategoryList}
          selected={selected}
          setSelected={setSelected}
          handleClick={handleClick}
          open2={open2}
        />
        <Box sx={{ mt: "0%" }}>{children}</Box>
      </Box>
    </Box>
  );
}
