import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userForgetPassword, selectUserForgetPassword } from "./userAsyncThunk";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StyledTextField } from "../../components/CustomComponents";
import { BlackButton } from "../../components/CustomButtons";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(10, 1),
  "& > *": {
    margin: theme.spacing(2, 0),
  },
}));

export default function UserForgetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userForgetPasswordStore = useSelector(selectUserForgetPassword);
  const { isSuccess: userForgetPasswordSuccess } = userForgetPasswordStore;
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    email: "",
  });
  const [showHint, setShowHint] = useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(userForgetPassword(values));
  };

  useEffect(() => {
    if (userForgetPasswordSuccess) history.replace("/");
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForgetPasswordSuccess]);

  return (
    <React.Fragment>
      <StyledContainer maxWidth="sm">
        <Typography variant="h3">Forget Password?</Typography>
        <Typography variant="h5">
          Enter your registered email to receive the recovery instruction.
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={10}>
            <StyledTextField
              error={
                isUserActionMade &&
                Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
              }
              defaultValue={values.email}
              onChange={handleValues("email")}
              variant="outlined"
              margin="dense"
              size="small"
              id="email"
              placeholder="your email here"
              type="email"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <BlackButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </BlackButton>
          </Grid>
        </Grid>
        {showHint && (
          <Typography variant="body1">
            Oops! Something is wrong above.
          </Typography>
        )}
      </StyledContainer>
    </React.Fragment>
  );
}
