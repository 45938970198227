import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListBrand,
  selectCatalogListBrand,
} from "../features/catalog/catalogAsyncThunk";
import Container from "@mui/material/Container";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { modal_background_colour } from "./styles/materialUIStyles";
import Grid from "@mui/material/Grid";
import AppBarBrandGroupButton from "./AppBarBrandGroupButton";
import AppBarMenuButton from "./AppBarMenuButton";

export default function BrandContentTable() {
  const dispatch = useDispatch();
  const catalogListBrandStore = useSelector(selectCatalogListBrand);
  const { payload } = catalogListBrandStore;
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    dispatch(catalogListBrand());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selections = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    selections.unshift("&@#");
    selections.unshift("0-9");
    let master = selections.map((x) =>
      Object.assign({}, { id: -1, url: null, logoURL: null, name: x })
    );
    const manipulate = payload
      .filter((a) => a.isDisabled === false && a.isProductExists === true)
      .map((x) => {
        return { ...x, url: `/brand/${x.name.replaceAll(" ", "-")}-${x.id}` };
      });
    const answer = [];
    for (let i = 0; i < selections.length; i++) {
      answer.push({ ...master[i] });
      const filteredBrands = manipulate.filter((x) => {
        if (master[i].name === "0-9") {
          return x.name.match(/^\d/);
        }
        if (master[i].name === "&@#") {
          return x.name.match(/^[~!@#$%^&*()_=?]/);
        }
        return x.name.toLowerCase().startsWith(master[i].name.toLowerCase());
      });
      if (filteredBrands.length === 0) {
        answer.pop();
      } else {
        answer.push(...filteredBrands);
      }
    }
    setBrand([...answer]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Container
      maxWidth={false}
      sx={{
        mb: 10,
        px: { xs: 2, sm: 4, md: 6 },
        mt: { xs: "56px", md: "64px" },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "start",
          fontSize: { xs: 28, sm: 30, md: 32 },
          fontWeight: 600,
          px: 0,
          pt: { xs: 2, sm: 4, md: 6 },
          pb: 0,
          color: "#181818",
        }}
      >
        Material Brand A~Z
      </DialogTitle>
      <DialogContent
        sx={{
          // background: modal_background_colour,
          // height: "fit-content",
          // padding: "20px 48px",
          px: 0,
          py: 0,
        }}
      >
        <Box my={2}>
          <Grid container spacing={0}>
            {brand
              .filter((ele) => !ele.isEOL)
              .map((item, j) =>
                Boolean(item.url) === true ? (
                  <Box
                    key={j}
                    sx={{
                      width: 1,
                    }}
                  >
                    <AppBarMenuButton {...item} />
                  </Box>
                ) : (
                  <Box
                    key={j}
                    sx={{
                      width: 1,
                      borderBottom: item.id === -1 ? "1px solid rgba(0,0,0,0.56)" : "none",
                      marginTop: item.id === -1 ? 2 : 0,
                    }}
                  >
                    <AppBarBrandGroupButton {...item} />
                  </Box>
                )
              )}
          </Grid>
        </Box>
      </DialogContent>
    </Container>
  );
}
