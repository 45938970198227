import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListCountry,
  catalogListSupplier,
  selectCatalogListCountry,
  selectCatalogListSupplier,
} from "../catalog/catalogAsyncThunk";
import {
  brandEdit,
  brandUpdateBoolean,
  selectBrandUpdateBoolean,
} from "./brandAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import SinglePreviewImageList from "../../components/SinglePreviewImageList";
import { useSnackbar } from "notistack";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { LightBlueButton, BlackButton } from "../../components/CustomButtons";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML, convertFromHTML } from "draft-convert";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const ImgBrandLogo = styled("img")(({ theme }) => ({
  width: theme.spacing(10),
  objectFit: "cover",
}));

const ImgBrandBanner = styled("img")(({ theme }) => ({
  width: theme.spacing(20),
  objectFit: "cover",
}));

function BrandEdit({ isSelected, data }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const catalogListCountryStore = useSelector(selectCatalogListCountry);
  const {
    isSuccess: brandUpdateBooleanSuccess,
    isLoading: brandUpdateBooleanLoading,
  } = useSelector(selectBrandUpdateBoolean);
  const { isLoading: countryListLoading, payload: countryList } =
    catalogListCountryStore;
  const catalogListSupplierStore = useSelector(selectCatalogListSupplier);
  const { isLoading: supplierListLoading, payload: supplierList } =
    catalogListSupplierStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);

  const blocks = convertFromHTML("<p>djijdijddj</p>");

  let _contentState = ContentState.createFromText("Sample content state");
  const raw = convertToRaw(_contentState);
  const [contentState, setContentState] = useState(raw);
  const [editorUpIntro, setEditorUpIntro] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorAbtract, setEditorAbtract] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorDownIntro, setEditorDownIntro] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (data.leftIntro) {
      const blocksFromHTML = convertFromHTML(data.leftIntro);
      const editorValue = EditorState.createWithContent(blocksFromHTML);
      setEditorUpIntro(editorValue);
    }
  }, [data]);

  useEffect(() => {
    if (data.rightIntro) {
      const blocksFromHTML = convertFromHTML(data.rightIntro);
      const editorValue = EditorState.createWithContent(blocksFromHTML);
      setEditorDownIntro(editorValue);
    }
  }, [data]);

  useEffect(() => {
    if (data.abtract) {
      const blocksFromHTML = convertFromHTML(data.abtract);
      const editorValue = EditorState.createWithContent(blocksFromHTML);
      setEditorAbtract(editorValue);
    }
  }, [data]);

  // const [news, setNews] = useState(() =>
  //   EditorState.createWithContent(
  //     ContentState.createFromText(
  //       convertFromHTML(data.leftIntro ? data.leftIntro : "sksksk")
  //     )
  //   )
  // );

  // console.log("leftIntro", news);
  const [convertedContent, setConvertedContent] = useState("");
  const [convertedContent2, setConvertedContent2] = useState("");
  const [convertedContent3, setConvertedContent3] = useState("");
  const [imagesValidation, setImageValidate] = useState("");

  const [values, setValues] = useState({
    id: data.id,
    name: data.name,
    country: data.country,
    country_id: data.country_id,
    country_object: data.country_object,
    website: data.website,
    logoURL: data.logoURL,
    bannerURL: data.bannerURL,
    supplier: data.supplier,
    supplier_id: data.supplier_id,
    supplier_object: data.supplier_object,
    leftIntro: data.leftIntro,
    rightIntro: data.rightIntro,
    image_ids: data.image_ids,
    image_objects: data.image_objects,
    isSupplier: data.isSupplier,
    isHKOffice: data.isHKOffice,
    isEOL: data.isEOL,
    isShowInLandingPage: data.isShowInLandingPage,
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorUpIntro.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const convertContentToHTML2 = () => {
    let currentContentAsHTML = convertToHTML(
      editorDownIntro.getCurrentContent()
    );
    setConvertedContent2(currentContentAsHTML);
  };
  const convertContentToHTML3 = () => {
    let currentContentAsHTML = convertToHTML(editorAbtract.getCurrentContent());
    setConvertedContent3(currentContentAsHTML);
  };
  const handleEditorChange = (state) => {
    setEditorUpIntro(state);
    convertContentToHTML();
  };
  const handleEditorChange2 = (state) => {
    setEditorDownIntro(state);
    convertContentToHTML2();
  };
  const handleEditorChange3 = (state) => {
    setEditorAbtract(state);
    convertContentToHTML3();
  };
  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    if (prop === "country_object") newObj.country_id = newValue.id;
    if (prop === "supplier_object") newObj.supplier_id = newValue.id;
    setValues(newObj);
  };

  const handleImageAdd = (image) => {
    let image_ids = [...values.image_ids];
    let image_objects = [...values.image_objects];
    image_ids.push(image.id);
    image_objects.push(image);
    setValues({ ...values, image_ids, image_objects });
  };

  const handleImageRemove = (index) => (event) => {
    let image_ids = [...values.image_ids];
    let image_objects = [...values.image_objects];
    image_ids.splice(index, 1);
    image_objects.splice(index, 1);
    if (image_ids.length > 0) {
      setValues({ ...values, image_ids, image_objects });
    } else {
      enqueueSnackbar("At least 1 picture required!", {
        message: "info",
        autoHideDuration: 3000,
      });
    }
  };

  const handleImageMoveLeft = (index) => (event) => {
    let image_ids = [...values.image_ids];
    let image_objects = [...values.image_objects];
    const swap_id = image_ids.splice(index, 1);
    const swap_object = image_objects.splice(index, 1);
    image_ids.splice(Math.abs(index - 1), 0, ...swap_id);
    image_objects.splice(Math.abs(index - 1), 0, ...swap_object);
    if (image_ids.length > 0) {
      setValues({ ...values, image_ids, image_objects });
    } else {
      enqueueSnackbar("At least 1 picture required!", {
        message: "info",
        autoHideDuration: 3000,
      });
    }
  };

  const handleSubmit = () => {
    const params = {
      ...values,
      isEOL: data?.isEOL,
      leftIntro: convertedContent
        ? convertedContent.toString()
        : data.leftIntro,
      rightIntro: convertedContent2
        ? convertedContent2.toString()
        : data.rightIntro,
      abtract: convertedContent3 ? convertedContent3.toString() : data.abtract,
    };
    setIsUserActionMade(true);
    if (Boolean(params.name) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.website) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.leftIntro) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.rightIntro) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.logoURL) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.bannerURL) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.image_ids.length !== 3)) {
      setShowHint(true);
      setImageValidate("Upload atleast 3 images !");
      return;
    }
    setShowHint(false);
    dispatch(brandEdit(params));
  };

  useEffect(() => {
    dispatch(catalogListCountry());
    dispatch(catalogListSupplier());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      id,
      name,
      website,
      logoURL,
      bannerURL,
      supplier,
      supplier_id,
      supplier_object,
      country,
      country_id,
      country_object,
      leftIntro,
      rightIntro,
      image_ids,
      image_objects,
      isSupplier,
      isHKOffice,
      isEOL,
      isShowInLandingPage,
    } = data;
    setValues({
      id,
      name,
      website,
      logoURL,
      bannerURL,
      supplier,
      supplier_id,
      supplier_object,
      country,
      country_id,
      country_object,
      leftIntro,
      rightIntro,
      image_ids,
      image_objects,
      isSupplier,
      isHKOffice,
      isEOL,
      isShowInLandingPage,
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <LightBlueButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Edit
      </LightBlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-edit-brand"
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <StyledDialogTitle id="form-dialog-edit-brand" onClose={handleClose}>
          Edit Brand
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.name) === false}
            defaultValue={values.name}
            onChange={handleValues("name")}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            sx={{ mb: 1.5, mt: 0 }}
            placeholder="Name"
            fullWidth
          />
          <Autocomplete
            id="country"
            options={countryList}
            loading={countryListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.country_object}
            onChange={handleAutoCompleteValues("country_object")}
            fullWidth
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Country"
                sx={{ mb: 1.5, mt: 0 }}
                variant="outlined"
              />
            )}
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.website) === false}
            defaultValue={values.website}
            onChange={handleValues("website")}
            variant="outlined"
            margin="dense"
            size="small"
            id="website"
            sx={{ mb: 1.5, mt: 0 }}
            placeholder="Website"
            fullWidth
          />
          <Autocomplete
            id="supplier"
            options={supplierList}
            loading={supplierListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.supplier_object}
            onChange={handleAutoCompleteValues("supplier_object")}
            fullWidth
            disableClearable
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Supplier"
                variant="outlined"
                sx={{ mb: 1.5, mt: 0 }}
              />
            )}
          />
          <Editor
            editorState={editorUpIntro}
            onEditorStateChange={handleEditorChange}
            placeholder="Introduction (Up)"
            wrapperClassName="wrapper-class"
            onFocus={true}
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <hr />
          <Editor
            editorState={editorDownIntro}
            placeholder="Introduction (Down)"
            onEditorStateChange={handleEditorChange2}
            wrapperClassName="wrapper-class"
            onFocus={true}
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <hr />
          <Editor
            editorState={editorAbtract}
            placeholder="Brand abstract"
            onEditorStateChange={handleEditorChange3}
            wrapperClassName="wrapper-class"
            onFocus={true}
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <hr />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isSupplier}
                onChange={(e) =>
                  setValues({ ...values, isSupplier: e.target.checked })
                }
                name="isSupplier"
                color="primary"
                inputProps={{
                  "aria-label": "Is Supplier",
                }}
              />
            }
            label="Is Supplier"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isHKOffice}
                onChange={(e) =>
                  setValues({ ...values, isHKOffice: e.target.checked })
                }
                name="isHKOffice"
                color="primary"
                inputProps={{
                  "aria-label": "Hong Kong Office",
                }}
              />
            }
            label="Hong Kong Office"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isEOL}
                onChange={(e) =>
                  setValues({ ...values, isEOL: e.target.checked })
                }
                name="isEOL"
                color="primary"
                inputProps={{
                  "aria-label": "End of Life",
                }}
              />
            }
            label="End of Life"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isShowInLandingPage}
                onChange={(e) => {
                  dispatch(
                    brandUpdateBoolean({
                      id: values?.id,
                      isShowInLandingPage: e.target.checked,
                    })
                  );
                  if (brandUpdateBooleanSuccess) {
                    setValues({
                      ...values,
                      isShowInLandingPage: e.target.checked,
                    });
                  }
                }}
                name="isShowInLandingPage"
                color="primary"
                inputProps={{
                  "aria-label": "Is Show In Landing Page",
                }}
              />
            }
            label="Is Show In Landing Page"
          />
          <ImageCreate
            label="Brand Logo URL"
            error={isUserActionMade && Boolean(values.logoURL) === false}
            onFinish={(image) => setValues({ ...values, logoURL: image.url })}
          />
          {values.logoURL && (
            <ImgBrandLogo src={values.logoURL} alt="Brand Logo" />
          )}
          <ImageCreate
            label="Brand Banner URL"
            error={isUserActionMade && Boolean(values.bannerURL) === false}
            onFinish={(image) => setValues({ ...values, bannerURL: image.url })}
          />
          {values.bannerURL && (
            <ImgBrandBanner src={values.bannerURL} alt="Brand Banner" />
          )}
          <ImageCreate
            disabled={values.image_ids?.length > 2 ? true : false}
            label="Image List"
            onFinish={handleImageAdd}
          />
          <SinglePreviewImageList
            image_objects={values.image_objects}
            onRemove={handleImageRemove}
            onLeft={handleImageMoveLeft}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            p: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(1, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Update Brand
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops!{" "}
            {imagesValidation
              ? imagesValidation
              : "Something is still wrong above."}
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

BrandEdit.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    country: PropTypes.string,
    country_id: PropTypes.number,
    country_object: PropTypes.object,
    website: PropTypes.string,
    logoURL: PropTypes.string,
    bannerURL: PropTypes.string,
    supplier: PropTypes.string,
    supplier_id: PropTypes.number,
    supplier_object: PropTypes.object,
    leftIntro: PropTypes.string,
    rightIntro: PropTypes.string,
    image_ids: PropTypes.arrayOf(PropTypes.number),
    image_objects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        thumbnailUrl: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      })
    ),
    isSupplier: PropTypes.bool,
    isHKOffice: PropTypes.bool,
    created_at: PropTypes.object,
    updated_at: PropTypes.object,
  }).isRequired,
};

export default BrandEdit;
