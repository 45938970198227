import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  imageCreate,
  imageCreateReset,
  selectImageCreate,
} from "./imageAsyncThunk";
import { IKContext, IKUpload } from "imagekitio-react";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

function ImageCreate({
  label,
  error,
  onFinish,
  PDF = false,
  labelStyle,
  inputStyle,
  id,
  svg,
  disabled,
  onClick,
  boxStyle,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const imageCreateStore = useSelector(selectImageCreate);
  const { payload, imageOwner } = imageCreateStore;
  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  const onError = (err) => {
    setIsLoading(false);
    handleVariant("Oops! Server Failure.", "error");
  };
  const onSuccess = (res) => {
    setIsLoading(false);
    PDF ? onFinish({ ...res }) : dispatch(imageCreate({ ...res, label }));
  };

  useEffect(() => {
    if (payload.hasOwnProperty("id") && imageOwner === label) {
      onFinish(payload);
      dispatch(imageCreateReset());
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <React.Fragment>
      <IKContext
        publicKey={process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY}
        urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL}
        authenticationEndpoint={`${process.env.REACT_APP_BACKEND_URL}/api/image/auth`}
      >
        <Box onClick={onClick} sx={boxStyle}>
          <Typography
            color={error ? "secondary" : "inherit"}
            variant="body2"
            sx={labelStyle}
            noWrap
          >
            {label}
          </Typography>
        </Box>
        <IKUpload
          disabled={disabled}
          accept={svg ? ".svg" : PDF ? ".pdf" : "image/*"}
          customCoordinates={"10,10,10,10"}
          onChange={(e) =>
            e.target.value ? setIsLoading(true) : setIsLoading(false)
          }
          transformation={[
            {
              width: 300, // The desired width of the image
              height: 0, // Set to 0 to maintain the original aspect ratio
              fit: "preserve", // or "contain" to avoid white gaps
            },
          ]}
          onError={onError}
          onSuccess={onSuccess}
          style={inputStyle}
          id={id}
        />
      </IKContext>
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          color: "common.white",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* ...other SDK components added previously */}
    </React.Fragment>
  );
}

ImageCreate.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
};

export default ImageCreate;
