import { createSlice } from "@reduxjs/toolkit";
import { spaceList } from "./spaceAsyncThunk";
import uniqBy from "lodash-es/uniqBy";

const spaceListSlice = createSlice({
  name: "space",
  initialState: {
    payload: [],
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [spaceList.pending]: (state, action) => {
      state.isSuccess = false;
      state.isFail = false;
    },
    [spaceList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = uniqBy(
        state.payload.concat(action.payload),
        function (elem) {
          return [elem.id, +elem.projectId].join();
        }
      );
    },
    [spaceList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.payload = [];
    },
  },
});

export default spaceListSlice.reducer;
