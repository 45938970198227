import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { productVariationEdit } from "./productAsyncThunk";
import {
  productGroupList,
  selectProductGroupList,
} from "../productGroup/productGroupAsyncThunk";
import ImageCreate from "../image/ImageCreate";

import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlueButton, BlackButton } from "../../components/CustomButtons";
import ImageArrayPreview from "../../components/ImageArrayPreview";
import AutoCompleteOption from "../../components/AutoCompleteOption";

function ProductVariationEdit({ isSelected, data }) {
  const dispatch = useDispatch();
  const productGroupListStore = useSelector(selectProductGroupList);
  const { isLoading: productGroupLoading, payload: productGroupRecord } =
    productGroupListStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    id: data.id,
    product_id: data.product_id,
    name: data.name,
    imageURL: [...data.imageURL],
    thumbnailURL: [...data.thumbnailURL],
    product_group_ids: data.product_group_ids,
    product_group_objects: data.product_group_objects,
    isDisabled: data.isDisabled,
    sku: data?.sku
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleImageAdd = (image) => {
    const imageURL = [...values.imageURL, image.url];
    const thumbnailURL = [...values.thumbnailURL, image.thumbnailURL];
    setValues({ ...values, imageURL, thumbnailURL });
  };

  const handleImageRemove = (index) => () => {
    const imageURL = [...values.imageURL];
    const thumbnailURL = [...values.thumbnailURL];
    imageURL.splice(index, 1);
    thumbnailURL.splice(index, 1);
    setValues({ ...values, imageURL, thumbnailURL });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = Array.isArray(newValue)
      ? { ...values, [prop]: [...newValue] }
      : { ...values, [prop]: newValue };
    if (prop === "product_group_objects")
      newObj.product_group_ids = newValue.map((x) => x.id);
    setValues(newObj);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.name) === false) {
      setShowHint(true);
      return;
    }
    if (values.imageURL.length < 1) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.sku) === false) {
      setShowHint(true);
      return;
    }
    if (
      Array.isArray(values.product_group_ids) &&
      values.product_group_ids.length === 0
    ) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(productVariationEdit(values));
  };

  useEffect(() => {
    const {
      id,
      product_id,
      name,
      imageURL,
      thumbnailURL,
      isDisabled,
      product_group_ids,
      product_group_objects,
      sku
    } = data;
    setValues({
      id,
      product_id,
      name,
      imageURL,
      thumbnailURL,
      isDisabled,
      product_group_ids,
      product_group_objects,
      sku
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(productGroupList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <BlueButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Edit
      </BlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-edit-product-variation"
        scroll="body"
        maxWidth="xs"
        fullWidth
      >
        <StyledDialogTitle
          id="form-dialog-edit-product-variation"
          onClose={handleClose}
        >
          Edit Product Variation
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            disabled
            defaultValue={data.product}
            variant="outlined"
            margin="dense"
            size="small"
            placeholder="Product"
            fullWidth
            sx={{ mb: 1.5, mt: 0 }}
          />
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.name) === false}
            defaultValue={values.name}
            onChange={handleValues("name")}
            variant="outlined"
            margin="dense"
            size="small"
            id="product-variation-name"
            placeholder="Name"
            sx={{ mb: 1.5, mt: 0 }}
            fullWidth
          />
          <StyledTextField
            autoFocus
            required
            sx={{ mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.sku) === false}
            defaultValue={values.sku}
            onChange={handleValues("sku")}
            variant="outlined"
            margin="dense"
            size="small"
            id="product-variation-sku"
            placeholder="Sku"
            fullWidth
          />
          <Autocomplete
            multiple
            id="productgroup"
            options={productGroupRecord}
            loading={productGroupLoading}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.product_group_objects}
            onChange={handleAutoCompleteValues("product_group_objects")}
            fullWidth
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.name} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <StyledTextField
                sx={{ mb: 1.5, mt: 0 }}
                {...params}
                placeholder="Product Group"
                variant="outlined"
                error={
                  isUserActionMade && values.product_group_objects.length === 0
                }
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isDisabled}
                onChange={(e) =>
                  setValues({ ...values, isDisabled: e.target.checked })
                }
                name="isDisabled"
                color="primary"
                inputProps={{
                  "aria-label": "Disabled",
                }}
              />
            }
            label="Disabled"
          />
          <ImageCreate
            label="Image URL"
            error={isUserActionMade && values.imageURL.length < 1}
            onFinish={handleImageAdd}
          />
          <ImageArrayPreview
            imageArray={values.thumbnailURL}
            onRemove={handleImageRemove}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", flexDirection: "column", px: 3 }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{ width: "100%", margin: (theme) => theme.spacing(0, 0, 3, 0) }}
            onClick={handleSubmit}
          >
            Update Product Variation
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

ProductVariationEdit.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    product_id: PropTypes.number,
    name: PropTypes.string,
    imageURL: PropTypes.arrayOf(PropTypes.string),
    thumbnailURL: PropTypes.arrayOf(PropTypes.string),
    isDisabled: PropTypes.bool,
    product: PropTypes.string,
    product_group_ids: PropTypes.arrayOf(PropTypes.number),
    product_group_objects: PropTypes.arrayOf(PropTypes.object),
    created_at: PropTypes.object,
    updated_at: PropTypes.object,
  }).isRequired,
};

export default ProductVariationEdit;
