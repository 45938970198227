import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.24137 11.3803C3.7784 11.3803 4.21375 10.945 4.21375 10.4079C4.21375 9.8709 3.7784 9.43555 3.24137 9.43555C2.70433 9.43555 2.26898 9.8709 2.26898 10.4079C2.26898 10.945 2.70433 11.3803 3.24137 11.3803Z"
        stroke="black"
        strokeWidth={1.94477}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3722 11.3803C10.9092 11.3803 11.3445 10.945 11.3445 10.4079C11.3445 9.8709 10.3722 9.43555 10.3722 9.43555C10.3722 9.43555 9.39978 9.8709 9.39978 10.4079C9.39978 10.945 9.83513 11.3803 10.3722 11.3803Z"
        stroke="black"
        strokeWidth={1.94477}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.503 11.3803C18.0401 11.3803 18.4754 10.945 18.4754 10.4079C18.4754 9.8709 18.0401 9.43555 17.503 9.43555C16.966 9.43555 16.5306 9.8709 16.5306 10.4079C16.5306 10.945 16.966 11.3803 17.503 11.3803Z"
        stroke="black"
        strokeWidth={1.94477}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuIcon;
