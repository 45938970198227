import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

function ProductSingleLineImageList({ gallery_array }) {
  const theme = useTheme();
  const [minHeight, setMinHeight] = useState(10);

  useEffect(() => {
    const maxHeight = Number(theme.spacing(20).slice(0, -2));
    setMinHeight(Math.min(...gallery_array.map((x) => x.height), maxHeight));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery_array]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        mx: 0,
        my: 1,
        display: "flex",
        "& *": {
          height: `${minHeight}px`,
          objectFit: "contain",
        },
        "& > *": {
          m: (theme) => theme.spacing(1, 0, 1, 1),
        },
        "& > *:last-child": {
          m: 1,
        },
      }}
    >
      {gallery_array.map((item) => (
        <Box
          onClick={item.onClick}
          sx={{
            cursor: "pointer",
          }}
        >
          <img src={item.url} alt={item.id} loading="lazy" />
        </Box>
      ))}
    </Box>
  );
}

ProductSingleLineImageList.propTypes = {
  gallery_array: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      thumbnailUrl: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default ProductSingleLineImageList;
