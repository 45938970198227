import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { userManageAdmin } from "./userAsyncThunk";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { LightBlueButton, BlackButton } from "../../components/CustomButtons";

function UserManageAdmin({ isSelected, data }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    remarks: data.remarks,
    isSuper: data.isSuper,
    isDisabled: data.isDisabled,
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(userManageAdmin(values));
  };

  useEffect(() => {
    const { id, firstName, lastName, remarks, isSuper, isDisabled } = data;
    setValues({ id, firstName, lastName, remarks, isSuper, isDisabled });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <LightBlueButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Edit
      </LightBlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-admin"
        scroll="body"
      >
        <StyledDialogTitle id="form-dialog-create-admin" onClose={handleClose}>
          Edit Admin
        </StyledDialogTitle>
        <DialogContent sx={{ px: 3, pt: 3, pb: 0 }}>
          <DialogContentText>Personal Details</DialogContentText>
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.firstName) === false}
            defaultValue={values.firstName}
            onChange={handleValues("firstName")}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            id="name"
            placeholder="First Name"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.lastName) === false}
            defaultValue={values.lastName}
            onChange={handleValues("lastName")}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            id="lastname"
            placeholder="Last Name"
            fullWidth
          />
          <StyledTextField
            disabled
            defaultValue={data.email}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            id="email"
            placeholder="Email Address"
            type="email"
            fullWidth
            helperText="Email change is forbidden. Disable the account if there is a typo."
            autoComplete="off"
          />
        </DialogContent>
        <DialogContent sx={{ px: 3, pt: 0, pb: 3 }}>
          <DialogContentText>Remarks</DialogContentText>
          <StyledTextField
            defaultValue={values.remarks}
            onChange={handleValues("remarks")}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            id="remarks"
            placeholder="Remarks"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isSuper}
                onChange={(e) =>
                  setValues({ ...values, isSuper: e.target.checked })
                }
                name="isSuper"
                color="primary"
                inputProps={{
                  "aria-label": "Super Admin (Able to Disable Other Admin)",
                }}
              />
            }
            label="Super Admin (Able to Disable Other Admin)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isDisabled}
                onChange={(e) =>
                  setValues({ ...values, isDisabled: e.target.checked })
                }
                name="isDisabled"
                color="primary"
                inputProps={{
                  "aria-label": "Disabled",
                }}
              />
            }
            label="Disabled"
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Update Account
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

UserManageAdmin.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    remarks: PropTypes.string,
    isSuper: PropTypes.bool,
    isDisabled: PropTypes.bool,
    created_at: PropTypes.object,
    updated_at: PropTypes.object,
  }).isRequired,
};

export default UserManageAdmin;
