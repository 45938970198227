import React, { useEffect } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  catalogListProjectType,
  selectCatalogListProjectType,
} from "../../features/catalog/catalogAsyncThunk";
import { selectAuthUserInfo } from "../../features/authentication/authAsyncThunk";
import {
  projectEdit,
  selectProjectList,
} from "../../features/project/projectAsyncThunk";
import { useParams } from "react-router-dom";
import { BlackButton } from "../CustomButtons";
import { PROJECT_FORM_DATA } from "../../constants/projects.constants";
import { StyledTextField } from "../CustomComponents";
import AutoCompleteOption from "../AutoCompleteOption";

const filter = createFilterOptions();
const freeSoloOptionsFilter = (options, params) => {
  const filtered = filter(options, params);
  if (params.inputValue !== "")
    filtered.push({ name: `Create "${params.inputValue}"` });
  return filtered;
};

const SettingsTab = () => {
  const catalogListProjectTypeStore = useSelector(selectCatalogListProjectType);
  const { isLoading: projectTypeListLoading, payload: projectTypeList } =
    catalogListProjectTypeStore;
  const [values, setValues] = React.useState({
    projectName: "",
    projectLocation: "",
    projectType: "",
    projectSize: "",
    projectDescription: "",
    projectTypeId: "",
  });
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [isUserActionMade, setIsUserActionMade] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);
  const { payload: projectsList } = useSelector(selectProjectList);
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo, csrfToken } = authUserInfoStore;
  const params = useParams();
  const theme = useTheme();

  useEffect(() => {
    if (projectsList.length > 0) {
      const data = projectsList.find((project) => project.id === params?.id);
      setSelectedProject(data);
    }
  }, [projectsList]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (selectedProject) {
      const isProjectTypeFound = projectTypeList.find(
        (item) => item.id == selectedProject?.id
      );
      setValues({
        ...values,
        projectName: selectedProject?.project_name,
        projectLocation: selectedProject?.project_location,
        projectSize: selectedProject?.project_size,
        projectDescription: selectedProject?.project_description,
        projectTypeId: selectedProject?.project_type_id.toString(),
        projectType: isProjectTypeFound,
      });
    }
  }, [selectedProject, projectTypeList]);

  React.useEffect(() => {
    if (csrfToken) {
      dispatch(catalogListProjectType());
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrfToken]);

  const handleValues = (prop) => (event, newValue) => {
    setIsUserActionMade(true);
    if (prop === "projectType") {
      if (
        String(newValue?.name).startsWith(`Create "`) &&
        String(newValue?.name).endsWith(`"`)
      ) {
        newValue["name"] = newValue?.name.slice(8, -1);
        setValues({ ...values, projectType: newValue, projectTypeId: "" });
        return;
      } else {
        setValues({
          ...values,
          projectTypeId: newValue?.id,
          projectType: newValue,
        });
        return;
      }
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleEditProject = async () => {
    setIsUserActionMade(true);
    if (Boolean(values.projectName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectSize) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectDescription) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectType) === false) {
      setShowHint(true);
      return;
    }
    const isProjectTypeFound = projectTypeList.some(
      (project) => project.id === values.projectTypeId
    );
    if (isProjectTypeFound) {
      await dispatch(
        projectEdit({
          projectName: values?.projectName,
          projectDescription: values?.projectDescription,
          projectLocation: values?.projectLocation,
          projectSize: values?.projectSize,
          projectTypeId: Number(values?.projectTypeId),
          id: Number(selectedProject?.id),
        })
      );
    } else {
      await dispatch(
        projectEdit({
          projectName: values?.projectName,
          projectDescription: values?.projectDescription,
          projectLocation: values?.projectLocation,
          projectSize: values?.projectSize,
          projectTypeId: Number(values?.projectTypeId),
          id: Number(selectedProject?.id),
        })
      );
    }
    setIsUserActionMade(false);
    setShowHint(false);
    window.location.href = `/project/${params.id}`;
  };

  return (
    <Box>
      <Typography
        sx={{ fontSize: { sm: "18px", lg: "24px" }, fontWeight: "600" }}
      >
        Project Information
      </Typography>
      <Box
        sx={{ marginTop: "1.5rem", display: "flex", flexDirection: "column" }}
      >
        <Grid container spacing={2} sx={{ marginTop: 0, marginLeft: 0 }}>
          {PROJECT_FORM_DATA.map((item, index) => {
            return (
              <Grid
                key={`project-form-${index}`}
                xs={12}
                sm={12}
                sx={{
                  width: "100%",
                }}
              >
                {item.type === "input" ? (
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel
                      shrink
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                        color: "#181818!important",
                      }}
                    >
                      {item.label}
                    </InputLabel>
                    <StyledTextField
                      variant="outlined"
                      required
                      InputProps={{
                        style: {
                          fontSize: 15,
                          fontWeight: "300",
                        },
                      }}
                      sx={{
                        width: { md: 448, sm: "100%" },
                        marginBottom: "1.5rem",
                        marginTop: "1.5rem",
                        backgroundColor:
                          theme.palette.mode === "light" ? "white" : "black",
                        borderWidth: 2,
                      }}
                      error={
                        isUserActionMade &&
                        Boolean(values[item.value]) === false
                      }
                      value={values[item.value]}
                      onChange={handleValues(`${item.value}`)}
                      margin="dense"
                      size="medium"
                      id={item.id}
                    />
                  </FormControl>
                ) : (
                  <Autocomplete
                    options={projectTypeList}
                    loading={projectTypeListLoading}
                    getOptionLabel={(option) => option.name || ""}
                    value={values.projectType}
                    onChange={handleValues("projectType")}
                    fullWidth
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => {
                      return (
                        <AutoCompleteOption {...props} name={option.name} />
                      );
                    }}
                    freeSolo={userInfo && userInfo?.isSuper}
                    filterOptions={
                      userInfo && userInfo?.isSuper && freeSoloOptionsFilter
                    }
                    sx={{ width: { md: 448, sm: "100%" } }}
                    margin="dense"
                    size="medium"
                    renderInput={(params) => (
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel
                          shrink
                          sx={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#181818!important",
                          }}
                        >
                          Project Type
                        </InputLabel>
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          margin="dense"
                          size="medium"
                          InputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "300",
                            },
                          }}
                          sx={{
                            width: { md: 448, sm: "100%" },
                            marginBottom: "1.5rem",
                            marginTop: "1.5rem",
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? "white"
                                : "black",

                            borderWidth: 2,
                          }}
                          error={
                            isUserActionMade &&
                            Boolean(values.projectType) === false
                          }
                        />
                      </FormControl>
                    )}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        <BlackButton
          sx={{
            width: { xs: "100%", sm: "200px" },
            height: 55,
          }}
          variant="contained"
          color="primary"
          onClick={handleEditProject}
        >
          Save Changes
        </BlackButton>
      </Box>
    </Box>
  );
};

export default SettingsTab;
