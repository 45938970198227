import React, { useCallback, useEffect, useMemo } from "react";
import {
  Backdrop,
  CircularProgress,
  Box,
  Tab,
  Tabs,
  Typography,
  Link,
  Avatar,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import { bodyFont } from "../components/styles/materialUIStyles";

import TabPanel from "../components/TabPanel";
import {
  ArchiveTab,
  ProjectsTab,
  SettingsTab,
  SampleOrdersTab,
} from "../components/Projects";

import {
  activeProjectList,
  archiveProjectList,
  projectCoWorkersList,
  selectArchiveProjectList,
  selectProjectCoWorkersList,
  selectProjectList,
} from "../features/project/projectAsyncThunk";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";
import { spaceList } from "../features/space/spaceAsyncThunk";

import { PROJECTS_TAB } from "../constants/projects.constants";

const StyledBackground = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: "#F4F4F4",
  maxHeight: "200px",
  height: "200px",
}));

export default function ProjectPage() {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectProjectList);
  const authUserInfo = useSelector(selectAuthUserInfo);
  const {
    payload: userInfo,
    isUserUpdateLoading,
    isPasswordLinkLoading,
    csrfToken,
  } = authUserInfo;
  const { isLoading: archiveLoading } = useSelector(selectArchiveProjectList);
  const { isLoading: coWorkerLoading } = useSelector(
    selectProjectCoWorkersList
  );

  const name = useMemo(() => {
    if (!userInfo.firstName) {
      return userInfo.email;
    }
    return `${userInfo.firstName} ${userInfo.lastName}`.trim();
  }, [userInfo]);

  const handleFetch = useCallback(async () => {
    if (csrfToken) {
      const res = await dispatch(activeProjectList()).then(unwrapResult);
      await dispatch(archiveProjectList());
      if (Array.isArray(res?.list)) {
        res.list.map((val) => dispatch(spaceList(val.id)));
        res.list.map((val) => dispatch(projectCoWorkersList(val.id)));
      }
    }
  }, [dispatch, csrfToken]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch, csrfToken]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {isLoading ||
      isUserUpdateLoading ||
      isPasswordLinkLoading ||
      archiveLoading ||
      coWorkerLoading ? (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: "common.white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Box sx={{ width: "100%", position: "relative" }}>
            <StyledBackground />
            <Box
              sx={{
                position: "absolute",
                bottom: "2px",
                display: "flex",
                marginBottom: "2rem",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "transparent",
                  border: "1px solid black",
                  width: 40,
                  height: 40,
                  marginLeft: { xs: "1rem", md: "3rem" },
                  marginRight: { xs: "0.5rem", md: "1rem" },
                }}
              >
                {userInfo.firstName ? (
                  <Typography variant="body2" color={"black"}>
                    {userInfo.firstName.charAt(0)}
                    {userInfo?.lastName?.charAt(0)}
                  </Typography>
                ) : (
                  "!"
                )}
              </Avatar>
              <Typography
                component="span"
                variant="subtitle1"
                color="black"
                sx={{ fontSize: { xs: 16, md: 24 } }}
                fontWeight={"600"}
              >
                {name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              px: { xs: 2, md: 6 },
              backgroundColor: "#F6F6F6",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: { backgroundColor: "#181818", height: "3.5px" },
              }}
            >
              {PROJECTS_TAB.map(({ title }, index) => {
                return (
                  <Tab
                    key={`projects-tab-${index}`}
                    style={{ minWidth: 50, paddingInline: "2em" }}
                    label={
                      <span
                        style={{
                          color: "black",
                          textTransform: "capitalize",
                          fontWeight: "600",
                          fontSize: 15,
                        }}
                      >
                        {title}
                      </span>
                    }
                  />
                );
              })}
              {userInfo.role === 0 && (
                <Tab
                  style={{ minWidth: 50, paddingInline: "2em" }}
                  label={
                    <span
                      style={{
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        fontSize: 15,
                      }}
                    >
                      Admin Functions
                    </span>
                  }
                />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ProjectsTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ArchiveTab />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SampleOrdersTab />
          </TabPanel>
          {userInfo.role === 0 && (
            <TabPanel value={value} index={4}>
              <Box>
                <Link color="inherit" href="/catalog" underline="hover">
                  Product Management
                </Link>
              </Box>
              {userInfo.isSuper && (
                <Box sx={{ my: 1 }}>
                  <Link color="inherit" href="/super" underline="hover">
                    Admin Management
                  </Link>
                </Box>
              )}
            </TabPanel>
          )}
          <TabPanel value={value} index={3}>
            <SettingsTab />
          </TabPanel>
        </>
      )}
    </React.Fragment>
  );
}
