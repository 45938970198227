// import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import regSignupReducer from "../features/registration/regSignupSlice";
import regVerifyRegistrationReducer from "../features/registration/regVerifyRegistrationSlice";
import regVerifySubscriptionReducer from "../features/registration/regVerifySubscriptionSlice";
import regSubscribeReducer from "../features/registration/regSubscribeSlice";
import userUpdateReducer from "../features/userChange/userUpdateSlice";
import userEditAdminReducer from "../features/userChange/userEditAdminSlice";
import userCreateAdminReducer from "../features/userChange/userCreateAdminSlice";
import userListAdminReducer from "../features/userChange/userListAdminSlice";
import userManageAdminReducer from "../features/userChange/userManageAdminSlice";
import userForgetPasswordReducer from "../features/userChange/userForgetPasswordSlice";
import userVerifyRecoveryReducer from "../features/userChange/userVerifyRecoverySlice";
import userFinishRecoveryReducer from "../features/userChange/userFinishRecoverySlice";
import userFederatedInfoReducer from "../features/userChange/userFederatedInfoSlice";
import authLoginReducer from "../features/authentication/authLoginSlice";
import authUserInfoReducer from "../features/authentication/authUserInfoSlice";
import catalogCreateCompanyTypeReducer from "../features/catalog/catalogCreateCompanyTypeSlice";
import catalogListCompanyTypeReducer from "../features/catalog/catalogListCompanyTypeSlice";
import catalogManageCompanyTypeReducer from "../features/catalog/catalogManageCompanyTypeSlice";
import catalogListCountryReducer from "../features/catalog/catalogListCountrySlice";
import catalogListSupplierReducer from "../features/catalog/catalogListSupplierSlice";
import catalogListBrandReducer from "../features/catalog/catalogListBrandSlice";
import catalogListCategoryReducer from "../features/catalog/catalogListCategorySlice";
import catalogListProductReducer from "../features/catalog/catalogListProductSlice";
import catalogListProductVariationReducer from "../features/catalog/catalogListProductVariationSlice";
import catalogCreateProjectTypeReducer from "../features/catalog/catalogCreateProjectTypeSlice";
import catalogListProjectTypeReducer from "../features/catalog/catalogListProjectTypeSlice";
import catalogManageProjectTypeReducer from "../features/catalog/catalogManageProjectTypeSlice";
import catalogDeleteProjectTypeReducer from "../features/catalog/catalogDeleteProjectTypeSlice";
import catalogCreateOrderStatusTypeReducer from "../features/catalog/catalogCreateOrderStatusTypeSlice";
import catalogListOrderStatusTypeReducer from "../features/catalog/catalogListOrderStatusTypeSlice";
import catalogManageOrderStatusTypeReducer from "../features/catalog/catalogManageOrderStatusTypeSlice";
import catalogDeleteOrderStatusTypeReducer from "../features/catalog/catalogDeleteOrderStatusTypeSlice";
import imageCreateReducer from "../features/image/imageCreateSlice";
import imageSearchReducer from "../features/image/imageSearchSlice";
import supplierCreateReducer from "../features/supplier/supplierCreateSlice";
import supplierListReducer from "../features/supplier/supplierListSlice";
import supplierEditReducer from "../features/supplier/supplierEditSlice";
import supplierGetReducer from "../features/supplier/supplierGetSlice";
import supplierContactReducer from "../features/supplier/supplierContactSlice";
import brandCreateReducer from "../features/brand/brandCreateSlice";
import brandListReducer from "../features/brand/brandListSlice";
import brandEditReducer from "../features/brand/brandEditSlice";
import brandGetReducer from "../features/brand/brandGetSlice";
import brandGetLandingPageReducer from "../features/brand/brandGetLandingPageSlice";
import brandGetLandingPageReducer2 from "../features/brand/brandGetLandingPageSlice2";
import brandGetLandingPageReducer3 from "../features/brand/brandGetLandingPageSlice3";
import brandGetLeadCapturePageReducer from "../features/brand/brandGetLeadCapturePageSlice";
import brandParamsReducer from "../features/brand/brandParamsSlice";
import brandRelevantReducer from "../features/brand/brandRelevantSlice";
import categoryCreateReducer from "../features/category/categoryCreateSlice";
import categoryListReducer from "../features/category/categoryListSlice";
import categoryEditReducer from "../features/category/categoryEditSlice";
import categoryDeleteReducer from "../features/category/categoryDeleteSlice";
import categoryGetByIDReducer from "../features/category/categoryGetByIDSlice";
import categoryRelevantReducer from "../features/category/categoryRelevantSlice";
import productCreateReducer from "../features/product/productCreateSlice";
import productListReducer from "../features/product/productListSlice";
import productEditReducer from "../features/product/productEditSlice";
import productGetReducer from "../features/product/productGetSlice";
import productSearchReducer from "../features/product/productSearchSlice";
import productParamsReducer from "../features/product/productParamsSlice";
import productGetLandingPageReducer from "../features/product/productGetLandingPageSlice";
import productVariationCreateReducer from "../features/product/productVariationCreateSlice";
import productVariationListReducer from "../features/product/productVariationListSlice";
import productVariationEditReducer from "../features/product/productVariationEditSlice";
import productVariationDeleteReducer from "../features/product/productVariationDeleteSlice";
import productPropCreateReducer from "../features/productProp/productPropCreateSlice";
import productPropListReducer from "../features/productProp/productPropListSlice";
import productPropGetReducer from "../features/productProp/productPropGetSlice";
import productPropGetAllReducer from "../features/productProp/productPropGetAllSlice";
import productPropDeleteReducer from "../features/productProp/productPropDeleteSlice";
import productPropPrecedenceListReducer from "../features/productProp/productPropPrecedenceListSlice";
import productPropPrecedenceEditReducer from "../features/productProp/productPropPrecedenceEditSlice";
import favoriteCreateReducer from "../features/favorite/favoriteCreateSlice";
import favoriteListReducer from "../features/favorite/favoriteListSlice";
import favoriteDeleteReducer from "../features/favorite/favoriteDeleteSlice";
import orderCreateReducer from "../features/order/orderCreateSlice";
import orderListReducer from "../features/order/orderListSlice";
import orderEditReducer from "../features/order/orderEditSlice";
import orderGetReducer from "../features/order/orderGetSlice";
import orderCartReducer from "../features/order/orderCartSlice";
import orderCartTokenReducer from "../features/order/orderCartTokenSlice";
import productGroupCreateReducer from "../features/productGroup/productGroupCreateSlice";
import productGroupListReducer from "../features/productGroup/productGroupListSlice";
import productGroupManageReducer from "../features/productGroup/productGroupManageSlice";
import productGroupDeleteReducer from "../features/productGroup/productGroupDeleteSlice";
import productGalleryTitleCreateReducer from "../features/productGalleryTitle/productGalleryTitleCreateSlice";
import productGalleryTitleListReducer from "../features/productGalleryTitle/productGalleryTitleListSlice";
import productGalleryTitleManageReducer from "../features/productGalleryTitle/productGalleryTitleManageSlice";
import productGalleryTitleDeleteReducer from "../features/productGalleryTitle/productGalleryTitleDeleteSlice";
import manuJoinReducer from "../features/manufacturer/manuJoinSlice";
import projectCreateReducer from "../features/project/projectCreateSlice";
import projectEditReducer from "../features/project/projectEditSlice";
import projectDeleteReducer from "../features/project/projectDeleteSlice";
import projectListReducer from "../features/project/projectListSlice";
import archiveProjectListReducer from "../features/project/archiveProjectListSlice";
import projectUpdateStatusReducer from "../features/project/projectUpdateStatusSlice";
import spaceCreateReducer from "../features/space/spaceCreateSlice";
import spaceListReducer from "../features/space/spaceListSlice";
import spaceProductsCreateReducer from "../features/spaceproducts/spaceProductsCreate";
import spaceProductsListReducer from "../features/spaceproducts/spaceProductsList";
import spaceEditReducer from "../features/space/spaceEditSlice";
import spaceDeleteReducer from "../features/space/spaceDeleteSlice";
import spaceMoveReducer from "../features/space/spaceMoveSlice";
import matterboardListReducer from "../features/matterboard/matterboardListSlice";
import matterboardDeleteReducer from "../features/matterboard/matterboardDeleteSlice";
import matterboardDetailsReducer from "../features/matterboard/materboardDetailsSlice";
import spaceProductRequestListReducer from "../features/space/spaceProductRequestListSlice";
import spaceProductRequestsDownloadReducer from "../features/space/spaceProductRequestsDownloadSlice";
import spaceProductsDeleteReducer from "../features/spaceproducts/spaceProductsDeleteSlice";
import similarCategoryProductsReducer from "../features/matterboard/similarCategoryProducts";
import projectCoWorkerCreateReducer from "../features/project/projectCoWorkerSlice";
import projectCoWorkersListReducer from "../features/project/projectCoWorkersListSlice";
import orderCancelReducer from "../features/order/orderCancelSlice";
import orderProductRemoveReducer from "../features/order/orderProductRemoveSlice";
import orderStatusUpdateReducer from "../features/order/orderStatusUpdateSlice";
import supplierContactByBrandReducer from "../features/supplier/supplierContactByBrandSlice";
import categoryLandingPageReducer from "../features/category/categoryLandingPageSlice";
import productLastThreeMonthReducer from "../features/product/productLastThreeMonthSlice";
import productDeleteByOrderIdReducer from "../features/project/productDeleteByOrderIdSlice";
import brandGetLandingPageWithProductsReducer from "../features/brand/brandGetLandingPageWithProductsSlice";
import brandUpdateBooleanReducer from "../features/brand/brandUpdateIsShowBooleanSlice";

export default configureStore({
  reducer: {
    regSignup: regSignupReducer,
    regVerifyRegistration: regVerifyRegistrationReducer,
    regVerifySubscription: regVerifySubscriptionReducer,
    regSubscribe: regSubscribeReducer,
    userUpdate: userUpdateReducer,
    userEditAdmin: userEditAdminReducer,
    userCreateAdmin: userCreateAdminReducer,
    userListAdmin: userListAdminReducer,
    userManageAdmin: userManageAdminReducer,
    userForgetPassword: userForgetPasswordReducer,
    userVerifyRecovery: userVerifyRecoveryReducer,
    userFinishRecovery: userFinishRecoveryReducer,
    userFederatedInfo: userFederatedInfoReducer,
    authLogin: authLoginReducer,
    authUserInfo: authUserInfoReducer,
    catalogCreateCompanyType: catalogCreateCompanyTypeReducer,
    catalogListCompanyType: catalogListCompanyTypeReducer,
    catalogManageCompanyType: catalogManageCompanyTypeReducer,
    catalogListCountry: catalogListCountryReducer,
    catalogListSupplier: catalogListSupplierReducer,
    catalogListBrand: catalogListBrandReducer,
    catalogListCategory: catalogListCategoryReducer,
    catalogListProduct: catalogListProductReducer,
    catalogListProductVariation: catalogListProductVariationReducer,
    catalogCreateProjectType: catalogCreateProjectTypeReducer,
    catalogListProjectType: catalogListProjectTypeReducer,
    catalogManageProjectType: catalogManageProjectTypeReducer,
    catalogDeleteProjectType: catalogDeleteProjectTypeReducer,
    catalogCreateOrderStatusType: catalogCreateOrderStatusTypeReducer,
    catalogListOrderStatusType: catalogListOrderStatusTypeReducer,
    catalogManageOrderStatusType: catalogManageOrderStatusTypeReducer,
    catalogDeleteOrderStatusType: catalogDeleteOrderStatusTypeReducer,
    imageCreate: imageCreateReducer,
    imageSearch: imageSearchReducer,
    supplierCreate: supplierCreateReducer,
    supplierEdit: supplierEditReducer,
    supplierList: supplierListReducer,
    supplierGet: supplierGetReducer,
    supplierContact: supplierContactReducer,
    brandCreate: brandCreateReducer,
    brandEdit: brandEditReducer,
    brandList: brandListReducer,
    brandGet: brandGetReducer,
    brandGetLandingPage: brandGetLandingPageReducer,
    brandGetLandingPage2: brandGetLandingPageReducer2,
    brandGetLandingPage3: brandGetLandingPageReducer3,
    brandGetLeadCapturePage: brandGetLeadCapturePageReducer,
    brandParams: brandParamsReducer,
    brandRelevant: brandRelevantReducer,
    categoryCreate: categoryCreateReducer,
    categoryEdit: categoryEditReducer,
    categoryList: categoryListReducer,
    categoryDelete: categoryDeleteReducer,
    categoryGetByID: categoryGetByIDReducer,
    categoryRelevant: categoryRelevantReducer,
    productCreate: productCreateReducer,
    productEdit: productEditReducer,
    productList: productListReducer,
    productGet: productGetReducer,
    productSearch: productSearchReducer,
    productParams: productParamsReducer,
    productGetLandingPage: productGetLandingPageReducer,
    productVariationCreate: productVariationCreateReducer,
    productVariationList: productVariationListReducer,
    productVariationEdit: productVariationEditReducer,
    productVariationDelete: productVariationDeleteReducer,
    productPropCreate: productPropCreateReducer,
    productPropList: productPropListReducer,
    productPropGet: productPropGetReducer,
    productPropGetAll: productPropGetAllReducer,
    productPropDelete: productPropDeleteReducer,
    productPropPrecedenceList: productPropPrecedenceListReducer,
    productPropPrecedenceEdit: productPropPrecedenceEditReducer,
    favoriteCreate: favoriteCreateReducer,
    favoriteList: favoriteListReducer,
    favoriteDelete: favoriteDeleteReducer,
    orderCreate: orderCreateReducer,
    orderList: orderListReducer,
    orderEdit: orderEditReducer,
    orderGet: orderGetReducer,
    orderCart: orderCartReducer,
    orderCartToken: orderCartTokenReducer,
    productGroupCreate: productGroupCreateReducer,
    productGroupList: productGroupListReducer,
    productGroupManage: productGroupManageReducer,
    productGroupDelete: productGroupDeleteReducer,
    productGalleryTitleCreate: productGalleryTitleCreateReducer,
    productGalleryTitleList: productGalleryTitleListReducer,
    productGalleryTitleManage: productGalleryTitleManageReducer,
    productGalleryTitleDelete: productGalleryTitleDeleteReducer,
    manuJoin: manuJoinReducer,
    projectCreate: projectCreateReducer,
    projectList: projectListReducer,
    archiveProjectList: archiveProjectListReducer,
    projectEdit: projectEditReducer,
    projectDelete: projectDeleteReducer,
    spaceCreate: spaceCreateReducer,
    spaceList: spaceListReducer,
    spaceProductCreate: spaceProductsCreateReducer,
    spaceProductsList: spaceProductsListReducer,
    spaceEdit: spaceEditReducer,
    spaceDelete: spaceDeleteReducer,
    spaceMove: spaceMoveReducer,
    matterboardList: matterboardListReducer,
    matterboardDelete: matterboardDeleteReducer,
    matterboardDetailsByID: matterboardDetailsReducer,
    similarCategoryProducts: similarCategoryProductsReducer,
    spaceProductRequestList: spaceProductRequestListReducer,
    spaceProductRequestDownload: spaceProductRequestsDownloadReducer,
    spaceProductsDelete: spaceProductsDeleteReducer,
    projectUpdateStatus: projectUpdateStatusReducer,
    projectCoWorkerCreate: projectCoWorkerCreateReducer,
    projectCoWorkersList: projectCoWorkersListReducer,
    orderCancel: orderCancelReducer,
    orderProductRemove: orderProductRemoveReducer,
    orderStatusUpdate: orderStatusUpdateReducer,
    supplierContactByBrand: supplierContactByBrandReducer,
    categoryGetLandingPage: categoryLandingPageReducer,
    productLastThreeMonthList: productLastThreeMonthReducer,
    productDeleteByOrderId: productDeleteByOrderIdReducer,
    brandGetLandingPageWithProducts: brandGetLandingPageWithProductsReducer,
    brandUpdateBoolean: brandUpdateBooleanReducer,
  },

  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
