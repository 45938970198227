import React, { useState, useEffect } from "react";
import { authLogin } from "../features/authentication/authAsyncThunk";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";

import { bodyFont } from "../components/styles/materialUIStyles";
import Container from "@mui/material/Container";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "../components/img/GoogleIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import {
  BlackButton,
  BlueButton,
  LightBlueButton,
} from "../components/CustomButtons";
import {
  DialogTextField,
  StyledDialogTitle,
} from "../components/CustomComponents";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { payload } = useSelector(selectAuthUserInfo);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    isStaySignin: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!Boolean(showPassword));
  };

  const handlePointerDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.password) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(authLogin(values));
  };

  const handleFacebook = (event) => {
    event.preventDefault();
    window.location.assign(
      `${process.env.REACT_APP_BACKEND_URL}/api/login/federated/facebook`
    );
  };

  const handleGoogle = (event) => {
    event.preventDefault();
    window.location.assign(
      `${process.env.REACT_APP_BACKEND_URL}/api/login/federated/google`
    );
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  useEffect(() => {
    const from = query.get("from");
    const { id } = payload;
    if (Boolean(id) === true) {
      from ? history.push(from) : history.push("/");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    const from = query.get("from");
    if (Boolean(from) === true) {
      enqueueSnackbar("You need to login to use this feature", {
        variant: "info",
        autoHideDuration: 3000,
        action,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        aria-labelledby="form-dialog-title"
      >
        <StyledDialogTitle id="form-dialog-title">Login</StyledDialogTitle>
        <DialogContent sx={{ textAlign: "left" }}>
          <DialogContentText sx={{ textAlign: "center" }}>
            Enter you email address and password to access your account
          </DialogContentText>
          <DialogTextField
            autoFocus
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            defaultValue={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            size="small"
            id="email"
            placeholder="Email address"
            fullWidth
          />
          <DialogTextField
            error={isUserActionMade && Boolean(values.password) === false}
            defaultValue={values.password}
            onChange={handleValues("password")}
            onKeyPress={handleEnter}
            variant="outlined"
            margin="dense"
            size="small"
            autoComplete="off"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onPointerDown={handlePointerDownPassword}
                    sx={{ marginRight: "0px!important" }}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isStaySignin}
                onChange={(e) =>
                  setValues({ ...values, isStaySignin: e.target.checked })
                }
                name="isStaySignin"
                color="primary"
                inputProps={{
                  "aria-label": "Stay Sign-in",
                }}
              />
            }
            label="Stay Sign-in"
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            py: 1,
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(1, 1, 0.5, 1),
            }}
            onClick={handleSubmit}
          >
            Log in
          </BlackButton>
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 1, 1, 1),
              textAlign: "center",
            }}
          >
            <Link href="/recovery/" color="inherit" underline="hover">
              Forget your password?
            </Link>
          </DialogContentText>
          <BlueButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(1, 1, 0.5, 1),
            }}
            startIcon={<FacebookIcon />}
            onClick={handleFacebook}
          >
            Continue with Facebook
          </BlueButton>
          <LightBlueButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(1, 1, 3, 1),
            }}
            startIcon={<GoogleIcon />}
            onClick={handleGoogle}
          >
            Sign in with Google
          </LightBlueButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 1, 1, 1),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Container>
    </React.Fragment>
  );
}
