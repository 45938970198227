import { createSlice } from '@reduxjs/toolkit';
import { productParams } from './productAsyncThunk';

const productParamsSlice = createSlice({
  name: "product",
  initialState: {
    brand_id: 0,
    category_id: 0,
    name: "",
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [productParams.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [productParams.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.brand_id = action.payload.brand_id;
      state.category_id = action.payload.category_id;
      state.name = action.payload.name;
    },
    [productParams.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
  },
});

export default productParamsSlice.reducer;