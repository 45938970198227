import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListCountry,
  catalogListCompanyType,
  selectCatalogListCountry,
  selectCatalogListCompanyType,
} from "../catalog/catalogAsyncThunk";
import { supplierEdit } from "./supplierAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { LightBlueButton, BlackButton } from "../../components/CustomButtons";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const ImgSupplierLogo = styled("img")(({ theme }) => ({
  width: theme.spacing(10),
  objectFit: "cover",
}));

function SupplierEdit({ isSelected, data }) {
  const dispatch = useDispatch();
  const catalogListCountryStore = useSelector(selectCatalogListCountry);
  const { isLoading: countryListLoading, payload: countryList } =
    catalogListCountryStore;
  const catalogListCompanyTypeStore = useSelector(selectCatalogListCompanyType);
  const { isLoading: companyTypeListLoading, payload: companyTypeList } =
    catalogListCompanyTypeStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    telephone: data.telephone,
    companyName: data.companyName,
    companyType: data.companyType,
    company_type_id: data.company_type_id,
    company_type_object: data.company_type_object,
    jobTitle: data.jobTitle,
    country: data.country,
    country_id: data.country_id,
    country_object: data.country_object,
    companyLocation: data.companyLocation,
    companyWebsite: data.companyWebsite,
    supplierLogoURL: data.supplierLogoURL,
    isAgency: data.isAgency,
    isManufacturer: data.isManufacturer,
    isPremium: data.isPremium,
    isDisabled: data.isDisabled,
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    if (prop === "country_object") newObj.country_id = newValue.id;
    if (prop === "company_type_object") newObj.company_type_id = newValue.id;
    setValues(newObj);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.telephone) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.jobTitle) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyWebsite) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.supplierLogoURL) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(supplierEdit(values));
  };

  useEffect(() => {
    dispatch(catalogListCountry());
    dispatch(catalogListCompanyType());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      id,
      firstName,
      lastName,
      email,
      telephone,
      companyName,
      companyType,
      company_type_id,
      company_type_object,
      country,
      country_id,
      country_object,
      jobTitle,
      companyLocation,
      companyWebsite,
      supplierLogoURL,
      isAgency,
      isManufacturer,
      isPremium,
      isDisabled,
    } = data;
    setValues({
      id,
      firstName,
      lastName,
      email,
      telephone,
      companyName,
      companyType,
      company_type_id,
      company_type_object,
      country,
      country_id,
      country_object,
      jobTitle,
      companyLocation,
      companyWebsite,
      supplierLogoURL,
      isAgency,
      isManufacturer,
      isPremium,
      isDisabled,
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <LightBlueButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Edit
      </LightBlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-edit-supplier"
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <StyledDialogTitle id="form-dialog-edit-supplier" onClose={handleClose}>
          Edit Supplier
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.firstName) === false}
            defaultValue={values.firstName}
            onChange={handleValues("firstName")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            id="name"
            placeholder="First Name"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.lastName) === false}
            defaultValue={values.lastName}
            onChange={handleValues("lastName")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            id="lastname"
            placeholder="Last Name"
            fullWidth
          />
          <StyledTextField
            required
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            defaultValue={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            size="small"
            id="email"
            placeholder="Email Address"
            type="email"
            sx={{ mt: 0, mb: 1.5 }}
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.telephone) === false}
            defaultValue={values.telephone}
            onChange={handleValues("telephone")}
            variant="outlined"
            margin="dense"
            size="small"
            id="telephone"
            placeholder="Telephone"
            fullWidth
            sx={{ mt: 0, mb: 1.5 }}
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.companyName) === false}
            defaultValue={values.companyName}
            onChange={handleValues("companyName")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            id="companyname"
            placeholder="Company Name"
            fullWidth
          />
          <Autocomplete
            id="companytype"
            options={companyTypeList}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            loading={companyTypeListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.company_type_object}
            onChange={handleAutoCompleteValues("company_type_object")}
            fullWidth
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                {...params}
                sx={{ mt: 0, mb: 1.5 }}
                placeholder="Company Type"
                variant="outlined"
              />
            )}
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.jobTitle) === false}
            defaultValue={values.jobTitle}
            onChange={handleValues("jobTitle")}
            variant="outlined"
            margin="dense"
            size="small"
            id="jobtitle"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="Job title"
            fullWidth
          />
          <Autocomplete
            id="country"
            options={countryList}
            loading={countryListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.country_object}
            onChange={handleAutoCompleteValues("country_object")}
            fullWidth
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Country"
                variant="outlined"
                sx={{ mt: 0, mb: 1.5 }}
              />
            )}
          />
          <StyledTextField
            required
            error={
              isUserActionMade && Boolean(values.companyLocation) === false
            }
            defaultValue={values.companyLocation}
            onChange={handleValues("companyLocation")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            id="companylocation"
            placeholder="Company Location"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.companyWebsite) === false}
            defaultValue={values.companyWebsite}
            onChange={handleValues("companyWebsite")}
            variant="outlined"
            margin="dense"
            size="small"
            id="companywebsite"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="Company Website"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isAgency}
                onChange={(e) =>
                  setValues({ ...values, isAgency: e.target.checked })
                }
                name="isAgency"
                color="primary"
                inputProps={{
                  "aria-label": "Agency",
                }}
              />
            }
            label="Agency"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isManufacturer}
                onChange={(e) =>
                  setValues({ ...values, isManufacturer: e.target.checked })
                }
                name="isManufacturer"
                color="primary"
                inputProps={{
                  "aria-label": "Manufacturer",
                }}
              />
            }
            label="Manufacturer"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isPremium}
                onChange={(e) =>
                  setValues({ ...values, isPremium: e.target.checked })
                }
                name="isPremium"
                color="primary"
                inputProps={{
                  "aria-label": "Premium Supplier",
                }}
              />
            }
            label="Premium Supplier"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isDisabled}
                onChange={(e) =>
                  setValues({ ...values, isDisabled: e.target.checked })
                }
                name="isDisabled"
                color="primary"
                inputProps={{
                  "aria-label": "Disabled",
                }}
              />
            }
            label="Disabled"
          />
          <ImageCreate
            label="Supplier Logo URL"
            error={
              isUserActionMade && Boolean(values.supplierLogoURL) === false
            }
            onFinish={(image) =>
              setValues({ ...values, supplierLogoURL: image.url })
            }
          />
          {values.supplierLogoURL && (
            <ImgSupplierLogo src={values.supplierLogoURL} alt="Supplier Logo" />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Update Supplier
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

SupplierEdit.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    telephone: PropTypes.string,
    companyName: PropTypes.string,
    companyType: PropTypes.string,
    company_type_id: PropTypes.number,
    company_type_object: PropTypes.object,
    jobTitle: PropTypes.string,
    country: PropTypes.string,
    country_id: PropTypes.number,
    country_object: PropTypes.object,
    companyLocation: PropTypes.string,
    companyWebsite: PropTypes.string,
    supplierLogoURL: PropTypes.string,
    isAgency: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    isDisabled: PropTypes.bool,
    created_at: PropTypes.object,
    updated_at: PropTypes.object,
  }).isRequired,
};

export default SupplierEdit;
