import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ButtonBase, { buttonBaseClasses } from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";

const ButtonBaseImage = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2, 0, 4),
  width: "100%",
  justifyContent: "flex-start",
  [`&:hover, &.${buttonBaseClasses["focusVisible"]}`]: {
    zIndex: 1,
    [`& ${buttonBaseClasses["root"]}-image-button`]: {
      opacity: 0.5,
    },
  },
}));

const SpanImageButton = styled("span")(({ theme }) => ({
  color: theme.palette.common.black,
  opacity: 1,

  "& span": {
    verticalAlign: "text-bottom",
  },
}));

AppBarMenuButton.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default function AppBarMenuButton({ name, url, isDisabled = false }) {
  const history = useHistory();
  const buttonClick = (link) => history.push(link);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        onClick={() => buttonClick(url)}
        sx={{
          cursor: "pointer",
          mb: "8px",
          width: "auto",
        }}
      >
        {!url ? null : (
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              color: "#181818",
              fontSize: { xs: "15px", sm: "20px" },
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
