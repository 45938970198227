import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { brandGetByID, selectBrandGet } from "./brandAsyncThunk";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import AspectRatioBox from "../../components/AspectRatioBox";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  WhiteButton,
  RBlackButton,
  PrimaryRoundButton,
} from "../../components/CustomButtons";
import DOMPurify from "dompurify";
import { Box } from "@mui/material";
import { landing_page_background_colour } from "../../components/styles/materialUIStyles";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 10,
  flexWrap: "wrap",
  padding: "30px 10px",
}));

const StyledImgs = styled("img")(({ theme }) => ({
  width: "49%",
  height: "60vh",
  objectFit: "cover",
  [theme.breakpoints.down("md")]: { width: "100%" },
  [theme.breakpoints.down("lg")]: { width: "100%" },
}));
const StyledSingleImg = styled("img")(({ theme }) => ({
  width: "100%",
  height: "40vh",
  objectFit: "cover",
}));

const TypographyText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontSize: 24,
  [theme.breakpoints.up("sm")]: { fontSize: 36 },
  [theme.breakpoints.up("md")]: { fontSize: 48 },
  [theme.breakpoints.up("lg")]: { fontSize: 60 },
}));

const StyledImg = styled("img")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  display: "block",
  alignSelf: "center",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  zIndex: theme.zIndex.mobileStepper - 1,
  color: "#fff",
  background: "linear-gradient(to top, rgba(0,0,0,0.25), rgba(0,0,0,0)60%)",
  paddingBottom: "16px",
  paddingLeft: "16px",
  [theme.breakpoints.up("sm")]: { paddingLeft: "32px", paddingBottom: "32px" },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "48px",
    paddingBottom: "64px",
    background: "linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0)60%)",
  },

  height: "100%",
}));

function BrandGet({ id }) {
  const dispatch = useDispatch();
  const brandGetStore = useSelector(selectBrandGet);
  const { payload } = brandGetStore;
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    dispatch(brandGetByID(id));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleNavigation = (item) => {
    if (isMdDown) {
      history.push(
        `/search/category/Materials/?brand=${encodeURIComponent(payload.id)}`
      );
    }
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      {payload?.image_objects ? (
        <Box
          sx={
            {
              // mt: { xs: 4, md: 6 },
            }
          }
        >
          <AspectRatioBox
            ratio={2 / 1}
            style={{ minHeight: "320px", maxHeight: "640px" }}
          >
            <Box
              onClick={() => handleNavigation(payload)}
              sx={{
                position: "relative",
                width: "100%",
                borderRadius: "0px",
                overflow: "hidden",
              }}
            >
              <StyledImg src={payload?.bannerURL} alt={payload.name} />
              <StyledContainer maxWidth={false}>
                <Link
                  component={RouterLink}
                  to={`/brand/${payload.name}-${payload.id}`}
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <TypographyText
                    sx={{
                      fontSize: { xs: "36px", sm: "42px", md: "48px" },
                      fontWeight: "600",
                      color: "white",
                      cursor: "pointer",
                      my: 0,
                      "&:hover": { color: "rgb(235, 235, 235)" },
                    }}
                    // onClick={() =>
                    //   history.push(`/brand/${payload.name}-${payload.id}`)
                    // }
                    noWrap
                  >
                    {payload.name}
                  </TypographyText>
                </Link>
                <TypographyText
                  sx={{
                    fontSize: "18px !important",
                    color: "white",
                    fontWeight: "500",
                    marginTop: "0",
                    marginBottom: { xs: 0, md: 4 },
                    width: "100%",
                    "& p": {
                      height: "auto",
                      margin: 0,
                    },
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <div
                    className="preview"
                    dangerouslySetInnerHTML={createMarkup(payload.abtract)}
                  ></div>
                </TypographyText>
                <Link
                  component={RouterLink}
                  to={`/search/category/Materials/?brand=${encodeURIComponent(
                    payload.id
                  )}`}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    textDecoration: "none",
                  }}
                >
                  <PrimaryRoundButton>Browse Products</PrimaryRoundButton>
                </Link>
              </StyledContainer>
            </Box>
          </AspectRatioBox>
          <TypographyText
            sx={{
              fontSize: "15px !important",
              color: "text.secondary",
              fontWeight: "500",
              marginTop: "0",
              marginBottom: 2,
              textAlign: "left",
              paddingTop: "8px",
              paddingLeft: { xs: "16px", sm: "32px" },
              width: "100%",
              "& p": {
                height: "auto",
                margin: 0,
              },
              display: { xs: "flex", sm: "none" },
            }}
          >
            <div dangerouslySetInnerHTML={createMarkup(payload.abtract)}></div>
          </TypographyText>
        </Box>
      ) : (
        <CircularProgress color="inherit" />
      )}
      {/* {product && product[0] && (
        <Box>
          <Box>
            <Typography
              color="primary.main"
              fontSize="20px"
              align="left"
              noWrap
              sx={{
                pl: 6,
                pt: 3,
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => history.push(item.url)}
            >
              <b>{item.brand_name}</b>
            </Typography>
            <Slider product={product} />
          </Box>
        </Box>
      )} */}
    </React.Fragment>
  );
}

BrandGet.propTypes = {
  id: PropTypes.number.isRequired,
};

export default BrandGet;
