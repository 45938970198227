import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import BaseTimelineItem from "@mui/lab/TimelineItem";
import TimelineContent from "@mui/lab/TimelineContent";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import { BlackButton } from "./CustomButtons";

import {
  activeProjectList,
  selectProjectList,
} from "../features/project/projectAsyncThunk";
import { selectSpaceRequestProductList } from "../features/space/spaceAsyncThunk";
import {
  orderCartReset,
  orderCreate,
  orderCreateReset,
  selectOrderCart,
  selectOrderCreate,
} from "../features/order/orderAsyncThunk";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";
import { Dialog, StyledTextField } from "./CustomComponents";
import { setFavoriteProductIds } from "../features/space/spaceMoveSlice";
import { selectCategoryList } from "../features/category/categoryAsyncThunk";
import { favoriteDelete } from "../features/favorite/favoriteAsyncThunk";

const TimelineItem = styled(BaseTimelineItem)(({ theme }) => ({
  "&:before": {
    flex: 0,
    padding: 0,
  },
}));

export default function RequestModal({
  open,
  onClose,
  spaceId,
  favoriteProducts,
  spaces,
}) {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const cardRef = React.useRef(null);
  const [showHint, setShowHint] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [spaceName, setSpaceName] = React.useState("");
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;
  const { csrfToken } = useSelector(selectAuthUserInfo);

  const [isUserActionMade, setIsUserActionMade] = React.useState(false);
  const [cartProducts, setCartProducts] = React.useState([]);

  const [values, setValues] = React.useState({
    project_id: null,
    project: {},
    space: {},
    space_id: null,
    deliveryLocation: userInfo.companyLocation || "",
    telephone: userInfo.telephone || "",
    order_status_id: 1,
    step: 0,
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    district: "",
    name: "",
    city: "",
    name: "",
    companyNameContactPerson: "",
    contactNumber: "",
    emailAddress: "",
  });
  const { isLoading } = useSelector(selectSpaceRequestProductList);
  const dispatch = useDispatch();
  const params = useParams();
  const orderCartStore = useSelector(selectOrderCart);
  const { payload: cartInfo } = orderCartStore;
  const orderCreateStore = useSelector(selectOrderCreate);
  const { successCode: orderCreateSuccess } = orderCreateStore;
  const { payload: categories } = useSelector(selectCategoryList);

  const { payload: projectsList } = useSelector(selectProjectList);

  React.useEffect(() => {
    if (csrfToken) {
      dispatch(activeProjectList());
    }
  }, [csrfToken]);

  React.useEffect(() => {
    if (favoriteProducts.length) {
      let temp = [...favoriteProducts];
      const updatedFavoriteProducts = temp.map((item) => ({
        ...item,
        quantity: 1,
      }));
      setCartProducts(updatedFavoriteProducts);
    }
  }, [favoriteProducts]);

  React.useEffect(() => {
    if (userInfo) {
      setValues({
        ...values,
        deliveryLocation: userInfo?.companyLocation,
        telephone: userInfo?.telephone,
        emailAddress: userInfo?.email,
        name: `${userInfo?.firstName} ${userInfo?.lastName}`,
        contactNumber: userInfo?.telephone,
        companyNameContactPerson: userInfo?.jobTitle,
      });
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (Boolean(orderCreateSuccess) === true) {
      sessionStorage.removeItem("cart");
      dispatch(orderCartReset());
      handleVariant(`Your order requests is submitted`, "success");
      dispatch(orderCreateReset());
      history.push(`/account`);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCreateSuccess]);

  React.useMemo(() => {
    if (spaces.length) {
      const space = spaces.find((item) => item.id === +spaceId);
      setSpaceName(space?.space_name);
      setValues({ ...values, space_id: space?.id, space });
    }
  }, [spaces, spaceId, setValues]);

  React.useMemo(() => {
    if (projectsList.length) {
      const project = projectsList.find((item) => item.id === params.id);
      setValues({ ...values, project, project_id: project?.id });
    }
  }, [projectsList, params, setValues]);

  React.useMemo(() => {
    if (orderCreateSuccess) {
      setSubmitted(true);
    }
  }, [orderCreateSuccess]);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleOrderCreate = async () => {
    setIsUserActionMade(true);
    if (Boolean(values.project_id) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    if (cartInfo.length) {
      await dispatch(
        orderCreate(
          Object.assign(
            {
              project_id: values.project_id,
              deliveryLocation: values?.deliveryLocation || values.addressLine1,
              telephone: values.telephone || values?.companyNameContactPerson,
              order_status_id: 1,
              space_id: +spaceId,
            },
            {
              product_variation: cartProducts.filter((product) =>
                cartInfo.map((x) => x.id).includes(product.id)
              ),
            }
          )
        )
      );
      handleReset();
    }
    await Promise.all(
      cartProducts.map((item) => dispatch(favoriteDelete(item.id)))
    );
    window.location.href = `/project/${params.id}`;
  };

  const handleIncrement = (id) => {
    const updatedCartProducts = [...cartProducts];
    const cartProductItem = updatedCartProducts.find(
      (cartProduct) => cartProduct.id === id
    );
    cartProductItem.quantity += 1;
    setCartProducts(updatedCartProducts);
  };

  const handleDecrement = (id) => {
    const updatedCartProducts = [...cartProducts];
    const cartProductItem = updatedCartProducts.find(
      (cartProduct) => cartProduct.id === id
    );
    if (cartProductItem.quantity > 1) {
      cartProductItem.quantity -= 1;
      setCartProducts(updatedCartProducts);
    }
  };

  const orderSampleProducts = React.useMemo(() => {
    if (cartProducts.length && categories.length) {
      const filteredProducts = categories.map((category) => {
        return {
          category_name: category.name,
          products: cartProducts.filter(
            (product) => product.categoryId === category.id
          ),
        };
      });
      return filteredProducts;
    }
    return [];
  }, [cartProducts, categories]);

  const handleReset = () => {
    onClose();
    setCartProducts([]);
    setValues({
      project_id: null,
      project: {},
      space: {},
      space_id: null,
      deliveryLocation: userInfo.companyLocation || "",
      telephone: userInfo.telephone || "",
      order_status_id: 1,
      step: 0,
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      district: "",
      name: "",
      city: "",
      name: "",
      companyNameContactPerson: "",
      contactNumber: "",
      emailAddress: "",
    });
    dispatch(setFavoriteProductIds([]));
  };

  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  const handleConfirmAddress = () => {
    setIsUserActionMade(true);
    if (Boolean(values.companyName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.addressLine1) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.addressLine1) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.district) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.city) === false) {
      setShowHint(true);
      return;
    }
    setValues({ ...values, step: 2 });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleReset}
        aria-labelledby="space-dialog-title"
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: { md: submitted ? 512 : 940, sm: "100%" },
            borderRadius: "12px",
            backgroundColor: "#F6F6F6",
          },
        }}
      >
        {submitted ? (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { md: 512, sm: "100%" },
            }}
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: "600", marginBottom: "1rem" }}
            >
              All is set!
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
              Your material requests are sent to suppliers.
            </Typography>
          </DialogContent>
        ) : (
          <>
            <MuiDialogTitle
              sx={{ p: 3, display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box display={"flex"}>
                  {values.step > 0 && (
                    <IconButton
                      disableRipple
                      sx={{
                        padding: 0,
                        color: "black",
                      }}
                      onClick={() =>
                        setValues({ ...values, step: values.step - 1 })
                      }
                      size="large"
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  )}

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: "600", fontSize: 24, py: 0.5 }}
                  >
                    Order Samples
                  </Typography>
                </Box>
                {onClose ? (
                  <IconButton
                    aria-label="close"
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: (theme) => theme.spacing(2.5),
                      color: "black",
                    }}
                    onClick={handleReset}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </Box>
            </MuiDialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: submitted ? 512 : 940, sm: "100%" },
                backgroundColor: "#F6F6F6",
                paddingBottom: "0",
              }}
            >
              {isLoading ? (
                <Backdrop
                  open
                  sx={{
                    zIndex: (theme) => theme.zIndex.modal + 1,
                    color: "common.white",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <>
                  {values.step === 0 ? (
                    <>
                      {orderSampleProducts.map(
                        ({ category_name, products }, index) => {
                          return (
                            <React.Fragment key={`request-category-${index}`}>
                              {products.length > 0 && (
                                <Box
                                  sx={{
                                    marginBottom: "2rem",
                                    padding: "1rem",
                                    backgroundColor: "white",
                                    borderRadius: "12px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "600",
                                        marginBottom: 1,
                                        fontSize: "15px",
                                      }}
                                    >
                                      {category_name}
                                    </Typography>
                                  </Box>
                                  {products.length > 0 &&
                                    products.map(
                                      (
                                        {
                                          title,
                                          thumbnail,
                                          brandName,
                                          subtitle,
                                          id,
                                          quantity,
                                        },
                                        index
                                      ) => {
                                        return (
                                          <Box
                                            key={`request-modal-products-${index}`}
                                            sx={{
                                              my: 3,
                                              width: "100%",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ display: "flex" }}>
                                                {thumbnail ? (
                                                  <Box
                                                    sx={{
                                                      width: 65,
                                                      height: 65,
                                                      borderRadius:
                                                        "8px !important",
                                                    }}
                                                  >
                                                    <Card
                                                      ref={cardRef}
                                                      sx={{
                                                        width: "100%",
                                                        borderRadius:
                                                          "8px !important",
                                                      }}
                                                    >
                                                      <CardMedia
                                                        height={"100%"}
                                                        component="img"
                                                        alt={"Product Image"}
                                                        image={thumbnail}
                                                        title={"Product Image"}
                                                        sx={{
                                                          height: 65,
                                                          borderRadius:
                                                            "8px !important",
                                                        }}
                                                      />
                                                    </Card>
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    sx={{
                                                      width: 65,
                                                      height: 65,
                                                      backgroundColor:
                                                        "#C4C4C4",
                                                      borderRadius:
                                                        "8px !important",
                                                    }}
                                                  />
                                                )}
                                                <Box
                                                  sx={{
                                                    ml: 1.5,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      width: "200px",

                                                      mb: 2,
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ fontSize: 12 }}
                                                      fontWeight={600}
                                                      noWrap
                                                    >
                                                      {title}
                                                    </Typography>
                                                    <Typography
                                                      fontSize={9}
                                                      fontWeight={300}
                                                      noWrap
                                                    >
                                                      Product Name
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "200px",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ fontSize: 12 }}
                                                      fontWeight={600}
                                                      noWrap
                                                    >
                                                      {brandName}
                                                    </Typography>
                                                    <Typography
                                                      fontSize={9}
                                                      fontWeight={300}
                                                      noWrap
                                                    >
                                                      Brand Name
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                      "space-between",
                                                    borderLeft: 1,
                                                    borderLeftColor: "#181818",
                                                    pl: 1.5,
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography
                                                      sx={{ fontSize: 12 }}
                                                      fontWeight={600}
                                                    >
                                                      {subtitle}
                                                    </Typography>
                                                    <Typography
                                                      fontSize={9}
                                                      fontWeight={300}
                                                    >
                                                      Variation Name
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "12px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    Quantity:
                                                  </Typography>
                                                  <AddOutlinedIcon
                                                    sx={{
                                                      mx: 1.5,
                                                      cursor: "pointer",
                                                      width: "12px",
                                                      height: "12px",
                                                    }}
                                                    onClick={() =>
                                                      handleIncrement(id)
                                                    }
                                                  />
                                                  <Typography
                                                    sx={{
                                                      fontSize: "12px",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {quantity}
                                                  </Typography>
                                                  <RemoveOutlinedIcon
                                                    sx={{
                                                      ml: 1.5,
                                                      cursor: "pointer",
                                                      width: "12px",
                                                      height: "12px",
                                                    }}
                                                    onClick={() =>
                                                      handleDecrement(id)
                                                    }
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        );
                                      }
                                    )}
                                </Box>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  ) : values?.step === 1 ? (
                    <Box sx={{ backgroundColor: "white", px: 1.5, py: 1 }}>
                      <Box
                        sx={{
                          borderBottom: 0.5,
                          borderBottomColor: "black",
                          marginTop: "12px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: 1,
                            fontSize: "15px",
                          }}
                        >
                          Shipping Address
                        </Typography>
                      </Box>
                      <StyledTextField
                        variant="outlined"
                        autoFocus
                        required
                        sx={{ width: "100%", marginTop: "12px" }}
                        error={
                          isUserActionMade &&
                          Boolean(values.companyName) === false
                        }
                        defaultValue={values.companyName}
                        onChange={handleValues("companyName")}
                        margin="dense"
                        size="medium"
                        id="company-name"
                        placeholder="Company Name"
                        fullWidth
                      />
                      <StyledTextField
                        variant="outlined"
                        autoFocus
                        required
                        sx={{ width: "100%", marginTop: "12px" }}
                        error={
                          isUserActionMade &&
                          Boolean(values.addressLine1) === false
                        }
                        defaultValue={values.addressLine1}
                        onChange={handleValues("addressLine1")}
                        margin="dense"
                        size="medium"
                        id="address-line-1"
                        placeholder="Address Line 1"
                        fullWidth
                      />
                      <StyledTextField
                        variant="outlined"
                        autoFocus
                        required
                        sx={{ width: "100%", marginTop: "12px" }}
                        error={
                          isUserActionMade &&
                          Boolean(values.addressLine2) === false
                        }
                        defaultValue={values.addressLine2}
                        onChange={handleValues("addressLine2")}
                        margin="dense"
                        size="medium"
                        id="address-line-2"
                        placeholder="Address Line 2"
                        fullWidth
                      />
                      <StyledTextField
                        variant="outlined"
                        autoFocus
                        required
                        sx={{ width: "100%", marginTop: "12px" }}
                        error={
                          isUserActionMade && Boolean(values.district) === false
                        }
                        defaultValue={values.district}
                        onChange={handleValues("district")}
                        margin="dense"
                        size="medium"
                        id="district"
                        placeholder="District"
                        fullWidth
                      />
                      <StyledTextField
                        variant="outlined"
                        autoFocus
                        required
                        sx={{ width: "100%", marginTop: "12px" }}
                        error={
                          isUserActionMade && Boolean(values.city) === false
                        }
                        defaultValue={values.city}
                        onChange={handleValues("city")}
                        margin="dense"
                        size="medium"
                        id="city"
                        placeholder="City"
                        fullWidth
                      />
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ backgroundColor: "white", px: 1.5, py: 1 }}>
                        <Box
                          sx={{
                            borderBottom: 0.5,
                            borderBottomColor: "black",
                            marginTop: "12px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              marginBottom: 1,
                              fontSize: "15px",
                            }}
                          >
                            Contact Person
                          </Typography>
                        </Box>
                        <StyledTextField
                          variant="outlined"
                          autoFocus
                          required
                          sx={{ width: "100%", marginTop: "12px" }}
                          error={
                            isUserActionMade && Boolean(values.name) === false
                          }
                          defaultValue={values.name}
                          onChange={handleValues("name")}
                          margin="dense"
                          size="medium"
                          id="name"
                          placeholder="Name"
                          fullWidth
                        />
                        <StyledTextField
                          variant="outlined"
                          autoFocus
                          required
                          sx={{ width: "100%", marginTop: "12px" }}
                          error={
                            isUserActionMade &&
                            Boolean(values.companyNameContactPerson) === false
                          }
                          defaultValue={values.companyNameContactPerson}
                          onChange={handleValues("companyNameContactPerson")}
                          margin="dense"
                          size="medium"
                          id="company-name"
                          placeholder="Company Name"
                          fullWidth
                        />
                        <StyledTextField
                          variant="outlined"
                          autoFocus
                          required
                          sx={{ width: "100%", marginTop: "12px" }}
                          error={
                            isUserActionMade &&
                            Boolean(values.contactNumber) === false
                          }
                          defaultValue={values.contactNumber}
                          onChange={handleValues("contactNumber")}
                          margin="dense"
                          size="medium"
                          id="contact-number"
                          placeholder="Contact Number"
                          fullWidth
                        />
                        <StyledTextField
                          variant="outlined"
                          autoFocus
                          required
                          sx={{ width: "100%", marginTop: "12px" }}
                          error={
                            isUserActionMade &&
                            Boolean(values.emailAddress) === false
                          }
                          defaultValue={values.emailAddress}
                          onChange={handleValues("emailAddress")}
                          margin="dense"
                          size="medium"
                          id="email-address"
                          placeholder="Email Address"
                          fullWidth
                        />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "white",
                          px: 1.5,
                          py: 1,
                          marginTop: "12px",
                        }}
                      >
                        <Box
                          sx={{
                            borderBottom: 0.5,
                            borderBottomColor: "black",
                            marginTop: "12px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              marginBottom: 1,
                              fontSize: "15px",
                            }}
                          >
                            Shipping Address
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: 1,
                            fontSize: "15px",
                          }}
                        >
                          {values.companyNameContactPerson}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: 1,
                            fontSize: "15px",
                          }}
                        >
                          {values.addressLine1}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: 1,
                            fontSize: "15px",
                          }}
                        >
                          {values.addressLine2}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: 1,
                            fontSize: "15px",
                          }}
                        >
                          {values.district}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: 1,
                            fontSize: "15px",
                          }}
                        >
                          {values.city}
                        </Typography>
                        <Box
                          sx={{
                            borderBottom: 0.5,
                            borderBottomColor: "black",
                            marginTop: "12px",
                            width: "28px",
                            cursor: "pointer",
                          }}
                          onClick={() => setValues({ ...values, step: 1 })}
                        >
                          <Typography
                            sx={{
                              fontWeight: "400",
                              marginBottom: "-2px",
                              fontSize: "15px",
                            }}
                          >
                            Edit
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
              {values.step === 0 ? (
                <BlackButton
                  sx={{
                    width: "100%",
                    height: 55,
                    margin: (theme) => theme.spacing(0, 0, 3, 0),
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => setValues({ ...values, step: 1 })}
                >
                  Next
                </BlackButton>
              ) : values.step === 1 ? (
                <BlackButton
                  sx={{
                    width: "100%",
                    height: 55,
                    margin: (theme) => theme.spacing(0, 0, 3, 0),
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmAddress}
                >
                  Confirm Address
                </BlackButton>
              ) : (
                <BlackButton
                  sx={{
                    width: "100%",
                    height: 55,
                    margin: (theme) => theme.spacing(0, 0, 3, 0),
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOrderCreate()}
                >
                  Submit requests
                </BlackButton>
              )}
            </DialogActions>
            {showHint && (
              <DialogContentText
                sx={{
                  margin: (theme) => theme.spacing(0, 3, 3, 3),
                  textAlign: "center",
                }}
              >
                Oops! Something is still wrong above.
              </DialogContentText>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}
