import { createSlice } from "@reduxjs/toolkit";
import { productDeleteByOrderId } from "./projectAsyncThunk";

const productDeleteByOrderIdSlice = createSlice({
  name: "project",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
    isError: "",
  },
  reducers: {},
  extraReducers: {
    [productDeleteByOrderId.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
      state.isError = "";
    },
    [productDeleteByOrderId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [productDeleteByOrderId.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.isError = action.payload.message;
    },
  },
});

export default productDeleteByOrderIdSlice.reducer;
