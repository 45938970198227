import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  createFilterOptions,
  Box,
  DialogContentText,
  Typography,
} from "@mui/material";
import { BlackButton } from "../../components/CustomButtons";
import NewProjectIcon from "../../components/img/NewProjectIcon";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { activeProjectList, projectCreate } from "./projectAsyncThunk";
import {
  catalogListProjectType,
  selectCatalogListProjectType,
} from "../catalog/catalogAsyncThunk";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const ImgProjectThumbnail = styled("img")(({ theme }) => ({
  width: theme.spacing(50),
  objectFit: "cover",
}));

const filter = createFilterOptions();
const freeSoloOptionsFilter = (options, params) => {
  const filtered = filter(options, params);
  if (params.inputValue !== "")
    filtered.push({ name: `Create "${params.inputValue}"` });
  return filtered;
};

export default function ProjectCreate({
  showIcon,
  showModal,
  onClose = () => {},
}) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const catalogListProjectTypeStore = useSelector(selectCatalogListProjectType);
  const { isLoading: projectTypeListLoading, payload: projectTypeList } =
    catalogListProjectTypeStore;
  const [values, setValues] = React.useState({
    projectName: "",
    projectLocation: "",
    projectType: "",
    projectSize: "",
    projectDescription: "",
    projectTypeId: "",
    thumbnailURL: "",
  });
  const [isUserActionMade, setIsUserActionMade] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;

  const handleClickOpen = () => {
    if (Boolean(userInfo.id) === false) {
      return history.push(`/login?from=${location.pathname}`);
    }
    setOpen(true);
  };

  React.useMemo(() => {
    if (showModal) {
      handleClickOpen();
    }
  }, [showModal]);

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(catalogListProjectType());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValues = (prop) => (event, newValue) => {
    setIsUserActionMade(true);
    if (prop === "projectType") {
      if (
        String(newValue?.name).startsWith(`Create "`) &&
        String(newValue?.name).endsWith(`"`)
      ) {
        newValue["name"] = newValue?.name.slice(8, -1);
        setValues({ ...values, projectType: newValue, projectTypeId: "" });
        return;
      } else {
        setValues({
          ...values,
          projectTypeId: newValue?.id,
          projectType: newValue,
        });
        return;
      }
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCreateProject = async () => {
    setIsUserActionMade(true);
    if (Boolean(values.projectName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectSize) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectDescription) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectType) === false) {
      setShowHint(true);
      return;
    }
    const isProjectTypeFound = projectTypeList.some(
      (project) => project.id === values.projectTypeId
    );
    if (isProjectTypeFound) {
      await dispatch(
        projectCreate({
          projectName: values?.projectName,
          projectDescription: values?.projectDescription,
          projectLocation: values?.projectLocation,
          projectSize: values?.projectSize,
          projectTypeId: Number(values?.projectTypeId),
          projectThumbnail: values?.thumbnailURL,
          projectRole: "Owner",
        })
      );
    } else {
      await dispatch(
        projectCreate({
          projectName: values?.projectName,
          projectDescription: values?.projectDescription,
          projectLocation: values?.projectLocation,
          projectSize: values?.projectSize,
          projectType: values?.projectType?.name,
          projectThumbnail: values?.thumbnailURL,
          projectRole: "Owner",
        })
      );
    }
    dispatch(activeProjectList());
    setValues({
      projectDescription: "",
      projectName: "",
      projectLocation: "",
      projectSize: "",
      projectType: "",
      projectTypeId: "",
    });
    setIsUserActionMade(false);
    setShowHint(false);
    setOpen(false);
    onClose();
  };

  return (
    <React.Fragment>
      {showIcon && (
        <Box
          sx={{
            backgroundColor: "#ffffff",
            height: { lg: 202, md: 198, sm: 204, xs: 198 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "8px",
            "&:hover": {
              border: 1,
            },
          }}
          onClick={handleClickOpen}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NewProjectIcon />
            <Typography sx={{ color: "#181818" }}>New project</Typography>
          </Box>
        </Box>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="project-dialog-title"
      >
        <StyledDialogTitle id="project-dialog-title" onClose={handleClose}>
          Project Information
        </StyledDialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", p: 3 }}>
          <StyledTextField
            variant="outlined"
            autoFocus
            required
            sx={{ width: { md: 448, sm: "100%" }, mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.projectName) === false}
            defaultValue={values.projectName}
            onChange={handleValues("projectName")}
            margin="dense"
            size="medium"
            id="project-name"
            placeholder="Project name"
            fullWidth
          />
          <StyledTextField
            variant="outlined"
            autoFocus
            required
            sx={{ width: { md: 448, sm: "100%" }, mb: 1.5, mt: 0 }}
            error={
              isUserActionMade && Boolean(values.projectLocation) === false
            }
            defaultValue={values.projectLocation}
            onChange={handleValues("projectLocation")}
            margin="dense"
            size="medium"
            id="project-location"
            placeholder="Project Location"
            fullWidth
          />
          <Autocomplete
            options={projectTypeList}
            loading={projectTypeListLoading}
            getOptionLabel={(option) => option.name || ""}
            value={values.projectType}
            onChange={handleValues("projectType")}
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            sx={{
              mb: 1.5,
              mt: 0,
            }}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            freeSolo={userInfo && userInfo?.isSuper}
            filterOptions={
              userInfo && userInfo?.isSuper && freeSoloOptionsFilter
            }
            margin="dense"
            size="medium"
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Project Type"
                variant="outlined"
                margin="dense"
                size="medium"
                error={
                  isUserActionMade && Boolean(values.projectType) === false
                }
              />
            )}
          />
          <StyledTextField
            variant="outlined"
            autoFocus
            required
            sx={{ width: { md: 448, sm: "100%" }, mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.projectSize) === false}
            defaultValue={values.projectSize}
            onChange={handleValues("projectSize")}
            margin="dense"
            size="medium"
            id="project-size"
            placeholder="Project Size"
            fullWidth
          />
          <StyledTextField
            variant="outlined"
            required
            sx={{ width: { md: 448, sm: "100%" }, mb: 1.5, mt: 0 }}
            multiline
            rows={5}
            error={
              isUserActionMade && Boolean(values.projectDescription) === false
            }
            defaultValue={values.projectDescription}
            onChange={handleValues("projectDescription")}
            margin="dense"
            size="medium"
            id="project-description"
            placeholder="Project Description"
            fullWidth
          />
          <ImageCreate
            id="project-thumbnail"
            label="Upload project thumbnail image"
            error={isUserActionMade && Boolean(values.thumbnailURL) === false}
            onFinish={(image) =>
              setValues({ ...values, thumbnailURL: image.url })
            }
            labelStyle={{
              fontSize: "12px",
              color: "#5A5A5A",
              borderBottom: 0.5,
              borderColor: "#5A5A5A",
            }}
            inputStyle={{ display: "none" }}
            onClick={() => document.getElementById("project-thumbnail").click()}
            boxStyle={{
              marginTop: "12px",
              marginBottom: "0.5rem",
              width: "180px",
              cursor: "pointer",
            }}
          />
          {values.thumbnailURL && (
            <ImgProjectThumbnail
              src={values.thumbnailURL}
              alt="Project Thumbnail"
            />
          )}
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <BlackButton
            sx={{
              width: "100%",
              height: 55,
              margin: (theme) => theme.spacing(0, 3, 3, 3),
            }}
            variant="contained"
            color="primary"
            onClick={handleCreateProject}
          >
            Add New Project
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}
