import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, IconButton } from "@mui/material";
import { landing_page_background_colour } from "./styles/materialUIStyles";

function items(data, handleClick) {
  return data?.map((item, index) => {
    return (
      <Box
        sx={{ cursor: "pointer" }}
        key={`gallery-${index}`}
        onClick={() => handleClick(item)}
      >
        <img
          style={{
            width: 250,
            height: 250,
            marginLeft: "10px",
            objectFit: "cover",
          }}
          src={`${item.url}?w=250&h=250&fit=crop&auto=format`}
          srcSet={`${item.url}?w=250&h=250&fit=crop&auto=format&dpr=2 2x`}
          alt={item.title}
          loading="lazy"
        />
      </Box>
    );
  });
}

const GallerySlider = ({ gallery, handleClick }) => {
  const [onHover, setOnHover] = useState(false);
  function SampleNextArrow(props) {
    const { className, style, onClick, slideCount, currentSlide } = props;
    let disabled = className.split(" ")[2];

    return (
      <IconButton
        className="slick-arrow-right"
        onClick={onClick}
        sx={{
          color: "white",
          ml: -0.8,
          display:
            onHover && !disabled ? "block !important" : "none !important",
        }}
      >
        <ChevronRightIcon sx={{ fontSize: "40px" }} />
      </IconButton>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;

    return (
      <IconButton
        className="slick-arrow-left"
        onClick={onClick}
        sx={{
          color: "white",
          ml: -0.8,
          display:
            onHover && currentSlide > 0
              ? "block !important"
              : "none !important",

          borderRadius: "10px !important",
        }}
      >
        <ChevronLeftIcon sx={{ fontSize: "40px" }} />
      </IconButton>
    );
  }

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 6,
    slidesToScroll: 5,
    centerMode: false,
    variableWidth: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          autoplay: false,
          slidesToShow: 5,
          slidesToScroll: 4,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          autoplay: false,
          slidesToShow: 4,
          slidesToScroll: 3,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 2,
          centerMode: false,
          prevArrow: false,
          nextArrow: false,
          arrows: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          prevArrow: false,
          nextArrow: false,
          arrows: false,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <>
      <Box
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        sx={{
          backgroundColor: landing_page_background_colour,
          pb: 3,
        }}
      >
        <Slider {...slickSettings}>{items(gallery, handleClick)}</Slider>
      </Box>
    </>
  );
};

export default GallerySlider;
