import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  brandParams,
  selectBrandParams,
} from "../features/brand/brandAsyncThunk";

import { bodyFont } from "../components/styles/materialUIStyles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BrandGetLeadCapturePage from "../features/brand/BrandGetLeadCapturePage";
import Error404 from "./Error404";
import BrandNotFound from "../components/BrandNotFound";

export default function BrandPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading, isSuccess, failCode, name } =
    useSelector(selectBrandParams);

  const { pathname } = location;
  const searchTerm = "-";
  const params = pathname
    .toLowerCase()
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "brand")
    .map((x) => x.split(searchTerm))
    .flat();
  const expectedID = Number(params[params.length - 1]);
  const expectedName = params.slice(0, params.length - 1).join(" ");

  useEffect(() => {
    if (isNaN(expectedID) === false) dispatch(brandParams(expectedID));
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectedID]);

  return (
    <React.Fragment>
      {isNaN(expectedID) === true && <BrandNotFound />}
      {isLoading && (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: "common.white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {isSuccess &&
        name.toLowerCase().replaceAll("-", " ") === expectedName && (
          <React.Fragment>
            <BrandGetLeadCapturePage id={expectedID} />
          </React.Fragment>
        )}
      {isSuccess &&
        name.toLowerCase().replaceAll("-", " ") !== expectedName && (
          <BrandNotFound />
        )}
      {failCode === 406 && <Error404 />}
    </React.Fragment>
  );
}
