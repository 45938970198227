import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  productPropGet,
  selectProductPropGet,
} from "../features/productProp/productPropAsyncThunk";
import {
  matterboardList,
  selectMatterboardList,
} from "../features/matterboard/matterboardAsyncThunk";

import { bodyFont } from "../components/styles/materialUIStyles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import LinearProgress from "@mui/material/LinearProgress";
import SearchRefiner from "../features/search/SearchRefiner";
import { landing_page_background_colour } from "../components/styles/materialUIStyles";
import CardActionArea from "@mui/material/CardActionArea";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import placeholderLogo from "../components/img/noImg.png";
export default function MatterBoard() {
  const dispatch = useDispatch();
  const productPropGetStore = useSelector(selectProductPropGet);
  let productPropList = productPropGetStore?.payload ?? {};
  const [criteria, setCriteria] = useState({});
  const location = useLocation();
  const history = useHistory();
  const [initialLoader, setInitialLoader] = useState(false);

  const [matterboardArrList, setMatterboardArrList] = useState([]);
  const matterBoardListStore = useSelector(selectMatterboardList);
  const { payload } = matterBoardListStore;

  useEffect(() => {
    dispatch(matterboardList());
  }, []);
  useEffect(() => {
    setMatterboardArrList(payload);
  }, [payload]);

  useEffect(() => {
    dispatch(productPropGet());
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRedirect = (id) => {
    history.push(`/matterboard/${id}`);
  };
  useEffect(() => {
    productPropList.length > 0
      ? setCriteria(
          Object.fromEntries(productPropList.map((x) => [x.key, x.values]))
        )
      : setCriteria({});
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropList]);

  useEffect(() => {
    setInitialLoader(true);
    setTimeout(() => setInitialLoader(false), 2000);
  }, []);
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <Box
        sx={{
          backgroundColor: landing_page_background_colour,
          display: { xs: "flex", md: "flex" },
          alignItems: { xs: "inherit", md: "center" },
          py: { xs: 0, sm: 0, md: 5 },
          px: { xs: 0, sm: 0, md: 0 },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {Object.keys(criteria).length > 0 ? (
                <SearchRefiner criteria={criteria} />
              ) : (
                <LinearProgress sx={{ my: 20 }} />
              )}
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  gap: 2,
                  width: "100%",
                }}
              >
                {matterboardArrList.map((item, index) => (
                  <>
                    <Card sx={{ maxWidth: 270, minWidth: 270, height: 280 }}>
                      <CardActionArea onClick={() => handleRedirect(item.id)}>
                        <CardMedia
                          component="img"
                          height="230"
                          image={item?.background_img ?? placeholderLogo}
                          alt="green iguana"
                        />
                        <CardContent>
                          <Typography
                            variant="body"
                            sx={{
                              fontWeight: "600",
                              fontSize: "16px",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            {item?.title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}
