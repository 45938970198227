import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Checkbox, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { selectSpaceMove } from "../features/space/spaceAsyncThunk";
import { setFavoriteProductIds } from "../features/space/spaceMoveSlice";
import { selectSpaceProductList } from "../features/spaceproducts/spaceProductsAsyncThunk";
import placeholderLogo from "../components/img/noImg.png";

const CustomCheckbox = styled(Checkbox)({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
});

const SpaceProductCard = ({ product, setCartItemInfo, projectRole }) => {
  const { push } = useHistory();
  const [checked, setChecked] = useState(false);
  const checkboxRef = useRef(null);
  const dispatch = useDispatch();
  const { payload: favorites } = useSelector(selectSpaceProductList);
  const { favoriteProductIds: data } = useSelector(selectSpaceMove);

  const handleChange = (checked, value) => {
    if (checked) {
      const favoriteProductIds = favorites.filter(
        (favorite) => +favorite.id === +value
      );
      dispatch(setFavoriteProductIds([...data, ...favoriteProductIds]));
      const newCart = favoriteProductIds.map((item) => {
        return {
          id: item.id,
          availability: item?.general?.hasOwnProperty("Availability")
            ? item?.general["Availability"][0]
            : "Made to Order",
          brandName: item.brandName,
          image: item.imageURL,
          link: item.url,
          productName: item.title,
          productSize: item.productSize,
          name: item.subtitle,
          thumbnail: item.thumbnail,
          categoryName: item.categoryName,
          categoryId: item.categoryId,
        };
      });
      setCartItemInfo((prevState) => [...prevState, ...newCart]);
    } else {
      const favoriteProductIds = data.filter(
        (favorite) => +favorite.id !== +value
      );
      dispatch(setFavoriteProductIds([...favoriteProductIds]));
      const newCart = favoriteProductIds.map((item) => {
        return {
          id: item.id,
          availability: item?.general?.hasOwnProperty("Availability")
            ? item?.general["Availability"][0]
            : "Made to Order",
          brandName: item.brandName,
          image: item.imageURL,
          link: item.url,
          productName: item.title,
          productSize: item.productSize,
          name: item.subtitle,
          thumbnail: item.thumbnail,
          categoryName: item.categoryName,
          categoryId: item.categoryId,
        };
      });
      setCartItemInfo([...newCart]);
    }
    if (checked) {
      setTimeout(() => {
        document
          .getElementById("spaces-action-bar")
          .scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
    setChecked(checked);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            textAlign: "left",
            borderRadius: "8px",
            "&:hover": {
              border: "1.5px solid",
            },
          }}
        >
          {projectRole === "Owner" && (
            <Box
              sx={{
                position: "absolute",
                cursor: "pointer",
              }}
            >
              <CustomCheckbox
                checked={checked}
                onChange={(e) => handleChange(e.target.checked, e.target.value)}
                value={product?.id}
                inputRef={checkboxRef}
                inputProps={{
                  "aria-label": "spaces-action-bar",
                  style: {
                    color: "red",
                  },
                }}
              />
            </Box>
          )}
          <Box sx={{ cursor: "pointer" }}>
            <Box
              onClick={() => {
                if (checked) {
                  checkboxRef.current.checked = false;
                } else {
                  checkboxRef.current.checked = true;
                }
                handleChange(
                  checkboxRef.current.checked,
                  checkboxRef.current.value
                );
              }}
            >
              <img
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
                alt={product?.title || placeholderLogo}
                src={product?.imageURL}
                height={220}
              />
            </Box>
            <Box sx={{ p: 1.5, display: "block" }}>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "600",
                  marginBottom: "1px",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => push(product?.url)}
                noWrap
              >
                <b>{product?.title}</b>
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "500",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => push(product?.brandUrl)}
                noWrap
              >
                {product?.brandName}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default SpaceProductCard;
