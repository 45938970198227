import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { productVariationCreate } from "./productAsyncThunk";
import {
  productGroupList,
  selectProductGroupList,
} from "../productGroup/productGroupAsyncThunk";
import ImageCreate from "../image/ImageCreate";

import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Chip from "@mui/material/Chip";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { LightBlueButton, BlackButton } from "../../components/CustomButtons";
import ImageArrayPreview from "../../components/ImageArrayPreview";
import AutoCompleteOption from "../../components/AutoCompleteOption";

function ProductVariationCreate({ data }) {
  const dispatch = useDispatch();
  const productGroupListStore = useSelector(selectProductGroupList);
  const { isLoading: productGroupLoading, payload: productGroupRecord } =
    productGroupListStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    product_id: data.product_id,
    product_name: data.product_name,
    name: "",
    imageURL: [],
    thumbnailURL: [],
    product_group_ids: [],
    product_group_objects: [],
    sku: ""
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleImageAdd = (image) => {
    const imageURL = [...values.imageURL, image.url];
    const thumbnailURL = [...values.thumbnailURL, image.thumbnailURL];
    setValues({ ...values, imageURL, thumbnailURL });
  };

  const handleImageRemove = (index) => () => {
    const imageURL = [...values.imageURL];
    const thumbnailURL = [...values.thumbnailURL];
    imageURL.splice(index, 1);
    thumbnailURL.splice(index, 1);
    setValues({ ...values, imageURL, thumbnailURL });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = Array.isArray(newValue)
      ? { ...values, [prop]: [...newValue] }
      : { ...values, [prop]: newValue };
    if (prop === "product_group_objects")
      newObj.product_group_ids = newValue.map((x) => x.id);
    setValues(newObj);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.name) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.sku) === false) {
      setShowHint(true);
      return;
    }
    if (values.imageURL.length < 1) {
      setShowHint(true);
      return;
    }
    if (
      Array.isArray(values.product_group_ids) &&
      values.product_group_ids.length === 0
    ) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(productVariationCreate(values));
  };

  useEffect(() => {
    const { product_id, product_name } = data;
    setValues({ ...values, product_id, product_name });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(productGroupList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LightBlueButton
        variant="contained"
        color="primary"
        sx={{ m: 1 }}
        onClick={handleClickOpen}
        disabled={Boolean(data.product_id) === false}
      >
        Create Variation
      </LightBlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-product-variation"
        scroll="body"
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle
          id="form-dialog-create-product-variation"
          onClose={handleClose}
        >
          Create Product Variation
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            disabled
            defaultValue={data.product_name}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            placeholder="Product"
            fullWidth
          />
          <StyledTextField
            autoFocus
            required
            sx={{ mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.name) === false}
            defaultValue={values.name}
            onChange={handleValues("name")}
            variant="outlined"
            margin="dense"
            size="small"
            id="product-variation-name"
            placeholder="Name"
            fullWidth
          />
          <StyledTextField
            autoFocus
            required
            sx={{ mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.sku) === false}
            defaultValue={values.sku}
            onChange={handleValues("sku")}
            variant="outlined"
            margin="dense"
            size="small"
            id="product-variation-sku"
            placeholder="Sku"
            fullWidth
          />
          <Autocomplete
            sx={{ my: 1 }}
            multiple
            id="productgroup"
            options={productGroupRecord}
            loading={productGroupLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            value={values.product_group_objects}
            onChange={handleAutoCompleteValues("product_group_objects")}
            fullWidth
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.name} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <StyledTextField
                sx={{ mb: 1.5, mt: 0 }}
                {...params}
                placeholder="Product Group"
                variant="outlined"
                error={
                  isUserActionMade && values.product_group_objects.length === 0
                }
              />
            )}
          />
          <ImageCreate
            label="Image URL"
            error={isUserActionMade && values.imageURL.length < 1}
            onFinish={handleImageAdd}
          />
          <ImageArrayPreview
            imageArray={values.thumbnailURL}
            onRemove={handleImageRemove}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Create Product Variation
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

ProductVariationCreate.propTypes = {
  data: PropTypes.shape({
    product_id: PropTypes.number,
    product_name: PropTypes.string,
  }).isRequired,
};

export default ProductVariationCreate;
