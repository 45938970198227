import { useState, useRef, useCallback, useEffect } from "react";

const useImageHoverState = (imageIds, delay) => {
  const [hoverStates, setHoverStates] = useState({});
  const leaveHandlers = useRef({});

  // Create debounced onMouseLeave handlers for each image
  useEffect(() => {
    imageIds?.forEach((imageId) => {
      if (!leaveHandlers.current[imageId]) {
        leaveHandlers.current[imageId] = () => {
          const handler = setTimeout(() => {
            setHoverStates((prevHoverStates) => ({
              ...prevHoverStates,
              [imageId]: false,
            }));
          }, delay);
          return () => {
            clearTimeout(handler);
          };
        };
      }
    });

    return () => {
      Object.values(leaveHandlers.current).forEach((handler) =>
        clearTimeout(handler)
      );
    };
  }, [imageIds, delay]);

  const handleMouseEnter = useCallback((imageId) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [imageId]: true,
    }));
  }, []);

  const handleMouseLeave = useCallback((imageId) => {
    if (leaveHandlers.current[imageId]) {
      leaveHandlers.current[imageId]();
    }
  }, []);

  return { hoverStates, handleMouseEnter, handleMouseLeave };
};

export default useImageHoverState;
