import React, { useState, forwardRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { styled, theme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import placeholderLogo from "../components/img/no_image_logo.png";

import { Box, ButtonBase, Grid, Link, Paper } from "@mui/material";
import AddIcon from "./img/add-icon.svg";
import SelectProductModal from "./SelectProductModal";
import Fade from "@mui/material/Fade";
import { landing_page_background_colour } from "./styles/materialUIStyles";
import { ClassNames } from "@emotion/react";
import useImageHoverState from "./customHooks/ImageHoverState";
import useThrottledHover from "./customHooks/throttleHoverState";

const StyledImg = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
}));

const ImgPlaceholder = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
}));

const useStyles = makeStyles((theme) => ({
  paperHover: {
    borderStyle: "solid",
    borderColor: "rgba(0,0,0,0.25)",
    boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.05)",
  },
  showChip: {
    opacity: 1,
  },
  showAddIcon: {
    opacity: 1,
  },
}));

function ImgMediaCard({ item }) {
  const [open, setOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [onHover, setOnHover] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const LinkBehavior = forwardRef((props, ref) => (
    <RouterLink
      ref={ref}
      to={item.url}
      // target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  ));

  const { hoverStates, handleMouseEnter, handleMouseLeave } = useThrottledHover(
    [item.id],
    100
  );

  // console.log(item);
  return (
    <React.Fragment>
      <Box
        onMouseLeave={() => {
          handleMouseLeave(item.id);
        }}
        onMouseEnter={() => {
          handleMouseEnter(item.id);
        }}
        sx={{
          flexGrow: 1,
          position: "relative",
          width: { xs: 180, sm: 230, md: 250, lg: 280 },
          paddingRight: { xs: "8px", sm: "12px" },
        }}
      >
        <Paper
          elevation={0}
          className={`${
            hoverStates[item.id] && !isMdDown ? classes.paperHover : ""
          }`}
          sx={{
            textAlign: "left",
            borderRadius: "12px",
            border: 1.5,
            borderColor: landing_page_background_colour,
            transition: "border-color 100ms ease-in-out",
          }}
        >
          <Grid
            container
            direction="column"
            alignContent="center"
            sx={{
              borderRadius: "12px",
              // ":hover": {
              //   boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.05)",
              //   transitionDelay: "100ms",
              // },
            }}
          >
            <Grid
              xs
              item
              sx={{
                width: "100%",
              }}
            >
              <Link
                component={LinkBehavior}
                // sx={{ height: 1000 }}
                color="inherit"
              >
                <Box
                  sx={{
                    width: "100%",
                    position: "relative",
                    aspectRatio: "1",
                  }}
                >
                  {item.imageURL ? (
                    <StyledImg
                      alt={item.subtitle}
                      src={`${item.imageURL}?tr=w-300,h-300`}
                    />
                  ) : (
                    <ImgPlaceholder alt={item.subtitle} src={placeholderLogo} />
                  )}
                </Box>
              </Link>
            </Grid>
            <Grid item xs sx={{ width: "100%" }}>
              <Link
                component={LinkBehavior}
                sx={{ height: 1000 }}
                color="inherit"
                underline="none"
              >
                <Box
                  sx={{
                    p: { xs: 1.2, md: 1.5 },
                    display: "block",
                    alignItem: "center",
                  }}
                >
                  <Typography
                    variant="title_secondary_black"
                    sx={{
                      display: "block",
                      textAlign: "left",
                      fontSize: "15px",
                      marginBottom: "1px",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    noWrap
                  >
                    <b>{item?.title}</b>
                  </Typography>
                  <Link
                    component={RouterLink}
                    to={`/brand/${item.brand_name}-${item.brand_id}`}
                    sx={{
                      textDecoration: "none",
                    }}
                  >
                    <Typography
                      variant="title_secondary_grey"
                      sx={{
                        display: "block",
                        textAlign: "left",
                        fontSize: "15px",
                        "&:hover": { textDecoration: "underline" },
                      }}
                      noWrap
                    >
                      {item?.brand_name}
                    </Typography>
                  </Link>
                </Box>
              </Link>
            </Grid>
          </Grid>
          <Box
            className={`${
              hoverStates[item.id] || isMdDown ? classes.showChip : ""
            }`}
            sx={{
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
              left: "8px",
              bottom: { xs: "73px", md: "80px" },
              transition: "opacity 100ms ease-in-out",
            }}
          >
            {/* <Link
              component={RouterLink}
              to={`/search/category/${item.category_name}`}
            > */}
            <Chip
              label={
                item.category_name.length > 18
                  ? `${item?.category_name.slice(0, 18)}...`
                  : item.category_name
              }
              size={isMdDown ? "small" : "medium"}
              sx={{
                fontSize: { xs: "11px", sm: "13px" },
                fontWeight: "500",
                // px: { xs: 0.5, sm: 1 },
                backgroundColor: "rgba(255, 255, 255, 0.6)",
                backdropFilter: "blur(8px)",
                color: "text.primary",
                border: "1px solid",
                borderColor: "rgba(0,0,0,0.25)",
              }}
            />
            {/* </Link> */}
          </Box>
          <Box
            className={`${
              hoverStates[item.id] && !isMdDown ? classes.showAddIcon : ""
            }`}
            sx={{
              opacity: 0,
              transition: "opacity 100ms ease-in-out",
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: "12px",
              right: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedProduct(item);
              setOpen(true);
            }}
          >
            <img
              alt="add icon"
              src={AddIcon}
              style={{ width: 20, height: 20 }}
            />
          </Box>
        </Paper>
      </Box>
      {open && (
        <SelectProductModal
          open={open}
          onClose={() => {
            setOpen(false);
            setOnHover(false);
            setSelectedProduct(null);
          }}
          url={selectedProduct?.url}
          selectedProduct={selectedProduct}
        />
      )}
    </React.Fragment>
  );
}

ImgMediaCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    imageURL: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default ImgMediaCard;
