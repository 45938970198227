import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { brandRelevant, selectBrandRelevant } from "./brandAsyncThunk";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material/";
import Slider from "../../components/Slider";
import { useHistory } from "react-router-dom";

function BrandRelevant({ product_id, brand_id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const brandRelevantStore = useSelector(selectBrandRelevant);
  const { payload } = brandRelevantStore;

  useEffect(() => {
    if (Boolean(product_id) === true && Boolean(brand_id) === true)
      dispatch(brandRelevant({ product_id, brand_id }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id, brand_id]);

  return (
    <React.Fragment>
      {Array.isArray(payload) && payload.length > 0 && (
        <Box sx={{ background: "#F6F6F6" }}>
          <Box
            sx={{
              // mb: 2,
              pl: { xs: 2, sm: 4, md: 6 },
              pt: { xs: "20px", md: 3 },
              pb: { xs: "8px", md: "16px" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              component={RouterLink}
              to={`/search/category/Materials/?brand=${brand_id}`}
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <Typography
                color="primary.main"
                fontSize="20px"
                align="left"
                noWrap
                sx={{
                  cursor: "pointer",
                  marginLeft: "6px",
                  // "&:hover": { textDecoration: "underline" },
                }}
              >
                <b>{payload[0]?.brand_name}</b>
              </Typography>
              <ChevronRightIcon
                sx={{
                  fontSize: { xs: "28px", md: "32px" },
                  color: theme.palette.text.secondary,
                }}
              />
            </Link>
          </Box>
          <Box>
            <Slider product={payload} />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

BrandRelevant.propTypes = {
  product_id: PropTypes.number.isRequired,
  brand_id: PropTypes.number.isRequired,
};

export default BrandRelevant;
