import { createSlice } from "@reduxjs/toolkit";
import { matterboardDetailsByID } from "./matterboardAsyncThunk";

const matterboardDetailsSlice = createSlice({
  name: "matterboard",
  initialState: {
    isLoading: false,
    isSuccess: false,
    matterboardDetails: null,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [matterboardDetailsByID.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [matterboardDetailsByID.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.matterboardDetails = action.payload.data.result;
    },
    [matterboardDetailsByID.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
  },
});

export default matterboardDetailsSlice.reducer;
