import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import LandingPageBanner from "../components/LandingPageBanner";
import LandingPageBrand from "../components/LandingPageBrand";
import LandingPageBrand2 from "../components/LandingPageBrand2";
import LandingPageBrand3 from "../components/LandingPageBrand3";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

import {
  categoryGetLandingPage,
  selectCategoryGetLandingPage,
} from "../features/category/categoryAsyncThunk";
import Slider from "../components/Slider";
import {
  productGetLandingPage,
  productLastThreeMonthList,
} from "../features/product/productAsyncThunk";
import {
  brandGetLandingPageWithProducts,
  selectBrandGetLandingPageWithProducts,
} from "../features/brand/brandAsyncThunk";

export default function LandingPage() {
  const { payload } = useSelector(selectCategoryGetLandingPage);
  const { payload: data } = useSelector(selectBrandGetLandingPageWithProducts);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    dispatch(categoryGetLandingPage());
    dispatch(productGetLandingPage());
    dispatch(productLastThreeMonthList());
    dispatch(brandGetLandingPageWithProducts());
  }, []);

  const filteredCategoryProducts = useMemo(() => {
    if (payload?.length) {
      return payload?.filter((item) => {
        return ["Wallcovering", "Ceramic", "Textile"].includes(
          item?.category_name
        );
      });
    }
  }, [payload]);

  useEffect(() => {
    dispatch(categoryGetLandingPage());
    dispatch(productGetLandingPage());
    dispatch(productLastThreeMonthList());
  }, []);

  const renderCategorySkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < 3; i++) {
      skeletons.push(
        <React.Fragment key={`skeleton-${i}`}>
          <Box
            sx={{
              pl: { xs: 2, sm: 4, md: 6 },
              pt: { xs: 4, md: 6 },
              pb: { xs: 1, sm: 1, md: 2 },
              alignItems: "center",
              display: "flex",
            }}
          >
            <Skeleton variant="text" width={150} height={24} />
          </Box>
          {/* Add Slider Skeleton if needed */}
        </React.Fragment>
      );
    }
    return skeletons;
  };

  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <LandingPageBanner />
      {filteredCategoryProducts?.length
        ? filteredCategoryProducts?.map((item, index) => {
            return (
              <React.Fragment key={`product-${index}`}>
                <Box
                  sx={{
                    pl: { xs: 2, sm: 4, md: 6 },
                    pt: {
                      xs: 4,
                      md: 6,
                    },
                    pb: {
                      xs: 1,
                      sm: 1,
                      md: 2,
                    },
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/search/category/Materials->${item?.category_name}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                  >
                    <Typography
                      color="primary.main"
                      // variant="h5"
                      textAlign="left"
                      noWrap
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        cursor: "pointer",
                        // "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      <b>New {item?.category_name}</b>
                    </Typography>
                    <ChevronRightIcon
                      sx={{
                        fontSize: { xs: "28px", md: "32px" },
                        color: theme.palette.text.secondary,
                      }}
                    />
                  </Link>
                </Box>
                <Slider product={item.product} />
              </React.Fragment>
            )}) : null }
      {/* {filteredCategoryProducts?.map((item, index) => {
        return (
          <React.Fragment key={`product-${index}`}>
            <Box
              sx={{
                // border: 5,
                // mb: "12px",
                pl: 6,
                pt: index === 0 ? 6 : 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color="primary.main"
                fontSize="20px"
                // variant="h5"
                align="left"
                noWrap
                sx={{
                  cursor: "pointer",
                  marginLeft: "6px",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() =>
                  history.push(
                    `/search/category/Materials->${item?.category_name}`
                  )
                }
              >
                <b>New {item?.category_name}</b>
              </Typography>
            </Box>
            <Slider product={item.product} />
          </React.Fragment>
        );
      })} */}
      {data && data.length > 0
        ? data.map((item, index) => {
            return (
              <React.Fragment key={`landing-page-brand-${index}`}>
                <LandingPageBrand data={item ? item : {}} />
              </React.Fragment>
            );
          })
        : null}
      {/* {data && data.length > 0 ? (
        <LandingPageBrand2 data={data[1] ? data[1] : []} />
      ) : null}
      {data && data.length > 0 ? (
        <LandingPageBrand2 data={data[1] ? data[1] : []} />
      ) : null} */}
    </React.Fragment>
  );
}