import { createSlice } from "@reduxjs/toolkit";
import { matterboardDeleteByID } from "./matterboardAsyncThunk";

const matterboardDeleteSlice = createSlice({
  name: "matterboard",
  initialState: {
    payload: [],
    isLoading: false,
    successCode: 0,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [matterboardDeleteByID.pending]: (state, action) => {
      state.isLoading = true;
      state.successCode = 0;
      state.isFail = false;
    },
    [matterboardDeleteByID.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.payload = [action.payload];
    },
    [matterboardDeleteByID.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default matterboardDeleteSlice.reducer;
