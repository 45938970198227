import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { supplierContact, selectSupplierContact } from "./supplierAsyncThunk";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";
import { useHistory, useLocation } from "react-router-dom";

import {
  Dialog,
  DialogTextField,
  StyledDialogTitle,
} from "../../components/CustomComponents";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import {
  LightGreyButton,
  BlackButton,
  OutlinedButton,
  NoColorButton,
} from "../../components/CustomButtons";
import { Box, Typography } from "@mui/material";
import AutoCompleteOption from "../../components/AutoCompleteOption";

function SupplierContact({
  supplierInfo,
  productInfo,
  blackButton,
  outlinedButton,
  isModal,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;
  const supplierContactStore = useSelector(selectSupplierContact);
  const { isSuccess: supplierContactSuccess } = supplierContactStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const enquiryTypeList = ["Information", "Request", "Others"];
  const projectTypeList = ["Construction", "Architecture", "Others"];
  const [values, setValues] = useState({
    enquiryType: enquiryTypeList[0],
    projectName: "",
    projectLocation: "",
    projectType: projectTypeList[0],
    projectSize: "",
    message: "",
    isContactByEmail: true,
    isContactByPhone: true,
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    if (Boolean(userInfo.id) === false) {
      return history.push(`/login?from=${location.pathname}`);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    setValues(newObj);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.enquiryType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectSize) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.message) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(
      supplierContact({ ...values, userInfo, supplierInfo, productInfo })
    );
  };

  useEffect(() => {
    if (supplierContactSuccess) handleClose();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierContactSuccess]);

  return (
    <React.Fragment>
      {blackButton ? (
        <BlackButton
          variant="contained"
          color="primary"
          sx={{ my: 1, width: (theme) => theme.spacing(35) }}
          onClick={handleClickOpen}
        >
          <b>Contact</b>
        </BlackButton>
      ) : outlinedButton ? (
        <OutlinedButton
          color="primary"
          variant="contained"
          sx={{ my: 1, width: { xs: "100%", lg: isModal ? 432 : "100%" } }}
          onClick={handleClickOpen}
        >
          <Typography
            sx={{
              fontSize: 15,
              lineHeight: "18px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <b>Contact Supplier</b>
          </Typography>
        </OutlinedButton>
      ) : (
        <LightGreyButton
          variant="contained"
          color="primary"
          sx={{ my: 1, width: { xs: "100%", lg: 472 } }}
          onClick={handleClickOpen}
        >
          <b>Contact Supplier</b>
        </LightGreyButton>
      )}
      <Dialog open={open} aria-labelledby="form-dialog-title" scroll="body">
        <StyledDialogTitle id="form-dialog-title" onClose={handleClose}>
          Contact Supplier
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <DialogContentText>
            <b>Hi{userInfo.firstName ? ` ${userInfo.firstName},` : ","}</b>
          </DialogContentText>
          <DialogContentText>
            {supplierInfo.companyName
              ? `Please fill in the form so ${supplierInfo.companyName} can get in touch with you.`
              : "Please wait..."}
          </DialogContentText>
          <Autocomplete
            options={enquiryTypeList}
            value={values.enquiryType}
            onChange={handleAutoCompleteValues("enquiryType")}
            fullWidth
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option} />;
            }}
            renderInput={(params) => (
              <DialogTextField
                {...params}
                autoFocus
                sx={{ mt: 1, mb: 1.5 }}
                required
                error={
                  isUserActionMade && Boolean(values.enquiryType) === false
                }
                label="Type of enquiry"
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
          />
          <DialogTextField
            required
            error={isUserActionMade && Boolean(values.projectName) === false}
            defaultValue={values.projectName}
            onChange={handleValues("projectName")}
            variant="outlined"
            sx={{ mt: 0, mb: 1.5 }}
            margin="dense"
            size="small"
            label="Project Name"
            fullWidth
          />
          <DialogTextField
            required
            error={
              isUserActionMade && Boolean(values.projectLocation) === false
            }
            defaultValue={values.projectLocation}
            onChange={handleValues("projectLocation")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            label="Project Location"
            fullWidth
          />
          <Autocomplete
            options={projectTypeList}
            value={values.projectType}
            onChange={handleAutoCompleteValues("projectType")}
            fullWidth
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option} />;
            }}
            renderInput={(params) => (
              <DialogTextField
                {...params}
                autoFocus
                required
                error={
                  isUserActionMade && Boolean(values.projectType) === false
                }
                label="Project Type"
                variant="outlined"
                sx={{ mt: 0, mb: 1.5 }}
                margin="dense"
                size="small"
              />
            )}
          />
          <DialogTextField
            required
            error={isUserActionMade && Boolean(values.projectSize) === false}
            defaultValue={values.projectSize}
            onChange={handleValues("projectSize")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            label="Project Size"
            fullWidth
          />
          <DialogTextField
            required
            multiline
            minRows={5}
            error={isUserActionMade && Boolean(values.message) === false}
            defaultValue={values.message}
            onChange={handleValues("message")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            label="Message"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isContactByEmail}
                onChange={(e) =>
                  setValues({
                    ...values,
                    isContactByEmail: e.target.checked,
                  })
                }
                name="isContactByEmail"
                color="primary"
                inputProps={{
                  "aria-label": "Contact via email",
                }}
              />
            }
            label="Contact via email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isContactByPhone}
                onChange={(e) =>
                  setValues({
                    ...values,
                    isContactByPhone: e.target.checked,
                  })
                }
                name="isContactByPhone"
                color="primary"
                inputProps={{
                  "aria-label": "Contact via phone",
                }}
              />
            }
            label="Contact via phone"
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            disabled={Boolean(supplierInfo.id) === false}
            onClick={handleSubmit}
          >
            Submit
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

SupplierContact.propTypes = {
  supplierInfo: PropTypes.object.isRequired,
  productInfo: PropTypes.object.isRequired,
  blackButton: PropTypes.bool,
};

export default SupplierContact;
