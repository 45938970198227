import { createSlice } from "@reduxjs/toolkit";
import { orderCartDecode, orderCartReset } from "./orderAsyncThunk";

const orderCartSlice = createSlice({
  name: "order",
  initialState: {
    payload: [],
    availability: "The cart is empty",
    projectId: "",
    spaceId: "",
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [orderCartDecode.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [orderCartDecode.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = [...action.payload.cart];
      state.availability = action.payload.availability;
      state.projectId = action.payload.projectId;
      state.spaceId = action.payload.spaceId;
    },
    [orderCartDecode.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
    [orderCartReset]: (state, action) => {
      state.payload = [];
      state.availability = "The cart is empty";
      state.isLoading = false;
      state.isSuccess = false;
      state.isFail = false;
    },
  },
});

export default orderCartSlice.reducer;
