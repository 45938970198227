import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProjectTypeGrid from '../features/catalog/ProjectTypeGrid';
import OrderStatusTypeGrid from '../features/catalog/OrderStatusTypeGrid';
import AdminManageGrid from '../components/AdminManageGrid';
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& *": {
    textTransform: 'none',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function SuperPage() {
  const theme = useTheme();
  const vertical = useMediaQuery(theme.breakpoints.up('lg'));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{
      flexGrow: 1,
      mt: { xs: 1, lg: 2 },
      bgcolor: "background.paper",
      display: "flex",
      flexDirection: { xs: "column", lg: "row" },
      textAlign: "left",
      justifyContent: "left",
    }}>
      <StyledTabs
        orientation={vertical ? "vertical" : "horizontal"}
        variant="scrollable"
        indicatorColor="secondary"
        value={value}
        onChange={handleChange}
        aria-label="Catalog"
      >
        <Tab label="Admin Account Management" {...a11yProps(0)} />
        <Tab label="Project Type" {...a11yProps(1)} />
        <Tab label="Types of Order Status" {...a11yProps(2)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <AdminManageGrid />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProjectTypeGrid />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrderStatusTypeGrid />
      </TabPanel>
    </Container>
  );
}
