import { createSlice } from '@reduxjs/toolkit';
import { catalogCreateOrderStatusType } from './catalogAsyncThunk';

const catalogCreateOrderStatusTypeSlice = createSlice({
  name: "catalog",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [catalogCreateOrderStatusType.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [catalogCreateOrderStatusType.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [catalogCreateOrderStatusType.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default catalogCreateOrderStatusTypeSlice.reducer;