import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import SorryLogo from "../components/img/figure_ojigi.png";

export default function BrandNotFound() {
  return (
    <Container maxWidth="sm">
      <Box sx={{
        width: 1,
        maxWidth: 500,
        my: { xs: 20, md: 15 },
        mx: 0,
      }}>
        <img
          alt="Sorry"
          src={SorryLogo}
        />
        <Typography sx={{ mt: 2 }} variant="subtitle1" gutterBottom>
            The Brand You Are Looking For Is Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
            <Link href="/" color="inherit" underline="hover">
            Back to Home
            </Link>
        </Typography>
      </Box>
    </Container>
  );};