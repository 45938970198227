import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { categoryDelete } from "./categoryAsyncThunk";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  LightBlueButton,
  HighlightButton,
  BlackButton,
} from "../../components/CustomButtons";
import { Dialog, StyledDialogTitle } from "../../components/CustomComponents";

function CategoryDelete({ isSelected, data }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(categoryDelete(data.id));
    handleClose();
  };

  return (
    <React.Fragment>
      <BlackButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Delete
      </BlackButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-delete-category"
        scroll="body"
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle
          id="form-dialog-delete-category"
          onClose={handleClose}
        >
          Delete Category ({data.fullname})
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <DialogContentText>Are You Sure?</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <HighlightButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 1.5, 0),
            }}
            onClick={handleSubmit}
          >
            Confirm
          </HighlightButton>
          <LightBlueButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
              marginLeft: "0!important",
            }}
            onClick={handleClose}
          >
            Cancel
          </LightBlueButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CategoryDelete.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    fullname: PropTypes.string,
  }).isRequired,
};

export default CategoryDelete;
