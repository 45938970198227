import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar, { imageListItemBarClasses } from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const StyledImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  [`& .${imageListItemBarClasses["root"]}`]: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  [`& .${imageListItemBarClasses["title"]}`]: {
    color: theme.palette.primary.light,
  },
}));

function ImageArrayPreview({ imageArray, onRemove }) {
  return (
    <Box sx={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: "inherit",
    }}>
      <ImageList
        cols={Math.min(imageArray.length, 2.5)}
        sx={{
          flexWrap: "nowrap",
          // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
          transform: "translateZ(0)",
        }}
      >
        {imageArray.map((item, i) => (
          <ImageListItem key={i}>
            <img src={item} alt={i} />
            <StyledImageListItemBar
              actionIcon={
                <IconButton aria-label={`cancel ${i}`} onClick={onRemove(i)} size="large">
                  <CancelIcon sx={{ color: "primary.light" }} />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

ImageArrayPreview.propTypes = {
  imageArray: PropTypes.arrayOf(PropTypes.string),
  onRemove: PropTypes.func.isRequired,
};

export default ImageArrayPreview;
