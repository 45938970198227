import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ImageList from "@mui/material/ImageList";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import ImageListItemBar, {
  imageListItemBarClasses,
} from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
  [`& .${imageListItemClasses["img"]}`]: {
    height: "100%",
    objectFit: "contain",
  },
}));

const StyledImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  background:
    "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  [`& .${imageListItemBarClasses["title"]}`]: {
    color: theme.palette.primary.light,
  },
}));

const StyledTopImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  background:
    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  [`& .${imageListItemBarClasses["title"]}`]: {
    color: theme.palette.primary.light,
  },
}));

function SinglePreviewImageList({ image_objects, onRemove, onLeft }) {
  const imageCount = image_objects ? Math.min(image_objects.length, 2) : 1;
  const columnWidth = window.innerWidth / imageCount;
  const imageWidth = image_objects
    ? Math.min(...image_objects.map((x) => x.width))
    : 180;
  const displayRatio = Math.ceil(imageWidth / columnWidth);
  const rowHeight = 200;
  // image_objects
  //   ? Math.min(...image_objects.map((x) => x.height / displayRatio))
  //   : 180;

  return (
    <React.Fragment>
      <ImageList
        sx={{ width: "100%", height: rowHeight }}
        cols={Math.min(image_objects.length, 2)}
        rowHeight={rowHeight}
      >
        {image_objects.map((item, i) => (
          <StyledImageListItem key={i}>
            <img
              src={`${item.url}?w=${imageWidth}&h=${rowHeight}&fit=crop&auto=format`}
              alt={i}
              loading="lazy"
            />
            <StyledTopImageListItemBar
              //   title={item.title}
              actionIcon={
                <IconButton
                  aria-label={`cancel ${i}`}
                  onClick={onRemove(i)}
                  size="large"
                >
                  <CancelIcon sx={{ color: "primary.light" }} />
                </IconButton>
              }
              position="top"
            />
            {Array.isArray(image_objects) && image_objects.length > 1 && i > 0 && (
              <StyledImageListItemBar
                //   title={item.title}
                actionIcon={
                  <IconButton
                    aria-label={`swap ${i}`}
                    onClick={onLeft(i)}
                    size="large"
                  >
                    <ArrowCircleLeftIcon sx={{ color: "primary.light" }} />
                  </IconButton>
                }
                actionPosition="left"
              />
            )}
          </StyledImageListItem>
        ))}
      </ImageList>
    </React.Fragment>
  );
}

SinglePreviewImageList.propTypes = {
  image_objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      thumbnailUrl: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    })
  ),
  onRemove: PropTypes.func.isRequired,
  onLeft: PropTypes.func.isRequired,
};

export default SinglePreviewImageList;
