import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fade, IconButton } from "@mui/material";
import ImgMediaCard from "./ImgMediaCard";
import { Box } from "@mui/system";
import { landing_page_background_colour } from "./styles/materialUIStyles";
import "../App.css";

// function shuffleArray(array) {
//   let newArray = [...array];
//   for (let i = newArray.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
//   }
//   return newArray;
// }

function items(data, numberOfItems) {
  // console.log(data)
  return data.slice(0, numberOfItems).map(function (item) {
    return (
      <div
        className="item"
        data-value="1"
        style={{ transition: "opacity 2s ease-out" }}
      >
        <ImgMediaCard item={item} />
      </div>
    );
  });
}
// function items(data) {
//   return data.map(function (item) {
//     return (
//       <div
//         className="item"
//         data-value="1"
//         style={{ transition: "opacity 2s ease-out" }}
//       >
//         <ImgMediaCard item={item} />
//       </div>
//     );
//   });
// }

const PSlider = ({ product }) => {
  // const [shuffledProducts, setShuffledProducts] = useState([]);
  // useEffect(() => {
  //   setShuffledProducts(shuffleArray(product));
  // }, [product]);

  const [onHover, setOnHover] = useState(false);
  function SampleNextArrow(props) {
    const { className, style, onClick, slideCount, currentSlide } = props;
    let disabled = className.split(" ")[2];

    return (
      <Fade in={onHover}>
        <IconButton
          className="slick-arrow-right"
          onClick={onClick}
          sx={{
            color: "primary.main",
            ml: -0.8,
            display:
              onHover && !disabled ? "block !important" : "none !important",
          }}
        >
          <ChevronRightIcon sx={{ fontSize: "48px" }} />
        </IconButton>
      </Fade>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;

    return (
      <Fade in={onHover}>
        <IconButton
          className="slick-arrow-left"
          onClick={onClick}
          sx={{
            color: "primary.main",
            ml: -0.8,
            display:
              onHover && currentSlide > 0
                ? "block !important"
                : "none !important",

            borderRadius: "10px !important",
          }}
        >
          <ChevronLeftIcon sx={{ fontSize: "48px" }} />
        </IconButton>
      </Fade>
    );
  }

  const slickSettings = {
    dots: false,
    infinite: false,
    adaptiveHeight: true,
    // lazyLoad: "progressive",
    speed: 500,
    autoplay: false,
    slidesToShow: 6,
    slidesToScroll: 5,
    centerMode: false,
    // centerPadding: "48px",
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1875,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1575,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1275,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 975,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          swipeToSlide: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          swipeToSlide: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 435,
        settings: {
          swipeToSlide: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Box
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        sx={{
          backgroundColor: "transparent",
          position: "relative",
        }}
      >
        <Slider className="slider-products" {...slickSettings}>
          {items(product, 20)}
        </Slider>
      </Box>
    </>
  );
};

export default PSlider;
