import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ButtonBase, { buttonBaseClasses } from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ButtonBaseImage = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  // padding: theme.spacing(1, 2, 0, 1),
  width: "100%",
  justifyContent: "flex-start",
  [`&:hover, &.${buttonBaseClasses["focusVisible"]}`]: {
    zIndex: 1,
    [`& ${buttonBaseClasses["root"]}-image-button`]: {
      opacity: 0.5,
    },
  },
}));

const SpanImageButton = styled("span")(({ theme }) => ({
  color: theme.palette.common.black,
  opacity: 1,

  "& span": {
    verticalAlign: "text-bottom",
  },
}));

AppBarMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default function AppBarMenuButton({
  name,
  onClick,
  isDisabled = false,
  id,
}) {
  return (
    <Box>
      {name.toLowerCase().endsWith("no brand found") ? null : (
        <Typography
          component="span"
          variant="subtitle1"
          color="inherit"
          sx={{
            fontWeight: name.toLowerCase().endsWith("no brand found")
              ? 400
              : 600,
            fontSize: { xs: 24, md: 28 },
            color: "#181818",
          }}
        >
          {name}
        </Typography>
      )}
    </Box>
  );
}
