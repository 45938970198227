import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Dialog, Grid } from "@mui/material";

import ProductDetailSection from "./ProductDetailSection";

const SelectProductModal = ({ url, open, onClose, selectedProduct }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedGallery, setSelectedGallery] = useState(null);
  const [characteristicsArray, setCharArray] = useState([]);

  const searchTerm = "-";
  let params = [];

  if (url) {
    params = url
      .toLowerCase()
      .split("/")
      .filter((x) => Boolean(x) === true)
      .filter((x) => x !== "product")
      .filter((x) => x.includes("=") === false && x.includes("?") === false)
      .map((x) => x.split(searchTerm))
      .flat();
  }
  const expectedID = Number(params[params.length - 1]);
  // const args = ""
  //   .slice(1)
  //   .toLowerCase()
  //   .split("&")
  //   .filter((x) => x.includes("var="))
  //   .map((x) => x.slice(x.indexOf("=") + 1));
  // const expectedVariationID = args.length > 0 ? Number(args[0]) : undefined;
  const expectedVariationID = selectedProduct
    ? selectedProduct.product_variation_id
    : undefined;

  const handleChangeGalleryImage = (gallery) => {
    setSelectedGallery(gallery);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: { md: 940, sm: "100%" },
          borderRadius: "12px",
          backgroundColor: "rgb(255, 255, 255)",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { md: 940, sm: "100%" },
          padding: { md: "24px" },
        }}
      >
        <Grid item xs={12}>
          <ProductDetailSection
            selectedProduct={selectedProduct}
            variationID={expectedVariationID}
            id={expectedID}
            selectedGallery={selectedGallery}
            setCharArray={setCharArray}
            setSelectedGallery={setSelectedGallery}
            handleChangeGalleryImage={handleChangeGalleryImage}
            isModal={true}
            onClose={onClose}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectProductModal;
