import React, { useState, useEffect, useRef } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MuiFormControlLabel, {
  formControlLabelClasses,
} from "@mui/material/FormControlLabel";
import MuiCheckbox, { checkboxClasses } from "@mui/material/Checkbox";
import CircleIcon from "@mui/icons-material/Circle";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import Box from "@mui/material/Box";

const FilterPreferenceGroup = ({
  preference,
  i,
  displayLimit,
  choice,
  handleChange,
}) => {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(true);
  };
  const theme = useTheme();

  const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
    [`&.${checkboxClasses["root"]}.${checkboxClasses["checked"]}`]: {
      color: "theme.palette.text.primary",
    },
  }));

  const Tooltip = styled(MuiTooltip)({
    [`& .${tooltipClasses["tooltip"]}`]: {
      textTransform: "capitalize",
    },
  });

  const FormControlLabel = styled(MuiFormControlLabel)({
    justifyContent: "space-between",
    marginLeft: 4,
    marginRight: -4,
    [`& .${formControlLabelClasses["label"]}`]: {
      display: "inline-grid",
      justifyContent: "flex-start",
      width: "75%",
    },
  });

  return (
    <FormGroup>
      {Object.entries(preference[i])
        .slice(
          0,
          showMore ? Object.entries(preference[i]).length : displayLimit
        )
        .map(([item, isSelected], j) => (
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={isSelected}
                onChange={handleChange(i)}
                name={item}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
            }
            label={
              <Tooltip title={item} placement="right">
                <Typography variant="caption" component="p" noWrap>
                  {Boolean(choice[i][j].rgb) === true && (
                    <CircleIcon
                      sx={{
                        color: choice[i][j].rgb,
                        fontSize: 20,
                      }}
                      style={{
                        marginRight: 8,
                        verticalAlign: "text-bottom",
                      }}
                    />
                  )}
                  {item}
                </Typography>
              </Tooltip>
            }
            key={j}
          />
        ))}
      {!showMore && choice[i].length > displayLimit ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleShowMore}
            variant="text"
            size="small"
            disableRipple
            disableFocusRipple
            sx={{
              color: theme.palette.text.secondary,
              mt: "12px",
              fontSize: "12px",
              borderRadius: 999,
              padding: "8px 16px",
            }}
          >
            Show More
          </Button>
        </Box>
      ) : null}
    </FormGroup>
  );
};

export default FilterPreferenceGroup;
