import renderCellExpand from "./renderCellExpand";

const orderStatusTypeColumns = [
  { field: "id", headerName: "ID", width: 100, renderCell: renderCellExpand, },
  { field: "name", headerName: "Name", width: 180, renderCell: renderCellExpand, },
  { field: "color", headerName: "Color", width: 180, renderCell: renderCellExpand, },
  {
    field: "created_at",
    headerName: "Created On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
  },
  {
    field: "updated_at",
    headerName: "Updated On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
  },
];

export default orderStatusTypeColumns;
