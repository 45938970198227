import React from "react";

import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

import { Dialog, StyledDialogTitle, StyledTextField } from "./CustomComponents";
import { BlackButton } from "./CustomButtons";

import { orderStatusUpdate } from "../features/order/orderAsyncThunk";
import { useParams } from "react-router-dom";

const SampleOrderModal = ({ open, onClose, orderStatusId, id }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [values, setValues] = React.useState({
    courier: "",
    trackingNumber: "",
    expectedDelivery: new Date(),
  });
  const params = useParams();
  const dispatch = useDispatch();
  const [isUserActionMade, setIsUserActionMade] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeDate = (newValue) => {
    setValues({ ...values, expectedDelivery: newValue });
  };

  const handleUpdate = async () => {
    setIsUserActionMade(true);
    if (Boolean(values.courier) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.expectedDelivery) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.trackingNumber) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(orderStatusId) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(id) === false) {
      setShowHint(true);
      return;
    }
    await dispatch(
      orderStatusUpdate({
        id,
        courier: values?.courier,
        expectedDelivery: values?.expectedDelivery,
        trackingNumber: values?.trackingNumber,
        orderStatusId,
      })
    );
    onClose();
    window.location.href = `/project/${params.id}`;
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open}>
        <StyledDialogTitle id="sample-order-title" onClose={onClose}>
          Add your sample order details
        </StyledDialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", p: 3 }}>
          <StyledTextField
            variant="outlined"
            autoFocus
            required
            sx={{ width: { md: 448, sm: "100%" }, mb: 1.5, mt: 1 }}
            error={isUserActionMade && Boolean(values.courier) === false}
            defaultValue={values.courier}
            onChange={handleValues("courier")}
            margin="dense"
            size="medium"
            id="courier"
            placeholder="Courier"
            fullWidth
          />
          <StyledTextField
            variant="outlined"
            autoFocus
            required
            sx={{ width: { md: 448, sm: "100%" }, mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.trackingNumber) === false}
            defaultValue={values.trackingNumber}
            onChange={handleValues("trackingNumber")}
            margin="dense"
            size="medium"
            id="tracking-number"
            placeholder="Tracking Number"
            fullWidth
          />
          <MobileDatePicker
            label="Expected Delivery"
            inputFormat="MM/DD/YYYY"
            value={values.expectedDelivery}
            minDate={new Date()}
            onChange={handleChangeDate}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                fullWidth
                sx={{ mb: 1.5, mt: 0 }}
                error={
                  isUserActionMade && Boolean(values.expectedDelivery) === false
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <BlackButton
            sx={{
              width: "100%",
              height: 55,
              margin: (theme) => theme.spacing(0, 3, 3, 3),
            }}
            variant="contained"
            color="primary"
            onClick={handleUpdate}
          >
            Update Order
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </div>
  );
};

export default SampleOrderModal;
