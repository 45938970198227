import React from "react";

const MatterBaseLogo4 = (props) => {
  const { fill = "#fff", ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      fill="none"
      viewBox="0 0 27 26"
    >
      <path
        fill="#fff"
        d="M12 7H0v12h12V7zM27 20a6 6 0 10-12 0 6 6 0 0012 0zM18.5 11L27 0H10l8.5 11z"
      ></path>
    </svg>
  );
};

export default MatterBaseLogo4;
