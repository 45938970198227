import React, { useEffect, useMemo, useState } from "react";

import { Grid, Typography, Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { BlackButton } from "../CustomButtons";
import SpaceProductCard from "../SpaceProductCard";
import RequestModal from "../RequestModal";

import { selectSpaceProductList } from "../../features/spaceproducts/spaceProductsAsyncThunk";
import {
  orderCartDecode,
  orderCartEncode,
  selectOrderCartToken,
} from "../../features/order/orderAsyncThunk";
import {
  selectSpaceList,
  selectSpaceMove,
} from "../../features/space/spaceAsyncThunk";
import { selectAuthUserInfo } from "../../features/authentication/authAsyncThunk";

const SpacesTab = ({
  spaceId,
  setSpaceDeleteModal,
  spaceDeleteModal,
  projectRole,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [cartItemInfo, setCartItemInfo] = useState([]);
  const [isCartItem, setIsCartItem] = useState(false);
  const { payload: favorites } = useSelector(selectSpaceProductList);
  const { payload: spaceListData } = useSelector(selectSpaceList);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const params = useParams();
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;

  const orderCartTokenStore = useSelector(selectOrderCartToken);
  const { token: cartToken } = orderCartTokenStore;
  const { favoriteProductIds } = useSelector(selectSpaceMove);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Boolean(cartToken) === true) {
      sessionStorage.setItem("cart", cartToken);
      dispatch(orderCartDecode({ cartToken }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartToken]);

  const favoritesList = useMemo(() => {
    if (spaceListData.length) {
      return favorites
        .filter((val) => +val.spaceId === +spaceListData[0]?.id)
        .map((item) => ({
          ...item,
          brandUrl: `/brand/${item.brandName.replaceAll(" ", "-")}-${
            item.brandId
          }`,
        }));
    }
  }, [spaceListData, favorites]);

  const handleOpenRequestModal = () => {
    setOpenRequestModal(!openRequestModal);
    handleSubmitRequest();
  };

  const handleCloseRequestModal = () => {
    setOpenRequestModal(false);
  };

  const handleSubmitRequest = () => {
    if (Boolean(userInfo.id) === false) {
      return history.push(`/login?from=${location.pathname}`);
    }
    dispatch(
      orderCartEncode({
        cart: cartItemInfo,
        projectId: params?.id,
        spaceId,
      })
    );
  };

  return (
    <div>
      <Box sx={{ px: { xs: 2, md: 6 }, py: 4 }}>
        <Grid container spacing={2}>
          {favoritesList &&
            favoritesList.length > 0 &&
            favoritesList.map((spaceproduct, index) => (
              <Grid
                key={`space-product-${index}`}
                item
                xs={12}
                sm={6}
                md={5 / 2}
                lg={2}
              >
                <SpaceProductCard
                  product={spaceproduct}
                  setCartItemInfo={setCartItemInfo}
                  setIsCartItem={setIsCartItem}
                  projectRole={projectRole}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      {favoriteProductIds &&
        favoriteProductIds.length > 0 &&
        projectRole === "Owner" && (
          <Box
            sx={{
              px: { xs: 2, md: 6 },
              py: 2.563,
              width: "100%",
              display: "flex",
              backgroundColor: "white",
            }}
            id="spaces-action-bar"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => setSpaceDeleteModal(!spaceDeleteModal)}
              >
                <Typography sx={{ fontSize: 18, color: "black" }}>
                  Remove Selection
                </Typography>
              </Box>
              <BlackButton
                sx={{
                  width: { lg: 350, lg: 350, md: 300 },
                  height: 55,
                  margin: (theme) => theme.spacing(0, 2, 0.5, 2),
                  fontWeight: 600,
                  fontSize: 15,
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenRequestModal();
                }}
              >
                <span>Review Matterbox Sample</span>
                <span style={{ marginLeft: "1.5rem" }}>
                  {cartItemInfo.length > 9
                    ? cartItemInfo.length
                    : `0${cartItemInfo.length}`}
                </span>
              </BlackButton>
            </Box>
          </Box>
        )}
      <RequestModal
        open={openRequestModal}
        onClose={handleCloseRequestModal}
        spaceId={spaceId}
        favoriteProducts={favoriteProductIds}
        spaces={spaceListData}
      />
    </div>
  );
};

export default SpacesTab;
