import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardMedia, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SupplierContactBrandName from "../features/supplier/SupplierContactBrandName";
import { WhiteButton } from "./CustomButtons";
import {
  productDeleteByOrderId,
  selectProjectList,
} from "../features/project/projectAsyncThunk";

const RequestCard = ({ products, categoryName }) => {
  const cardRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [supplierInfo, setSupplierInfo] = useState(null);
  const [project, setProject] = useState(null);
  const { payload: projects } = useSelector(selectProjectList);

  useEffect(() => {
    if (params?.id) {
      const selected_project = projects.find(
        (project) => project.id === params.id
      );
      setProject(selected_project);
    }
  }, [params]);

  return (
    <>
      {products?.filter((product) => product.order_status !== "Completed")
        .length > 0 ? (
        <Box
          sx={{
            marginTop: "2rem",
            marginBottom: "2rem",
            padding: "1rem",
            backgroundColor: "white",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              sx={{ fontSize: "24px", color: "#181818", fontWeight: "600" }}
            >
              {categoryName}
            </Typography>
          </Box>
          {products?.length > 0 &&
            products
              ?.filter((product) => product.order_status !== "Completed")
              .filter(
                (v, i, a) =>
                  a.findIndex((v2) => v2.product_id === v.product_id) === i
              )
              ?.map(
                (
                  {
                    product_name,
                    product_variation_name,
                    imageURL,
                    supplier_object,
                    brand_name,
                    id,
                  },
                  index
                ) => {
                  return (
                    <Box
                      key={`request-products-${index}`}
                      sx={{
                        marginTop: "1rem",
                        marginBottom: "1.5rem",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                          }}
                        >
                          {imageURL ? (
                            <Box
                              sx={{
                                width: { xs: 140, sm: 112 },
                                height: 112,
                                borderRadius: "8px",
                              }}
                            >
                              <Card
                                ref={cardRef}
                                sx={{
                                  width: "100%",
                                  borderRadius: "8px",
                                }}
                              >
                                <CardMedia
                                  height={"100%"}
                                  component="img"
                                  alt={"Product Image"}
                                  image={imageURL}
                                  title={"Product Image"}
                                  sx={{ height: 112, borderRadius: "8px" }}
                                />
                              </Card>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: { xs: 140, sm: 112 },
                                height: 112,
                                backgroundColor: "#C4C4C4",
                                borderRadius: "8px",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              ml: { xs: 0, sm: 1.5 },
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              my: { xs: 1.5, sm: 0 },
                            }}
                          >
                            <Box sx={{ width: { sm: "200px" } }}>
                              <Typography
                                sx={{ fontSize: 15 }}
                                fontWeight={600}
                                noWrap
                              >
                                {product_name}
                              </Typography>
                              <Typography fontSize={12} fontWeight={300} noWrap>
                                Product Name
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{ fontSize: 15 }}
                                fontWeight={600}
                                noWrap
                              >
                                {brand_name}
                              </Typography>
                              <Typography fontSize={12} fontWeight={300} noWrap>
                                Brand Name
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              borderLeft: { xs: 0, sm: 1 },
                              borderLeftColor: "#181818",
                              pl: { xs: 0, sm: 1.5 },
                              mb: { xs: 1.5, sm: 0 },
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{ fontSize: 15 }}
                                fontWeight={600}
                              >
                                {product_variation_name}
                              </Typography>
                              <Typography fontSize={12} fontWeight={300}>
                                Variation Name
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              display: "flex",
                              cursor: "pointer",
                            }}
                            onClick={() => dispatch(productDeleteByOrderId(id))}
                          >
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                            <Typography>Delete</Typography>
                          </Box>
                          <WhiteButton
                            sx={{
                              px: 2,
                              height: "40px",
                              alignSelf: "flex-end",
                              borderRadius: "40px",
                            }}
                            onClick={() => {
                              setSupplierInfo(supplier_object);
                              setOpen(!open);
                            }}
                          >
                            Contact Supplier
                          </WhiteButton>
                        </Box>
                      </Box>
                    </Box>
                  );
                }
              )}
        </Box>
      ) : (
        <></>
      )}
      {open && (
        <SupplierContactBrandName
          productInfo={{}}
          open={open}
          onClose={() => setOpen(false)}
          supplierInfo={supplierInfo}
          project={project}
        />
      )}
    </>
  );
};

export default RequestCard;
