import { createSlice } from '@reduxjs/toolkit';
import { productEdit } from './productAsyncThunk';

const productEditSlice = createSlice({
  name: "product",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [productEdit.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [productEdit.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [productEdit.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default productEditSlice.reducer;