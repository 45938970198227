import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: { position: "relative" },
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": { height: "100%", width: "100%" },
  },
}));
const AspectRatioBox = ({ children, ratio = 1, style }) => {
  const classes = useStyles();
  const maxHeight = style?.maxHeight || "100%";
  return (
    <div className={classes.root} style={style}>
      <div className={classes.wrapper}>{children}</div>
      <div
        style={{
          paddingBottom: `min(${(1 / ratio) * 100}%, ${maxHeight})`,
        }}
      />
    </div>
  );
};

export default AspectRatioBox;
