import { createSlice } from '@reduxjs/toolkit';
import { productSearch, productVariationSearch, } from './productAsyncThunk';

const productSearchSlice = createSlice({
  name: "product",
  initialState: {
    count: 0,
    list: [],
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [productSearch.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [productSearch.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.failCode = 0;
      state.count = action.payload.count;
      state.list = action.payload.list;
    },
    [productSearch.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.failCode = action.payload.status;
    },
    [productVariationSearch.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [productVariationSearch.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.failCode = 0;
      state.count = action.payload.count;
      state.list = action.payload.list;
    },
    [productVariationSearch.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.failCode = action.payload.status;
    },
  },
});

export default productSearchSlice.reducer;