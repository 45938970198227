import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectRegSignup,
  selectRegSubscribe,
} from "../features/registration/regAsyncThunk";
import {
  selectAuthLogin,
  selectAuthUserInfo,
} from "../features/authentication/authAsyncThunk";
import { selectManuJoin } from "../features/manufacturer/manuAsyncThunk";
import {
  selectUserUpdate,
  selectUserEditAdmin,
  selectUserCreateAdmin,
  selectUserManageAdmin,
  selectUserForgetPassword,
  selectUserFinishRecovery,
  selectUserFederatedInfo,
} from "../features/userChange/userAsyncThunk";
import {
  selectCatalogCreateCompanyType,
  selectCatalogManageCompanyType,
  selectCatalogCreateProjectType,
  selectCatalogManageProjectType,
  selectCatalogDeleteProjectType,
  selectCatalogCreateOrderStatusType,
  selectCatalogManageOrderStatusType,
  selectCatalogDeleteOrderStatusType,
} from "../features/catalog/catalogAsyncThunk";
import {
  selectImageCreate,
  selectImageSearch,
} from "../features/image/imageAsyncThunk";
import {
  selectSupplierCreate,
  selectSupplierEdit,
  selectSupplierContact,
  selectSupplierContactByBrand,
} from "../features/supplier/supplierAsyncThunk";
import {
  selectBrandCreate,
  selectBrandEdit,
  selectBrandUpdateBoolean,
} from "../features/brand/brandAsyncThunk";
import {
  selectCategoryCreate,
  selectCategoryEdit,
  selectCategoryDelete,
} from "../features/category/categoryAsyncThunk";
import {
  selectProductPropCreate,
  selectProductPropDelete,
  selectProductPropPrecedenceEdit,
} from "../features/productProp/productPropAsyncThunk";
import {
  selectProductCreate,
  selectProductEdit,
  selectProductVariationCreate,
  selectProductVariationEdit,
  selectProductVariationDelete,
} from "../features/product/productAsyncThunk";
import {
  selectFavoriteCreate,
  selectFavoriteDelete,
} from "../features/favorite/favoriteAsyncThunk";
import {
  selectOrderCancel,
  selectOrderCartToken,
  selectOrderCreate,
  selectOrderEdit,
  selectOrderProductRemove,
  selectOrderStatusUpdate,
} from "../features/order/orderAsyncThunk";
import {
  selectProductGroupCreate,
  selectProductGroupManage,
  selectProductGroupDelete,
} from "../features/productGroup/productGroupAsyncThunk";
import {
  selectProductGalleryTitleCreate,
  selectProductGalleryTitleManage,
  selectProductGalleryTitleDelete,
} from "../features/productGalleryTitle/productGalleryTitleAsyncThunk";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import {
  selectProductDeleteByOrderId,
  selectProjectCoWorkerCreate,
  selectProjectCreate,
  selectProjectDelete,
  selectProjectEdit,
} from "../features/project/projectAsyncThunk";
import {
  selectSpaceCreate,
  selectSpaceDelete,
  selectSpaceEdit,
  selectSpaceMove,
} from "../features/space/spaceAsyncThunk";
import {
  selectSpaceProductCreate,
  selectSpaceProductDelete,
} from "../features/spaceproducts/spaceProductsAsyncThunk";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  color: theme.palette.common.white,
}));

function FeedbackSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const regSignup = useSelector(selectRegSignup);
  const regSubscribe = useSelector(selectRegSubscribe);
  const userUpdate = useSelector(selectUserUpdate);
  const userEditAdmin = useSelector(selectUserEditAdmin);
  const userCreateAdmin = useSelector(selectUserCreateAdmin);
  const userManageAdmin = useSelector(selectUserManageAdmin);
  const userForgetPassword = useSelector(selectUserForgetPassword);
  const userFinishRecovery = useSelector(selectUserFinishRecovery);
  const userFederatedInfo = useSelector(selectUserFederatedInfo);
  const authLogin = useSelector(selectAuthLogin);
  const authUserInfo = useSelector(selectAuthUserInfo);
  const catalogCreateCompanyType = useSelector(selectCatalogCreateCompanyType);
  const catalogManageCompanyType = useSelector(selectCatalogManageCompanyType);
  const catalogCreateProjectType = useSelector(selectCatalogCreateProjectType);
  const catalogManageProjectType = useSelector(selectCatalogManageProjectType);
  const catalogDeleteProjectType = useSelector(selectCatalogDeleteProjectType);
  const catalogCreateOrderStatusType = useSelector(
    selectCatalogCreateOrderStatusType
  );
  const catalogManageOrderStatusType = useSelector(
    selectCatalogManageOrderStatusType
  );
  const catalogDeleteOrderStatusType = useSelector(
    selectCatalogDeleteOrderStatusType
  );
  const imageCreate = useSelector(selectImageCreate);
  const imageSearch = useSelector(selectImageSearch);
  const supplierCreate = useSelector(selectSupplierCreate);
  const supplierEdit = useSelector(selectSupplierEdit);
  const supplierContact = useSelector(selectSupplierContact);
  const supplierContactByBrand = useSelector(selectSupplierContactByBrand);
  const brandCreate = useSelector(selectBrandCreate);
  const brandEdit = useSelector(selectBrandEdit);
  const brandUpdateBoolean = useSelector(selectBrandUpdateBoolean);
  const categoryCreate = useSelector(selectCategoryCreate);
  const categoryEdit = useSelector(selectCategoryEdit);
  const categoryDelete = useSelector(selectCategoryDelete);
  const productPropCreate = useSelector(selectProductPropCreate);
  const productPropDelete = useSelector(selectProductPropDelete);
  const productPropPrecedenceEdit = useSelector(
    selectProductPropPrecedenceEdit
  );
  const productCreate = useSelector(selectProductCreate);
  const productEdit = useSelector(selectProductEdit);
  const productVariationCreate = useSelector(selectProductVariationCreate);
  const productVariationEdit = useSelector(selectProductVariationEdit);
  const productVariationDelete = useSelector(selectProductVariationDelete);
  const favoriteCreate = useSelector(selectFavoriteCreate);
  const favoriteDelete = useSelector(selectFavoriteDelete);
  const orderCartToken = useSelector(selectOrderCartToken);
  const orderCreate = useSelector(selectOrderCreate);
  const orderEdit = useSelector(selectOrderEdit);
  const productGroupCreate = useSelector(selectProductGroupCreate);
  const productGroupManage = useSelector(selectProductGroupManage);
  const productGroupDelete = useSelector(selectProductGroupDelete);
  const productGalleryTitleCreate = useSelector(
    selectProductGalleryTitleCreate
  );
  const productGalleryTitleManage = useSelector(
    selectProductGalleryTitleManage
  );
  const productGalleryTitleDelete = useSelector(
    selectProductGalleryTitleDelete
  );
  const manuJoin = useSelector(selectManuJoin);
  const projectCreate = useSelector(selectProjectCreate);
  const projectEdit = useSelector(selectProjectEdit);
  const projectDelete = useSelector(selectProjectDelete);
  const spaceCreate = useSelector(selectSpaceCreate);
  const spaceEdit = useSelector(selectSpaceEdit);
  const spaceDelete = useSelector(selectSpaceDelete);
  const spaceMove = useSelector(selectSpaceMove);
  const spaceProductCreate = useSelector(selectSpaceProductCreate);
  const spaceProductsDelete = useSelector(selectSpaceProductDelete);
  const projectCoWorkerCreate = useSelector(selectProjectCoWorkerCreate);
  const orderCancel = useSelector(selectOrderCancel);
  const orderProductRemove = useSelector(selectOrderProductRemove);
  const orderStatusUpdate = useSelector(selectOrderStatusUpdate);
  const productDeleteByOrderId = useSelector(selectProductDeleteByOrderId);
  const {
    isLoading: regSignupLoading,
    isSuccess: regSignupSuccess,
    isFail: regSignupFail,
  } = regSignup;
  const {
    isLoading: regSubscribeLoading,
    isSuccess: regSubscribeSuccess,
    isFail: regSubscribeFail,
  } = regSubscribe;
  const {
    isLoading: userUpdateLoading,
    successCode: userUpdateSuccess,
    failCode: userUpdateFail,
  } = userUpdate;
  const {
    isLoading: userEditAdminLoading,
    isSuccess: userEditAdminSuccess,
    failCode: userEditAdminFail,
  } = userEditAdmin;
  const {
    isLoading: userCreateAdminLoading,
    isSuccess: userCreateAdminSuccess,
    failCode: userCreateAdminFail,
  } = userCreateAdmin;
  const {
    isLoading: userManageAdminLoading,
    isSuccess: userManageAdminSuccess,
    isFail: userManageAdminFail,
  } = userManageAdmin;
  const {
    isLoading: userForgetPasswordLoading,
    isSuccess: userForgetPasswordSuccess,
    failCode: userForgetPasswordFail,
  } = userForgetPassword;
  const {
    isLoading: userFinishRecoveryLoading,
    isSuccess: userFinishRecoverySuccess,
    isFail: userFinishRecoveryFail,
  } = userFinishRecovery;
  const {
    isLoading: userFederatedInfoLoading,
    isSuccess: userFederatedInfoSuccess,
    isFail: userFederatedInfoFail,
  } = userFederatedInfo;
  const {
    isLoading: catalogCreateCompanyTypeLoading,
    isSuccess: catalogCreateCompanyTypeSuccess,
    isFail: catalogCreateCompanyTypeFail,
  } = catalogCreateCompanyType;
  const {
    isLoading: catalogManageCompanyTypeLoading,
    isSuccess: catalogManageCompanyTypeSuccess,
    isFail: catalogManageCompanyTypeFail,
  } = catalogManageCompanyType;
  const {
    isLoading: catalogCreateProjectTypeLoading,
    isSuccess: catalogCreateProjectTypeSuccess,
    isFail: catalogCreateProjectTypeFail,
  } = catalogCreateProjectType;
  const {
    isLoading: catalogManageProjectTypeLoading,
    isSuccess: catalogManageProjectTypeSuccess,
    isFail: catalogManageProjectTypeFail,
  } = catalogManageProjectType;
  const {
    isLoading: catalogDeleteProjectTypeLoading,
    isSuccess: catalogDeleteProjectTypeSuccess,
    failCode: catalogDeleteProjectTypeFail,
  } = catalogDeleteProjectType;
  const {
    isLoading: catalogCreateOrderStatusTypeLoading,
    isSuccess: catalogCreateOrderStatusTypeSuccess,
    isFail: catalogCreateOrderStatusTypeFail,
  } = catalogCreateOrderStatusType;
  const {
    isLoading: catalogManageOrderStatusTypeLoading,
    isSuccess: catalogManageOrderStatusTypeSuccess,
    isFail: catalogManageOrderStatusTypeFail,
  } = catalogManageOrderStatusType;
  const {
    isLoading: catalogDeleteOrderStatusTypeLoading,
    isSuccess: catalogDeleteOrderStatusTypeSuccess,
    failCode: catalogDeleteOrderStatusTypeFail,
  } = catalogDeleteOrderStatusType;
  const {
    isLoading: imageCreateLoading,
    isSuccess: imageCreateSuccess,
    isFail: imageCreateFail,
  } = imageCreate;
  const {
    isLoading: imageSearchLoading,
    isSuccess: imageSearchSuccess,
    isFail: imageSearchFail,
  } = imageSearch;
  const {
    isLoading: supplierCreateLoading,
    isSuccess: supplierCreateSuccess,
    failCode: supplierCreateFail,
  } = supplierCreate;
  const {
    isLoading: supplierEditLoading,
    isSuccess: supplierEditSuccess,
    failCode: supplierEditFail,
  } = supplierEdit;
  const {
    isLoading: supplierContactLoading,
    isSuccess: supplierContactSuccess,
    isFail: supplierContactFail,
    payload: supplierContactPayload,
  } = supplierContact;
  const {
    isLoading: supplierContactByBrandLoading,
    isSuccess: supplierContactByBrandSuccess,
    isFail: supplierContactByBrandFail,
    payload: supplierContactByBrandPayload,
  } = supplierContactByBrand;
  const {
    isLoading: brandCreateLoading,
    isSuccess: brandCreateSuccess,
    failCode: brandCreateFail,
    failMessage: brandCreateFailMessage,
  } = brandCreate;
  const {
    isLoading: brandEditLoading,
    isSuccess: brandEditSuccess,
    failCode: brandEditFail,
    failMessage: brandEditFailMessage,
  } = brandEdit;
  const {
    isLoading: brandUpdateBooleanLoading,
    isSuccess: brandUpdateBooleanSuccess,
    failCode: brandUpdateBooleanFail,
    failMessage: brandUpdateBooleanFailMessage,
  } = brandUpdateBoolean;
  const {
    isLoading: categoryCreateLoading,
    isSuccess: categoryCreateSuccess,
    isFail: categoryCreateFail,
  } = categoryCreate;
  const {
    isLoading: categoryEditLoading,
    isSuccess: categoryEditSuccess,
    failCode: categoryEditFail,
  } = categoryEdit;
  const {
    isLoading: categoryDeleteLoading,
    isSuccess: categoryDeleteSuccess,
    failCode: categoryDeleteFail,
  } = categoryDelete;
  const {
    isLoading: productPropCreateLoading,
    isSuccess: productPropCreateSuccess,
    isFail: productPropCreateFail,
  } = productPropCreate;
  const {
    isLoading: productPropDeleteLoading,
    isSuccess: productPropDeleteSuccess,
    isFail: productPropDeleteFail,
  } = productPropDelete;
  const {
    isLoading: productPropPrecedenceEditLoading,
    isSuccess: productPropPrecedenceEditSuccess,
    isFail: productPropPrecedenceEditFail,
  } = productPropPrecedenceEdit;
  const {
    isLoading: productCreateLoading,
    isSuccess: productCreateSuccess,
    isFail: productCreateFail,
  } = productCreate;
  const {
    isLoading: productEditLoading,
    isSuccess: productEditSuccess,
    isFail: productEditFail,
  } = productEdit;
  const {
    isLoading: productVariationCreateLoading,
    isSuccess: productVariationCreateSuccess,
    isFail: productVariationCreateFail,
  } = productVariationCreate;
  const {
    isLoading: productVariationEditLoading,
    isSuccess: productVariationEditSuccess,
    isFail: productVariationEditFail,
  } = productVariationEdit;
  const {
    isLoading: productVariationDeleteLoading,
    isSuccess: productVariationDeleteSuccess,
    isFail: productVariationDeleteFail,
  } = productVariationDelete;
  const { isLoading: favoriteCreateLoading, isFail: favoriteCreateFail } =
    favoriteCreate;
  const {
    isLoading: favoriteDeleteLoading,
    isSuccess: favoriteDeleteSuccess,
    isFail: favoriteDeleteFail,
  } = favoriteDelete;
  const { isLoading: orderCartTokenLoading, isFail: orderCartTokenFail } =
    orderCartToken;
  const { isLoading: orderCreateLoading, isFail: orderCreateFail } =
    orderCreate;
  const {
    isLoading: orderEditLoading,
    isSuccess: orderEditSuccess,
    isFail: orderEditFail,
  } = orderEdit;
  const {
    isLoading: productGroupCreateLoading,
    isSuccess: productGroupCreateSuccess,
    isFail: productGroupCreateFail,
  } = productGroupCreate;
  const {
    isLoading: productGroupManageLoading,
    isSuccess: productGroupManageSuccess,
    isFail: productGroupManageFail,
  } = productGroupManage;
  const {
    isLoading: productGroupDeleteLoading,
    isSuccess: productGroupDeleteSuccess,
    failCode: productGroupDeleteFail,
  } = productGroupDelete;
  const {
    isLoading: productGalleryTitleCreateLoading,
    isSuccess: productGalleryTitleCreateSuccess,
    isFail: productGalleryTitleCreateFail,
  } = productGalleryTitleCreate;
  const {
    isLoading: productGalleryTitleManageLoading,
    isSuccess: productGalleryTitleManageSuccess,
    isFail: productGalleryTitleManageFail,
  } = productGalleryTitleManage;
  const {
    isLoading: productGalleryTitleDeleteLoading,
    isSuccess: productGalleryTitleDeleteSuccess,
    failCode: productGalleryTitleDeleteFail,
  } = productGalleryTitleDelete;
  const {
    isLoading: authLoginLoading,
    successCode: authLoginSuccess,
    failCode: authLoginFail,
  } = authLogin;
  const {
    isLoading: manuJoinLoading,
    isSuccess: manuJoinSuccess,
    isFail: manuJoinFail,
  } = manuJoin;
  const {
    isLoggedOut,
    isFederatedLoginFail,
    isUserUpdateLoading,
    isUserUpdateSuccess,
    isUserUpdateFail,
    isPasswordLinkLoading,
    isPasswordLinkSuccess,
    isPasswordLinkFail,
  } = authUserInfo;
  const {
    isLoading: projectCreateLoading,
    isSuccess: projectCreateSuccess,
    isFail: projectCreateFailed,
  } = projectCreate;
  const {
    isLoading: projectEditLoading,
    isSuccess: projectEditSuccess,
    isFail: projectEditFailed,
  } = projectEdit;
  const {
    isLoading: projectDeleteLoading,
    isSuccess: projectDeleteSuccess,
    isFail: projectDeleteFailed,
  } = projectDelete;
  const {
    isLoading: spaceCreateLoading,
    isSuccess: spaceCreateSuccess,
    isFail: spaceCreateFailed,
  } = spaceCreate;
  const {
    isLoading: spaceEditLoading,
    isSuccess: spaceEditSuccess,
    isFail: spaceEditFailed,
  } = spaceEdit;
  const {
    isLoading: spaceDeleteLoading,
    isSuccess: spaceDeleteSuccess,
    isFail: spaceDeleteFailed,
  } = spaceDelete;
  const {
    isLoading: spaceProductCreateLoading,
    isSuccess: spaceProductCreateSuccess,
    failedCode: spaceProductCreateFailed,
  } = spaceProductCreate;
  const {
    isLoading: spaceMoveLoading,
    isSuccess: spaceMoveSuccess,
    isFail: spaceMoveFailed,
  } = spaceMove;
  const {
    isLoading: spaceProductDeleteLoading,
    isSuccess: spaceProductDeleteSuccess,
    isFail: spaceProductDeleteFailed,
  } = spaceProductsDelete;
  const {
    isLoading: projectCoWorkerCreateLoading,
    isFail: projectCoWorkerCreateFail,
    isSuccess: projectCoWorkerCreateSuccess,
    isError: projectCoWorkerCreateError,
  } = projectCoWorkerCreate;
  const {
    isLoading: orderCancelLoading,
    isSuccess: orderCancelSuccess,
    isFail: orderCancelFailed,
  } = orderCancel;
  const {
    isLoading: orderProductRemoveLoading,
    isSuccess: orderProductRemoveSuccess,
    isFail: orderProductRemoveFailed,
  } = orderProductRemove;
  const {
    isLoading: orderStatusUpdateLoading,
    isSuccess: orderStatusUpdateSuccess,
    isFail: orderStatusUpdateFailed,
  } = orderStatusUpdate;
  const {
    isLoading: productDeleteByOrderIdLoading,
    isSuccess: productDeleteByOrderIdSuccess,
    isFail: productDeleteByOrderIdFailed,
  } = productDeleteByOrderId;

  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
      action: null,
      dense: true,
    });
  };
  const handlePersist = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action,
      autoHideDuration: 8000,
      dense: true,
    });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  // TEST SNACKBAR
  // useEffect(() => {
  //   const message = "this is a test message.";
  //   const variant = "error";
  //   enqueueSnackbar(message, {
  //     variant,
  //     autoHideDuration: 3000,
  //     // persist: true,
  //     action: null,
  //     dense: true,
  //     // hideIconVariant: true,
  //   });
  // }, []);

  useEffect(() => {
    setBackdropOpen(
      regSignupLoading ||
        regSubscribeLoading ||
        userUpdateLoading ||
        userCreateAdminLoading ||
        authLoginLoading ||
        manuJoinLoading ||
        userEditAdminLoading ||
        userManageAdminLoading ||
        userForgetPasswordLoading ||
        userFinishRecoveryLoading ||
        userFederatedInfoLoading ||
        catalogCreateCompanyTypeLoading ||
        catalogManageCompanyTypeLoading ||
        catalogCreateProjectTypeLoading ||
        catalogManageProjectTypeLoading ||
        catalogDeleteProjectTypeLoading ||
        catalogCreateOrderStatusTypeLoading ||
        catalogManageOrderStatusTypeLoading ||
        catalogDeleteOrderStatusTypeLoading ||
        orderCartTokenLoading ||
        orderCreateLoading ||
        orderEditLoading ||
        supplierCreateLoading ||
        supplierEditLoading ||
        supplierContactLoading ||
        supplierContactByBrandLoading ||
        brandCreateLoading ||
        brandEditLoading ||
        brandUpdateBooleanLoading ||
        categoryCreateLoading ||
        categoryEditLoading ||
        categoryDeleteLoading ||
        productCreateLoading ||
        productEditLoading ||
        productVariationCreateLoading ||
        productVariationEditLoading ||
        productVariationDeleteLoading ||
        productPropCreateLoading ||
        productPropDeleteLoading ||
        productPropPrecedenceEditLoading ||
        productGroupCreateLoading ||
        productGroupManageLoading ||
        productGroupDeleteLoading ||
        productGalleryTitleCreateLoading ||
        productGalleryTitleManageLoading ||
        productGalleryTitleDeleteLoading ||
        favoriteCreateLoading ||
        favoriteDeleteLoading ||
        imageCreateLoading ||
        imageSearchLoading ||
        projectCreateLoading ||
        spaceCreateLoading ||
        projectEditLoading ||
        projectDeleteLoading ||
        spaceEditLoading ||
        spaceDeleteLoading ||
        spaceProductCreateLoading ||
        spaceMoveLoading ||
        spaceProductDeleteLoading ||
        isUserUpdateLoading ||
        isPasswordLinkLoading ||
        projectCoWorkerCreateLoading ||
        orderCancelLoading ||
        orderProductRemoveLoading ||
        orderStatusUpdateLoading,
      productDeleteByOrderIdLoading
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    regSignupLoading,
    regSubscribeLoading,
    userUpdateLoading,
    userEditAdminLoading,
    userCreateAdminLoading,
    userManageAdminLoading,
    userForgetPasswordLoading,
    userFinishRecoveryLoading,
    userFederatedInfoLoading,
    catalogCreateCompanyTypeLoading,
    catalogManageCompanyTypeLoading,
    catalogCreateProjectTypeLoading,
    catalogManageProjectTypeLoading,
    catalogDeleteProjectTypeLoading,
    catalogCreateOrderStatusTypeLoading,
    catalogManageOrderStatusTypeLoading,
    catalogDeleteOrderStatusTypeLoading,
    orderCartTokenLoading,
    orderCreateLoading,
    orderEditLoading,
    supplierCreateLoading,
    supplierEditLoading,
    supplierContactLoading,
    supplierContactByBrandLoading,
    brandCreateLoading,
    brandEditLoading,
    categoryCreateLoading,
    categoryEditLoading,
    categoryDeleteLoading,
    productCreateLoading,
    productEditLoading,
    productVariationCreateLoading,
    productVariationEditLoading,
    productVariationDeleteLoading,
    productPropCreateLoading,
    productPropDeleteLoading,
    productPropPrecedenceEditLoading,
    productGroupCreateLoading,
    productGroupManageLoading,
    productGroupDeleteLoading,
    productGalleryTitleCreateLoading,
    productGalleryTitleManageLoading,
    productGalleryTitleDeleteLoading,
    favoriteCreateLoading,
    favoriteDeleteLoading,
    imageCreateLoading,
    imageSearchLoading,
    authLoginLoading,
    manuJoinLoading,
    projectCreateLoading,
    spaceCreateLoading,
    projectDeleteLoading,
    projectEditLoading,
    spaceEditLoading,
    spaceDeleteLoading,
    spaceProductCreateLoading,
    spaceMoveLoading,
    spaceProductDeleteLoading,
    isUserUpdateLoading,
    isPasswordLinkLoading,
    projectCoWorkerCreateLoading,
    orderCancelLoading,
    orderProductRemoveLoading,
    orderStatusUpdateLoading,
    productDeleteByOrderIdLoading,
    brandUpdateBooleanLoading,
  ]);
  useEffect(() => {
    if (regSignupSuccess) {
      handlePersist(
        "An Email has been sent to you for verification. Please check.",
        "success"
      );
    }
    if (regSignupFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regSignupSuccess, regSignupFail]);
  useEffect(() => {
    if (regSubscribeSuccess) {
      handlePersist(
        "An Email has been sent to you for verification. Please check.",
        "success"
      );
    }
    if (regSubscribeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regSubscribeSuccess, regSubscribeFail]);
  useEffect(() => {
    if (userUpdateSuccess === 200) {
      handleVariant("Updated!", "success");
    }
    if (userUpdateSuccess === 205) {
      handlePersist(
        "As you changed the email, a new verifying process is needed.",
        "info"
      );
      handlePersist(
        "An Email has been sent to you for verification. Please check.",
        "success"
      );
    }
    if (userUpdateFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (userUpdateFail === 406) {
      handleVariant("Incorrect current password.", "error");
    }
    if (userUpdateFail === 409) {
      handlePersist("The email you requested is already occupied.", "error");
    }
    if (userUpdateFail === 412) {
      handleVariant("Data tampering is criminal!", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateSuccess, userUpdateFail]);
  useEffect(() => {
    if (isUserUpdateSuccess) {
      handleVariant("Updated!", "success");
    }
    if (isUserUpdateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserUpdateSuccess, isUserUpdateFail]);
  useEffect(() => {
    if (isPasswordLinkSuccess) {
      handleVariant("Link sent into your account");
    }
    if (isPasswordLinkFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordLinkSuccess, isPasswordLinkFail]);
  useEffect(() => {
    if (projectCoWorkerCreateSuccess) {
      handleVariant("Added!", "success");
    }
    if (projectCoWorkerCreateFail) {
      if (projectCoWorkerCreateError === "Error: Already Added") {
        handlePersist("You are already part of project.", "error");
        return;
      }
      if (projectCoWorkerCreateError === "Error: not a member") {
        handlePersist("Error: not a member.", "error");
        return;
      }
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectCoWorkerCreateSuccess,
    projectCoWorkerCreateFail,
    projectCoWorkerCreateError,
  ]);
  useEffect(() => {
    if (userEditAdminSuccess) {
      handleVariant("Updated.", "success");
    }
    if (userEditAdminFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (userEditAdminFail === 406) {
      handleVariant("Incorrect current password.", "error");
    }
    if (userEditAdminFail === 412) {
      handleVariant("Data tampering is criminal!", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEditAdminSuccess, userEditAdminFail]);
  useEffect(() => {
    if (userCreateAdminSuccess) {
      handleVariant("New Admin Created.", "success");
    }
    if (userCreateAdminFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (userCreateAdminFail === 403) {
      handlePersist("Insufficient privilege.", "error");
    }
    if (userCreateAdminFail === 406) {
      handlePersist("Email already occupied.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreateAdminSuccess, userCreateAdminFail]);
  useEffect(() => {
    if (userManageAdminSuccess) {
      handleVariant("Updated.", "success");
    }
    if (userManageAdminFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManageAdminSuccess, userManageAdminFail]);
  useEffect(() => {
    if (userForgetPasswordSuccess) {
      handlePersist(
        "The recovery instruction is sent to your email. Please check.",
        "success"
      );
    }
    if (userForgetPasswordFail === 401) {
      handleVariant("Oops! Something is wrong, please retry.", "error");
    }
    if (userForgetPasswordFail === 406) {
      handleVariant("The Email specified is not registered.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForgetPasswordSuccess, userForgetPasswordFail]);
  useEffect(() => {
    if (userFinishRecoverySuccess) {
      handleVariant("Password changed successfully.", "success");
    }
    if (userFinishRecoveryFail) {
      handleVariant("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFinishRecoverySuccess, userFinishRecoveryFail]);
  useEffect(() => {
    if (userFederatedInfoSuccess) {
      handleVariant("Well received and record updated.", "success");
    }
    if (userFederatedInfoFail) {
      handleVariant("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFederatedInfoSuccess, userFederatedInfoFail]);
  useEffect(() => {
    if (catalogCreateCompanyTypeSuccess) {
      handleVariant("New Company Type Created.", "success");
    }
    if (catalogCreateCompanyTypeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogCreateCompanyTypeSuccess, catalogCreateCompanyTypeFail]);
  useEffect(() => {
    if (catalogManageCompanyTypeSuccess) {
      handleVariant("Updated.", "success");
    }
    if (catalogManageCompanyTypeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogManageCompanyTypeSuccess, catalogManageCompanyTypeFail]);
  useEffect(() => {
    if (catalogCreateProjectTypeSuccess) {
      handleVariant("New Company Type Created.", "success");
    }
    if (catalogCreateProjectTypeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogCreateProjectTypeSuccess, catalogCreateProjectTypeFail]);
  useEffect(() => {
    if (catalogManageProjectTypeSuccess) {
      handleVariant("Updated.", "success");
    }
    if (catalogManageProjectTypeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogManageProjectTypeSuccess, catalogManageProjectTypeFail]);
  useEffect(() => {
    if (catalogDeleteProjectTypeSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (catalogDeleteProjectTypeFail === 406) {
      handleVariant(
        "Used project type deletion forbidden for consistency.",
        "info"
      );
    }
    if (catalogDeleteProjectTypeFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogDeleteProjectTypeSuccess, catalogDeleteProjectTypeFail]);
  useEffect(() => {
    if (catalogCreateOrderStatusTypeSuccess) {
      handleVariant("New Company Type Created.", "success");
    }
    if (catalogCreateOrderStatusTypeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogCreateOrderStatusTypeSuccess, catalogCreateOrderStatusTypeFail]);
  useEffect(() => {
    if (catalogManageOrderStatusTypeSuccess) {
      handleVariant("Updated.", "success");
    }
    if (catalogManageOrderStatusTypeFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogManageOrderStatusTypeSuccess, catalogManageOrderStatusTypeFail]);
  useEffect(() => {
    if (catalogDeleteOrderStatusTypeSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (catalogDeleteOrderStatusTypeFail === 406) {
      handleVariant(
        "Used project type deletion forbidden for consistency.",
        "info"
      );
    }
    if (catalogDeleteOrderStatusTypeFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogDeleteOrderStatusTypeSuccess, catalogDeleteOrderStatusTypeFail]);
  useEffect(() => {
    if (productDeleteByOrderIdSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (productDeleteByOrderIdFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
  }, [productDeleteByOrderIdSuccess, productDeleteByOrderIdFailed]);
  useEffect(() => {
    if (spaceProductDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (spaceProductDeleteFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceProductDeleteSuccess, spaceProductDeleteFailed]);
  useEffect(() => {
    if (orderCancelSuccess) {
      handleVariant("Processed.", "success");
    }
    if (orderCancelFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCancelSuccess, orderCancelFailed]);
  useEffect(() => {
    if (orderProductRemoveSuccess) {
      handleVariant("Processed.", "success");
    }
    if (orderProductRemoveFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProductRemoveSuccess, orderProductRemoveFailed]);
  useEffect(() => {
    if (orderStatusUpdateSuccess) {
      handleVariant("Updated.", "success");
    }
    if (orderStatusUpdateFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusUpdateSuccess, orderStatusUpdateFailed]);
  useEffect(() => {
    if (imageCreateSuccess) {
      handleVariant("Uploaded.", "success");
    }
    if (imageCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageCreateSuccess, imageCreateFail]);
  useEffect(() => {
    if (imageSearchSuccess) {
      handleVariant("Image classification completed.", "success");
    }
    if (imageSearchFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSearchSuccess, imageSearchFail]);
  useEffect(() => {
    if (supplierCreateSuccess) {
      handleVariant("New supplier created.", "success");
    }
    if (supplierCreateFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (supplierCreateFail === 406) {
      handleVariant("Email already occupied.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierCreateSuccess, supplierCreateFail]);
  useEffect(() => {
    if (supplierEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (supplierEditFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (supplierEditFail === 406) {
      handleVariant("Email already occupied.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierEditSuccess, supplierEditFail]);
  useEffect(() => {
    if (supplierContactSuccess) {
      handlePersist(
        `${supplierContactPayload} will get in touch with you shortly`,
        "success"
      );
    }
    if (supplierContactFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierContactSuccess, supplierContactFail]);
  useEffect(() => {
    if (supplierContactByBrandSuccess) {
      handlePersist(
        `${supplierContactByBrandPayload} will get in touch with you shortly`,
        "success"
      );
    }
    if (supplierContactByBrandFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierContactByBrandSuccess, supplierContactByBrandFail]);
  useEffect(() => {
    if (brandCreateSuccess) {
      handleVariant("New brand created.", "success");
    }
    if (
      brandCreateFailMessage === "Error: Show only 3 brands in landing page."
    ) {
      handleVariant("Show only 3 brands in landing page.");
      return;
    }
    if (brandCreateFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (brandCreateFail === 406) {
      handleVariant("Image is missing.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCreateSuccess, brandCreateFail]);
  useEffect(() => {
    if (brandEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (brandEditFailMessage === "Error: Show only 3 brands in landing page.") {
      handleVariant("Show only 3 brands in landing page.");
      return;
    }
    if (brandEditFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (brandEditFail === 406) {
      handleVariant("Image is missing.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandEditSuccess, brandEditFail, brandEditFailMessage]);
  useEffect(() => {
    if (brandUpdateBooleanSuccess) {
      handleVariant("Updated.", "success");
    }
    if (
      brandUpdateBooleanFailMessage ===
      "Error: Show only 3 brands in landing page."
    ) {
      handleVariant("Show only 3 brands in landing page.");
      return;
    }
    if (brandUpdateBooleanFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (brandUpdateBooleanFail === 406) {
      handleVariant("Image is missing.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brandUpdateBooleanSuccess,
    brandUpdateBooleanFail,
    brandUpdateBooleanFailMessage,
  ]);
  useEffect(() => {
    if (categoryCreateSuccess) {
      handleVariant("New category created.", "success");
    }
    if (categoryCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryCreateSuccess, categoryCreateFail]);
  useEffect(() => {
    if (categoryEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (categoryEditFail === 403) {
      handleVariant(
        'Edit of "Materials" & "Furniture" is not allowed.',
        "info"
      );
    }
    if (categoryEditFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryEditSuccess, categoryEditFail]);
  useEffect(() => {
    if (categoryDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (categoryDeleteFail === 406) {
      handleVariant("Delete of category with children is not allowed.", "info");
    }
    if (categoryDeleteFail === 403) {
      handleVariant(
        'Delete of "Materials" & "Furniture" is not allowed.',
        "info"
      );
    }
    if (categoryDeleteFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDeleteSuccess, categoryDeleteFail]);
  useEffect(() => {
    if (productPropCreateSuccess) {
      handleVariant("Processed.", "success");
    }
    if (productPropCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropCreateSuccess, productPropCreateFail]);
  useEffect(() => {
    if (projectCreateSuccess) {
      handleVariant("Processed.", "success");
    }
    if (projectCreateFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCreateSuccess, projectCreateFailed]);
  useEffect(() => {
    if (projectEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (projectEditFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEditSuccess, projectEditFailed]);
  useEffect(() => {
    if (projectDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (projectDeleteFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDeleteSuccess, projectDeleteFailed]);
  useEffect(() => {
    if (spaceCreateSuccess) {
      handleVariant("Processed.", "success");
    }
    if (spaceCreateFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceCreateSuccess, spaceCreateFailed]);
  useEffect(() => {
    if (spaceEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (spaceEditFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceEditSuccess, spaceEditFailed]);
  useEffect(() => {
    if (spaceDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (spaceDeleteFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDeleteSuccess, spaceDeleteFailed]);
  useEffect(() => {
    if (spaceProductCreateSuccess) {
      handleVariant("Processed.", "success");
    }
    if (spaceProductCreateFailed === 406) {
      handlePersist("Product Already exist in this space.", "error");
    }
    if (spaceProductCreateFailed === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceProductCreateSuccess, spaceProductCreateFailed]);
  useEffect(() => {
    if (spaceMoveSuccess) {
      handleVariant("Processed.", "success");
    }
    if (spaceMoveFailed) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceMoveSuccess, spaceMoveFailed]);
  useEffect(() => {
    if (productPropDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (productPropDeleteFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropDeleteSuccess, productPropDeleteFail]);
  useEffect(() => {
    if (productPropPrecedenceEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (productPropPrecedenceEditFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropPrecedenceEditSuccess, productPropPrecedenceEditFail]);
  useEffect(() => {
    if (productCreateSuccess) {
      handleVariant("Processed.", "success");
    }
    if (productCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCreateSuccess, productCreateFail]);
  useEffect(() => {
    if (productEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (productEditFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEditSuccess, productEditFail]);
  useEffect(() => {
    if (productVariationCreateSuccess) {
      handleVariant("Processed.", "success");
    }
    if (productVariationCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariationCreateSuccess, productVariationCreateFail]);
  useEffect(() => {
    if (productVariationEditSuccess) {
      handleVariant("Updated.", "success");
    }
    if (productVariationEditFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariationEditSuccess, productVariationEditFail]);
  useEffect(() => {
    if (productVariationDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (productVariationDeleteFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariationDeleteSuccess, productVariationDeleteFail]);
  useEffect(() => {
    if (favoriteCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteCreateFail]);
  useEffect(() => {
    if (favoriteDeleteSuccess) {
      handleVariant("Removed.", "success");
    }
    if (favoriteDeleteFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteDeleteSuccess, favoriteDeleteFail]);
  useEffect(() => {
    if (orderCartTokenFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCartTokenFail]);
  useEffect(() => {
    if (orderCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCreateFail]);
  useEffect(() => {
    if (orderEditSuccess) {
      handleVariant("Order updated.", "success");
    }
    if (orderEditFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderEditSuccess, orderEditFail]);
  useEffect(() => {
    if (productGroupCreateSuccess) {
      handleVariant("New Product Group Created.", "success");
    }
    if (productGroupCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroupCreateSuccess, productGroupCreateFail]);
  useEffect(() => {
    if (productGroupManageSuccess) {
      handleVariant("Updated.", "success");
    }
    if (productGroupManageFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroupManageSuccess, productGroupManageFail]);
  useEffect(() => {
    if (productGroupDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (productGroupDeleteFail === 406) {
      handleVariant(
        "Used product group deletion forbidden for consistency.",
        "info"
      );
    }
    if (productGroupDeleteFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroupDeleteSuccess, productGroupDeleteFail]);
  useEffect(() => {
    if (productGalleryTitleCreateSuccess) {
      handleVariant("New Product Gallery Title Created.", "success");
    }
    if (productGalleryTitleCreateFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGalleryTitleCreateSuccess, productGalleryTitleCreateFail]);
  useEffect(() => {
    if (productGalleryTitleManageSuccess) {
      handleVariant("Updated.", "success");
    }
    if (productGalleryTitleManageFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGalleryTitleManageSuccess, productGalleryTitleManageFail]);
  useEffect(() => {
    if (productGalleryTitleDeleteSuccess) {
      handleVariant("Deleted.", "success");
    }
    if (Boolean(productGalleryTitleDeleteFail) === true) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGalleryTitleDeleteSuccess, productGalleryTitleDeleteFail]);
  useEffect(() => {
    if (authLoginFail === 401) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    if (authLoginFail === 406) {
      handlePersist("Account disabled. Ask your boss why.", "error");
    }
    if (authLoginSuccess === 202) {
      handlePersist("We resent the verification mail. Please verify.", "info");
    }
    if (authLoginSuccess === 200) {
      handleVariant("Welcome back!", "success");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoginSuccess, authLoginFail]);
  useEffect(() => {
    if (isLoggedOut) {
      handleVariant("You have successfully logged out.", "success");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedOut]);
  useEffect(() => {
    if (isFederatedLoginFail) {
      handleVariant("Oops! Login fails, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFederatedLoginFail]);
  useEffect(() => {
    if (manuJoinSuccess) {
      handlePersist("Well received. We will follow up. Thanks.", "success");
    }
    if (manuJoinFail) {
      handlePersist("Oops! Something is wrong, please retry.", "error");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manuJoinSuccess, manuJoinFail]);

  return (
    <React.Fragment>
      {/* <StyledBackdrop open={backdropOpen}>
        <CircularProgress color="inherit" />
      </StyledBackdrop> */}
    </React.Fragment>
  );
}

export default FeedbackSnackbar;
