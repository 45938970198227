import { createSlice } from '@reduxjs/toolkit';
import { catalogDeleteOrderStatusType } from './catalogAsyncThunk';

const catalogDeleteOrderStatusTypeSlice = createSlice({
  name: "catalog",
  initialState: {
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [catalogDeleteOrderStatusType.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [catalogDeleteOrderStatusType.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [catalogDeleteOrderStatusType.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
  },
});

export default catalogDeleteOrderStatusTypeSlice.reducer;