import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { authLogin } from "./authAsyncThunk";

import {
  Dialog,
  StyledTextField,
  StyledDialogTitle,
} from "../../components/CustomComponents";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { BlackButton } from "../../components/CustomButtons";
import RegDesktop from "../../features/registration/RegDesktop";
import ProfileLogo from "../../components/img/profile-icon.svg";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { useTheme } from "@mui/material/styles";

import { Box } from "@mui/material";

export default function AuthDesktop() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    isStaySignin: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!Boolean(showPassword));
  };

  const handlePointerDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.password) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(authLogin(values));
  };

  const handleFacebook = (event) => {
    event.preventDefault();
    window.location.assign(
      `${process.env.REACT_APP_BACKEND_URL}/api/login/federated/facebook`
    );
  };

  const handleGoogle = (event) => {
    event.preventDefault();
    window.location.assign(
      `${process.env.REACT_APP_BACKEND_URL}/api/login/federated/google`
    );
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Box sx={{ cursor: "pointer" }} onClick={handleClickOpen}>
        <AccountCircleSharpIcon
          sx={{
            fontSize: "36px",
            // padding: "0px",
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.main,
          }}
        />
      </Box>
      <Dialog
        open={open}
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
        scroll="body"
      >
        <StyledDialogTitle id="form-dialog-title" onClose={handleClose}>
          Sign in
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <DialogContentText sx={{ fontSize: "13px", pb: 1.5 }}>
            Enter you email address and password to access your account
          </DialogContentText>
          <StyledTextField
            autoFocus
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            defaultValue={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            id="email"
            placeholder="Email address"
            fullWidth
          />
          <StyledTextField
            error={isUserActionMade && Boolean(values.password) === false}
            defaultValue={values.password}
            onChange={handleValues("password")}
            onKeyPress={handleEnter}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mb: 1.5, mt: 0 }}
            autoComplete="off"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onPointerDown={handlePointerDownPassword}
                    sx={{ marginRight: "0px!important" }}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isStaySignin}
                onChange={(e) =>
                  setValues({ ...values, isStaySignin: e.target.checked })
                }
                name="isStaySignin"
                color="primary"
                inputProps={{
                  "aria-label": "Stay Sign-in",
                }}
              />
            }
            label="Stay Sign-in"
          />
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(1.5, 0, 0, 0),
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            <Link
              href="/recovery/"
              color="inherit"
              fontWeight="500"
              underline="hover"
            >
              Forget your password?
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            pb: 3,
            px: 3,
          }}
        >
          <BlackButton
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 1.5, 0),
            }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Log in
          </BlackButton>

          {/* <BlueButton
                sx={{
                  width: "100%",
                  margin: (theme) => theme.spacing(1, 1, 0.5, 1),
                }}
                variant="contained"
                color="primary"
                startIcon={<FacebookIcon />}
                onClick={handleFacebook}
              >
                Continue with Facebook
              </BlueButton>
              <LightBlueButton
                sx={{
                  width: "100%",
                  margin: (theme) => theme.spacing(1, 1, 3, 1),
                }}
                variant="contained"
                color="primary"
                startIcon={<GoogleIcon />}
                onClick={handleGoogle}
              >
                Sign in with Google
              </LightBlueButton> */}

          <RegDesktop />
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 1, 1, 1),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </>
  );
}
