import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Slider from "../components/Slider";
function ImgMediaCardSwiper({ items }) {
  const cardsPerRow = 25;
  const [pageIndex, setPageIndex] = useState(0);
  const [activeItems, setActiveItems] = useState(
    Array.isArray(items) ? items.slice(0, cardsPerRow) : []
  );

  useEffect(() => {
    setPageIndex(0);
    setActiveItems(Array.isArray(items) ? items.slice(0, cardsPerRow) : []);
    return () => {};
  }, [items]);

  useEffect(() => {
    setActiveItems(
      Array.isArray(items)
        ? items.slice(pageIndex, pageIndex + cardsPerRow)
        : []
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  return (
    <Box>
      <Slider product={activeItems} />
    </Box>
  );
}

ImgMediaCardSwiper.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      imageURL: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  sx: PropTypes.object,
};

export default ImgMediaCardSwiper;
