import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlackButton } from "../../components/CustomButtons";

import {
  projectCoWorkerCreate,
  selectProjectCoWorkerCreate,
} from "./projectAsyncThunk";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";

export default function ProjectCoWorker({ open, onClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [values, setValues] = useState({
    email: "",
    projectRole: "Read",
  });
  const { isSuccess } = useSelector(selectProjectCoWorkerCreate);
  const [submitted, setSubmitted] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const { payload: userInfo } = useSelector(selectAuthUserInfo);
  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClose = () => {
    if (submitted) {
      setSubmitted(false);
      onClose();
      return;
    }
    return;
  };

  useEffect(() => {
    if (isSuccess) {
      setSubmitted(true);
      window.location.href = `/project/${params.id}`;
    }
  }, [isSuccess]);

  const handleSubmit = async () => {
    setIsUserActionMade(true);
    if (Boolean(values.email) === false) {
      setShowHint(true);
    }
    if (values.email === userInfo.email) {
      handlePersist("You are already the creator of this project", "error");
      return;
    }
    await dispatch(
      projectCoWorkerCreate({
        projectId: params?.id,
        projectRole: values.projectRole,
        email: values.email,
      })
    );
  };

  const handlePersist = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, action, persist: true });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="project-co-worker-dialog"
        onClose={handleClose}
      >
        {submitted ? (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: "600" }}>
              An e-mail request has been sent to your co-workers email.
            </Typography>
          </DialogContent>
        ) : (
          <>
            <StyledDialogTitle
              id="project-co-worker-dialog-title"
              onClose={onClose}
            >
              Type your co-worker email below
            </StyledDialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <StyledTextField
                variant="outlined"
                autoFocus
                required
                sx={{ width: { md: 448, sm: "100%", mt: 0, mb: 1.5 } }}
                error={isUserActionMade && Boolean(values.email) === false}
                defaultValue={values.email}
                onChange={handleValues("email")}
                margin="dense"
                size="medium"
                id="email"
                placeholder="Enter your co-worker email"
                fullWidth
              />
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
              <BlackButton
                sx={{
                  width: "100%",
                  height: 55,
                  margin: (theme) => theme.spacing(0, 0, 3, 0),
                }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Send email requests
              </BlackButton>
            </DialogActions>
            {showHint && (
              <DialogContentText
                sx={{
                  margin: (theme) => theme.spacing(0, 3, 3, 3),
                  textAlign: "center",
                }}
              >
                Oops! Something is still wrong above.
              </DialogContentText>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}
