import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { useSelector } from "react-redux";

import ProjectCreate from "../../features/project/ProjectCreate";
import ProjectCard from "../ProjectCard";

import {
  selectProjectCoWorkersList,
  selectProjectList,
} from "../../features/project/projectAsyncThunk";
import { selectSpaceList } from "../../features/space/spaceAsyncThunk";

const ProjectsTab = () => {
  const { payload: spaces } = useSelector(selectSpaceList);
  const { payload: projects } = useSelector(selectProjectList);
  const { payload: coWorkers } = useSelector(selectProjectCoWorkersList);

  const projectSpaces = useMemo(() => {
    return projects.map((val) => {
      return {
        ...val,
        items: spaces.filter((space) => space.projectId === val.id).length,
        workers: coWorkers.filter(
          (item) => item.project_id === +val.id && item.role === "Read"
        ),
      };
    });
  }, [projects, spaces, coWorkers]);

  return (
    <Box>
      <Typography
        sx={{ fontSize: { xs: 16, sm: 20, md: 24 } }}
        fontWeight={600}
      >
        Your active projects
      </Typography>
      <Box sx={{ pt: 1.5, pb: 4 }}>
        <Grid container spacing={1.5}>
          {projectSpaces && projectSpaces.length > 0 ? (
            projectSpaces.map((project, index) => {
              return (
                <React.Fragment key={`project-${index}`}>
                  <Grid item xs={12} sm={6} md={3} lg={12 / 5}>
                    <ProjectCard project={project} />
                  </Grid>
                  {projectSpaces.length - 1 === index && (
                    <Grid item xs={12} sm={6} md={3} lg={12 / 5}>
                      <ProjectCreate showIcon={true} />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <Grid item xs={12} sm={6} md={3} lg={12 / 5}>
              <ProjectCreate showIcon={true} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProjectsTab;
