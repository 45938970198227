import React, { useState, useEffect } from "react";
import categoryColumns from "../../components/types/category";
import CategoryCreate from "./CategoryCreate";
import CategoryEdit from "./CategoryEdit";
import CategoryDelete from "./CategoryDelete";
import { useSelector, useDispatch } from "react-redux";
import {
  categoryList,
  selectCategoryList,
  selectCategoryCreate,
  selectCategoryEdit,
  selectCategoryDelete,
} from "./categoryAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function CategoryManageGrid() {
  const dispatch = useDispatch();
  const [categoryFullList, setCategoryFullList] = useState([]);
  const [createBtnKey, setCreateBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const [editBtnKey, setEditBtnKey] = useState(
    `${Math.random().toString().substring(14)}`
  );
  const [createButtonProps, setCreateButtonProps] = useState({
    data: {
      parent_id: null,
      parent_name: "null",
      name: "",
      imageURL: "",
    },
  });
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
      imageURL: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
  });
  const categoryListStore = useSelector(selectCategoryList);
  const { payload } = categoryListStore;
  const categoryEditStore = useSelector(selectCategoryEdit);
  const { isSuccess: categoryEditSuccess } = categoryEditStore;
  const categoryCreateStore = useSelector(selectCategoryCreate);
  const { isSuccess: categoryCreateSuccess } = categoryCreateStore;
  const categoryDeleteStore = useSelector(selectCategoryDelete);
  const { isSuccess: categoryDeleteSuccess } = categoryDeleteStore;

  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = categoryFullList.findIndex((x) => x.id === selected[0]);
      setCreateButtonProps({
        data: {
          parent_id: selected[0],
          parent_name: categoryFullList[index].fullname,
          name: "",
          imageURL: "",
        },
      });
      setEditButtonProps({
        isSelected: true,
        data: { ...categoryFullList[index] },
      });
    } else {
      setCreateButtonProps({
        data: {
          parent_id: null,
          parent_name: "null",
          name: "",
          imageURL: "",
        },
      });
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
          imageURL: "",
          created_at: new Date(),
          updated_at: new Date(),
        },
      });
    }
  };

  useEffect(() => {
    dispatch(categoryList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryEditSuccess, categoryCreateSuccess, categoryDeleteSuccess]);

  useEffect(() => {
    setCreateBtnKey(
      `category-create-${Math.random().toString().substring(14)}`
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryCreateSuccess]);

  useEffect(() => {
    setEditBtnKey(`category-edit-${Math.random().toString().substring(14)}`);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryEditSuccess]);

  useEffect(() => {
    let category = [...payload];
    let list = [];
    if (category.length > 0) {
      for (let i = 0; i < category.length; i++) {
        let item = {
          ...category[i],
          created_at: new Date(category[i].created_at),
          updated_at: new Date(category[i].updated_at),
        };
        list.push(item);
      }
      setCategoryFullList(list);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Typography>Category Management</Typography>
      <CategoryCreate key={createBtnKey} {...createButtonProps} />
      {categoryFullList[0] && (
        <CategoryEdit key={editBtnKey} {...editButtonProps} />
      )}
      {categoryFullList[0] && <CategoryDelete {...editButtonProps} />}
      {categoryFullList[0] && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={categoryFullList}
                columns={categoryColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
