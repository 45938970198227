import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const productGroupCreate = createAsyncThunk(
  "productGroup/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGroup/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const productGroupList = createAsyncThunk(
  "productGroup/list",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGroup/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productGroupManage = createAsyncThunk(
  "productGroup/manage",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGroup/`,
        payload
        );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productGroupDelete = createAsyncThunk(
  "productGroup/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGroup/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const selectProductGroupCreate = (state) => state.productGroupCreate;
export const selectProductGroupList = (state) => state.productGroupList;
export const selectProductGroupManage = (state) => state.productGroupManage;
export const selectProductGroupDelete = (state) => state.productGroupDelete;