import Button from "@mui/material/Button";
import MuiFab from "@mui/material/Fab";
import { highlight_colour } from "./styles/materialUIStyles";
import { styled } from "@mui/material/styles";
import { red, grey, lightBlue, common } from "@mui/material/colors";

const customRed = { ...red, 500: highlight_colour };
const customGrey = { ...grey, 500: common.black };
const customWhite = { ...grey, 100: common.white };

export const PrimaryRoundButton = styled(Button)(({ theme }) => ({
  borderRadius: 999,
  color: theme.palette.primary.main,
  textTransform: "none",
  fontWeight: "600",
  boxShadow: "none",
  backgroundColor: customWhite[100],
  //
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "36px",
    paddingRight: "36px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  //
  "&:hover": {
    backgroundColor: "rgba(235, 235, 235, 1)",
  },
}));

export const PrimaryOutlineRoundButton = styled(Button)(({ theme }) => ({
  borderRadius: 999,
  color: theme.palette.primary.main,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "40px",
  paddingRight: "40px",
  textTransform: "none",
  fontSize: "15px",
  fontWeight: "600",
  boxShadow: "none",
  backgroundColor: customWhite[100],
  "&:hover": {
    backgroundColor: customWhite[200],
  },
}));

export const WhiteButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid black",
  color: theme.palette.common.black,
  paddingTop: "12px",
  paddingBottom: "12px",
  textTransform: "none",
  fontSize: "15px",
  fontWeight: "600",
  boxShadow: "none",
  backgroundColor: customWhite[100],
  "&:hover": {
    backgroundColor: customWhite[200],
  },
}));
export const RwhiteButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid #000",
  fontSize: "15px",
  fontWeight: "600",
  color: theme.palette.common.black,
  paddingTop: "12px",
  paddingBottom: "12px",
  width: "100%",
  backgroundColor: customWhite[100],
  "&:hover": {
    backgroundColor: customWhite[200],
  },
}));

export const HighlightButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  fontSize: "15px",
  fontWeight: "600",
  paddingTop: "12px",
  paddingBottom: "12px",
  backgroundColor: customRed[500],
  "&:hover": {
    backgroundColor: customRed[900],
  },
}));

export const BlackButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "16px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "16px",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.primary,
  },
}));

export const RBlackButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  width: "100%",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  backgroundColor: customGrey[500],
  "&:hover": {
    backgroundColor: customGrey[800],
  },
}));

export const NoColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.black,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  backgroundColor: customWhite[100],
  "&:hover": {
    backgroundColor: customWhite[200],
  },
}));

export const BlueButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  backgroundColor: lightBlue[900],
  "&:hover": {
    backgroundColor: lightBlue[800],
  },
}));

export const LightBlueButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "15px",
  fontWeight: "600",
  backgroundColor: lightBlue[700],
  "&:hover": {
    backgroundColor: lightBlue[600],
  },
}));

export const white = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  backgroundColor: lightBlue[700],
  "&:hover": {
    backgroundColor: lightBlue[600],
  },
}));

export const GreyButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  backgroundColor: grey[700],
  "&:hover": {
    backgroundColor: grey[600],
  },
}));

export const LightGreyButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.black,
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  paddingBottom: "12px",
  backgroundColor: grey[300],
  fontSize: "15px",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: grey[200],
  },
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: theme.palette.common.black,
  textTransform: "none",
  boxShadow: "none",
  backgroundColor: "transparent",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

export const TextButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "15px",
  fontWeight: "600",
  color: theme.palette.common.black,
  textTransform: "none",
  boxShadow: "none",
}));

export const LeftTextButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: "inherit",
  textTransform: "none",
  boxShadow: "none",
  paddingTop: "12px",
  fontSize: "15px",
  fontWeight: "600",
  paddingBottom: "12px",
  display: "inline-flex",
  padding: "6px 0px",
}));

export const HighlightFab = styled(MuiFab)(({ theme, isSticky, bottom }) => ({
  borderRadius: "8px",
  color: theme.palette.common.white,
  position: "fixed",
  bottom: `12px`,
  paddingTop: "12px",
  paddingBottom: "12px",
  marginBottom: "12px",
  fontSize: "15px",
  fontWeight: "600",
  left: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    left: theme.spacing(4),
  },
  zIndex: theme.zIndex.mobileStepper + 1,
  textTransform: "none",
  boxShadow: "none",
  backgroundColor: customRed[500],
  "&:hover": {
    backgroundColor: customRed[900],
  },
}));
