import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { regSignup } from "./regAsyncThunk";

import {
  Dialog,
  DialogTextField,
  StyledDialogTitle,
} from "../../components/CustomComponents";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  BlackButton,
  RwhiteButton,
  PrimaryOutlineRoundButton,
} from "../../components/CustomButtons";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

export default function RegDesktop({ type }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyType: "",
    jobTitle: "",
    companyLocation: "",
    companyWebsite: "",
    isSubscribe: true,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!Boolean(showPassword));
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!Boolean(showConfirmPassword));
  };

  const handlePointerDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (values.password.length < 9) {
      setShowHint(true);
      return;
    }
    if (confirmPassword !== values.password) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.jobTitle) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyWebsite) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(regSignup(values));
  };

  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {type === 1 ? (
        <PrimaryOutlineRoundButton
          sx={{
            m: 1,
            borderRadius: 999,
            border: 1,
            borderColor: "rgba(255,255,255,0)",
            backgroundColor: "rgba(0,0,0,0.1)",
            backdropFilter: "blur(8px)",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.main",
              color: "white",
            },
          }}
          onClick={() =>
            handleVariant("New user sign up will be available soon.")
          }
        >
          Register
        </PrimaryOutlineRoundButton>
      ) : (
        <RwhiteButton
          variant="contained"
          color="primary"
          sx={{ m: 1, border: "hidden" }}
          onClick={() =>
            handleVariant("New user sign up will be available soon.")
          }
        >
          Register
        </RwhiteButton>
      )}

      <Dialog open={open} aria-labelledby="form-dialog-title" scroll="body">
        <StyledDialogTitle id="form-dialog-title" onClose={handleClose}>
          Register
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <DialogContentText>Enter your information below</DialogContentText>
          <DialogTextField
            autoFocus
            required
            sx={{ maxWidth: "48%", mr: 1, mt: 1, mb: 1.5 }}
            error={isUserActionMade && Boolean(values.firstName) === false}
            defaultValue={values.firstName}
            onChange={handleValues("firstName")}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            label="First Name"
            fullWidth
          />
          <DialogTextField
            required
            sx={{
              maxWidth: (theme) => `calc(52% - ${theme.spacing(1)})`,
              mt: 1,
              mb: 1.5,
            }}
            error={isUserActionMade && Boolean(values.lastName) === false}
            defaultValue={values.lastName}
            onChange={handleValues("lastName")}
            variant="outlined"
            margin="dense"
            size="small"
            id="lastname"
            label="Last Name"
            fullWidth
          />
          <DialogTextField
            required
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            defaultValue={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            size="small"
            id="email"
            label="Work email"
            sx={{ mt: 0, mb: 1.5 }}
            type="email"
            fullWidth
            autoComplete="off"
          />
        </DialogContent>
        <DialogContent sx={{ pt: 0, px: 3, pb: 3 }}>
          <DialogContentText>Password</DialogContentText>
          <DialogTextField
            required
            sx={{ maxWidth: "48%", mr: 1, mt: 1, mb: 1.5 }}
            error={isUserActionMade && values.password.length < 9}
            defaultValue={values.password}
            onChange={handleValues("password")}
            variant="outlined"
            margin="dense"
            size="small"
            autoComplete="new-password"
            label="Password"
            type={showPassword ? "text" : "password"}
            helperText="At least 9-character long"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    sx={{ marginRight: "0px!important" }}
                    onClick={handleClickShowPassword}
                    onPointerDown={handlePointerDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <DialogTextField
            required
            sx={{
              maxWidth: (theme) => `calc(52% - ${theme.spacing(1)})`,
              mt: 1,
              mb: 1.5,
            }}
            error={isUserActionMade && confirmPassword !== values.password}
            defaultValue={confirmPassword}
            onChange={(e) => {
              setIsUserActionMade(true);
              setConfirmPassword(e.target.value);
            }}
            variant="outlined"
            margin="dense"
            size="small"
            autoComplete="off"
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onPointerDown={handlePointerDownPassword}
                    edge="end"
                    sx={{ marginRight: "0px!important" }}
                    size="large"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogContent sx={{ pt: 0, px: 3, pb: 3 }}>
          <DialogContentText>Work Details</DialogContentText>
          <DialogTextField
            required
            error={isUserActionMade && Boolean(values.companyType) === false}
            defaultValue={values.companyType}
            onChange={handleValues("companyType")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 1, mb: 1.5 }}
            id="company-type"
            label="Company Type"
            fullWidth
          />
          <DialogTextField
            required
            error={isUserActionMade && Boolean(values.jobTitle) === false}
            defaultValue={values.jobTitle}
            onChange={handleValues("jobTitle")}
            variant="outlined"
            margin="dense"
            sx={{ mt: 0, mb: 1.5 }}
            size="small"
            id="job-title"
            label="Job Title"
            fullWidth
          />
          <DialogTextField
            required
            error={
              isUserActionMade && Boolean(values.companyLocation) === false
            }
            defaultValue={values.companyLocation}
            onChange={handleValues("companyLocation")}
            variant="outlined"
            margin="dense"
            sx={{ mt: 0, mb: 1.5 }}
            size="small"
            id="company-location"
            label="Company Location"
            helperText="You can enter multiple rows here."
            fullWidth
            multiline
          />
          <DialogTextField
            required
            error={isUserActionMade && Boolean(values.companyWebsite) === false}
            defaultValue={values.companyWebsite}
            onChange={handleValues("companyWebsite")}
            variant="outlined"
            sx={{ mt: 0, mb: 1.5 }}
            margin="dense"
            size="small"
            id="company-website"
            label="Company Website"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isSubscribe}
                onChange={(e) =>
                  setValues({ ...values, isSubscribe: e.target.checked })
                }
                name="isSubscribe"
                color="primary"
                inputProps={{
                  "aria-label": "Subscribe to our weekly newslatter",
                }}
              />
            }
            label="Subscribe to our weekly newslatter"
          />
        </DialogContent>

        <Box sx={{ p: "25px 0px 0px 25px" }}>
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            By registering with Matterbase you agree to our Terms and Conditions
            and Privacy Statement.
          </Typography>
        </Box>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            disabled
            onClick={handleSubmit}
          >
            Create Account
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}
