import { createSlice } from "@reduxjs/toolkit";
import { projectCoWorkerCreate } from "./projectAsyncThunk";

const projectCoWorkerCreateSlice = createSlice({
  name: "project",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
    isError: "",
  },
  reducers: {},
  extraReducers: {
    [projectCoWorkerCreate.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
      state.isError = "";
    },
    [projectCoWorkerCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [projectCoWorkerCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.isError = action.payload.message;
    },
  },
});

export default projectCoWorkerCreateSlice.reducer;
