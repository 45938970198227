import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ButtonBase from "@mui/material/ButtonBase";
import PremiumIcon from "@mui/icons-material/LocalActivitySharp";
import placeholderLogo from "../components/img/no_image_logo.png";
import AddIcon from "./img/add-icon.svg";
import SelectProductModal from "./SelectProductModal";
import { Chip } from "@mui/material";
import NewTag from "../components/img/new-tag.svg";
import { makeStyles } from "@mui/styles";
import useThrottledHover from "./customHooks/throttleHoverState";
import { landing_page_background_colour } from "./styles/materialUIStyles";
import useImageHoverState from "./customHooks/ImageHoverState";

const StyledImg = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
}));

const ImgPlaceholder = styled("img")(({ theme }) => ({
  margin: "auto",
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
}));

const useStyles = makeStyles((theme) => ({
  paperHover: {
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.25)",
    transition: "border-color 100ms ease-in-out",
    boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.05)",
  },
  showChip: {
    opacity: 1,
    transition: "opacity 100ms ease-in-out",
  },
  showAddIcon: {
    opacity: 1,
    transition: "opacity 100ms ease-in-out",
  },
}));

function SearchPaper({ key, item, hovered }) {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categoryShow, setCategoryShow] = useState(false);
  const [iconShow, setIconShow] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const LinkBehavior = forwardRef((props, ref) => (
    <RouterLink ref={ref} to={item.url} rel="noopener noreferrer" {...props} />
  ));

  const { hoverStates, handleMouseEnter, handleMouseLeave } = useThrottledHover(
    [item.id],
    100
  );

  return (
    <React.Fragment>
      <Box
        onMouseLeave={() => {
          handleMouseLeave(item.id);
        }}
        onMouseEnter={() => {
          handleMouseEnter(item.id);
        }}
        sx={{
          flexGrow: 1,
          position: "relative",
          width: "auto",
        }}
      >
        <Paper
          elevation={0}
          className={`${hoverStates[item.id] ? classes.paperHover : ""}`}
          sx={{
            // margin: -1,
            textAlign: "left",
            borderRadius: "12px",
            border: 1.5,
            borderColor: landing_page_background_colour,
            // marginRight: 6,
            // transition: "border 50ms ease-in-out",
            // ":hover": {
            //   border: 1.5,
            //   borderColor: "text.secondary",
            //   transitionDelay: "100ms",
            // },
          }}
        >
          <Grid
            xs
            item
            sx={{
              width: "100%",
            }}
          >
            <Link
              component={LinkBehavior}
              // sx={{ height: 1000 }}
              color="inherit"
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  aspectRatio: "1",
                }}
              >
                {item.imageURL ? (
                  <StyledImg
                    alt={item.subtitle}
                    src={`${item.imageURL}?tr=w-300,h-300`}
                  />
                ) : (
                  <ImgPlaceholder alt={item.subtitle} src={placeholderLogo} />
                )}
              </Box>
            </Link>
          </Grid>
          <Grid item xs sx={{ width: "100%" }}>
            <Link
              component={LinkBehavior}
              sx={{ height: 1000 }}
              color="inherit"
              underline="none"
            >
              <Box
                sx={{
                  p: { xs: 1.2, md: 1.5 },
                  display: "block",
                  alignItem: "center",
                }}
              >
                <Typography
                  variant="title_secondary_black"
                  sx={{
                    display: "block",
                    textAlign: "left",
                    fontSize: "15px",
                    marginBottom: "1px",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  noWrap
                >
                  <b>{item.subtitle}</b>
                </Typography>
                <Link
                  component={RouterLink}
                  to={`/brand/${item.title}-${item.brand_id}`}
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="title_secondary_grey"
                    sx={{
                      display: "block",
                      textAlign: "left",
                      fontSize: "15px",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    noWrap
                  >
                    {item?.title?.replace(/_/g, "/")}
                  </Typography>
                </Link>
              </Box>
            </Link>
          </Grid>
          <Box
            className={`${
              hoverStates[item.id] || isMdDown ? classes.showChip : ""
            }`}
            sx={{
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
              left: "8px",
              bottom: { xs: "73px", md: "80px" },
            }}
          >
            <Chip
              label={item?.category?.split("->")?.slice(-1)[0]}
              size={isMdDown ? "small" : "medium"}
              sx={{
                fontSize: { xs: "12px", md: "13px" },
                px: 0.5,
                fontWeight: "500",
                backgroundColor: "rgba(255, 255, 255, 0.6)",
                backdropFilter: "blur(8px)",
                color: "text.primary",

                border: "1px solid",
                borderColor: "rgba(0, 0, 0, 0.25)",
              }}
            />
          </Box>
          {new Date() - new Date(item.created_at) < 864000000 && (
            <Chip
              label="new"
              size={isMdDown ? "small" : "medium"}
              sx={{
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                backdropFilter: "blur(8px)",
                color: "white",
                px: 0.5,
                fontSize: { xs: "12px", md: "13px" },
                top: { xs: "8px", md: "12px" },
                left: { xs: "8px", md: "12px" },
              }}
            />
            // <img
            //   alt="new tag"
            //   src={NewTag}
            //   style={{
            //     width: 38,
            //     height: 24,
            //     position: "absolute",
            //     top: 5,
            //     left: 5,
            //   }}
            // />
          )}
          <Box
            className={`${hoverStates[item.id] ? classes.showAddIcon : ""}`}
            sx={{
              opacity: 0,

              position: "absolute",
              top: "12px",
              right: "12px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedProduct(item);
              setOpen(true);
            }}
          >
            <img
              alt="add icon"
              src={AddIcon}
              style={{ width: 20, height: 20 }}
            />
          </Box>
        </Paper>
        {open && (
          <SelectProductModal
            open={open}
            onClose={() => {
              setIconShow(false);
              setOpen(false);
              setSelectedProduct(null);
            }}
            url={selectedProduct?.url}
            selectedProduct={selectedProduct}
          />
        )}
      </Box>
    </React.Fragment>
  );
}

SearchPaper.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isPremium: PropTypes.bool,
    price: PropTypes.arrayOf(PropTypes.string),
    imageURL: PropTypes.string,
    url: PropTypes.string,
    category: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default SearchPaper;
