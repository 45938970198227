import { createSlice } from '@reduxjs/toolkit';
import { orderCreate, orderCreateReset } from './orderAsyncThunk';

const orderCreateSlice = createSlice({
  name: "order",
  initialState: {
    isLoading: false,
    successCode: 0,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [orderCreate.pending]: (state, action) => {
      state.isLoading = true;
      state.successCode = 0;
      state.isFail = false;
    },
    [orderCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.successCode = action.payload.id;
    },
    [orderCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
    [orderCreateReset]: (state, action) => {
      state.isLoading = false;
      state.successCode = 0;
      state.isFail = false;
    },
  },
});

export default orderCreateSlice.reducer;