import React from "react";
import { useSelector } from "react-redux";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";
import ManufacturerJoin from "../features/manufacturer/ManufacturerJoin";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  footer_title_colour,
  footer_secondary_colour,
  footer_background_colour,
} from "./styles/materialUIStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import CountryFlag from "react-country-flag";

const TypographyContent = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "baseline",
  alignItems: "baseline",
  color: footer_title_colour,
  fontSize: 16,
  [theme.breakpoints.up("sm")]: { fontSize: 30 },
  [theme.breakpoints.up("md")]: { fontSize: 36 },
  [theme.breakpoints.up("lg")]: { fontSize: 42 },
}));

const TypographyHeading = styled(Typography)(({ theme }) => ({
  display: "flex",
  color: footer_secondary_colour,
  marginBottom: theme.spacing(1),
}));

const TypographyNavigation = styled(Typography)(({ theme }) => ({
  display: "flex",
  color: footer_title_colour,
  marginBottom: theme.spacing(0.5),
}));

const TypographySecondary = styled(Typography)(({ theme }) => ({
  display: "flex",
  color: footer_title_colour,
}));

const StyledCountryFlag = styled(CountryFlag)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2),
  alignSelf: "center",
}));

export default function BottomAppBar() {
  const authUserInfo = useSelector(selectAuthUserInfo);
  const { countryCode, countryName } = authUserInfo;
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} id="footer">
        <AppBar
          position="static"
          sx={{
            top: "auto",
            bottom: 0,
            background: footer_background_colour,
            mt: { xs: 2, sm: 3, md: 12, lg: 12, lg: 12 },
            pt: { xs: 2, sm: 3, md: 12, lg: 12, lg: 12 },
            px: { xs: 2, sm: 4, md: 8 },
            pb: "200px !important",
            boxShadow: "none",
          }}
        >
          <Box sx={{ display: "grid" }}>
            <Toolbar>
              <Grid
                container
                rowSpacing={{ xs: 2, sm: 4, md: 6 }}
                columnSpacing={0}
                sx={{ my: 2, mx: 0 }}
              >
                <Grid item xs={12} sm={4}>
                  {/* <TypographyContent variant="body2" noWrap sx={{ mb: 3 }}>
                        <Box component="span" mr={1}>
                          <StyledMatterBaseLogo fill={footer_title_colour} />
                        </Box>
                        <StyledStrong>Matterbase</StyledStrong>
                      </TypographyContent> */}
                  <TypographyContent variant="body2" noWrap>
                    <Link
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      color="inherit"
                      target="_blank"
                      rel="opener"
                      underline="hover"
                    >
                      <svg
                        width="13"
                        height="22"
                        viewBox="0 0 13 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.34783 13.8H11.3292L12.5217 9H8.34783V6.6C8.34783 5.364 8.34783 4.2 10.7329 4.2H12.5217V0.168C12.133 0.1164 10.6649 0 9.11463 0C5.87687 0 3.57764 1.9884 3.57764 5.64V9H0V13.8H3.57764V24H8.34783V13.8Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                    <Link
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      color="inherit"
                      target="_blank"
                      rel="opener"
                      underline="hover"
                      sx={{ mx: 3 }}
                    >
                      <svg
                        width="20"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                    <Link
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      color="inherit"
                      target="_blank"
                      rel="opener"
                      underline="hover"
                    >
                      <svg
                        width="18"
                        height="22"
                        viewBox="0 0 18 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.5625 0C4.75275 0 0 3.20625 0 8.3955C0 11.6955 1.85625 13.5705 2.98125 13.5705C3.4455 13.5705 3.7125 12.2767 3.7125 11.9115C3.7125 11.4757 2.60175 10.5472 2.60175 8.733C2.60175 4.96425 5.4705 2.292 9.183 2.292C12.375 2.292 14.7375 4.10625 14.7375 7.43925C14.7375 9.9285 13.7393 14.5972 10.5045 14.5972C9.3375 14.5972 8.3385 13.7535 8.3385 12.5445C8.3385 10.7722 9.576 9.057 9.576 7.2285C9.576 4.12575 5.17425 4.68825 5.17425 8.43825C5.17425 9.22575 5.2725 10.0973 5.62425 10.815C4.977 13.5998 3.6555 17.748 3.6555 20.6168C3.6555 21.5025 3.78225 22.3747 3.86625 23.2605C4.02525 23.439 3.94575 23.4202 4.1895 23.331C6.552 20.0962 6.468 19.464 7.53675 15.231C8.1135 16.3283 9.60375 16.9185 10.785 16.9185C15.7627 16.9185 17.9993 12.0667 17.9993 7.6935C17.9993 3.039 13.977 0.0015 9.56175 0.0015L9.5625 0Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </TypographyContent>
                  <TypographySecondary variant="body2" noWrap>
                    Your location: {countryName}
                    <StyledCountryFlag countryCode={countryCode} svg />
                  </TypographySecondary>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TypographyHeading variant="body2" noWrap>
                    Search
                  </TypographyHeading>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Materials
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Hardware
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Furnitures
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Brand
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Projects
                    </Link>
                  </TypographyNavigation>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TypographyHeading variant="body2" noWrap>
                    Learn more
                  </TypographyHeading>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      About us
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      How it works
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Substainability
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Q&amp;A
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Careers
                    </Link>
                  </TypographyNavigation>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <TypographyHeading variant="body2" noWrap>
                    Policy
                  </TypographyHeading>
                  <TypographyNavigation variant="body2">
                    <Link
                      href="/Terms and conditions of sale.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      underline="hover"
                    >
                      Terms and Conditions of Sale
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2">
                    <Link
                      href="/Terms and Conditions.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      underline="hover"
                    >
                      Terms and Conditions
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2">
                    <Link
                      href="/Privacy Statement.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      underline="hover"
                    >
                      Privacy Statement
                    </Link>
                  </TypographyNavigation>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TypographyHeading variant="body2" noWrap>
                    Professional
                  </TypographyHeading>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Advertise
                    </Link>
                  </TypographyNavigation>
                  <TypographyNavigation variant="body2" noWrap>
                    <Link
                      href="/comingSoon"
                      onClick={(e) => e.preventDefault}
                      color="inherit"
                      underline="hover"
                    >
                      Press and media
                    </Link>
                  </TypographyNavigation>
                  <ManufacturerJoin />
                </Grid>
              </Grid>
            </Toolbar>
          </Box>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
