import React, { useState, useEffect } from "react";
import productGroupColumns from "../../components/types/productGroup";
import ProductGroupCreate from "./ProductGroupCreate";
import ProductGroupManage from "./ProductGroupManage";
import ProductGroupDelete from "./ProductGroupDelete";
import { useSelector, useDispatch } from "react-redux";
import {
  productGroupList,
  selectProductGroupCreate,
  selectProductGroupList,
  selectProductGroupManage,
  selectProductGroupDelete,
} from "./productGroupAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProductGroupManageGrid() {
  const dispatch = useDispatch();
  const [productGroupArray, setProductGroupArray] = useState([]);
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
  });
  const productGroupListStore = useSelector(selectProductGroupList);
  const { payload } = productGroupListStore;
  const productGroupManageStore = useSelector(selectProductGroupManage);
  const { isSuccess: productGroupManageSuccess } = productGroupManageStore;
  const productGroupCreateStore = useSelector(selectProductGroupCreate);
  const { isSuccess: productGroupCreateSuccess } = productGroupCreateStore;
  const productGroupDeleteStore = useSelector(selectProductGroupDelete);
  const { isSuccess: productGroupDeleteSuccess } = productGroupDeleteStore;

  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = productGroupArray.findIndex((x) => x.id === selected[0]);
      setEditButtonProps({
        isSelected: true,
        data: { ...productGroupArray[index] },
      });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
          created_at: new Date(),
          updated_at: new Date(),
        },
      });
    }
  };

  useEffect(() => {
    dispatch(productGroupList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productGroupManageSuccess,
    productGroupCreateSuccess,
    productGroupDeleteSuccess,
  ]);

  useEffect(() => {
    let productGroup = [...payload];
    let list = [];
    if (productGroup.length > 0) {
      for (let i = 0; i < productGroup.length; i++) {
        let item = {
          ...productGroup[i],
          created_at: new Date(productGroup[i].created_at),
          updated_at: new Date(productGroup[i].updated_at),
        };
        list.push(item);
      }
      setProductGroupArray(list);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Typography>Product Group Management</Typography>
      <ProductGroupCreate />
      {productGroupArray[0] && <ProductGroupManage {...editButtonProps} />}
      {productGroupArray[0] && <ProductGroupDelete {...editButtonProps} />}
      {productGroupArray[0] && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={productGroupArray}
                columns={productGroupColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
