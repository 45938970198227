import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectProductSearch,
  setCursor,
} from "../features/product/productAsyncThunk";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";

import Link from "@mui/material/Link";
import SearchPaper from "./SearchPaper";
import { StyledTextField } from "../components/CustomComponents";
import { landing_page_background_colour } from "./styles/materialUIStyles";
import ProductFilterNotFound from "./ProductFilterNotFound";
import { Autocomplete, Paper, autocompleteClasses } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useThrottledHover from "./customHooks/throttleHoverState";
import { useTheme } from "@mui/material/styles";

const BoxRoot = styled(Box)(({ theme }) => ({
  backgroundColor: landing_page_background_colour,
  // margin: theme.spacing(1, 0),
  "& p + *": {
    margin: theme.spacing(1, 0),
  },
}));

function SearchGrid({ setSearchRefinerHeight }) {
  const location = useLocation();
  const productSearchStore = useSelector(selectProductSearch);
  const {
    isLoading: productSearchLoading,
    isSuccess: productSearchSuccess,
    failCode: productSearchFail,
    count,
    list: readOnlyList,
  } = productSearchStore;
  const material = [...readOnlyList];
  const resultPerPage = 50;
  const numOfPage = Math.ceil(count / resultPerPage);
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState((page - 1) * resultPerPage);
  const [lastIndex, setLastIndex] = useState(
    Math.min(page * resultPerPage - 1, count - 1)
  );
  const [list, setList] = useState([]);
  const sortOption = [
    {
      id: 0,
      title: "Sort By Date (New)",
    },
    {
      id: 1,
      title: "Sort By Date (Old)",
    },
    {
      id: 2,
      title: "Sort By Name (A-Z)",
    },
    {
      id: 3,
      title: "Sort By Name (Z-A)",
    },
    // {
    //   id: 4,
    //   title: "Sort By Price ($-$$$)",
    // },
    // {
    //   id: 5,
    //   title: "Sort By Price ($$$-$)",
    // },
  ];
  const sortByDateAsc = (a, b) => {
    var nameA = a.created_at.toUpperCase(); // ignore upper and lowercase
    var nameB = b.created_at.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // dates must be equal
    return 0;
  };
  const sortByDateDesc = (a, b) => {
    var nameA = a.created_at.toUpperCase(); // ignore upper and lowercase
    var nameB = b.created_at.toUpperCase(); // ignore upper and lowercase
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    // dates must be equal
    return 0;
  };
  const sortByNameAsc = (a, b) => {
    var nameA = a.subtitle.toUpperCase(); // ignore upper and lowercase
    var nameB = b.subtitle.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  };
  const sortByNameDesc = (a, b) => {
    var nameA = a.subtitle.toUpperCase(); // ignore upper and lowercase
    var nameB = b.subtitle.toUpperCase(); // ignore upper and lowercase
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  };
  const sortByPriceAsc = (a, b) => {
    if (Boolean(a.price) && Boolean(b.price) === true) {
      // Both a.price and b.price are not null
      return a.price.join(",").localeCompare(b.price.join(","));
    } else {
      if (Boolean(a.price) === false) {
        // if a.price is null
        return -1; // swap b in front of a
      } else {
        return 1; // swap a in front of b
      }
    }
  };
  const sortByPriceDesc = (a, b) => {
    if (Boolean(a.price) && Boolean(b.price) === true) {
      // Both a.price and b.price are not null
      return a.price.join(",").localeCompare(b.price.join(",")) * -1;
    } else {
      if (Boolean(a.price) === false) {
        // if a.price is null
        return -1; // swap b in front of a
      } else {
        return 1; // swap a in front of b
      }
    }
  };
  const sortID = Number(sessionStorage.getItem("sortID"));
  const [sort, setSort] = useState(sortOption[sortID]);

  const { pathname } = location;
  const params = pathname
    .toLowerCase()
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "search");
  const expectedMethod = params[0];
  const expectedKeyword = params.slice(1).join(" ");
  const breadcrumbString = pathname
    .split("/")
    .filter((x) => Boolean(x) === true)
    .filter((x) => x !== "search")
    .slice(1)
    .join(" ");

  const handleSort = (_, value) => {
    setSort(value);
    sessionStorage.setItem("sortID", value.id);
  };

  const handleChange = (event, value) => {
    setPage(value);
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView();
    }
  };

  useEffect(() => {
    switch (sort.id) {
      case 0:
        Array.isArray(material) && material.sort(sortByDateDesc);
        break;
      case 1:
        Array.isArray(material) && material.sort(sortByDateAsc);
        break;
      case 2:
        Array.isArray(material) && material.sort(sortByNameAsc);
        break;
      case 3:
        Array.isArray(material) && material.sort(sortByNameDesc);
        break;
      case 4:
        Array.isArray(material) && material.sort(sortByPriceAsc);
        break;
      case 5:
        Array.isArray(material) && material.sort(sortByPriceDesc);
        break;
      default:
        break;
    }
    const firstInd = (page - 1) * resultPerPage;
    const lastInd = Math.min(page * resultPerPage - 1, count - 1);
    setFirstIndex(firstInd);
    setLastIndex(lastInd);
    setList(
      Array.isArray(material)
        ? material.filter((_, i) => i >= firstInd && i <= lastInd)
        : []
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, productSearchSuccess, count]);

  // const boxRef = useCallback(
  //   (node) => {
  //     if (node !== null) {
  //       if (Number(node.clientHeight) > 0) {
  //         setSearchRefinerHeight(
  //           Number(node.clientHeight) > 800 ? node.clientHeight : "80vh"
  //         );
  //       }
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },
  //   [list]
  // );

  const customPaper = (props) => (
    <Paper
      {...props}
      sx={{
        borderRadius: "8px",
      }}
    />
  );

  // const { hoverStates, handleMouseEnter, handleMouseLeave } = useThrottledHover(
  //   list.map((mediaCard) => mediaCard.id),
  //   500
  // );

  return (
    <BoxRoot>
      {productSearchLoading && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "24px",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {Boolean(productSearchFail) === true && (
        <>
          <ProductFilterNotFound />
          <Typography
            color="primary.main"
            // variant="h5"
            align="left"
            noWrap
            sx={{
              fontSize: { xs: "18px", md: "20px" },
              // cursor: "pointer",
              // "&:hover": { textDecoration: "underline" },
            }}
          >
            <b>Trending Material</b>
          </Typography>
          <Grid item xs>
            <Grid container spacing={{ xs: "8px", sm: "12px" }} sx={{ my: 1 }}>
              {Array.isArray(list) &&
                list.slice(0, 10).map((item, i) => (
                  <Grid key={i} item xs={6} sm={12 / 3} lg={3} xl={12 / 5}>
                    <SearchPaper item={item} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </>
      )}
      {productSearchSuccess && count < 1 && (
        <>
          <ProductFilterNotFound />
          <Typography
            color="primary.main"
            // variant="h5"
            align="left"
            noWrap
            sx={{
              fontSize: { xs: "18px", md: "20px" },
              // cursor: "pointer",
              // "&:hover": { textDecoration: "underline" },
            }}
          >
            <b>Trending Material</b>
          </Typography>
          <Grid item xs>
            <Grid container spacing={{ xs: "8px", sm: "12px" }} sx={{ my: 1 }}>
              {Array.isArray(list) &&
                list.slice(0, 10).map((item, i) => (
                  <Grid key={i} item xs={6} sm={12 / 3} lg={3} xl={12 / 5}>
                    <SearchPaper item={item} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </>
      )}
      {productSearchSuccess && count > 0 && (
        <Grid>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {expectedMethod === "keyword" && (
              <Box sx={{ textAlign: "start" }}>
                <Typography
                  variant="title_primary_grey"
                  sx={{ mx: 1, fontSize: "20px", textTransform: "capitalize" }}
                >
                  Keyword search:
                  {
                    expectedMethod === "keyword" && ` ${expectedKeyword}`
                    // expectedMethod === "keyword" ?
                    //   `Keyword = ${expectedKeyword}` :
                    //   expectedKeyword.slice(
                    //     expectedKeyword.indexOf("->") === -1 ?
                    //       0 :
                    //       expectedKeyword.lastIndexOf("->") + 2
                    //   )
                  }
                </Typography>
              </Box>
            )}
            {expectedMethod !== "keyword" && (
              <Breadcrumbs
                aria-label="breadcrumb"
                sx={{
                  paddingBottom: { xs: "8px", sm: "0px" },
                  textAlign: "start",
                }}
              >
                {breadcrumbString.split("->").map((x, i, arr) => {
                  const isLastItem = i === arr.length - 1;

                  return isLastItem ? (
                    <Typography
                      key={i}
                      color={"inherit"}
                      sx={{
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {x}
                    </Typography>
                  ) : (
                    <Link
                      component={RouterLink}
                      key={i}
                      color={"inherit"}
                      sx={{
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      to={`/search/category/${breadcrumbString
                        .split("->")
                        .slice(0, i + 1)
                        .join("->")}`}
                      underline="hover"
                    >
                      {x}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            )}
            <Box
              sx={{
                paddingBottom: { xs: "4px", sm: "0px" },
              }}
            >
              <Autocomplete
                disableClearable
                size="small"
                options={sortOption}
                getOptionLabel={(option) => option.title || ""}
                value={sort}
                placeholder="Sort By Date"
                PaperComponent={customPaper}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    sx={{
                      maxWidth: 200,
                      width: 200,
                      "& .MuiAutocomplete-inputRoot": {
                        borderRadius: "50px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  />
                )}
                onChange={handleSort}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                ListboxProps={{
                  sx: {
                    [`& .${autocompleteClasses["option"]}[aria-selected="true"]`]:
                      {
                        textAlign: "left",
                      },
                  },
                }}
                popupIcon={
                  <KeyboardArrowDownIcon
                    sx={{
                      stroke: "#181818",
                      strokeOpacity: "0.1",
                      strokeWidth: "0",
                    }}
                  />
                }
              />
            </Box>
          </Box>

          <Grid item xs sx={{ my: 1 }}>
            <Grid container spacing={{ xs: "8px", sm: "12px" }} sx={{ my: 1 }}>
              {Array.isArray(list) &&
                list.map((item, i) => (
                  <Grid key={i} item xs={6} sm={12 / 3} lg={3} xl={12 / 5}>
                    <SearchPaper
                      item={item}
                      // handleMouseEnter={handleMouseEnter(item.id)}
                      // handleMouseLeave={handleMouseLeave(item.id)}
                      // hovered={hoverStates[item]}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs sx={{ my: expectedMethod === "keyword" ? 12 : 1 }}>
            <Grid container alignItems="flex-end" spacing={1} sx={{ my: 1 }}>
              <Grid
                item
                xs
                sx={{ mt: 3, mb: 1, flexBasis: "100%", textAlign: "center" }}
              >
                <Typography>
                  Showing {firstIndex + 1} to {lastIndex + 1} of {count}
                </Typography>
                <Pagination
                  sx={{ display: "inline-flex" }}
                  count={numOfPage}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </BoxRoot>
  );
}

SearchGrid.propTypes = {
  setSearchRefinerHeight: PropTypes.func.isRequired,
};

export default SearchGrid;
