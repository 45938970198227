import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListCountry,
  catalogListSupplier,
  selectCatalogListCountry,
  selectCatalogListSupplier,
} from "../catalog/catalogAsyncThunk";
import { brandCreate } from "./brandAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import SinglePreviewImageList from "../../components/SinglePreviewImageList";
import { useSnackbar } from "notistack";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlueButton, BlackButton } from "../../components/CustomButtons";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML, convertFromHTML } from "draft-convert";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const ImgBrandLogo = styled("img")(({ theme }) => ({
  width: theme.spacing(10),
  objectFit: "cover",
}));

const ImgBrandBanner = styled("img")(({ theme }) => ({
  width: theme.spacing(20),
  objectFit: "cover",
}));

export default function BrandCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const catalogListCountryStore = useSelector(selectCatalogListCountry);
  const { isLoading: countryListLoading, payload: countryList } =
    catalogListCountryStore;
  const catalogListSupplierStore = useSelector(selectCatalogListSupplier);
  const {
    isLoading: supplierListLoading,
    isFail: supplierListFail,
    payload: supplierList,
  } = catalogListSupplierStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);

  const [editorUpIntro, setEditorUpIntro] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorAbtract, setEditorAbtract] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorDownIntro, setEditorDownIntro] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState("");
  const [convertedContent2, setConvertedContent2] = useState("");
  const [convertedContent3, setConvertedContent3] = useState("");
  const [imagesValidation, setImageValidate] = useState("");
  const [values, setValues] = useState({
    name: "",
    country_id: 0,
    country_object: {},
    website: "",
    logoURL: "",
    bannerURL: "",
    supplier_id: 0,
    supplier_object: {},
    leftIntro: "",
    rightIntro: "",
    image_ids: [],
    image_objects: [],
    isSupplier: false,
    isHKOffice: false,
    isShowInLandingPage: false
  });
  const [showHint, setShowHint] = useState(false);
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorUpIntro.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const convertContentToHTML2 = () => {
    let currentContentAsHTML = convertToHTML(
      editorDownIntro.getCurrentContent()
    );
    setConvertedContent2(currentContentAsHTML);
  };

  const convertContentToHTML3 = () => {
    let currentContentAsHTML = convertToHTML(editorAbtract.getCurrentContent());
    setConvertedContent3(currentContentAsHTML);
  };

  const handleEditorChange = (state) => {
    setEditorUpIntro(state);
    convertContentToHTML();
  };
  const handleEditorChange2 = (state) => {
    setEditorDownIntro(state);
    convertContentToHTML2();
  };
  const handleEditorChange3 = (state) => {
    setEditorAbtract(state);
    convertContentToHTML3();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    if (prop === "country_object") newObj.country_id = newValue.id;
    if (prop === "supplier_object") newObj.supplier_id = newValue.id;
    setValues(newObj);
  };

  const handleImageAdd = (image) => {
    let image_ids = [...values.image_ids];
    let image_objects = [...values.image_objects];
    image_ids.push(image.id);
    image_objects.push(image);
    setValues({ ...values, image_ids, image_objects });
  };

  const handleImageRemove = (index) => (event) => {
    let image_ids = [...values.image_ids];
    let image_objects = [...values.image_objects];
    image_ids.splice(index, 1);
    image_objects.splice(index, 1);
    if (image_ids.length > 0) {
      setValues({ ...values, image_ids, image_objects });
    } else {
      enqueueSnackbar("At least 1 picture required!", {
        message: "info",
        autoHideDuration: 3000,
      });
    }
  };

  const handleImageMoveLeft = (index) => (event) => {
    let image_ids = [...values.image_ids];
    let image_objects = [...values.image_objects];
    const swap_id = image_ids.splice(index, 1);
    const swap_object = image_objects.splice(index, 1);
    image_ids.splice(Math.abs(index - 1), 0, ...swap_id);
    image_objects.splice(Math.abs(index - 1), 0, ...swap_object);
    if (image_ids.length > 0) {
      setValues({ ...values, image_ids, image_objects });
    } else {
      enqueueSnackbar("At least 1 picture required!", {
        message: "info",
        autoHideDuration: 3000,
      });
    }
  };
  const handleSubmit = () => {
    const params = {
      ...values,
      leftIntro: convertedContent.toString(),
      rightIntro: convertedContent2.toString(),
      abtract: convertedContent3.toString(),
    };

    setIsUserActionMade(true);
    if (Boolean(params.name) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.website) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.leftIntro) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.rightIntro) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.logoURL) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.bannerURL) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(params.image_ids.length !== 3)) {
      setShowHint(true);
      setImageValidate("Upload atleast 3 images !");
      return;
    }
    setShowHint(false);
    dispatch(brandCreate(params));
  };

  useEffect(() => {
    dispatch(catalogListCountry());
    dispatch(catalogListSupplier());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryList[99])
      setValues({
        ...values,
        country_object: { ...countryList[99] },
        country_id: countryList[99].id,
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList]);

  useEffect(() => {
    if (supplierList[0])
      setValues({
        ...values,
        supplier_object: { ...supplierList[0] },
        supplier_id: supplierList[0].id,
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierList]);

  return (
    <React.Fragment>
      <BlueButton
        variant="contained"
        color="primary"
        sx={{ m: 1 }}
        onClick={handleClickOpen}
        disabled={supplierListLoading || supplierListFail}
      >
        {supplierListFail
          ? "Create a Supplier first before the Brand!"
          : "Create Brand"}
      </BlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-brand"
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <StyledDialogTitle id="form-dialog-create-brand" onClose={handleClose}>
          Create New Brand
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            autoFocus
            required
            sx={{ mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.name) === false}
            defaultValue={values.name}
            onChange={handleValues("name")}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            placeholder="Name"
            fullWidth
          />
          <Autocomplete
            id="country"
            options={countryList}
            loading={countryListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.country_object}
            onChange={handleAutoCompleteValues("country_object")}
            fullWidth
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Country"
                margin="dense"
                sx={{ mb: 1.5, mt: 0 }}
                size="small"
                variant="outlined"
              />
            )}
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.website) === false}
            defaultValue={values.website}
            onChange={handleValues("website")}
            variant="outlined"
            margin="dense"
            size="small"
            id="website"
            placeholder="Website"
            sx={{ mb: 1.5, mt: 0 }}
            fullWidth
          />
          <Autocomplete
            id="supplier"
            options={supplierList}
            loading={supplierListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.supplier_object}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            onChange={handleAutoCompleteValues("supplier_object")}
            fullWidth
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Supplier"
                margin="dense"
                size="small"
                sx={{ mb: 1.5, mt: 0 }}
                variant="outlined"
              />
            )}
          />
          <Editor
            editorState={editorUpIntro}
            onEditorStateChange={handleEditorChange}
            placeholder="Introduction (Up)"
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <hr />
          <Editor
            editorState={editorDownIntro}
            placeholder="Introduction (Down)"
            onEditorStateChange={handleEditorChange2}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <Editor
            editorState={editorAbtract}
            placeholder="Brand abstract"
            onEditorStateChange={handleEditorChange3}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <hr />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isSupplier}
                onChange={(e) =>
                  setValues({ ...values, isSupplier: e.target.checked })
                }
                name="isSupplier"
                color="primary"
                inputProps={{
                  "aria-label": "Is Supplier",
                }}
              />
            }
            label="Is Supplier"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isHKOffice}
                onChange={(e) =>
                  setValues({ ...values, isHKOffice: e.target.checked })
                }
                name="isHKOffice"
                color="primary"
                inputProps={{
                  "aria-label": "Hong Kong Office",
                }}
              />
            }
            label="Hong Kong Office"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isShowInLandingPage}
                onChange={(e) =>
                  setValues({ ...values, isShowInLandingPage: e.target.checked })
                }
                name="isShowInLandingPage"
                color="primary"
                inputProps={{
                  "aria-label": "Is Show In Landing Page",
                }}
              />
            }
            label="Is Show In Landing Page"
          />
          <ImageCreate
            label="Brand Logo URL"
            error={isUserActionMade && Boolean(values.logoURL) === false}
            onFinish={(image) => setValues({ ...values, logoURL: image.url })}
          />
          {values.logoURL && (
            <ImgBrandLogo src={values.logoURL} alt="Brand Logo" />
          )}
          <ImageCreate
            label="Brand Banner URL"
            error={isUserActionMade && Boolean(values.bannerURL) === false}
            onFinish={(image) => setValues({ ...values, bannerURL: image.url })}
          />
          {values.bannerURL && (
            <ImgBrandBanner src={values.bannerURL} alt="Brand Banner" />
          )}
          <ImageCreate
            label="Image List"
            disabled={values.image_ids.length > 2 ? true : false}
            error={isUserActionMade && values.image_ids.length < 1}
            onFinish={handleImageAdd}
          />
          <SinglePreviewImageList
            image_objects={values.image_objects}
            onRemove={handleImageRemove}
            onLeft={handleImageMoveLeft}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(1, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Create Brand
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops!{" "}
            {imagesValidation
              ? imagesValidation
              : "Something is still wrong above."}
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}
