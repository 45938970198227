import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  brandGetLandingPage3,
  selectBrandGetLandingPage3,
} from "../features/brand/brandAsyncThunk";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { landing_page_background_colour } from "./styles/materialUIStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import Slider from "../components/Slider";
import { WhiteButton, RBlackButton, PrimaryRoundButton } from "./CustomButtons";
import { autocompleteClasses } from "@mui/material";
import AspectRatioBox from "../components/AspectRatioBox";
import { Skeleton } from "@mui/material";

const StyledImg = styled("img")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  display: "block",
  alignSelf: "center",
}));
const StyledContainer = styled(Container)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  zIndex: theme.zIndex.mobileStepper - 1,
  color: "#fff",
  background: "linear-gradient(to top, rgba(0,0,0,0.25), rgba(0,0,0,0)60%)",
  paddingBottom: "16px",
  paddingLeft: "16px",
  [theme.breakpoints.up("sm")]: { paddingLeft: "32px", paddingBottom: "32px" },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "48px",
    paddingBottom: "64px",

    background: "linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0)60%)",
  },

  height: "100%",
}));

const TypographyText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontSize: 24,
  [theme.breakpoints.up("sm")]: { fontSize: 36 },
  [theme.breakpoints.up("md")]: { fontSize: 48 },
  [theme.breakpoints.up("lg")]: { fontSize: 60 },
}));
export default function LandingPageBrand() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const brandGetLandingPage3Store = useSelector(selectBrandGetLandingPage3);
  const { payload } = brandGetLandingPage3Store;
  const [item, setItem] = useState({});
  const [product, setProduct] = useState([]);
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleNavigation = (item) => {
    if (isMdDown) {
      history.push(
        `/search/category/Materials/?brand=${encodeURIComponent(item.id)}`
      );
    }
  };

  useEffect(() => {
    dispatch(brandGetLandingPage3("Spaghetti Wall"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  useEffect(() => {
    if (Boolean(payload.id) === true) {
      setItem({ ...payload });
      const productList = [...payload.product];
      // const randomOrder = Array.from({ length: productList.length }, (_, i) => i)
      //   .sort(() => 0.5 - Math.random())
      //   .slice(0, 6);
      // setProduct(productList.filter((_, i) => randomOrder.includes(i)));
      const randomOrder = Array.from(
        { length: productList.length },
        (_, i) => i
      ).sort(() => 0.5 - Math.random());
      setProduct(randomOrder.map((i) => productList[i]));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <React.Fragment>
      <React.Fragment>
        <Box
          sx={{
            mx: { xs: 0, md: 6 },
            mt: { xs: 4, md: 6 },
          }}
        >
          <AspectRatioBox
            ratio={2 / 1}
            style={{ minHeight: "320px", maxHeight: "640px" }}
          >
            {Boolean(item.id) === true ? (
              <Box
                onClick={() => handleNavigation(item)}
                sx={{
                  position: "relative",
                  width: "100%",
                  borderRadius: { xs: "0px", md: "8px" },
                  overflow: "hidden",
                }}
              >
                <StyledImg src={item.bannerURL} alt={item.brand_name} />
                <StyledContainer maxWidth={false}>
                  <Link
                    component={RouterLink}
                    to={item.url}
                    sx={{
                      textDecoration: "none",
                    }}
                  >
                    <TypographyText
                      sx={{
                        fontSize: { xs: "36px", sm: "42px", md: "48px" },
                        fontWeight: "600",
                        color: "white",
                        cursor: "pointer",
                        my: 0,
                        transition: "color 200ms",

                        "&:hover": { color: "rgb(235, 235, 235)" },
                      }}
                      // onClick={() => history.push(item.url)}
                      noWrap
                    >
                      {item.brand_name}
                    </TypographyText>
                  </Link>
                  <TypographyText
                    sx={{
                      fontSize: "18px !important",
                      color: "white",
                      fontWeight: "500",
                      marginTop: "0",
                      marginBottom: { xs: 0, md: 4 },
                      width: { sm: "100%", md: "50%", lg: "40%" },
                      "& p": {
                        height: "auto",
                        margin: 0,
                      },
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={createMarkup(item.abtract)}
                    ></div>
                  </TypographyText>
                  <Link
                    component={RouterLink}
                    to={`/search/category/Materials/?brand=${encodeURIComponent(
                      item.id
                    )}`}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      textDecoration: "none",
                    }}
                  >
                    <PrimaryRoundButton>Browse Products</PrimaryRoundButton>
                  </Link>
                </StyledContainer>
              </Box>
            ) : (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"100%"}
                sx={{
                  borderRadius: { xs: "0px", md: "8px" },
                }}
              />
            )}
          </AspectRatioBox>
          <TypographyText
            sx={{
              fontSize: "15px !important",
              color: "text.secondary",
              fontWeight: "500",
              marginTop: "0",
              marginBottom: 2,
              textAlign: "left",
              paddingTop: "8px",
              paddingLeft: { xs: "16px", sm: "32px" },
              width: "100%",
              "& p": {
                height: "auto",
                margin: 0,
              },
              display: { xs: "flex", sm: "none" },
            }}
          >
            <div dangerouslySetInnerHTML={createMarkup(item.abtract)}></div>
          </TypographyText>
        </Box>

        {product && product[0] && (
          <Box>
            <Box
              sx={{
                pl: { xs: 2, sm: 4, md: 6 },
                pt: {
                  xs: 4,
                  md: 6,
                },
                pb: {
                  xs: 1,
                  md: 2,
                },
                alignItems: "center",
                display: "flex",
              }}
            >
              <Link
                component={RouterLink}
                to={`/search/category/Materials/?brand=${encodeURIComponent(
                  item.id
                )}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <Typography
                  color="primary.main"
                  // variant="h5"
                  align="left"
                  noWrap
                  sx={{
                    fontSize: { xs: "18px", md: "20px" },
                    cursor: "pointer",
                    // "&:hover": { textDecoration: "underline" },
                  }}
                >
                  <b>{item.brand_name}</b>
                </Typography>
                <ChevronRightIcon
                  sx={{
                    fontSize: { xs: "28px", md: "32px" },
                    color: theme.palette.text.secondary,
                  }}
                />
              </Link>
            </Box>
            <Slider product={product} />
          </Box>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
