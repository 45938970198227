import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const imageCreate = createAsyncThunk(
  "image/create",
  async (payload, thunkAPI) => {
    const { label, ...values } = payload;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/image/`,
        values
      );
      return { ...data, label };
    } catch (err) {
      const data = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const imageCreateReset = createAction("image/create");

const toBase64 = (file) => new Promise(
  (resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }
);

export const imageSearch = createAsyncThunk(
  "image/search",
  async (file, thunkAPI) => {
    try {
      const base64 = await toBase64(file);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/image/label`,
        { base64 }
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const imageSearchReset = createAction("image/search");

export const selectImageCreate = (state) => state.imageCreate;
export const selectImageSearch = (state) => state.imageSearch;
