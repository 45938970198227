import React, { useRef, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import placeholderLogo from "./img/no_image_logo.png";
import MenuIcon from "./img/Menu";
import { useDispatch } from "react-redux";
import {
  activeProjectList,
  projectDelete,
  updateProjectStatus,
} from "../features/project/projectAsyncThunk";

export default function ArchiveProjectCard({ project }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const cardRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteProject = async () => {
    await dispatch(projectDelete(project?.id));

    await dispatch(activeProjectList());
  };

  return (
    <Box sx={{ position: "relative", cursor: "pointer" }}>
      <Box>
        {project.project_thumbnail ? (
          <Box
            sx={{
              height: { lg: 202, md: 198, sm: 204, xs: 198 },
              borderRadius: "8px",
              "&:hover": {
                border: 1,
              },
            }}
          >
            <Card
              ref={cardRef}
              sx={{
                width: "100%",
                borderRadius: 0,
              }}
            >
              <CardMedia
                height={"100%"}
                component="img"
                alt={"Project Thumbnail"}
                image={project.project_thumbnail || placeholderLogo}
                title={"Project Thumbnail"}
                sx={{
                  height: { lg: 202, md: 198, sm: 204, xs: 198 },
                  borderRadius: "8px",
                }}
              />
            </Card>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "#C4C4C4",
              height: { lg: 202, md: 198, sm: 204, xs: 198 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "8px",
              "&:hover": {
                border: 1,
              },
            }}
          ></Box>
        )}
        <Typography
          textAlign={"left"}
          sx={{ fontSize: 20, mt: 2, fontWeight: 600 }}
        >
          {project.project_name}
        </Typography>
      </Box>
      {project.role === "Owner" && (
        <Box>
          <Box
            sx={{ position: "absolute", right: 12, top: 8, cursor: "pointer" }}
            onClick={handleClick}
          >
            <MenuIcon />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                dispatch(
                  updateProjectStatus({
                    id: project?.id,
                    status: "active",
                  })
                );
                window.location.href = `/account`;
              }}
            >
              UnArchive
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteProject();
                handleClose();
                window.location.href = `/account`;
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}
