import { createSlice } from "@reduxjs/toolkit";
import { similarCategoryProducts } from "./matterboardAsyncThunk";

const similarCategoryProductsSlice = createSlice({
  name: "matterboard",
  initialState: {
    payload: [],
    isSimilarCategoryProducts: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [similarCategoryProducts.pending]: (state, action) => {
      state.isSimilarCategoryProducts = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [similarCategoryProducts.fulfilled]: (state, action) => {
      state.isSimilarCategoryProducts = false;
      state.isSuccess = true;
      state.payload = action.payload.data.result ?? [];
    },
    [similarCategoryProducts.rejected]: (state, action) => {
      state.isSimilarCategoryProducts = false;
      state.isFail = true;
    },
  },
});

export default similarCategoryProductsSlice.reducer;
