import { createSlice } from '@reduxjs/toolkit';
import { orderCartEncode } from './orderAsyncThunk';

const orderCartTokenSlice = createSlice({
  name: "order",
  initialState: {
    token: undefined,
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [orderCartEncode.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [orderCartEncode.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.token = action.payload.token;
    },
    [orderCartEncode.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default orderCartTokenSlice.reducer;