import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { Box, FormControl, Grid, InputLabel, Typography } from "@mui/material";

import { BlackButton } from "../CustomButtons";

import {
  authLogout,
  authUserInfoSettings,
  authUserPasswordChangeLink,
  selectAuthUserInfo,
} from "../../features/authentication/authAsyncThunk";

import { ACCOUNT_FORM_DATA } from "../../constants/projects.constants";
import { useHistory } from "react-router-dom";
import { StyledTextField } from "../CustomComponents";

const SettingsTab = () => {
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const theme = useTheme();
  const [isUserActionMade, setIsUserActionMade] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleLogOut = () => {
    push("/");
    dispatch(authLogout());
  };

  React.useEffect(() => {
    if (userInfo) {
      setValues({
        ...values,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        email: userInfo?.email,
      });
    }
  }, [userInfo]);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleEditUser = async () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    await dispatch(
      authUserInfoSettings({
        firstName: values?.firstName,
        lastName: values?.lastName,
      })
    );
  };

  const handleSendPasswordLink = async () => {
    await dispatch(
      authUserPasswordChangeLink({
        email: values?.email,
      })
    );
  };

  return (
    <Box>
      <Typography
        sx={{ fontSize: { sm: "18px", lg: "24px" }, fontWeight: "600" }}
      >
        Account Information
      </Typography>
      <Box
        sx={{ marginTop: "1.5rem", display: "flex", flexDirection: "column" }}
      >
        <Grid container spacing={2} sx={{ marginTop: 0, marginLeft: 0 }}>
          {ACCOUNT_FORM_DATA.map((item, index) => {
            return (
              <Grid
                key={`account-form-${index}`}
                xs={12}
                sm={12}
                sx={{
                  width: "100%",
                }}
              >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      color: "#181818!important",
                    }}
                  >
                    {item.label}
                  </InputLabel>
                  <StyledTextField
                    variant="outlined"
                    required
                    InputProps={{
                      style: {
                        fontSize: 15,
                        fontWeight: "300",
                      },
                    }}
                    sx={{
                      width: { md: 448, sm: "100%" },
                      marginBottom: "1.5rem",
                      marginTop: "1.5rem",
                      backgroundColor:
                        theme.palette.mode === "light" ? "white" : "black",

                      borderWidth: 2,
                    }}
                    error={
                      isUserActionMade && Boolean(values[item.value]) === false
                    }
                    disabled={item.value === "email"}
                    value={values[item.value]}
                    onChange={handleValues(`${item.value}`)}
                    margin="dense"
                    size="medium"
                    id={item.id}
                  />
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            alignItems: "center",
          }}
        >
          <BlackButton
            sx={{
              width: { xs: "100%", sm: "138px" },
              marginRight: { xs: 0, lg: "1rem" },
              marginBottom: { xs: "1rem", lg: 0 },
              height: 55,
            }}
            variant="contained"
            color="primary"
            onClick={handleEditUser}
          >
            Save Changes
          </BlackButton>
          <BlackButton
            sx={{
              width: { xs: "100%", sm: "138px" },
              marginRight: { xs: 0, lg: "1rem" },
              marginBottom: { xs: "1rem", lg: 0 },
              height: 55,
            }}
            variant="contained"
            color="primary"
            onClick={handleSendPasswordLink}
          >
            Change Password
          </BlackButton>
          <BlackButton
            sx={{
              width: { xs: "100%", sm: "138px" },
              height: 55,
            }}
            variant="contained"
            color="primary"
            onClick={handleLogOut}
          >
            Logout
          </BlackButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsTab;
