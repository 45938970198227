import React, { useRef, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import placeholderLogo from "./img/no_image_logo.png";
import MenuIcon from "./img/Menu";
import { useDispatch } from "react-redux";
import {
  activeProjectList,
  projectDelete,
  updateProjectStatus,
} from "../features/project/projectAsyncThunk";

export default function ProjectCard({ project }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const cardRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteProject = async () => {
    await dispatch(projectDelete(project?.id));
    await dispatch(activeProjectList());
  };

  const handleGetUserName = (obj) => {
    if (obj?.admin_id) {
      return `${obj?.admin_object?.firstName
        .slice(0, 1)
        .toLocaleUpperCase()}${obj?.admin_object?.lastName
        .slice(0, 1)
        .toLocaleUpperCase()}`;
    }
    if (obj?.designer_id) {
      return `${obj?.designer_object?.firstName
        .slice(0, 1)
        .toLocaleUpperCase()}${obj?.designer_object?.lastName
        .slice(0, 1)
        .toLocaleUpperCase()}`;
    }
    if (obj?.federated_designer_id) {
      return `${obj?.federated_designer_object?.firstName
        .slice(0, 1)
        .toLocaleUpperCase()}${obj?.federated_designer_object?.lastName
        .slice(0, 1)
        .toLocaleUpperCase()}`;
    }
  };

  return (
    <Box sx={{ position: "relative", cursor: "pointer", width: "100%" }}>
      <Box
        onClick={async () => {
          localStorage.setItem("projectId", project?.id);
          window.location.href = `/project/${project?.id}`;
        }}
      >
        {project?.project_thumbnail ? (
          <Box
            sx={{
              height: { lg: 202, md: 198, sm: 204, xs: 198 },
              position: "relative",
              borderRadius: "8px",
              "&:hover": {
                border: 1,
              },
            }}
          >
            <Card
              ref={cardRef}
              sx={{
                width: "100%",
                borderRadius: 0,
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <CardMedia
                height={"100%"}
                component="img"
                alt={"Project Thumbnail"}
                image={project?.project_thumbnail || placeholderLogo}
                title={"Project Thumbnail"}
                sx={{
                  height: { lg: 202, md: 198, sm: 204, xs: 198 },
                  borderRadius: "8px",
                }}
              />
            </Card>
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                bottom: "0.75rem",
                left: "0.8rem",
              }}
            >
              {project?.workers?.length > 0 &&
                project?.workers?.slice(0, 3).map((worker, i) => {
                  return (
                    <Box
                      sx={{
                        width: { xs: "22px", md: "30px" },
                        height: { xs: "22px", md: "30px" },
                        backgroundColor: "#86CB50",
                        marginLeft: { xs: "0.2rem", md: "0.2rem" },
                        marginRight: { xs: "0.2rem", md: "0.2rem" },
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      key={`user-icon-${i}`}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, md: 14 },
                          color: "white",
                          fontWeight: "600",
                        }}
                      >
                        {handleGetUserName(worker)}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "#C4C4C4",
              height: { lg: 202, md: 198, sm: 204, xs: 198 },
              position: "relative",
              display: "flex",
              "&:hover": {
                border: 1,
              },
              justifyContent: "flex-start",
              alignItems: "flex-end",
              cursor: "pointer",
              paddingLeft: "0.8rem",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                bottom: "0.75rem",
                left: "0.8rem",
              }}
            >
              {project?.workers?.length > 0 &&
                project?.workers?.slice(0, 3).map((worker, i) => {
                  return (
                    <Box
                      sx={{
                        width: { xs: "22px", md: "30px" },
                        height: { xs: "22px", md: "30px" },
                        backgroundColor: "#86CB50",
                        marginLeft: { xs: "0.2rem", md: "0.2rem" },
                        marginRight: { xs: "0.2rem", md: "0.2rem" },
                        marginBottom: "0.75rem",
                        borderRadius: "50%",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                      }}
                      key={`user-icon-${i}`}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 10, md: 14 },
                          color: "white",
                          fontWeight: "600",
                        }}
                      >
                        {handleGetUserName(worker)}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
        <Typography
          textAlign={"left"}
          sx={{ fontSize: 15, mt: 2, fontWeight: 600 }}
        >
          {project?.project_name}
        </Typography>
      </Box>
      {project?.role === "Owner" && (
        <Box>
          <Box
            sx={{ position: "absolute", right: 12, top: 8, cursor: "pointer" }}
            onClick={handleClick}
          >
            <MenuIcon />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                dispatch(
                  updateProjectStatus({
                    id: +project?.id,
                    status: "archive",
                  })
                );
                window.location.href = `/account`;
              }}
            >
              Archive
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteProject();
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}
