import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const productPropCreate = createAsyncThunk(
  "productProp/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productPropList = createAsyncThunk(
  "productProp/list",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productPropGet = createAsyncThunk(
  "productProp/get",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/group/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productPropGetAll = createAsyncThunk(
  "productProp/get",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/all_group/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productPropDelete = createAsyncThunk(
  "productProp/deleteByID",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productPropPrecedenceList = createAsyncThunk(
  "productProp/precedenceList",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/precedence`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productPropPrecedenceEdit = createAsyncThunk(
  "productProp/precedenceEdit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/productProp/precedence`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const selectProductPropCreate = (state) => state.productPropCreate;
export const selectProductPropList = (state) => state.productPropList;
export const selectProductPropGet = (state) => state.productPropGet;
export const selectProductPropGetAll = (state) => state.productPropGetAll;
export const selectProductPropDelete = (state) => state.productPropDelete;
export const selectProductPropPrecedenceList = (state) =>
  state.productPropPrecedenceList;
export const selectProductPropPrecedenceEdit = (state) =>
  state.productPropPrecedenceEdit;
