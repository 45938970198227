import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as XLSX from "xlsx";

export const spaceCreate = createAsyncThunk(
  "space/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/`,
        payload
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const spaceList = createAsyncThunk(
  "space/list",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/${id}`
      );
      return data?.list?.map((val) => ({ ...val, projectId: id }));
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const spaceEdit = createAsyncThunk(
  "space/edit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const spaceDelete = createAsyncThunk(
  "space/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const spaceMove = createAsyncThunk(
  "space/move",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/move/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const spaceRequestProductList = createAsyncThunk(
  "space/request",
  async (projectId, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/product-request/${projectId}`
      );
      return data?.data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const downloadProductRequestSheet = createAsyncThunk(
  "space/download",
  async (projectId, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-space/download/${projectId}`
      );
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data?.data);
      XLSX.utils.book_append_sheet(wb, ws, "Requests");
      XLSX.writeFile(wb, "Requests.xlsx");
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const selectSpaceCreate = (state) => state.spaceCreate;
export const selectSpaceList = (state) => state.spaceList;
export const selectSpaceEdit = (state) => state.spaceEdit;
export const selectSpaceDelete = (state) => state.spaceDelete;
export const selectSpaceMove = (state) => state.spaceMove;
export const selectSpaceRequestProductList = (state) =>
  state.spaceProductRequestList;
export const selectSpaceDownloadRequest = (state) =>
  state.spaceProductRequestDownload;
