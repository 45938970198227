import renderCellExpand from "./renderCellExpand";

const productVariationColumns = [
  { field: "id", headerName: "ID", width: 100, hide: true, renderCell: renderCellExpand, },
  { field: "product", headerName: "Product", width: 300, renderCell: renderCellExpand, },
  { field: "name", headerName: "Name", width: 200, renderCell: renderCellExpand, },
  { field: "sku", headerName: "SKU", width: 150, renderCell: renderCellExpand, },
  {
    field: "isDisabled",
    headerName: "Is Disabled?",
    width: 180,
    type: "boolean",
  },
  {
    field: "created_at",
    headerName: "Created On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
    hide: true,
  },
  {
    field: "updated_at",
    headerName: "Updated On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
    hide: true,
  },
];

export default productVariationColumns;
