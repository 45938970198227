import { createSlice } from '@reduxjs/toolkit';
import { productPropCreate } from './productPropAsyncThunk';

const productPropCreateSlice = createSlice({
  name: "productProp",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [productPropCreate.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [productPropCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [productPropCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default productPropCreateSlice.reducer;