import { createSlice } from "@reduxjs/toolkit";
import { spaceRequestProductList } from "./spaceAsyncThunk";

const spaceRequestProductListSlice = createSlice({
  name: "space",
  initialState: {
    payload: [],
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [spaceRequestProductList.pending]: (state, action) => {
      state.isSuccess = false;
      state.isFail = false;
    },
    [spaceRequestProductList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = [...action.payload];
    },
    [spaceRequestProductList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.payload = [];
    },
  },
});

export default spaceRequestProductListSlice.reducer;
