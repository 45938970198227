import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const brandCreate = createAsyncThunk(
  "brand/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const brandList = createAsyncThunk("brand/list", async (_, thunkAPI) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/brand/`
    );
    return data;
  } catch (err) {
    const { data } = err.response;
    return thunkAPI.rejectWithValue(data);
  }
});

export const brandEdit = createAsyncThunk(
  "brand/edit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      console.log("status", status, data);
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const brandUpdateBoolean = createAsyncThunk(
  "brand/updateIsShow",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/updateBoolean`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      console.log("status", status, data);
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const brandGetByID = createAsyncThunk(
  "brand/getByID",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const brandGetLandingPage = createAsyncThunk(
  "brand/getLandingPage",
  async (brandName, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/landingPage/${brandName}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const brandGetLandingPageWithProducts = createAsyncThunk(
  "brand/products",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/landingPageBrandWithProducts`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const brandGetLandingPage2 = createAsyncThunk(
  "brand/getLandingPage2",
  async (brandName, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/landingPage/${brandName}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const brandGetLandingPage3 = createAsyncThunk(
  "brand/getLandingPage3",
  async (brandName, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/landingPage/${brandName}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const brandGetLeadCapturePage = createAsyncThunk(
  "brand/getLeadCapturePage",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/leadCapturePage/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const brandParams = createAsyncThunk(
  "brand/params",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/brand/parameter/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const brandRelevant = createAsyncThunk(
  "brand/relevant",
  async (criteria, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/sameBrand`,
        { params: criteria }
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const selectBrandCreate = (state) => state.brandCreate;
export const selectBrandList = (state) => state.brandList;
export const selectBrandEdit = (state) => state.brandEdit;
export const selectBrandGet = (state) => state.brandGet;
export const selectBrandGetLandingPage = (state) => state.brandGetLandingPage;
export const selectBrandGetLandingPage2 = (state) => state.brandGetLandingPage2;
export const selectBrandGetLandingPage3 = (state) => state.brandGetLandingPage3;
export const selectBrandGetLeadCapturePage = (state) =>
  state.brandGetLeadCapturePage;
export const selectBrandParams = (state) => state.brandParams;
export const selectBrandRelevant = (state) => state.brandRelevant;
export const selectBrandGetLandingPageWithProducts = (state) =>
  state.brandGetLandingPageWithProducts;
export const selectBrandUpdateBoolean = (state) => state.brandUpdateBoolean;
