import React from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ServiceLogo from "../components/img/figure_service.png";

const StyledImg = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  objectFit: "cover",
}));

export default function LookingUp() {
  return (
    <Container maxWidth="sm">
      <Box sx={{
        width: "100%",
        maxWidth: 500,
        my: { xs: 20, md: 15 },
        mx: 0,
      }}>
        <StyledImg
          alt="Please wait..."
          src={ServiceLogo}
        />
        <Typography sx={{ mt: 2 }} variant="subtitle1" gutterBottom>
            Looking up for you. Please wait...
        </Typography>
      </Box>
    </Container>
  );};