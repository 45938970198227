import React, { useCallback, useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import "../App.css";
import useImageHoverState from "./customHooks/ImageHoverState";
import ImgZoomModal from "./ImgZoomModal";
import { Typography } from "@mui/material";
import useThrottledHover from "./customHooks/throttleHoverState";
import Fade from "@mui/material/Fade";
import ImageWrapper from "./ImageWrapper";
import useMediaQuery from "@mui/material/useMediaQuery";

const SliderContainer = styled(Paper)(({ theme }) => ({
  width: "calc(100vw - 77px)",
  overflowX: "scroll",
  display: "block",
  whiteSpace: "normal",
  boxShadow: "none",
  borderRadius: "0px",
  [theme.breakpoints.down("md")]: {
    width: "calc(100vw - 7px)",
  },
  paddingBottom: "none",
  backgroundColor: "transparent",

  "&::-webkit-scrollbar": {
    height: "6px",
  },

  "&::-webkit-scrollbar-track": {
    marginLeft: "48px",
    marginRight: "48px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "32px",
      marginRight: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "16px",
      marginRight: "16px",
    },
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888", // Set the background color of the scrollbar thumb
    borderRadius: "6px", // Set the border-radius of the scrollbar thumb
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555", // Set the background color of the scrollbar thumb when hovered
  },
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  height: "500px",
  justifyContent: "center",
  gap: "12px",

  [theme.breakpoints.down("xl")]: {
    height: "420px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "360px",
  },
  [theme.breakpoints.down("md")]: {
    height: "320px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "280px",
    gap: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
  },
  showIconButton: {
    opacity: 1,
  },
}));

const ProductGallerySlider = ({ gallery }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const sliderContainerRef = useRef(null);
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [loadedImages, setLoadedImages] = useState(0);

  const [onHover, setOnHover] = useState(false);

  const handleMouseEnter = () => {
    setOnHover(true);
  };
  const handleMouseLeave = () => {
    setOnHover(false);
  };

  const updateArrowVisibility = () => {
    if (sliderContainerRef.current) {
      // Check if the scroll is at the start
      const atStart = sliderContainerRef.current.scrollLeft === 0;

      // Check if the scroll is at the end
      const atEnd =
        sliderContainerRef.current.scrollLeft +
          sliderContainerRef.current.clientWidth >=
        sliderContainerRef.current.scrollWidth;

      // Check if there's nothing to scroll
      const noScroll =
        sliderContainerRef.current.clientWidth >=
        sliderContainerRef.current.scrollWidth;

      setShowPrevArrow(!atStart);
      setShowNextArrow(!atEnd && !noScroll);
    }
  };

  const handleNextClick = () => {
    if (sliderContainerRef.current) {
      const scrollAmount = window.innerWidth * 0.6;
      sliderContainerRef.current.scrollBy({
        top: 0,
        left: scrollAmount,
        behavior: "smooth",
      });
    }
    updateArrowVisibility();
  };

  const handlePrevClick = () => {
    if (sliderContainerRef.current) {
      const scrollAmount = window.innerWidth * 0.6;
      sliderContainerRef.current.scrollBy({
        top: 0,
        left: -scrollAmount,
        behavior: "smooth",
      });
    }
    updateArrowVisibility();
  };

  const handleChangeGalleryImage = (gallery) => {
    setOpen(true);
    setSelectedGallery(gallery);
  };

  const handleImageLoad = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };

  const SampleNextArrow = React.memo(({ onClick }) => {
    // const { className, style, onClick, slideCount, currentSlide } = props;
    // let disabled = null;

    return (
      <Fade in={onHover && showNextArrow}>
        <IconButton
          className="slick-arrow-right"
          onClick={onClick}
          sx={{
            color: "primary.main",
            ml: -0.8,
            display:
              onHover && !isMdDown ? "block !important" : "none !important",
          }}
        >
          <ChevronRightIcon sx={{ fontSize: "48px" }} />
        </IconButton>
      </Fade>
    );
  });
  const SamplePrevArrow = React.memo(({ onClick }) => {
    // const { className, style, onClick, currentSlide } = props;

    return (
      <Fade in={onHover && showPrevArrow}>
        <IconButton
          className="slick-arrow-left"
          onClick={onClick}
          sx={{
            color: "primary.main",
            ml: -0.8,
            display:
              onHover && !isMdDown ? "block !important" : "none !important",

            borderRadius: "10px !important",
          }}
        >
          <ChevronLeftIcon sx={{ fontSize: "48px" }} />
        </IconButton>
      </Fade>
    );
  });

  useEffect(() => {
    const handleScroll = () => {
      updateArrowVisibility();
    };

    if (sliderContainerRef.current) {
      sliderContainerRef.current.addEventListener("scroll", handleScroll);
    }

    updateArrowVisibility(); //update arrow visibility on mount

    return () => {
      if (sliderContainerRef.current) {
        sliderContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [gallery]);

  useEffect(() => {
    if (loadedImages === gallery.length) {
      updateArrowVisibility();
    }
  }, [loadedImages]);

  return (
    <Box
      sx={{
        maxWidth: {
          xs: "calc(100vw - 16px)",
          sm: "calc(100vw - 32px)",
          md: "calc(100vw - 69px)",
          position: "relative",
          overflow: "visible",
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
        }}
      >
        <SampleNextArrow onClick={handleNextClick} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
        }}
      >
        <SamplePrevArrow onClick={handlePrevClick} />
      </Box>
      <SliderContainer
        ref={sliderContainerRef}
        // onMouseDown={handleMouseDown}
      >
        <ImageContainer>
          {gallery?.map((selectedImage, index) => (
            <ImageWrapper
              key={selectedImage.id}
              selectedImage={selectedImage}
              index={index}
              handleChangeGalleryImage={handleChangeGalleryImage}
              gallery={gallery}
              onLoad={handleImageLoad}
            />
          ))}
        </ImageContainer>
      </SliderContainer>
      <ImgZoomModal
        selectedGallery={selectedGallery}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  );
};

export default ProductGallerySlider;
