import { createSlice } from '@reduxjs/toolkit';
import { brandParams } from './brandAsyncThunk';

const brandParamsSlice = createSlice({
  name: "brand",
  initialState: {
    name: "",
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  reducers: {},
  extraReducers: {
    [brandParams.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [brandParams.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.name = action.payload.name;
    },
    [brandParams.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
  },
});

export default brandParamsSlice.reducer;