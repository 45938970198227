import { createSlice } from "@reduxjs/toolkit";
import uniqBy from "lodash-es/uniqBy";
import { spaceProductsList } from "./spaceProductsAsyncThunk";

const spaceProductsListSlice = createSlice({
  name: "spaceProduct",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
    payload: [],
  },
  reducers: {},
  extraReducers: {
    [spaceProductsList.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [spaceProductsList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = uniqBy(
        state.payload.concat(action.payload),
        function (elem) {
          return [elem.id, elem.spaceId].join();
        }
      );
    },
    [spaceProductsList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.payload = [];
    },
  },
});

export default spaceProductsListSlice.reducer;
