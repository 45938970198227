import { createSlice } from '@reduxjs/toolkit';
import { productGetByID } from './productAsyncThunk';

const productGetSlice = createSlice({
  name: "product",
  initialState: {
    payload: {},
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [productGetByID.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [productGetByID.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = { ...action.payload.product };
    },
    [productGetByID.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
  },
});

export default productGetSlice.reducer;