import { useState } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const StyledImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  height: "100%",
}));

const ImageWrapper = ({
  selectedImage,
  index,
  handleChangeGalleryImage,
  gallery,
  onLoad,
}) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <Box
      onMouseDown={(e) => {
        if (e.target.nodeName !== "IMG") {
          e.stopPropagation();
        }
      }}
      sx={{
        marginLeft:
          index === 0 ? { xs: "16px", sm: "32px", md: "48px" } : "0px",
        marginRight:
          index === gallery.length - 1
            ? { xs: "16px", sm: "32px", md: "48px" }
            : "0px",
        position: "relative",
        height: "100%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onMouseEnter={() => {
        setIsHovered(selectedImage.id);
      }}
      onMouseLeave={() => {
        setIsHovered(null);
      }}
    >
      <StyledImage
        key={selectedImage.id}
        src={selectedImage.url}
        alt={selectedImage.title}
        onDragStart={(e) => e.preventDefault()}
        onClick={() => {
          handleChangeGalleryImage(selectedImage);
        }}
        onLoad={onLoad}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: { xs: "none", md: "flex" },
          justifyContent: "flex-start",
          alignItems: "flex-end",
          background: {
            xs: "none",
            md: "linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.05) 15%)",
          },
          color: "white",
          fontWeight: "bold",
          fontSize: "1.2em",
          opacity: isHovered === selectedImage.id ? 1 : 0,
          transition: "opacity 0.1s ease-in-out",
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(245, 245, 245, 0.5)",
        }}
        onClick={() => {
          handleChangeGalleryImage(selectedImage);
        }}
      >
        <Typography
          sx={{
            pb: "8px",
            pl: "8px",
            fontWeight: "bold",
            display: { xs: "none", md: "block" },
          }}
        >
          {selectedImage.title}
        </Typography>
      </Box>
      {/* <IconButton
                className={`${
                  hoverStates[selectedImage.id] ? classes.showIconButton : ""
                } ${classes.iconButton}`}
                onClick={() => {
                  handleChangeGalleryImage(selectedImage);
                }}
              >
                <ZoomInIcon />
              </IconButton> */}
    </Box>
  );
};

export default ImageWrapper;
