import renderCellExpand from "./renderCellExpand";

const productPropColumns = [
  { field: "id", headerName: "ID", width: 100, hide: true, renderCell: renderCellExpand, },
  { field: "key", headerName: "Key", width: 200, renderCell: renderCellExpand, },
  { field: "value", headerName: "Value", width: 400, renderCell: renderCellExpand, },
  { field: "rgb", headerName: "RGB#", width: 120, renderCell: renderCellExpand, },
  {
    field: "created_at",
    headerName: "Created On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
    hide: true,
  },
  {
    field: "updated_at",
    headerName: "Updated On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
    hide: true,
  },
];

export default productPropColumns;
