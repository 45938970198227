import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { categoryCreate } from "./categoryAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { LightBlueButton, BlackButton } from "../../components/CustomButtons";

const ImgCategoryLogo = styled("img")(({ theme }) => ({
  width: theme.spacing(10),
  objectFit: "cover",
}));

function CategoryCreate({ data }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    parent_id: data.parent_id,
    name: data.name,
    imageURL: data.imageURL,
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.name) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.imageURL) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(categoryCreate(values));
  };

  useEffect(() => {
    const { parent_id, name, imageURL } = data;
    setValues({ parent_id, name, imageURL });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <LightBlueButton
        variant="contained"
        color="primary"
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Create
      </LightBlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-category"
        scroll="body"
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle
          id="form-dialog-create-category"
          onClose={handleClose}
        >
          Create Category
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            disabled
            defaultValue={data.parent_name}
            variant="outlined"
            margin="dense"
            size="small"
            placeholder="Parent"
            sx={{ mb: 1.5, mt: 0 }}
            fullWidth
          />
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.name) === false}
            defaultValue={values.name}
            onChange={handleValues("name")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mb: 1.5, mt: 0 }}
            id="category-name"
            placeholder="Name"
            fullWidth
          />
          <ImageCreate
            label="Image URL"
            svg={!values.parent_id ? true : false}
            error={isUserActionMade && Boolean(values.imageURL) === false}
            onFinish={(image) => setValues({ ...values, imageURL: image.url })}
          />
          {values.imageURL && (
            <ImgCategoryLogo src={values.imageURL} alt="Category" />
          )}
          {!values.parent_id && (
            <p style={{ fontSize: "12px", color: "#F75D59" }}>
              <b>Note - </b> If you are uploading a category icon, please ensure
              that the image dimensions are 50 x 50 pixels.
            </p>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Create Category
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

CategoryCreate.propTypes = {
  data: PropTypes.shape({
    parent_id: PropTypes.number,
    parent_name: PropTypes.string,
    name: PropTypes.string,
    imageURL: PropTypes.string,
  }).isRequired,
};

export default CategoryCreate;
