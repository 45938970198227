import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const productGalleryTitleCreate = createAsyncThunk(
  "productGalleryTitle/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGalleryTitle/`,
        payload
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const productGalleryTitleList = createAsyncThunk(
  "productGalleryTitle/list",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGalleryTitle/`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productGalleryTitleManage = createAsyncThunk(
  "productGalleryTitle/manage",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGalleryTitle/`,
        payload
        );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productGalleryTitleDelete = createAsyncThunk(
  "productGalleryTitle/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/productGalleryTitle/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const selectProductGalleryTitleCreate = (state) => state.productGalleryTitleCreate;
export const selectProductGalleryTitleList = (state) => state.productGalleryTitleList;
export const selectProductGalleryTitleManage = (state) => state.productGalleryTitleManage;
export const selectProductGalleryTitleDelete = (state) => state.productGalleryTitleDelete;