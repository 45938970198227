import * as React from "react";

import {
  Avatar,
  Backdrop,
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { bodyFont } from "../components/styles/materialUIStyles";
import TabPanel from "../components/TabPanel";
import SpaceProductMoveModal from "../features/space/SpaceProductMove";
import {
  SpacesTab,
  RequestsTab,
  SettingsTab,
} from "../components/ProjectDetails";
import WhitePlustIcon from "../components/img/WhitePlusIcon";

import {
  selectSpaceList,
  selectSpaceRequestProductList,
  spaceList,
} from "../features/space/spaceAsyncThunk";
import {
  activeProjectList,
  projectCoWorkersList,
  selectProjectCoWorkerCreate,
  selectProjectCoWorkersList,
  selectProjectList,
} from "../features/project/projectAsyncThunk";
import {
  selectSpaceProductList,
  spaceProductsList,
} from "../features/spaceproducts/spaceProductsAsyncThunk";

import {
  PROJECT_OWNER_DETAILS_TAB,
  PROJECT_READ_DETAILS_TAB,
} from "../constants/projects.constants";
import {
  orderCartDecode,
  selectOrderCartToken,
} from "../features/order/orderAsyncThunk";
import SpaceProductDeleteModal from "../features/spaceproducts/SpaceProductsDelete";
import ProjectCoWorker from "../features/project/ProjectCoWorker";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";

const StyledBackground = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: "#F6F6F6",
  maxHeight: "200px",
  height: "200px",
}));

export default function SpacePage() {
  const [value, setValue] = React.useState(0);
  const [projectName, setProjectName] = React.useState("");
  const [projectRole, setProjectRole] = React.useState("");
  const [projectThumbnail, setProjectThumbnail] = React.useState("");
  const history = useHistory();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const { csrfToken } = authUserInfo;
  const { payload: spaceListData, isLoading } = useSelector(selectSpaceList);
  const [spaceModalOpen, setSpaceModalOpen] = React.useState(false);
  const [spaceId, setSpaceId] = React.useState("");
  const { isLoading: favoritesLoading } = useSelector(selectSpaceProductList);
  const [spaceMoveToModalOpen, setSpaceMoveToModalOpen] = React.useState(false);
  const { isLoading: requestsLoading } = useSelector(
    selectSpaceRequestProductList
  );
  const { isLoading: projectCoWorkerCreateRequestLoading } = useSelector(
    selectProjectCoWorkerCreate
  );
  const { isLoading: coWorkerLoading, payload: coWorkers } = useSelector(
    selectProjectCoWorkersList
  );
  const [spaceDeleteModal, setSpaceDeleteModal] = React.useState(false);
  const [coWorkerModalOpen, setCoWorkerModalOpen] = React.useState(false);

  const orderCartTokenStore = useSelector(selectOrderCartToken);
  const { token: cartToken } = orderCartTokenStore;

  const { payload: projectsList } = useSelector(selectProjectList);
  const dispatch = useDispatch();
  const params = useParams();

  const handleFetch = React.useCallback(async () => {
    if (csrfToken) {
      if (!params?.id) {
        return;
      }
      const res = await dispatch(spaceList(Number(params?.id))).then(
        unwrapResult
      );
      if (Array.isArray(res)) {
        res.map((val) => dispatch(spaceProductsList(Number(val.id))));
      }
    }
  }, [dispatch, params?.id, csrfToken]);

  React.useEffect(() => {
    handleFetch();
  }, [handleFetch, csrfToken]);

  React.useEffect(() => {
    if (csrfToken) {
      dispatch(activeProjectList());
    }
  }, [dispatch, csrfToken]);

  React.useEffect(() => {
    if (!params?.id) {
      return;
    }
    if (csrfToken) {
      dispatch(projectCoWorkersList(params?.id));
    }
  }, [dispatch, params?.id, csrfToken]);

  React.useEffect(() => {
    if (projectsList && projectsList.length > 0) {
      const spaceProjectName = projectsList.find(
        (project) => project.id === params.id
      );
      setSpaceId(spaceProjectName?.space_id);
      setProjectName(spaceProjectName?.project_name);
      setProjectThumbnail(spaceProjectName?.project_thumbnail);
      setProjectRole(spaceProjectName?.role);
    }
  }, [params.id, projectsList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (Boolean(cartToken) === true) {
      sessionStorage.setItem("cart", cartToken);
      dispatch(orderCartDecode({ cartToken }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartToken]);

  const handleGetUserName = (obj) => {
    if (obj?.admin_id) {
      return `${obj?.admin_object?.firstName
        .slice(0, 1)
        .toLocaleUpperCase()}${obj?.admin_object?.lastName
        .slice(0, 1)
        .toLocaleUpperCase()}`;
    }
    if (obj?.designer_id) {
      return `${obj?.designer_object?.firstName
        .slice(0, 1)
        .toLocaleUpperCase()}${obj?.designer_object?.lastName
        .slice(0, 1)
        .toLocaleUpperCase()}`;
    }
    if (obj?.federated_designer_id) {
      return `${obj?.federated_designer_object?.firstName
        .slice(0, 1)
        .toLocaleUpperCase()}${obj?.federated_designer_object?.lastName
        .slice(0, 1)
        .toLocaleUpperCase()}`;
    }
  };

  return (
    <React.Fragment>
      {isLoading ||
      favoritesLoading ||
      requestsLoading ||
      projectCoWorkerCreateRequestLoading ||
      coWorkerLoading ? (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: "common.white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              position: "relative",
              backgroundColor: "#F6F6F6",
            }}
          >
            <StyledBackground />
            <Box
              sx={{
                position: "absolute",
                bottom: "2px",
                display: "flex",
                marginBottom: "3rem",
                alignItems: "center",
                marginLeft: { xs: "1rem", md: "3rem" },
                marginRight: { xs: "0.5rem", md: "1rem" },
              }}
            >
              <Typography
                variant="subtitle1"
                color="black"
                sx={{
                  fontSize: { xs: 16, md: 24 },
                  cursor: "pointer",
                  ":hover": {
                    borderBottom: 1,
                  },
                }}
                onClick={() => history.push("/account")}
                marginRight={1}
                fontWeight={"600"}
              >
                Projects
              </Typography>
              <Typography
                component="span"
                variant="subtitle1"
                color="black"
                sx={{ fontSize: { xs: 16, md: 24 } }}
                fontWeight={"600"}
              >
                / {projectName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginLeft: { xs: "1rem", md: "2.8rem" },
                marginRight: { xs: "0.5rem", md: "1rem" },
              }}
            >
              {coWorkers &&
                coWorkers.length > 0 &&
                coWorkers
                  .filter(({ role }) => role === "Read")
                  .map((item, i) => {
                    return (
                      <Box
                        sx={{
                          width: { xs: "22px", md: "30px" },
                          height: { xs: "22px", md: "30px" },
                          bgcolor: "transparent",
                          marginLeft: { xs: "0.25rem", md: "0.25rem" },
                          marginRight: { xs: "0.25rem", md: "0.25rem" },
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                          display: "flex",
                        }}
                        key={`user-icon-${i}`}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 10, md: 14 },
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          {handleGetUserName(item)}
                        </Typography>
                      </Box>
                    );
                  })}
              {projectRole === "Owner" && (
                <Box
                  sx={{
                    width: { xs: "22px", md: "30px" },
                    height: { xs: "22px", md: "30px" },
                    backgroundColor: "#C1C1C1",
                    marginLeft: { xs: "0.25rem", md: "0.25rem" },
                    marginRight: { xs: "0.25rem", md: "0.25rem" },
                    borderRadius: "50%",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={() => setCoWorkerModalOpen(!coWorkerModalOpen)}
                >
                  <WhitePlustIcon />
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ backgroundColor: "#F6F6F6" }}>
            {projectRole === "Read" ? (
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: { xs: 2, md: 6 },
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="spaces tabs"
                  TabIndicatorProps={{
                    style: { backgroundColor: "#181818" },
                  }}
                >
                  {PROJECT_READ_DETAILS_TAB.map(({ title }, index) => {
                    return (
                      <Tab
                        key={`projects-details-tab-${index}`}
                        style={{ minWidth: 50, paddingInline: "2em" }}
                        label={
                          <span
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "600",
                              fontSize: 15,
                            }}
                          >
                            {title}
                          </span>
                        }
                      />
                    );
                  })}
                </Tabs>
              </Box>
            ) : (
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: { xs: 2, md: 6 },
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="spaces tabs"
                  TabIndicatorProps={{
                    style: { backgroundColor: "#181818", height: "3.5px" },
                  }}
                >
                  {PROJECT_OWNER_DETAILS_TAB.map(({ title }, index) => {
                    return (
                      <Tab
                        key={`projects-details-tab-${index}`}
                        style={{ minWidth: 50, paddingInline: "2em" }}
                        label={
                          <span
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "600",
                              fontSize: 15,
                            }}
                          >
                            {title}
                          </span>
                        }
                      />
                    );
                  })}
                </Tabs>
              </Box>
            )}
          </Box>
          <div role="tabpanel" hidden={value}>
            {value === 0 && (
              <Box
                sx={{
                  pt: { xs: 2, md: 6 },
                  textAlign: "left",
                  backgroundColor: "#F6F6F6",
                }}
              >
                <SpacesTab
                  spaceId={spaceId}
                  setSpaceModalOpen={setSpaceModalOpen}
                  spaceModalOpen={spaceModalOpen}
                  setSpaceId={setSpaceId}
                  setSpaceMoveToModalOpen={setSpaceMoveToModalOpen}
                  spaceMoveToModalOpen={spaceMoveToModalOpen}
                  setSpaceDeleteModal={setSpaceDeleteModal}
                  spaceDeleteModal={spaceDeleteModal}
                  projectRole={projectRole}
                />
              </Box>
            )}
          </div>
          <TabPanel value={value} index={1}>
            <RequestsTab />
          </TabPanel>
          {projectRole === "Owner" && (
            <TabPanel value={value} index={2}>
              <SettingsTab />
            </TabPanel>
          )}
        </>
      )}

      <SpaceProductMoveModal
        projects={projectsList}
        open={spaceModalOpen}
        onClose={() => setSpaceModalOpen(false)}
        spaceId={spaceId}
        handleFetch={handleFetch}
        setSpaceId={setSpaceId}
        setSpaceMoveToModalOpen={setSpaceMoveToModalOpen}
      />
      <SpaceProductDeleteModal
        spaces={spaceListData}
        open={spaceDeleteModal}
        onClose={() => setSpaceDeleteModal(false)}
        spaceId={spaceId}
        handleFetch={handleFetch}
        setSpaceId={setSpaceId}
      />
      <ProjectCoWorker
        open={coWorkerModalOpen}
        onClose={() => setCoWorkerModalOpen(false)}
      />
    </React.Fragment>
  );
}
