import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { orderGetByID, selectOrderGet } from "./orderAsyncThunk";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";
import ImgMediaCard from "../../components/ImgMediaCard";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import BaseTimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { StyledTextField } from "../../components/CustomComponents";
import LookingUp from "../../components/LookingUp";
import Error404 from "../../screens/Error404";

const TimelineItem = styled(BaseTimelineItem)(({ theme }) => ({
  "&:before": {
    flex: 0,
  },
}));

export default function OrderGet() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const authUserInfoStore = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfoStore;
  const orderGetStore = useSelector(selectOrderGet);
  const { isLoading, payload } = orderGetStore;

  useEffect(() => {
    dispatch(orderGetByID(id));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userInfo]);

  useEffect(() => {
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <React.Fragment>
      {payload && Boolean(payload.id) === true ? (
        <Container maxWidth="md">
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <MonitorHeartIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Status
                </Typography>
                <StyledTextField
                  value={payload.orderStatus}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="orderstatus"
                  placeholder="Order Status"
                  fullWidth
                  multiline
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <HomeWorkIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Project Information
                </Typography>
                <StyledTextField
                  value={payload.projectName}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="projectname"
                  placeholder="Project Name"
                  fullWidth
                />
                <StyledTextField
                  value={payload.projectLocation}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="projectlocation"
                  placeholder="Project Location"
                  fullWidth
                  multiline
                />
                <StyledTextField
                  value={payload.projectType}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="projecttype"
                  placeholder="Project Type"
                  fullWidth
                />
                <StyledTextField
                  value={payload.projectSize}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="projectsize"
                  placeholder="Project Size"
                  fullWidth
                />
                <StyledTextField
                  value={payload.projectDescription}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="projectdescription"
                  placeholder="Project Description"
                  fullWidth
                  multiline
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <EmojiTransportationIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Delivery Details
                </Typography>
                <StyledTextField
                  value={payload.deliveryLocation}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="deliverylocation"
                  placeholder="Delivery Location"
                  fullWidth
                  multiline
                />
                <StyledTextField
                  value={payload.telephone}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  id="telephone"
                  placeholder="Telephone"
                  fullWidth
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <FactCheckIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  Sample List
                </Typography>
                <Grid
                  sx={{ mt: 1 }}
                  container
                  spacing={2}
                  alignItems="flex-end"
                >
                  {payload.variation_object.map((item, i) => (
                    <Grid key={i} item xs={12} sm={6}>
                      <ImgMediaCard item={item} />
                    </Grid>
                  ))}
                </Grid>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ borderColor: "black" }} variant="outlined">
                  <ThumbUpOffAltIcon />
                </TimelineDot>
              </TimelineSeparator>
              {userInfo.role === 0 && (
                <TimelineContent>
                  <Typography variant="h6" component="span">
                    User Information
                  </Typography>
                  <StyledTextField
                    value={payload.user_object.name}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    id="name"
                    placeholder="User Name"
                    fullWidth
                  />
                  <StyledTextField
                    value={payload.user_object.email}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    id="email"
                    placeholder="User Email"
                    fullWidth
                  />
                </TimelineContent>
              )}
            </TimelineItem>
          </Timeline>
        </Container>
      ) : isLoading ? (
        <LookingUp />
      ) : (
        <Error404 />
      )}
    </React.Fragment>
  );
}
