import React, { useEffect, useState } from "react";

import {
  Checkbox,
  DialogContent,
  DialogContentText,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import { BlackButton } from "../../components/CustomButtons";
import {
  Dialog,
  StyledDialogTitle,
  DialogTextField,
} from "../../components/CustomComponents";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUserInfo } from "../authentication/authAsyncThunk";
import { supplierContact } from "./supplierAsyncThunk";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const SupplierContactBrandName = ({
  open,
  onClose,
  supplierInfo,
  productInfo,
  project,
}) => {
  const dispatch = useDispatch();

  const authUserInfo = useSelector(selectAuthUserInfo);
  const { payload: userInfo } = authUserInfo;
  const enquiryTypeList = ["Information", "Request", "Others"];
  const projectTypeList = ["Construction", "Architecture", "Others"];
  const [values, setValues] = useState({
    enquiryType: enquiryTypeList[0],
    projectName: "",
    projectLocation: "",
    projectType: projectTypeList[0],
    projectSize: "",
    message: "",
    isContactByEmail: true,
    isContactByPhone: true,
  });

  useEffect(() => {
    if (project) {
      setValues({
        ...values,
        projectName: project?.project_name,
        projectLocation: project?.project_location,
        projectSize: project?.project_size,
      });
    }
  }, [project]);

  const [showHint, setShowHint] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    setValues(newObj);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.enquiryType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectType) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.projectSize) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.message) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(
      supplierContact({ ...values, userInfo, supplierInfo, productInfo })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      scroll="body"
    >
      <StyledDialogTitle id="form-dialog-title">
        Contact Supplier
      </StyledDialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <DialogContentText sx={{ mb: 1 }}>
          <b>Hi{userInfo.firstName ? ` ${userInfo.firstName},` : ","}</b>
        </DialogContentText>
        <DialogContentText sx={{ mb: 2 }}>
          {supplierInfo.companyName
            ? `Please fill in the form so ${supplierInfo.companyName} can get in touch with you.`
            : "Please wait..."}
        </DialogContentText>
        <Autocomplete
          options={enquiryTypeList}
          value={values.enquiryType}
          onChange={handleAutoCompleteValues("enquiryType")}
          fullWidth
          renderOption={(props, option) => {
            return <AutoCompleteOption {...props} name={option} />;
          }}
          renderInput={(params) => (
            <DialogTextField
              {...params}
              autoFocus
              required
              sx={{ mt: 0, mb: 1.5 }}
              error={isUserActionMade && Boolean(values.enquiryType) === false}
              label="Type of enquiry"
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
        />
        <DialogTextField
          required
          error={isUserActionMade && Boolean(values.projectName) === false}
          defaultValue={values.projectName}
          onChange={handleValues("projectName")}
          variant="outlined"
          margin="dense"
          size="small"
          sx={{ mt: 0, mb: 1.5 }}
          label="Project Name"
          fullWidth
        />
        <DialogTextField
          required
          error={isUserActionMade && Boolean(values.projectLocation) === false}
          defaultValue={values.projectLocation}
          onChange={handleValues("projectLocation")}
          variant="outlined"
          margin="dense"
          sx={{ mt: 0, mb: 1.5 }}
          size="small"
          label="Project Location"
          fullWidth
        />
        <Autocomplete
          options={projectTypeList}
          value={values.projectType}
          onChange={handleAutoCompleteValues("projectType")}
          renderOption={(props, option) => {
            return <AutoCompleteOption {...props} name={option} />;
          }}
          fullWidth
          renderInput={(params) => (
            <DialogTextField
              {...params}
              autoFocus
              required
              sx={{ mt: 0, mb: 1.5 }}
              error={isUserActionMade && Boolean(values.projectType) === false}
              label="Project Type"
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
        />
        <DialogTextField
          required
          error={isUserActionMade && Boolean(values.projectSize) === false}
          defaultValue={values.projectSize}
          onChange={handleValues("projectSize")}
          variant="outlined"
          margin="dense"
          sx={{ mt: 0, mb: 1.5 }}
          size="small"
          label="Project Size"
          fullWidth
        />
        <DialogTextField
          required
          multiline
          minRows={5}
          error={isUserActionMade && Boolean(values.message) === false}
          defaultValue={values.message}
          onChange={handleValues("message")}
          variant="outlined"
          margin="dense"
          size="small"
          sx={{ mt: 0, mb: 1.5 }}
          label="Message"
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.isContactByEmail}
              onChange={(e) =>
                setValues({
                  ...values,
                  isContactByEmail: e.target.checked,
                })
              }
              name="isContactByEmail"
              color="primary"
              inputProps={{
                "aria-label": "Contact via email",
              }}
            />
          }
          label="Contact via email"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.isContactByPhone}
              onChange={(e) =>
                setValues({
                  ...values,
                  isContactByPhone: e.target.checked,
                })
              }
              name="isContactByPhone"
              color="primary"
              inputProps={{
                "aria-label": "Contact via phone",
              }}
            />
          }
          label="Contact via phone"
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          px: 3,
        }}
      >
        <BlackButton
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
          disabled={Boolean(supplierInfo.id) === false}
          onClick={handleSubmit}
        >
          Submit
        </BlackButton>
      </DialogActions>
      {showHint && (
        <DialogContentText
          sx={{
            margin: (theme) => theme.spacing(0, 3, 3, 3),
            textAlign: "center",
          }}
        >
          Oops! Something is still wrong above.
        </DialogContentText>
      )}
    </Dialog>
  );
};

export default SupplierContactBrandName;
