import React from "react";

import { Box, Typography } from "@mui/material";
import moment from "moment";

const SampleOrderCard = ({ order, setOrderId }) => {
  const handleBackgroundColor = (id) => {
    if (id === 1 || id === 2) {
      return "#D1D1D1";
    }
    if (id === 3) {
      return "#F8E399";
    }
    return "#C0EF83";
  };

  const handleChangeText = (id) => {
    if (id === 1 || id === 2) {
      return "Preparing Matterbox";
    }
    if (id === 3) {
      return "Matterbox Shipped";
    }
    return "Matterbox Delivered";
  };
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        px: 1.5,
        py: 3,
        mb: 2,
        cursor: "pointer",
        borderRadius: "12px"
      }}
      onClick={() => setOrderId(order?.id)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "flex-start", sm: "space-between" },
          alignItems: { xs: "flex-start", sm: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", sm: "space-between" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ mb: { xs: 1.5, sm: 0 } }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 16, md: 20, lg: 24 },
              }}
            >
              {order?.matterboxNumber}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 11, md: 11, lg: 15 },
                color: "#181818",
                fontWeight: "400",
              }}
            >
              Matterbox Number
            </Typography>
          </Box>
          <Box sx={{ mx: { xs: 0, sm: 8 }, mb: { xs: 1.5, sm: 0 } }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 16, md: 20, lg: 24 } }}
            >
              {moment(order?.created_at).format("DD/MM/YYYY")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 11, md: 11, lg: 15 },
                color: "#181818",
                fontWeight: "400",
              }}
            >
              Placed on
            </Typography>
          </Box>
          <Box sx={{ mb: { xs: 1.5, sm: 0 } }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: { xs: 16, md: 20, lg: 24 } }}
            >
              {order?.expectedDelivery
                ? moment(order?.expectedDelivery).format("DD/MM/YYYY")
                : "-"}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 11, md: 11, lg: 15 },
                color: "#181818",
                fontWeight: "400",
              }}
            >
              Expected Delivery
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              px: 2,
              py: 0.5,
              borderRadius: 10,
              backgroundColor: handleBackgroundColor(order?.order_status_id),
              letterSpacing: 0.5,
            }}
          >
            <Typography
              sx={{ fontSize: { xs: 11, md: 11, lg: 15 }, fontWeight: "600" }}
            >
              {handleChangeText(order?.order_status_id)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SampleOrderCard;
