import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListCountry,
  catalogListCompanyType,
  selectCatalogListCountry,
  selectCatalogListCompanyType,
} from "../catalog/catalogAsyncThunk";
import { supplierCreate } from "./supplierAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import { BlueButton, BlackButton } from "../../components/CustomButtons";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const ImgSupplierLogo = styled("img")(({ theme }) => ({
  width: theme.spacing(10),
  objectFit: "cover",
}));

export default function SupplierCreate() {
  const dispatch = useDispatch();
  const catalogListCountryStore = useSelector(selectCatalogListCountry);
  const { isLoading: countryListLoading, payload: countryList } =
    catalogListCountryStore;
  const catalogListCompanyTypeStore = useSelector(selectCatalogListCompanyType);
  const { isLoading: companyTypeListLoading, payload: companyTypeList } =
    catalogListCompanyTypeStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    companyName: "",
    company_type_id: 1,
    company_type_object: {},
    jobTitle: "",
    country_id: 100,
    country_object: {},
    companyLocation: "",
    companyWebsite: "",
    supplierLogoURL: "",
    isAgency: false,
    isManufacturer: false,
    isPremium: false,
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    if (prop === "country_object") newObj.country_id = newValue.id;
    if (prop === "company_type_object") newObj.company_type_id = newValue.id;
    setValues(newObj);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.email.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.telephone) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.jobTitle) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyLocation) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyWebsite) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.supplierLogoURL) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(supplierCreate(values));
  };

  useEffect(() => {
    dispatch(catalogListCountry());
    dispatch(catalogListCompanyType());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryList[99])
      setValues({ ...values, country_object: { ...countryList[99] } });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList]);

  useEffect(() => {
    if (companyTypeList[0])
      setValues({ ...values, company_type_object: { ...companyTypeList[0] } });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyTypeList]);

  return (
    <React.Fragment>
      <BlueButton
        variant="contained"
        color="primary"
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Create Supplier
      </BlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-create-supplier"
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <StyledDialogTitle
          id="form-dialog-create-supplier"
          onClose={handleClose}
        >
          Create New Supplier
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            autoFocus
            required
            error={isUserActionMade && Boolean(values.firstName) === false}
            defaultValue={values.firstName}
            onChange={handleValues("firstName")}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="First Name"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.lastName) === false}
            defaultValue={values.lastName}
            onChange={handleValues("lastName")}
            variant="outlined"
            sx={{ mt: 0, mb: 1.5 }}
            margin="dense"
            size="small"
            id="lastname"
            placeholder="Last Name"
            fullWidth
          />
          <StyledTextField
            required
            error={
              isUserActionMade &&
              Boolean(values.email.match(/\S+@\S+\.\S+/)) === false
            }
            defaultValue={values.email}
            onChange={handleValues("email")}
            variant="outlined"
            margin="dense"
            size="small"
            id="email"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="Email Address"
            type="email"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.telephone) === false}
            defaultValue={values.telephone}
            onChange={handleValues("telephone")}
            variant="outlined"
            margin="dense"
            sx={{ mt: 0, mb: 1.5 }}
            size="small"
            id="telephone"
            placeholder="Telephone"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.companyName) === false}
            defaultValue={values.companyName}
            onChange={handleValues("companyName")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 0, mb: 1.5 }}
            id="companyname"
            placeholder="Company Name"
            fullWidth
          />
          <Autocomplete
            id="companytype"
            options={companyTypeList}
            loading={companyTypeListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.company_type_object}
            onChange={handleAutoCompleteValues("company_type_object")}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            fullWidth
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                sx={{ mt: 0, mb: 1.5 }}
                {...params}
                placeholder="Company Type"
                variant="outlined"
              />
            )}
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.jobTitle) === false}
            defaultValue={values.jobTitle}
            onChange={handleValues("jobTitle")}
            variant="outlined"
            margin="dense"
            size="small"
            id="jobtitle"
            placeholder="Job title"
            fullWidth
            sx={{ mt: 0, mb: 1.5 }}
          />
          <Autocomplete
            id="country"
            options={countryList}
            loading={countryListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.country_object}
            onChange={handleAutoCompleteValues("country_object")}
            fullWidth
            disableClearable
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                sx={{ mt: 0, mb: 1.5 }}
                placeholder="Country"
                variant="outlined"
              />
            )}
          />
          <StyledTextField
            required
            error={
              isUserActionMade && Boolean(values.companyLocation) === false
            }
            defaultValue={values.companyLocation}
            onChange={handleValues("companyLocation")}
            variant="outlined"
            sx={{ mt: 0, mb: 1.5 }}
            margin="dense"
            size="small"
            id="companylocation"
            placeholder="Company Location"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.companyWebsite) === false}
            defaultValue={values.companyWebsite}
            onChange={handleValues("companyWebsite")}
            variant="outlined"
            margin="dense"
            size="small"
            id="companywebsite"
            sx={{ mt: 0, mb: 1.5 }}
            placeholder="Company Website"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isAgency}
                onChange={(e) =>
                  setValues({ ...values, isAgency: e.target.checked })
                }
                name="isAgency"
                color="primary"
                inputProps={{
                  "aria-label": "Agency",
                }}
              />
            }
            label="Agency"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isManufacturer}
                onChange={(e) =>
                  setValues({ ...values, isManufacturer: e.target.checked })
                }
                name="isManufacturer"
                color="primary"
                inputProps={{
                  "aria-label": "Manufacturer",
                }}
              />
            }
            label="Manufacturer"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isPremium}
                onChange={(e) =>
                  setValues({ ...values, isPremium: e.target.checked })
                }
                name="isPremium"
                color="primary"
                inputProps={{
                  "aria-label": "Premium Supplier",
                }}
              />
            }
            label="Premium Supplier"
          />
          <ImageCreate
            id="test1"
            label="Supplier Logo URL"
            error={
              isUserActionMade && Boolean(values.supplierLogoURL) === false
            }
            onFinish={(image) =>
              setValues({ ...values, supplierLogoURL: image.url })
            }
          />
          {values.supplierLogoURL && (
            <ImgSupplierLogo src={values.supplierLogoURL} alt="Supplier Logo" />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlackButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            onClick={handleSubmit}
          >
            Create Supplier
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}
