import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogContentText } from "@mui/material";
import { BlackButton } from "../../components/CustomButtons";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, StyledDialogTitle } from "../../components/CustomComponents";
import { selectSpaceMove } from "../space/spaceAsyncThunk";
import { useParams } from "react-router-dom";
import { spaceProductsDelete } from "./spaceProductsAsyncThunk";

export default function SpaceProductDeleteModal({
  open,
  onClose,
  spaceId,
  handleFetch,
  setSpaceId,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showHint, setShowHint] = React.useState(false);
  const { favoriteProductIds } = useSelector(selectSpaceMove);
  const [nextSpaceId, setNextSpaceId] = React.useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const handleMove = async () => {
    await dispatch(
      spaceProductsDelete({
        prevSpaceId: +spaceId,
        nextSpaceId,
        favoriteProductIds,
      })
    );
    await handleFetch();
    window.location.href = `/project/${params.id}`;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="space-dialog-title"
      >
        <StyledDialogTitle id="space-dialog-title" onClose={onClose}>
          Are you sure to delete selection?
        </StyledDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: 512, sm: "100%" },
          }}
        ></DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <BlackButton
            sx={{
              width: "100%",
              height: 55,
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (Boolean(spaceId) === false) {
                setShowHint(true);
                return;
              }
              handleMove();
              onClose();
              setSpaceId("");
              setNextSpaceId("");
            }}
          >
            Confirm
          </BlackButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 1, 1, 1),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </div>
  );
}
