import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const productCreate = createAsyncThunk(
  "product/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productList = createAsyncThunk(
  "product/list",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/list`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productEdit = createAsyncThunk(
  "product/edit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productGetByID = createAsyncThunk(
  "product/getByID",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productSearch = createAsyncThunk(
  "product/search",
  async (criteria, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/search`,
        { params: criteria }
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const productParams = createAsyncThunk(
  "product/params",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/parameter/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const productGetLandingPage = createAsyncThunk(
  "product/getLandingPage",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/landingPage`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productVariationCreate = createAsyncThunk(
  "product/variationCreate",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/variation`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productVariationList = createAsyncThunk(
  "product/variationList",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/variationList`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productVariationEdit = createAsyncThunk(
  "product/variationEdit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/variation`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productVariationDelete = createAsyncThunk(
  "product/variationDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/variation/${id}`
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const productVariationSearch = createAsyncThunk(
  "product/variationSearch",
  async (criteria, thunkAPI, cursor) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/variationSearch`,
        { params: criteria, cursor }
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const productLastThreeMonthList = createAsyncThunk(
  "product/lastThreeMonth",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/product/landingPage/products`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const selectProductCreate = (state) => state.productCreate;
export const selectProductList = (state) => state.productList;
export const selectProductEdit = (state) => state.productEdit;
export const selectProductGet = (state) => state.productGet;
export const selectProductSearch = (state) => state.productSearch;
export const selectProductParams = (state) => state.productParams;
export const selectProductGetLandingPage = (state) =>
  state.productGetLandingPage;
export const selectProductVariationCreate = (state) =>
  state.productVariationCreate;
export const selectProductVariationList = (state) => state.productVariationList;
export const selectProductVariationEdit = (state) => state.productVariationEdit;
export const selectProductVariationDelete = (state) =>
  state.productVariationDelete;
export const selectLastThreeMonthsProductList = (state) =>
  state.productLastThreeMonthList;
