import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListCountry,
  catalogListSupplier,
  selectCatalogListCountry,
  selectCatalogListSupplier,
} from "../catalog/catalogAsyncThunk";
import {
  matterboardCreate,
  matterboardImageUpload,
  imageCreateMatterboard,
  matterboardList,
} from "../matterboard/matterboardAsyncThunk";
import ImageCreate from "../image/ImageCreate";
import SinglePreviewImageList from "../../components/SinglePreviewImageList";
import { useSnackbar } from "notistack";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import {
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import {
  BlueButton,
  BlackButton,
  LightBlueButton,
} from "../../components/CustomButtons";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import SvgUploader from "../../features/matterboard/imageCreate";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML, convertFromHTML } from "draft-convert";
import ImageMapper from "react-image-mapper";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {
  productList,
  selectProductList,
  selectProductCreate,
  selectProductEdit,
  selectProductVariationCreate,
} from "../product/productAsyncThunk";
import {
  categoryList,
  selectCategoryList,
  selectCategoryCreate,
  selectCategoryEdit,
  selectCategoryDelete,
} from "../category/categoryAsyncThunk";
import Grid from "@mui/material/Grid";
import NoImg from "../../components/img/no-image-selected.png";
import SvgEditor from "../../features/matterboard/SvgEditor";
import ImageKit from "imagekit";
import * as _ from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";

const ImgBrandBanner = styled("img")(({ theme }) => ({
  width: "100%",
  objectFit: "fill",
  height: "30vh",
  overflow: "hidden",
}));

export default function MatterBoardCreate({ handleToggle }) {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    title: "",
    description: "",
    background_img: "",
    matterBoard_url: "",
    product_ids: [],
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedImg, setSelectedImg] = useState("");
  const [showHint, setShowHint] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [productIds, setProductIds] = useState([]);
  const [imageLoader, setImageLoader] = useState(false);
  const [submitLoader, setSumbitLoader] = useState(false);
  const productListStore = useSelector(selectProductList);
  const { payload } = productListStore;
  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    dispatch(productList());
    dispatch(categoryList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const disableFooter = document.getElementById("footer");
    if (disableFooter) {
      disableFooter.style.display = "none";
    }
  }, []);

  const handleImageChange = (evt) => {
    let file = evt.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = function (e) {
        let contents = e.target.result;
        if (file.type.indexOf("svg") > 0) {
          setSelectedImg(contents);
        }
      };

      reader.readAsText(file);
    } else {
    }
  };
  const getProductIds = (ids) => {
    setProductIds(ids);
  };
  useEffect(() => {
    if (productIds.length > 0) {
      let ids = _.without(productIds, undefined);
      setValues({ ...values, product_ids: ids });
    }
  }, [productIds, imageLoader]);

  const UploadToServerSvg = () => {
    setIsSubmitting(true);
    setImageLoader(true);
    var s = new XMLSerializer();
    const allHTMLTags = document.getElementById("svg-details");
    let svgRaw = allHTMLTags.getElementsByTagName("svg");
    let newSvg = s.serializeToString(svgRaw[0]);
    const newFile = new File([newSvg], "name.svg", {
      type: "image/svg+xml",
    });

    const imagekit = new ImageKit({
      publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY,
      urlEndpoint: process.env.REACT_APP_IMAGEKIT_URL,
      privateKey: "private_/qJqcsmQLSw4Gt0+1Tkq0mTm8AE=",
    });
    imagekit
      .upload({
        file: newFile,
        fileName: "image.svg",
      })
      .then((imgResponse) => {
        dispatch(imageCreateMatterboard(imgResponse));
        setValues({ ...values, matterBoard_url: imgResponse.url });
        setImageLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setImageLoader(false);
      });
  };

  const handleSubmit = async () => {
    const params = {
      ...values,
    };
    if (Boolean(params.title) === false) {
      setShowHint(true);
      setErrorText("This Field Is Requred !");
      return;
    }
    if (Boolean(params.description) === false) {
      setShowHint(true);
      setErrorText("This Field Is Requred !");
      return;
    }
    if (Boolean(params.background_img) === false) {
      setShowHint(true);
      setErrorText("background_img Requred !");
      return;
    }
    setShowHint(false);
    const response = await dispatch(matterboardCreate(params));
    if (response?.payload?.status === 200) {
      handleVariant(response?.payload?.data?.message);
      dispatch(matterboardList());
      handleToggle();
    } else {
      handleVariant("Oops! Something went wrong.", "error");
    }
  };

  useEffect(() => {
    dispatch(matterboardImageUpload());
  }, []);
  return (
    <React.Fragment>
      <Card sx={{ pb: 3 }}>
        <Box sx={{ m: 3 }}>
          {/* <SvgUploader svg={"sdjd"} label="dddd" /> */}
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Create Matterboard
          </Typography>
          <Box sx={{ mt: 3 }}>
            <StyledTextField
              autoFocus
              required
              error={isUserActionMade && Boolean(values.title) === false}
              helperText={Boolean(values.title) === false ? errorText : ""}
              defaultValue={values.title}
              onChange={handleValues("title")}
              variant="outlined"
              margin="dense"
              size="small"
              id="title"
              placeholder="Name"
              fullWidth
            />

            <StyledTextField
              autoFocus
              required
              error={isUserActionMade && Boolean(values.description) === false}
              helperText={
                Boolean(values.description) === false ? errorText : ""
              }
              defaultValue={values.description}
              onChange={handleValues("description")}
              variant="outlined"
              margin="dense"
              size="small"
              id="description"
              placeholder="Description"
              fullWidth
            />
            <Box sx={{ display: "flex", align: "center", gap: 2, mt: 3 }}>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Choose background Image
              </Typography>
              <ImageCreate
                id="test1"
                error={isUserActionMade && Boolean(values.bannerImg) === false}
                onFinish={(image) =>
                  setValues({ ...values, background_img: image.url })
                }
              />
            </Box>
            {/* <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", color: "crimson" }}
                  >
                    {showHint ? errorText : ""}
                  </Typography>
                </Box> */}
            <Box
              sx={{
                mt: 3,
              }}
            >
              <ImgBrandBanner
                src={values.background_img ? values.background_img : NoImg}
              />
            </Box>
            <Box sx={{ display: "flex", align: "center", gap: 2, mt: 3 }}>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Select Svg
              </Typography>
              <input accept=".svg" type="file" onChange={handleImageChange} />
            </Box>
          </Box>

          {selectedImg && (
            <Box sx={{ mt: 4, border: "1px solid #ECECEC" }}>
              <SvgEditor
                file={selectedImg}
                products={payload}
                isSubmitting={isSubmitting}
                getProductIds={getProductIds}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            ml: 3,
          }}
        >
          {!values.matterBoard_url ? (
            <Button
              variant="contained"
              color="success"
              disabled={selectedImg ? false : true}
              onClick={UploadToServerSvg}
            >
              {imageLoader ? (
                <>
                  <CircularProgress
                    color="inherit"
                    size={"20px"}
                    sx={{ mr: 2 }}
                  />
                  Upload Svg Image
                </>
              ) : (
                "Upload Svg Image"
              )}
            </Button>
          ) : (
            <BlackButton
              disabled={!values.matterBoard_url ? true : false}
              onClick={handleSubmit}
            >
              Create Matterboard
            </BlackButton>
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
}
