import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { manuJoin } from "./manuAsyncThunk";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import MuiDialog, { dialogClasses } from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  StyledTextField,
  DialogTitle,
  StyledDialogTitle,
  StyledDialogContentText,
} from "../../components/CustomComponents";
import Box from "@mui/material/Box";
import Checkbox from "@mui/icons-material/CheckCircle";
import { red } from "@mui/material/colors";
import { BlackButton, WhiteButton } from "../../components/CustomButtons";
import { useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${dialogClasses["paper"]}`]: {
    borderRadius: "12px",
  },
  [`& .${dialogClasses["paperScrollPaper"]}`]: {
    display: "flex",
    flexDirection: "row",
    maxHeight: "calc(100% - 64px)",
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: red[700],
  verticalAlign: "middle",
  marginRight: theme.spacing(1),
}));

export default function ManufacturerJoin() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    title: "",
    companyName: "",
    workEmail: "",
    phone: "",
  });
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.firstName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.lastName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.title) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.companyName) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.workEmail.match(/\S+@\S+\.\S+/)) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.phone) === false) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(manuJoin(values));
  };

  const handleVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant, autoHideDuration: 3000, action });
  };
  const action = (key) => (
    <React.Fragment>
      <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
        <CheckIcon sx={{ color: "common.white" }} />
      </Button>
    </React.Fragment>
  );


  return (
    <React.Fragment>
      <WhiteButton
        variant="contained"
        color="primary"
        size="small"
        sx={{
          my: 2,
          display: "flex",
          borderRadius: 0,
          border: "hidden",
          padding: "4px 10px",
          backgroundColor: "transparent",
          fontWeight: 300,
          fontSize: 15,
          whiteSpace: "nowrap",
        }}
        onClick={() => handleVariant("New user sign up will be available soon.")}
      >
        Supplier Register
      </WhiteButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-manufacturer"
        maxWidth="md"
        scroll="paper"
      >
        <Box sx={{ flexBasis: "50%" }}>
          <StyledDialogTitle
            id="form-dialog-manufacturer"
            onClose={handleClose}
            sx={{ p: (theme) => theme.spacing(4, 6) }}
          >
            Drive new business with Matterbase.
          </StyledDialogTitle>
          <DialogContent sx={{ p: 3 }}>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              Matterbase, the world’s largest material marketplace for the
              architecture and design industry, simplifies the complex process
              of material search and solves the unique problems of sampling. See
              why over 340+ brands have already adopted Matterbase to help them
              grow.
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              Get your products in front of 50,000+ design professionals who are
              actively sourcing materials for their projects.
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <StyledCheckbox />
              Highly qualified leads
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <StyledCheckbox />
              Valuable connections for your reps
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <StyledCheckbox />
              Massive exposure at no cost
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <StyledCheckbox />
              Fastest possible sample logistics
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <StyledCheckbox />
              Sample reclamation simplified
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <StyledCheckbox />
              Low risk business model
            </StyledDialogContentText>
            <StyledDialogContentText
              component="p"
              variant="body2"
              color="textPrimary"
              sx={{ mb: 3 }}
            >
              <StyledCheckbox />
              Powerful ROI
            </StyledDialogContentText>
          </DialogContent>
        </Box>
        <Box sx={{ flexBasis: "50%" }}>
          <DialogTitle>Let's Talk!</DialogTitle>
          <DialogContent>
            <StyledTextField
              autoFocus
              required
              error={isUserActionMade && Boolean(values.firstName) === false}
              defaultValue={values.firstName}
              onChange={handleValues("firstName")}
              variant="outlined"
              margin="dense"
              sx={{ mt: 0, mb: 1.5 }}
              size="small"
              id="name"
              placeholder="First Name"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.lastName) === false}
              defaultValue={values.lastName}
              onChange={handleValues("lastName")}
              sx={{ mt: 0, mb: 1.5 }}
              variant="outlined"
              margin="dense"
              size="small"
              id="lastname"
              placeholder="Last Name"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.title) === false}
              defaultValue={values.title}
              onChange={handleValues("title")}
              variant="outlined"
              margin="dense"
              size="small"
              id="title"
              placeholder="Your Title"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.companyName) === false}
              defaultValue={values.companyName}
              onChange={handleValues("companyName")}
              sx={{ mt: 0, mb: 1.5 }}
              variant="outlined"
              margin="dense"
              size="small"
              id="company-name"
              placeholder="Company Name"
              fullWidth
            />
            <StyledTextField
              required
              error={
                isUserActionMade &&
                Boolean(values.workEmail.match(/\S+@\S+\.\S+/)) === false
              }
              defaultValue={values.workEmail}
              onChange={handleValues("workEmail")}
              variant="outlined"
              margin="dense"
              sx={{ mt: 0, mb: 1.5 }}
              size="small"
              id="work-email"
              placeholder="Work Email"
              type="email"
              fullWidth
            />
            <StyledTextField
              required
              error={isUserActionMade && Boolean(values.phone) === false}
              defaultValue={values.phone}
              onChange={handleValues("phone")}
              variant="outlined"
              sx={{ mt: 0, mb: 1.5 }}
              margin="dense"
              size="small"
              id="phone"
              placeholder="Phone Number"
              fullWidth
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              py: 2,
              px: 6,
            }}
          >
            <BlackButton
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                margin: (theme) => theme.spacing(1, 1, 3, 1),
              }}
              onClick={handleSubmit}
            >
              Submit
            </BlackButton>
          </DialogActions>
          {showHint && (
            <StyledDialogContentText
              sx={{
                margin: (theme) => theme.spacing(0, 1, 3, 1),
                textAlign: "center",
              }}
            >
              Oops! Something is still wrong above.
            </StyledDialogContentText>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
