import React, { useState, useEffect } from "react";
import projectTypeColumns from "../../components/types/projectType";
import CatalogCreateProjectType from "./CatalogCreateProjectType";
import CatalogManageProjectType from "./CatalogManageProjectType";
import CatalogDeleteProjectType from "./CatalogDeleteProjectType";
import { useSelector, useDispatch } from "react-redux";
import {
  catalogListProjectType,
  selectCatalogCreateProjectType,
  selectCatalogListProjectType,
  selectCatalogManageProjectType,
  selectCatalogDeleteProjectType,
} from "./catalogAsyncThunk";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function ProjectTypeGrid() {
  const dispatch = useDispatch();
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [editButtonProps, setEditButtonProps] = useState({
    isSelected: false,
    data: {
      id: 0,
      name: "",
      created_at: new Date(),
      updated_at: new Date(),
    }
  });
  const catalogListProjectTypeStore = useSelector(selectCatalogListProjectType);
  const { payload } = catalogListProjectTypeStore;
  const catalogManageProjectTypeStore = useSelector(selectCatalogManageProjectType);
  const { isSuccess: catalogManageProjectTypeSuccess } = catalogManageProjectTypeStore;
  const catalogCreateProjectTypeStore = useSelector(selectCatalogCreateProjectType);
  const { isSuccess: catalogCreateProjectTypeSuccess } = catalogCreateProjectTypeStore;
  const catalogDeleteProjectTypeStore = useSelector(selectCatalogDeleteProjectType);
  const { isSuccess: catalogDeleteProjectTypeSuccess } = catalogDeleteProjectTypeStore;
  
  const handleSelection = (selected) => {
    if (selected[0]) {
      const index = projectTypeList.findIndex(x => x.id === selected[0]);
      setEditButtonProps({ isSelected: true, data: { ...projectTypeList[index] } });
    } else {
      setEditButtonProps({
        isSelected: false,
        data: {
          id: 0,
          name: "",
          created_at: new Date(),
          updated_at: new Date(),
        }
      });
    };
  };

  useEffect(() => {
    dispatch(catalogListProjectType());
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogManageProjectTypeSuccess, catalogCreateProjectTypeSuccess, catalogDeleteProjectTypeSuccess]);

  useEffect(() => {
    let projectType = [...payload];
    let list = [];
    if (projectType.length > 0) {
      for (let i = 0; i < projectType.length; i++) {
        let item = {
          ...projectType[i],
          created_at: new Date(projectType[i].created_at),
          updated_at: new Date(projectType[i].updated_at),
        }
        list.push(item);
      };
      setProjectTypeList(list);
    };
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Box sx={{ flexGrow: 1, mt: 1, bgcolor: "background.paper" }}>
      <Typography>Project Type Management</Typography>
      <CatalogCreateProjectType />
      {
        projectTypeList[0] &&
        <CatalogManageProjectType {...editButtonProps} />
      }
      { projectTypeList[0] && <CatalogDeleteProjectType {...editButtonProps} /> }
      {
        projectTypeList[0] &&
        <Box sx={{ height: 400, width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={projectTypeList}
                columns={projectTypeColumns}
                pageSize={25}
                components={{
                  Toolbar: GridToolbar,
                }}
                onSelectionModelChange={handleSelection}
              />
            </Box>
          </Box>
        </Box>
      }
    </Box>
  );
}
