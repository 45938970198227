import * as React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";

import { bodyFont } from "../components/styles/materialUIStyles";
import TabPanel from "../components/TabPanel";
import SpaceProductCard from "../components/SpaceProductCard";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSpaceProductList,
  spaceProductsList,
} from "../features/spaceproducts/spaceProductsAsyncThunk";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";

export default function SpacePage() {
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const dispatch = useDispatch();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const { csrfToken } = authUserInfo;
  const { payload, isLoading } = useSelector(selectSpaceProductList);

  React.useEffect(() => {
    if (csrfToken) {
      if (params?.id) {
        dispatch(spaceProductsList(Number(params.id)));
      }
    }
  }, [dispatch, params?.id, csrfToken]);

  const favorites = React.useMemo(() => {
    return payload.filter((val) => +val.spaceId === +params.id);
  }, [params.id, payload]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: "common.white",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <React.Fragment>
          <Box sx={{ backgroundColor: "#F6F6F6" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", px: 6 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: { backgroundColor: "#181818" },
                }}
              >
                <Tab
                  label={
                    <span
                      style={{
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        fontSize: 16,
                      }}
                    >
                      Product
                    </span>
                  }
                />
              </Tabs>
            </Box>
          </Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ py: 4 }}>
              <Grid container spacing={2}>
                {favorites.map((spaceproduct, index) => (
                  <Grid
                    key={`space-product-${index}`}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2}
                  >
                    <SpaceProductCard product={spaceproduct} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </TabPanel>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
