import React, { useState, useEffect, Suspense, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, ThemeProvider } from "@mui/material/styles";
import {
  matterboardDetailsByID,
  selectMatterboardDetailsByID,
  similarCategoryProducts,
  selectSimilarCategoryProducts,
} from "../features/matterboard/matterboardAsyncThunk";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  bodyFont,
  landing_page_background_colour,
} from "../components/styles/materialUIStyles";
// import SimilarProducts from "../components/matterboard/SimilarProducts";
import { useLocation } from "react-router-dom";
import SvgViewer from "../components/matterboard/SvgViewer";
import { selectAuthUserInfo } from "../features/authentication/authAsyncThunk";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import * as _ from "lodash";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
const SimilarProducts = React.lazy(() =>
  import("../components/matterboard/SimilarProducts")
);

const ContainerBox = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  marginBottom: 10,
  // backgroundColor: "#C4C4C4",
  height: "650px",
  [theme.breakpoints.down("md")]: {
    height: "auto",
    paddingBottom: "20px",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontWeight: "600",
  fontWeight: "700",
  fontSize: "32px",
  marginLeft: "10%",
  [theme.breakpoints.down("md")]: { fontSize: "20px", marginLeft: "15px" },
  // [theme.breakpoints.only("md")]: { fontSize: "20px", marginLeft: "15px" },
}));

const MatterbaseAbout = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontWeight: "600",
  width: "40%",
  fontSize: "14px",
  fontWeight: "300",
  marginLeft: "10%",
  textAlign: "start",
  marginRight: "20px",

  marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    width: "93%",
    marginTop: "10px",
    fontSize: "13px",
    marginLeft: "15px",
  },
}));

export const LoaderProducts = () => {
  return (
    <Card sx={{ maxWidth: 345, m: 2 }}>
      <CardContent>
        <React.Fragment>
          <Skeleton
            sx={{ height: 190 }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
};
function MatterboradDetailPage() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [similarProductsList, setSimilarProductsList] = useState([]);
  const [selectedImg, setSelectedImg] = useState("");
  const location = useLocation();
  const { pathname } = location;
  let splitPath = pathname.split("/");
  let id = splitPath[2];
  const [initialLoader, setInitialLoader] = useState(false);

  const matterboardDetailsStore = useSelector(selectMatterboardDetailsByID);
  const { matterboardDetails, isLoading } = matterboardDetailsStore;
  const similarCategoryProductsInfo = useSelector(
    selectSimilarCategoryProducts
  );
  const { payload } = similarCategoryProductsInfo;
  const { csrfToken } = useSelector(selectAuthUserInfo);
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    if (id) {
      dispatch(matterboardDetailsByID(id));
    }
  }, [id]);

  useEffect(() => {
    if (payload.length > 0) {
      setSimilarProductsList(payload);
    } else {
      setSimilarProductsList([]);
    }
  }, [payload]);

  useEffect(() => {
    if (matterboardDetails)
      fetch(matterboardDetails?.matterBoard_url)
        .then((res) => res.text())
        .then((res) => {
          setSelectedImg(res);
        });
  }, [matterboardDetails]);

  useEffect(() => {
    let categoryIds = [];
    if (csrfToken && matterboardDetails) {
      categoryIds = matterboardDetails?.product_objects.map(
        (item) => item?.category_id
      );
      const newCategoryIdsArr = _.without(categoryIds, null, undefined);
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
      let params = {
        category_ids: newCategoryIdsArr,
      };
      dispatch(similarCategoryProducts(params));
    }
  }, [csrfToken, matterboardDetails]);

  useEffect(() => {
    setInitialLoader(true);
    setTimeout(() => setInitialLoader(false), 1000);
  }, []);
  return (
    <React.Fragment>
      {!initialLoader ? (
        <>
          {matterboardDetails ? (
            <Box sx={{ backgroundColor: "#e5e5e569" }}>
              <ContainerBox
                sx={{
                  backgroundImage: `url(${matterboardDetails.background_img})`,
                }}
              >
                <Box
                  sx={{
                    height: "auto",
                    marginTop: "10px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {selectedImg ? (
                    <SvgViewer file={selectedImg} />
                  ) : (
                    <Box sx={{ height: "55vh" }}></Box>
                  )}
                </Box>

                <StyledTypography variant="h1">
                  {matterboardDetails.title}{" "}
                </StyledTypography>

                <MatterbaseAbout variant="inherit">
                  {matterboardDetails.description}
                </MatterbaseAbout>
              </ContainerBox>

              <Box sx={{ mt: 5, pb: 3 }}>
                {similarProductsList.length > 0 ? (
                  <>
                    {similarProductsList.map((item, index) => (
                      <>
                        <Typography
                          key={index}
                          variant="initial"
                          sx={{
                            display: xs ? "flex" : "block",
                            justifyContent: xs ? "center" : "start",
                            ml: xs ? "" : "2%",

                            mb: xs ? "10px" : "",
                            fontWeight: "bold",
                          }}
                        >
                          More "similar product category"
                        </Typography>
                        <Box
                          key={index}
                          style={{
                            // margin: !xs ? "10px 6%" : "0px",
                            marginTop: "20px",
                            paddingBottom: "30px",
                          }}
                        >
                          <Suspense fallback={<LoaderProducts />}>
                            <SimilarProducts
                              product={
                                item.similarCategoryProducts
                                  ? item.similarCategoryProducts
                                  : []
                              }
                            />
                          </Suspense>
                        </Box>
                      </>
                    ))}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pb: 5,
                    }}
                  >
                    <p>No Similar Products !</p>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            ""
          )}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
}

export default React.memo(MatterboradDetailPage);
