import { createSlice } from "@reduxjs/toolkit";
import { updateProjectStatus } from "./projectAsyncThunk";

const projectUpdateStatusSlice = createSlice({
  name: "project",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
  },
  reducers: {},
  extraReducers: {
    [updateProjectStatus.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [updateProjectStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isFail = false;
    },
    [updateProjectStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isFail = true;
    },
  },
});

export default projectUpdateStatusSlice.reducer;
