import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const favoriteCreate = createAsyncThunk(
  "favorite/create",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const favoriteCreateBulk = createAsyncThunk(
  "favorite/createBulk",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/bulk`,
        payload
      );
      return data;
    } catch (err) {
      const { data } = err.response;
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const favoriteList = createAsyncThunk(
  "favorite/list",
  async (projectSpaceId, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/${projectSpaceId}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const favoriteListBulk = createAsyncThunk(
  "favorite/listBulk",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/bulk`, {
          params: {
            id: payload,
          }
        }
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const favoriteDelete = createAsyncThunk(
  "favorite/deleteByID",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorite/${id}`
      );
      return data;
    } catch (err) {
      const { data, status } = err.response;
      return thunkAPI.rejectWithValue({ data, status });
    }
  }
);

export const favoriteLocalCreateStart = createAction("favorite/createLocal");
export const favoriteLocalCreateEnd = createAction("favorite/createLocal");
export const favoriteLocalDeleteStart = createAction("favorite/deleteLocal");
export const favoriteLocalDeleteEnd = createAction("favorite/deleteLocal");
export const favoriteCreateReset = createAction("favorite/createReset");

export const selectFavoriteCreate = (state) => state.favoriteCreate;
export const selectFavoriteList = (state) => state.favoriteList;
export const selectFavoriteDelete = (state) => state.favoriteDelete;
