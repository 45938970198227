import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

import Popover from "@mui/material/Popover";

import * as _ from "lodash";
import NoImg from "../../components/img/noImg.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SvgEditor = (props) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const [values, setValues] = useState([
    {
      name: "",
      height: "1500",
      width: "600",
      tooltip: "",
      link: "",
      product: null,
    },
  ]);
  const [basicSettings, setBasicSettings] = useState({
    width: "auto",
    height: "500",
  });

  const [path, setPath] = useState(null);
  const [selectedImg, setSelectedIng] = useState("");
  const [imageIds, setImageIds] = useState([]);
  const [id, setId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [title, setTitle] = useState("");
  const [tooltip, setTooltip] = useState("");
  const [mouseX, setMouseX] = useState();
  const [mouseY, setMouseY] = useState();
  const [productImg, setProductImg] = useState("");
  const [redireactLink, setRedireactLink] = useState("");
  const open2 = Boolean(anchorEl);
  const open3 = Boolean(anchorEl2);
  const [product, setProduct] = useState(null);

  const handlePopoverHoverOpen = (e) => {
    // setAnchorEl2(e.nativeEvent.path[0]);
  };

  const handlePopoverHoverClose = () => {
    setAnchorEl2(null);
  };

  function getPath(e) {
    const allHTMLTags = document.getElementById("svg-details");
    let selectedPath = e.nativeEvent.srcElement;
    setPath(selectedPath);
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    if (selectedPath.nodeName) {
      setAnchorEl(e.nativeEvent.srcElement);
    }
    setTitle(e.target.getAttribute("name"));
    setProductImg(e.target?.getAttribute("product_url"));
    setRedireactLink(e.target.getAttribute("link"));
    let selectedProduct = e.target.getAttribute("product");
    if (selectedProduct) {
      setProduct(JSON.parse(selectedProduct));
    } else {
      setProduct(null);
    }
  }
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedImg) {
      let allHTMLTagsArr = [];
      const allHTMLTags = document.getElementById("svg-details");
      let ids = allHTMLTags.querySelectorAll("*[id]");
      allHTMLTagsArr = [...allHTMLTagsArr, ids];
      let newArr = [];
      for (let i = 0; i < Object.keys(allHTMLTagsArr[0]).length; i++) {
        let news = allHTMLTagsArr.map((item) => item[i].id);
        newArr = [...newArr, news[0]];
      }
      setImageIds(newArr);
    }
  }, [selectedImg]);

  useEffect(() => {
    if (selectedImg) {
      let allHTMLTagsArr = [];
      const allHTMLTags = document.getElementById("svg-details");
      let ids = allHTMLTags.querySelectorAll("*[id]");
      allHTMLTagsArr = [...allHTMLTagsArr, ids];
      let initalValues = [];
      ids.forEach(function (item) {
        let selectedStringProduct = item?.getAttribute("product");
        let selectedProduct = selectedStringProduct
          ? JSON?.parse(selectedStringProduct)
          : null;
        initalValues = [
          ...initalValues,
          {
            name: item?.getAttribute("name") ?? "",
            tooltip: item?.getAttribute("tooltip") ?? "",
            link: item?.getAttribute("link") ?? "https://",
            product: selectedProduct ? selectedProduct : null,
            product_url: item?.getAttribute("product_url") ?? "",
          },
        ];
      });
      setValues(initalValues);
    }
  }, [selectedImg]);

  function hoverChange(v) {
    let allHTMLTagsArr = [];
    const allHTMLTags = document.getElementById("svg-details");

    let ids = allHTMLTags.querySelectorAll("*[id]");

    allHTMLTagsArr = [...allHTMLTagsArr, ids];

    ids.forEach(function (item, index) {
      if (item.id === v.target.id) {
        let paths = document.getElementById(v.target.id);

        paths.setAttribute(
          "filter",
          "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
        );
      } else if (item.id !== v.target.id) {
        item.removeAttribute("filter");
      }
    });
    setTooltip(v.target.getAttribute("tooltip"));
    if (v.target.getAttribute("title")) {
      handlePopoverHoverOpen(v);
    } else {
      handlePopoverHoverClose();
    }
  }

  useEffect(() => {
    if (props.isSubmitting) {
      let productIds = values?.map((item) => item?.product?.id);
      props.getProductIds(productIds ? productIds : []);
    }
  }, [props.isSubmitting]);

  useEffect(() => {
    if (id) {
      let allHTMLTagsArr = [];
      const allHTMLTags = document.getElementById("svg-details");
      let ids = allHTMLTags.querySelectorAll("*[id]");

      allHTMLTagsArr = [...allHTMLTagsArr, ids];
      ids.forEach(function (item, index) {
        if (item.id === id) {
          // let paths = document.getElementById(id);
          item.setAttribute("name", values[index]?.name);
          item.setAttribute("tooltip", values[index]?.tooltip);
          item.setAttribute("link", values[index]?.link);
          item.setAttribute("product_url", values[index]?.product_url);
          item.setAttribute(
            "product",
            values[index]?.product ? JSON.stringify(values[index]?.product) : ""
          );
          item?.setAttribute(
            "filter",
            "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
          );
        } else if (item.id !== id) {
          item.removeAttribute("filter");
        }
      });
    }
  }, [path, id, values]);

  useEffect(() => {
    if (props.file) {
      setSelectedIng(props.file);
      if (selectedImg && props.file) {
        const allHTMLTags = document.getElementById("svg-details");
        const fetchPathNodes = allHTMLTags.querySelector("svg");
        if (xs || sm || md || xl) {
          fetchPathNodes?.setAttribute(
            "width",
            xs
              ? "300"
              : sm
              ? "500"
              : md
              ? "700"
              : xl
              ? "1500"
              : basicSettings.width
          );
          fetchPathNodes?.setAttribute(
            "height",
            xs
              ? "200"
              : sm
              ? "300"
              : md
              ? "400"
              : xl
              ? "500"
              : basicSettings.width
          );
        } else {
          fetchPathNodes?.setAttribute("width", basicSettings.width);
          fetchPathNodes?.setAttribute("height", basicSettings.height);
        }
      }
    }
  }, [props, selectedImg, basicSettings, sm, xl, md]);
  return (
    <Box>
      <Popover
        id={id}
        anchorPosition={{ x: mouseX, y: mouseY }}
        open={open2}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card sx={{ minWidth: 300, maxWidth: 310 }}>
          {product ? (
            <>
              <CardMedia
                component="img"
                height="140"
                image={productImg ? productImg : NoImg}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  {product?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product?.brand}
                </Typography>
              </CardContent>
              <CardActions>
                {redireactLink && (
                  <Button
                    sx={{
                      size: "10px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    }}
                    href={redireactLink}
                    target="_blank"
                    size="small"
                  >
                    Search more about this Product
                  </Button>
                )}
              </CardActions>
            </>
          ) : (
            <Box sx={{ p: 3 }}>
              <Typography variant="body2">No Product Added !</Typography>
            </Box>
          )}
        </Card>
      </Popover>

      <Popover
        id={id}
        sx={{
          pointerEvents: "none",
        }}
        open={open3}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{tooltip ? tooltip : "No Tags"}</Typography>
      </Popover>

      <Box
        onMouseOverCapture={hoverChange}
        onClick={getPath}
        sx={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {selectedImg ? (
          //   <Dragable>
          <div
            id="svg-details"
            style={{ overflow: "hidden", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: selectedImg }}
          />
        ) : (
          //   </Dragable>
          ""
        )}
      </Box>
    </Box>
  );
};

export default SvgEditor;
