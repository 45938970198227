import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  authLogout,
  selectAuthUserInfo,
} from "../features/authentication/authAsyncThunk";
import ManufacturerJoin from "../features/manufacturer/ManufacturerJoin";
import AuthDesktop from "../features/authentication/AuthDesktop";
import RegMobile from "../features/registration/RegMobile";
import AuthMobile from "../features/authentication/AuthMobile";
import SearchPopover from "./SearchPopover";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  header_background_colour3,
  header_title_colour,
} from "../components/styles/materialUIStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import MatterBaseLogo3 from "./img/MatterBaseLogo3";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Link as RouterLink } from "react-router-dom";
import SwipeableTemporaryDrawer from "../components/SwipeableTemporaryDrawer";
import Box from "@mui/material/Box";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { TextButton } from "../components/CustomButtons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Popover, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Avatar from "@mui/material/Avatar";
import {
  activeProjectList,
  selectProjectList,
} from "../features/project/projectAsyncThunk";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MatterbaseWord from "../components/img/matterbase-word.svg";
import MatterbaseLogoMobile from "../components/img/matterbase_logo_mobile.svg";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Fade appear={false} direction="down" in={!trigger}>
      {children}
    </Fade>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const SectionDesktop = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  opacity: 1,
  pointerEvents: "auto",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const SectionMobile = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  color: theme.palette.common.black,
  // stroke: theme.palette.common.white,
  background: header_background_colour3,
  borderBottom: "1px solid rgba(0,0,0, 0.1)",
  backdropFilter: "blur(12px)",

  // boxShadow: "0 -1px 8px -2px #F6F6F6",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("sm")]: {
    justifyContent: "space-between",
  },
}));

const FixedAppBarFiller = styled(Typography)(({ theme }) => ({
  height: theme.spacing(8),
}));

const DivGrow = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const StyledProjectsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginRight: "-10px",
  },
}));

const CustomTreeItem = styled(TreeItem)`
  .MuiTreeItem-iconContainer {
    display: none !important;
  }
`;
export default function PrimarySearchAppBar(props) {
  const dispatch = useDispatch();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const { push } = useHistory();
  const theme = useTheme();
  const { payload: userInfo } = authUserInfo;

  const { payload: projects } = useSelector(selectProjectList);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const { csrfToken } = useSelector(selectAuthUserInfo);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const { pathname } = useLocation();

  const [openUserInfo, setOpenUserInfo] = useState(null);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery("(max-width:1084px)");
  const xs = useMediaQuery("(max-width:450px)");

  const openUserInfoClick = () => {
    push("/account");
  };
  const closeUserInfoClick = () => {
    setOpenUserInfo(null);
  };

  const isMenuOpenn = Boolean(anchorEll);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  useEffect(() => {
    if (csrfToken && Object.keys(userInfo).length > 0) {
      dispatch(activeProjectList());
    }
  }, [dispatch, csrfToken, userInfo]);

  const handleProjectsOpen = (event) => {
    setAnchorEll(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleProjectClose = () => {
    setAnchorEll(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {Object.keys(userInfo).length > 0 ? (
        <div>
          {/* <MenuItem>
            <p>Welcome{userInfo.firstName ? `, ${userInfo.firstName}` : "!"}</p>
          </MenuItem> */}
          <Link href="/account" color="inherit" underline="hover">
            <MenuItem>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                size="large"
              >
                <AccountCircle />
              </IconButton>
              <p>Profile</p>
            </MenuItem>
          </Link>
        </div>
      ) : (
        <Box sx={{ height: "40px" }} onClick={handleMenuClose}>
          <RegMobile />
        </Box>
      )}
      {Object.keys(userInfo).length < 1 && (
        <div onClick={handleMenuClose}>
          <AuthMobile />
        </div>
      )}
      {Object.keys(userInfo).length > 0 && (
        <MenuItem
          onClick={() => {
            push("/");
            dispatch(authLogout());
          }}
        >
          <IconButton
            aria-label="logout"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            size="large"
          >
            <AccountCircle />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Box>
      <DivGrow id="back-to-top-anchor">
        {/* <SectionDesktop>
          {"/" === pathname || pathname.split("/")[1] === "brand" ? null : (
            <Container maxWidth="sm">
              <FixedAppBarFiller component="div" />
            </Container>
          )}
            <Toolbar>
              <Box
                sx={{
                  position: "absolute",
                  right: (theme) => {
                    return { xs: theme.spacing(7), md: theme.spacing(3) };
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SectionDesktop>
                        {Object.keys(userInfo).length < 1 && <AuthDesktop />}
                      </SectionDesktop>
              </Box>
              <SectionMobile></SectionMobile>
            </Toolbar>
        </SectionDesktop> */}
        {/* <HideOnScroll> */}
        <StyledAppBar elevation={0}>
          <SectionMobile>
            <Toolbar
              sx={{
                px: 2,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <SwipeableTemporaryDrawer sx={{ mr: 2 }} {...props} />

              <Link component={RouterLink} to={"/"}>
                <Box
                  component="img"
                  src={MatterbaseLogoMobile}
                  alt="matterbase-logo-mobile"
                  sx={{
                    width: { xs: 150, sm: 170 },
                    display: "block",
                  }}
                />
              </Link>
              {/* {Object.keys(userInfo).length > 0 ? (
                <div>
                  <MenuItem>
            <p>Welcome{userInfo.firstName ? `, ${userInfo.firstName}` : "!"}</p>
          </MenuItem>
                  <Link href="/account" color="inherit" underline="hover">
                    <MenuItem>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                        size="large"
                      >
                        <AccountCircle />
                      </IconButton>
                      <p>Profile</p>
                    </MenuItem>
                  </Link>
                </div>
              ) : (
                <Box sx={{ height: "40px" }} onClick={handleMenuClose}>
                  <RegMobile />
                </Box>
              )} */}
              {Object.keys(userInfo).length < 1 && (
                <Box
                  onClick={handleMenuClose}
                  // sx={{
                  //   width: "50px",
                  // }}
                >
                  <AuthMobile />
                </Box>
              )}
              {Object.keys(userInfo).length > 0 && (
                <Avatar
                  sx={{
                    bgcolor: "transparent",
                    width: { xs: 28, sm: 30 },
                    height: { xs: 28, sm: 30 },
                    cursor: "pointer",
                    border: "1px solid #181818",
                  }}
                  onClick={openUserInfoClick}
                >
                  {userInfo.firstName ? (
                    <Typography
                      variant="body2"
                      color={"black"}
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {userInfo.firstName.charAt(0)}
                      {userInfo.lastName.charAt(0)}
                    </Typography>
                  ) : (
                    "!"
                  )}
                </Avatar>
                // <MenuItem
                //   onClick={() => {
                //     push("/");
                //     dispatch(authLogout());
                //   }}
                // >
                //   <IconButton
                //     aria-label="logout"
                //     aria-controls="primary-search-account-menu"
                //     aria-haspopup="true"
                //     color="inherit"
                //     size="large"
                //   >
                //     <AccountCircle />
                //   </IconButton>
                //   <p>Logout</p>
                // </MenuItem>
              )}
            </Toolbar>
          </SectionMobile>
          <SectionDesktop
            sx={{
              paddingRight: props.open2 ? "6px" : "0px",
            }}
          >
            <Toolbar
              sx={{
                paddingLeft: "69px !important",
                display: "flex",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 2,
                }}
              >
                {/* Matterbase Logo */}
                <Box
                  sx={{
                    cursor: "pointer",
                    marginLeft: "24px",
                    alignSelf: "center",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => push("/")}
                >
                  <img
                    src={MatterbaseWord}
                    alt="matterbase-word"
                    style={{ width: 150, display: "block" }}
                  />
                </Box>
                {/* Search Bar */}
                <Box
                  sx={{
                    background: "#fff",
                    width: "100%",
                    // justifyContent: "center",
                    borderRadius: "20px",
                  }}
                >
                  <SearchPopover />
                </Box>
                {/* Auth and projects lists */}
                <Box
                  sx={{
                    // right: (theme) => {
                    //   return { xs: theme.spacing(7), md: theme.spacing(3) };
                    // },
                    display: "inline-flex",
                    // width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {Object.keys(userInfo).length > 0 ? (
                    <>
                      <Box>
                        <Avatar
                          sx={{
                            bgcolor: "transparent",
                            width: 32,
                            height: 32,
                            cursor: "pointer",
                            border: "1px solid #181818",
                          }}
                          onClick={openUserInfoClick}
                        >
                          {userInfo.firstName ? (
                            <Typography
                              variant="body2"
                              color={"#181818"}
                              sx={{
                                fontSize: "12px",
                              }}
                            >
                              {userInfo.firstName.charAt(0)}
                              {userInfo.lastName.charAt(0)}
                            </Typography>
                          ) : (
                            "!"
                          )}
                        </Avatar>
                      </Box>
                      <>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            // width: "10%",
                            mx: 2,
                            borderColor: "black",
                          }}
                        />
                        <StyledProjectsBox
                          onClick={handleProjectsOpen}
                          sx={{
                            fontWeight: "400",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="inherit"
                            sx={{ fontWeight: "500", fontSize: "15px" }}
                          >
                            Projects
                          </Typography>
                          <KeyboardArrowDownIcon
                            sx={{
                              stroke: "#000000",
                              strokeOpacity: "0.1",
                              strokeWidth: "0",
                            }}
                          />
                        </StyledProjectsBox>
                        {/* Project Popover */}
                        <Popover
                          anchorEl={anchorEll}
                          onClose={handleProjectClose}
                          open={isMenuOpenn}
                          anchorOrigin={{
                            vertical: 50,
                            horizontal: "right",
                          }}
                          sx={{
                            borderRadius: "0px !important",
                            maxHeight: "100vh !important",
                            minHeight: "100vh !important",
                          }}
                          PaperProps={{
                            style: {
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <Box>
                            <TreeView
                              aria-label="file system navigator"
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpandIcon={<ChevronRightIcon />}
                              onNodeSelect={(_, id) => {
                                push(`/project/${id}`);
                                handleProjectClose();
                              }}
                              sx={{
                                flexGrow: 1,
                                minWidth: 200,
                                backgroundColor: "white",
                              }}
                            >
                              {projects.map((item) => (
                                <CustomTreeItem
                                  nodeId={item.id}
                                  label={item.project_name}
                                  sx={{
                                    textAlign: "left",
                                    fontSize: 15,
                                    fontWeight: 300,
                                  }}
                                />
                              ))}
                              <Box
                                sx={{
                                  marginTop: "1rem",
                                  marginBottom: "0.5rem",
                                  px: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  push("/account");
                                  handleProjectClose();
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 15,
                                    fontWeight: 600,
                                    textAlign: "left",
                                    textDecoration: "underline",
                                  }}
                                >
                                  View All Projects
                                </Typography>
                              </Box>
                            </TreeView>
                          </Box>
                        </Popover>
                      </>
                    </>
                  ) : (
                    <>
                      <AuthDesktop />
                      <Divider
                        orientation="vertical"
                        // flexItem
                        sx={{
                          mx: 2,
                          height: 32,
                          borderColor: "black",
                        }}
                      />
                      <ManufacturerJoin />
                    </>
                  )}
                </Box>
              </Box>
            </Toolbar>
          </SectionDesktop>
        </StyledAppBar>
        {renderMobileMenu}
      </DivGrow>
    </Box>
  );
}
