import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import { productEdit } from "../../features/product/productAsyncThunk";

const GridCellExpand = React.memo(({ params }) => {
  const dispatch = useDispatch();
  const onDataEdit = async (value) => {
    await dispatch(productEdit({ ...value, isEOL: !value?.isEOL }));
  };
  return (
    <div>
      <IconButton onClick={() => onDataEdit(params?.row)}>
        {params?.row?.isEOL ? <CheckIcon /> : <CloseIcon />}
      </IconButton>
    </div>
  );
});

const renderIsEOLCell = (params) => {
  return <GridCellExpand params={params} />;
};

export default renderIsEOLCell;
