import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { productCreate } from "./productAsyncThunk";
import {
  productPropGet,
  selectProductPropGet,
} from "../productProp/productPropAsyncThunk";
import {
  productGalleryTitleList,
  selectProductGalleryTitleList,
} from "../productGalleryTitle/productGalleryTitleAsyncThunk";
import {
  catalogListBrand,
  catalogListCategory,
  selectCatalogListBrand,
  selectCatalogListCategory,
} from "../catalog/catalogAsyncThunk";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Dialog,
  StyledDialogTitle,
  StyledTextField,
} from "../../components/CustomComponents";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Box from "@mui/material/Box";
import ImageCreate from "../image/ImageCreate";
import { BlueButton, BlackButton } from "../../components/CustomButtons";
import AutoCompleteOption from "../../components/AutoCompleteOption";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filter = createFilterOptions();
const freeSoloOptionsFilter = (options, params) => {
  const filtered = filter(options, params);
  if (params.inputValue !== "") filtered.push(`Create "${params.inputValue}"`);
  return filtered;
};

const ImgProductGallery = styled("img")(({ theme }) => ({
  width: theme.spacing(20),
  objectFit: "cover",
}));

function ProductCopy({ isSelected, data }) {
  const dispatch = useDispatch();
  const productPropGetStore = useSelector(selectProductPropGet);
  const { isLoading: productPropLoading, payload: productPropList } =
    productPropGetStore;
  const productGalleryTitleListStore = useSelector(
    selectProductGalleryTitleList
  );
  const {
    isLoading: productGalleryTitleLoading,
    payload: productGalleryTitleRecord,
  } = productGalleryTitleListStore;
  const [keyList, setKeyList] = useState([]);
  const keyMap = useRef(new Map());
  const catalogListBrandStore = useSelector(selectCatalogListBrand);
  const { isLoading: brandListLoading, payload: brandList } =
    catalogListBrandStore;
  const catalogListCategoryStore = useSelector(selectCatalogListCategory);
  const { isLoading: categoryListLoading, payload: categoryList } =
    catalogListCategoryStore;
  const [open, setOpen] = useState(false);
  const [isUserActionMade, setIsUserActionMade] = useState(false);
  const [values, setValues] = useState({
    name: data.name,
    brand: data.brand,
    brand_id: data.brand_id,
    brand_object: data.brand_object,
    category: data.category,
    category_id: data.category_id,
    category_object: data.category_object,
    size: data.size,
    sku: data.sku,
    tradePrice: data.tradePrice,
    isEOL: false,
  });
  const [generalArray, setGeneralArray] = useState([["", []]]);
  const [characteristicsArray, setCharArray] = useState([["", ""]]);
  const [downloadArray, setDownloadArray] = useState([["", ""]]);
  const [galleryArray, setGalleryArray] = useState([{ title: "", url: "" }]);
  const [showHint, setShowHint] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = (prop) => (event) => {
    setIsUserActionMade(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAutoCompleteValues = (prop) => (_, newValue) => {
    setIsUserActionMade(true);
    const newObj = { ...values, [prop]: newValue };
    if (prop === "brand_object") newObj.brand_id = newValue.id;
    if (prop === "category_object") newObj.category_id = newValue.id;
    setValues(newObj);
  };

  const handleGeneralKey = (index) => (_, newValue) => {
    setIsUserActionMade(true);
    if (
      String(newValue).startsWith(`Create "`) &&
      String(newValue).endsWith(`"`)
    ) {
      newValue = newValue.slice(8, -1);
    }
    const general = [...generalArray];
    general[index][0] = newValue;
    setGeneralArray(general);
  };

  const handleCharKey = (index) => (event) => {
    setIsUserActionMade(true);
    const characteristics = [...characteristicsArray];
    characteristics[index][0] = event.target.value;
    setCharArray(characteristics);
  };

  const handleDownloadKey = (index) => (event) => {
    setIsUserActionMade(true);
    const download = [...downloadArray];
    download[index][0] = event.target.value;
    setDownloadArray(download);
  };

  const handleGeneralValue = (index) => (_, newValue) => {
    setIsUserActionMade(true);
    const general = [...generalArray];
    general[index][1] = newValue;
    setGeneralArray(general);
  };

  const handleCharValue = (index) => (event) => {
    setIsUserActionMade(true);
    const characteristics = [...characteristicsArray];
    characteristics[index][1] =
      Boolean(event.target.value) === true ? event.target.value : "";
    setCharArray(characteristics);
  };

  const handleDownloadValueByUpload = ({ index, url }) => {
    setIsUserActionMade(true);
    const download = [...downloadArray];
    download[index][1] = url;
    setDownloadArray(download);
  };

  const handleDownloadValue = (index) => (event) => {
    setIsUserActionMade(true);
    const download = [...downloadArray];
    download[index][1] = event.target.value;
    setDownloadArray(download);
  };

  const addGeneralAttribute = () => {
    const general = [...generalArray];
    general.push(["", []]);
    setGeneralArray(general);
  };

  const removeGeneralAttribute = (i) => {
    const general = [...generalArray];
    general.splice(i, 1);
    setGeneralArray(general);
  };

  const addCharAttribute = () => {
    const characteristics = [...characteristicsArray];
    characteristics.push(["", ""]);
    setCharArray(characteristics);
  };

  const removeCharAttribute = (i) => {
    const characteristics = [...characteristicsArray];
    characteristics.splice(i, 1);
    setCharArray(characteristics);
  };

  const moveUpCharAttribute = (i) => {
    const characteristics = [...characteristicsArray];
    const element = characteristics[i];
    characteristics.splice(i, 1);
    characteristics.splice(Math.max(0, i - 1), 0, element);
    setCharArray(characteristics);
  };

  const moveDownCharAttribute = (i) => {
    const characteristics = [...characteristicsArray];
    const element = characteristics[i];
    characteristics.splice(i, 1);
    characteristics.splice(
      Math.min(characteristicsArray.length - 1, i + 1),
      0,
      element
    );
    setCharArray(characteristics);
  };

  const addDownloadAttribute = () => {
    const download = [...downloadArray];
    download.push(["", ""]);
    setDownloadArray(download);
  };

  const removeDownloadAttribute = (i) => {
    const download = [...downloadArray];
    download.splice(i, 1);
    setDownloadArray(download);
  };

  const addGalleryAttribute = () => {
    const gallery = [...galleryArray];
    gallery.push({ title: "", url: "" });
    setGalleryArray(gallery);
  };

  const removeGalleryAttribute = (i) => {
    const gallery = [...galleryArray];
    gallery.splice(i, 1);
    setGalleryArray(gallery);
  };

  const handleGalleryTitle = (index) => (_, newValue) => {
    setIsUserActionMade(true);
    if (
      String(newValue).startsWith(`Create "`) &&
      String(newValue).endsWith(`"`)
    ) {
      newValue = newValue.slice(8, -1);
    }
    const gallery = [...galleryArray];
    gallery[index].title = newValue;
    setGalleryArray(gallery);
  };

  const handleGalleryImage = ({ index, ...image }) => {
    setIsUserActionMade(true);
    const gallery = [...galleryArray];
    const { id, url, thumbnailURL, width, height } = image;
    gallery[index] = {
      ...gallery[index],
      id,
      url,
      thumbnailURL,
      width,
      height,
    };
    setGalleryArray(gallery);
  };

  const moveUpGalleryAttribute = (i) => {
    const gallery = [...galleryArray];
    const element = { ...gallery[i] };
    gallery.splice(i, 1);
    gallery.splice(Math.max(0, i - 1), 0, element);
    setGalleryArray(gallery);
  };

  const moveDownGalleryAttribute = (i) => {
    const gallery = [...galleryArray];
    const element = { ...gallery[i] };
    gallery.splice(i, 1);
    gallery.splice(Math.min(galleryArray.length - 1, i + 1), 0, element);
    setGalleryArray(gallery);
  };

  const handleSubmit = () => {
    setIsUserActionMade(true);
    if (Boolean(values.name) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.tradePrice) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.sku) === false) {
      setShowHint(true);
      return;
    }
    if (Boolean(values.size) === false) {
      setShowHint(true);
      return;
    }
    const refinedGeneralArray = [...generalArray];
    for (let i = 0; i < refinedGeneralArray.length; i++) {
      for (let j = 0; j < refinedGeneralArray[i][1].length; j++) {
        if (
          String(refinedGeneralArray[i][1][j]).startsWith(`Create "`) &&
          String(refinedGeneralArray[i][1][j]).endsWith(`"`)
        ) {
          refinedGeneralArray[i][1][j] = refinedGeneralArray[i][1][j].slice(
            8,
            -1
          );
        }
      }
    }
    const general = Object.fromEntries(
      refinedGeneralArray.filter(
        ([key, value]) => Boolean(key) && Boolean(value)
      )
    );
    if (Object.keys(general).length < 1) {
      setShowHint(true);
      return;
    }
    const characteristics = characteristicsArray.filter(([key, _]) =>
      Boolean(key)
    );
    if (
      characteristics.length < 1 ||
      characteristics.length < characteristicsArray.length
    ) {
      setShowHint(true);
      return;
    }
    const download = Object.fromEntries(
      downloadArray.filter(([key, value]) => Boolean(key) && Boolean(value))
    );
    if (Object.keys(download).length < 1) {
      setShowHint(true);
      return;
    }
    const gallery = galleryArray.filter(
      (item) => Boolean(item.title) && Boolean(item.url)
    );
    if (gallery.length < 1 || gallery.length < galleryArray.length) {
      setShowHint(true);
      return;
    }
    setShowHint(false);
    dispatch(
      productCreate({ ...values, general, characteristics, download, gallery })
    );
  };

  useEffect(() => {
    dispatch(catalogListBrand());
    dispatch(catalogListCategory());
    dispatch(productPropGet());
    dispatch(productGalleryTitleList());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productPropList[0]) {
      setKeyList(productPropList.map((x) => x.key));
      keyMap.current = new Map();
      for (let i = 0; i < productPropList.length; i++) {
        keyMap.current.set(
          productPropList[i].key,
          productPropList[i].values.map((y) => y.value)
        );
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPropList]);

  useEffect(() => {
    if (data) {
      const {
        id,
        name,
        size,
        sku,
        tradePrice,
        brand,
        brand_id,
        brand_object,
        category,
        category_id,
        category_object,
        isEOL,
      } = data;
      setValues({
        id,
        name,
        size,
        sku,
        tradePrice,
        brand,
        brand_id,
        brand_object,
        category,
        category_id,
        category_object,
        isEOL,
      });
      setGeneralArray(Object.entries(data.general));
      setCharArray(
        Boolean(data.characteristics) === true
          ? Array.isArray(data.characteristics)
            ? data.characteristics.map((x) =>
                Array.isArray(x) ? [...x] : ["", ""]
              )
            : Object.entries(data.characteristics)
          : []
      );
      setDownloadArray(Object.entries(data.download));
      setGalleryArray(Boolean(data.gallery) === true ? [...data.gallery] : []);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <BlueButton
        variant="contained"
        color="primary"
        disabled={isSelected === false}
        sx={{ m: 1 }}
        onClick={handleClickOpen}
      >
        Copy Details to New Product
      </BlueButton>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-copy-product"
        scroll="body"
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle id="form-dialog-copy-product" onClose={handleClose}>
          Create New Product
        </StyledDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <StyledTextField
            autoFocus
            required
            sx={{ mb: 1.5, mt: 0 }}
            error={isUserActionMade && Boolean(values.name) === false}
            defaultValue={values.name}
            onChange={handleValues("name")}
            variant="outlined"
            margin="dense"
            size="small"
            placeholder="Name"
            fullWidth
          />
          <Autocomplete
            id="brand"
            options={brandList}
            loading={brandListLoading}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionDisabled={(option) => option.isDisabled}
            value={values.brand_object}
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.name} />;
            }}
            onChange={handleAutoCompleteValues("brand_object")}
            fullWidth
            disableClearable
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Brand"
                sx={{ mb: 1.5, mt: 0 }}
                margin="dense"
                size="small"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            id="category"
            options={categoryList}
            loading={categoryListLoading}
            getOptionLabel={(option) => option.fullname || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.category_object}
            onChange={handleAutoCompleteValues("category_object")}
            fullWidth
            disableClearable
            renderOption={(props, option) => {
              return <AutoCompleteOption {...props} name={option.fullname} />;
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                sx={{ mb: 1.5, mt: 0 }}
                placeholder="Category"
                margin="dense"
                size="small"
                variant="outlined"
              />
            )}
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.tradePrice) === false}
            defaultValue={values.tradePrice}
            onChange={handleValues("tradePrice")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mb: 1.5, mt: 0 }}
            placeholder="Trade Priceq"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.sku) === false}
            defaultValue={values.sku}
            onChange={handleValues("sku")}
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mb: 1.5, mt: 0 }}
            placeholder="Stock Keeping Unit (SKU)"
            fullWidth
          />
          <StyledTextField
            required
            error={isUserActionMade && Boolean(values.size) === false}
            defaultValue={values.size}
            onChange={handleValues("size")}
            variant="outlined"
            margin="dense"
            sx={{ mb: 1.5, mt: 0 }}
            size="small"
            placeholder="Size"
            fullWidth
          />
        </DialogContent>
        <DialogContent sx={{ px: 3, py: 0 }}>
          <DialogContentText>General</DialogContentText>
          {generalArray.map((x, i) => (
            <React.Fragment key={i}>
              <Autocomplete
                options={keyList}
                loading={productPropLoading}
                isOptionEqualToValue={(option, value) => option === value}
                value={x[0]}
                onChange={handleGeneralKey(i)}
                fullWidth
                selectOnFocus
                clearOnBlur
                renderOption={(props, option) => {
                  return <AutoCompleteOption {...props} name={option} />;
                }}
                handleHomeEndKeys
                freeSolo
                filterOptions={freeSoloOptionsFilter}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Key"
                    sx={{ mb: 1.5, mt: 0 }}
                    margin="dense"
                    size="small"
                    variant="outlined"
                    error={isUserActionMade && Boolean(x[0]) === false}
                  />
                )}
              />
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={keyMap.current.get(x[0]) || []}
                loading={productPropLoading}
                isOptionEqualToValue={(option, value) => option === value}
                size="small"
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                value={x[1]}
                onChange={handleGeneralValue(i)}
                fullWidth
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                filterOptions={freeSoloOptionsFilter}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Value"
                    sx={{ mb: 1.5, mt: 0 }}
                    margin="dense"
                    size="small"
                    variant="outlined"
                    error={isUserActionMade && Boolean(x[1]) === false}
                  />
                )}
              />
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ display: "block", maxWidth: "20%", my: 1 }}
                onClick={() => removeGeneralAttribute(i)}
                disabled={generalArray.length === 1}
              >
                Remove
              </BlackButton>
            </React.Fragment>
          ))}
          <BlackButton
            variant="contained"
            color="primary"
            sx={{ width: "100%", my: 1 }}
            onClick={addGeneralAttribute}
          >
            Create New Property
          </BlackButton>
        </DialogContent>
        <DialogContent sx={{ p: 3 }}>
          <DialogContentText>Characteristics</DialogContentText>
          {characteristicsArray.map((x, i) => (
            <React.Fragment key={i}>
              <StyledTextField
                required
                sx={{ maxWidth: "48%", mr: 1, mb: 1.5, mt: 0 }}
                error={isUserActionMade && Boolean(x[0]) === false}
                value={x[0]}
                onChange={handleCharKey(i)}
                variant="outlined"
                margin="dense"
                size="small"
                placeholder="Key"
                fullWidth
              />
              <StyledTextField
                sx={{
                  maxWidth: (theme) => `calc(52% - ${theme.spacing(2)})`,
                  mr: 1,
                  mb: 1.5,
                  mt: 0,
                }}
                value={x[1]}
                onChange={handleCharValue(i)}
                variant="outlined"
                margin="dense"
                size="small"
                placeholder="Multiple rows enabled."
                fullWidth
                multiline
                helperText={
                  Boolean(x[1]) === true ? " " : "Blank value: a subtitle."
                }
              />
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ maxWidth: "20%", mr: 1, mb: 1.5, mt: 0 }}
                onClick={() => removeCharAttribute(i)}
                disabled={characteristicsArray.length === 1}
              >
                Remove
              </BlackButton>
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ maxWidth: "20%", mr: 1, mb: 1.5, mt: 0 }}
                onClick={() => moveUpCharAttribute(i)}
                disabled={characteristicsArray.length === 1}
              >
                Move Up
              </BlackButton>
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ maxWidth: "20%", mr: 1, mb: 1.5, mt: 0 }}
                onClick={() => moveDownCharAttribute(i)}
                disabled={characteristicsArray.length === 1}
              >
                Move Down
              </BlackButton>
              <Box sx={{ mb: 1 }} />
            </React.Fragment>
          ))}
          <BlackButton
            variant="contained"
            color="primary"
            sx={{ width: "100%", my: 1 }}
            onClick={addCharAttribute}
          >
            Create New Property
          </BlackButton>
        </DialogContent>
        <DialogContent sx={{ px: 3, py: 0 }}>
          <DialogContentText>Download</DialogContentText>
          {downloadArray.map((x, i) => (
            <React.Fragment key={i}>
              <StyledTextField
                required
                error={isUserActionMade && Boolean(x[0]) === false}
                value={x[0]}
                onChange={handleDownloadKey(i)}
                variant="outlined"
                margin="dense"
                size="small"
                placeholder="Description"
                fullWidth
                sx={{ mt: 0, mb: 1.5 }}
              />
              <ImageCreate
                label="Upload PDF"
                error={isUserActionMade && Boolean(x[1]) === false}
                onFinish={(image) =>
                  handleDownloadValueByUpload({ index: i, url: image.url })
                }
                PDF
              />
              <StyledTextField
                error={isUserActionMade && Boolean(x[1]) === false}
                value={x[1]}
                onChange={handleDownloadValue(i)}
                variant="outlined"
                margin="dense"
                size="small"
                placeholder="Or Enter URL Driectly"
                fullWidth
                sx={{ mt: 1.5, mb: 1.5 }}
              />
              <BlackButton
                variant="contained"
                color="primary"
                onClick={() => removeDownloadAttribute(i)}
                disabled={downloadArray.length === 1}
              >
                Remove
              </BlackButton>
            </React.Fragment>
          ))}
          <BlackButton
            variant="contained"
            color="primary"
            sx={{ width: "100%", my: 1.5 }}
            onClick={addDownloadAttribute}
          >
            Create New Entry
          </BlackButton>
        </DialogContent>
        <DialogContent sx={{ px: 3, py: 0 }}>
          <DialogContentText>Gallery</DialogContentText>
          {galleryArray.map((x, i) => (
            <React.Fragment key={i}>
              <Autocomplete
                options={productGalleryTitleRecord.map((a) => a.name)}
                loading={productGalleryTitleLoading}
                isOptionEqualToValue={(option, value) => option === value}
                value={x["title"]}
                onChange={handleGalleryTitle(i)}
                fullWidth
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                filterOptions={freeSoloOptionsFilter}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Title"
                    margin="dense"
                    size="small"
                    sx={{ mb: 1.5, mt: 0 }}
                    variant="outlined"
                    error={isUserActionMade && Boolean(x["title"]) === false}
                  />
                )}
              />
              <ImageCreate
                label={`Upload Gallery Image ${i + 1}`}
                error={isUserActionMade && Boolean(x["url"]) === false}
                onFinish={(image) => {
                  handleGalleryImage({ index: i, ...image });
                }}
              />
              {x.url && <ImgProductGallery src={x.url} alt="Product Gallery" />}
              <br />
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ maxWidth: "20%", mr: 1 }}
                onClick={() => removeGalleryAttribute(i)}
                disabled={galleryArray.length === 1}
              >
                Remove
              </BlackButton>
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ maxWidth: "20%", mr: 1 }}
                onClick={() => moveUpGalleryAttribute(i)}
                disabled={galleryArray.length === 1}
              >
                Move Up
              </BlackButton>
              <BlackButton
                variant="contained"
                color="primary"
                sx={{ maxWidth: "20%", mr: 1 }}
                onClick={() => moveDownGalleryAttribute(i)}
                disabled={galleryArray.length === 1}
              >
                Move Down
              </BlackButton>
              <Box sx={{ mb: 1 }} />
            </React.Fragment>
          ))}
          <BlackButton
            variant="contained"
            color="primary"
            sx={{ width: "100%", my: 1.5 }}
            onClick={addGalleryAttribute}
          >
            Create New Gallery Entry
          </BlackButton>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            px: 3,
          }}
        >
          <BlueButton
            variant="contained"
            color="primary"
            sx={{ width: "100%", margin: (theme) => theme.spacing(0, 0, 3, 0) }}
            onClick={handleSubmit}
          >
            Create Product
          </BlueButton>
        </DialogActions>
        {showHint && (
          <DialogContentText
            sx={{
              margin: (theme) => theme.spacing(0, 3, 3, 3),
              textAlign: "center",
            }}
          >
            Oops! Something is still wrong above.
          </DialogContentText>
        )}
      </Dialog>
    </React.Fragment>
  );
}

ProductCopy.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    brand: PropTypes.string,
    brand_id: PropTypes.number,
    brand_object: PropTypes.object,
    category: PropTypes.string,
    category_id: PropTypes.number,
    category_object: PropTypes.object,
    size: PropTypes.string,
    sku: PropTypes.string,
    tradePrice: PropTypes.string,
    general: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    characteristics: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    download: PropTypes.objectOf(PropTypes.string),
    gallery: PropTypes.arrayOf(PropTypes.object),
    isEOL: PropTypes.bool,
    created_at: PropTypes.object,
    updated_at: PropTypes.object,
  }).isRequired,
};

export default ProductCopy;
