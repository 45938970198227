import { createSlice } from "@reduxjs/toolkit";
import { orderGetByID } from "./orderAsyncThunk";

const orderGetSlice = createSlice({
  name: "order",
  initialState: {
    payload: {},
    isLoading: false,
    isSuccess: false,
    failCode: 0,
  },
  extraReducers: {
    [orderGetByID.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.failCode = 0;
    },
    [orderGetByID.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.payload = { ...action.payload.order };
    },
    [orderGetByID.rejected]: (state, action) => {
      state.isLoading = false;
      state.failCode = action.payload.status;
    },
  },
});

export default orderGetSlice.reducer;
