import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  categoryList,
  selectCategoryList,
} from "../features/category/categoryAsyncThunk";
import {
  catalogListBrand,
  selectCatalogListBrand,
} from "../features/catalog/catalogAsyncThunk";
import { imageSearchReset } from "../features/image/imageAsyncThunk";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiPopover, { popoverClasses } from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import MuiPaper from "@mui/material/Paper";
import MatterBaseLogo3 from "../components/img/MatterBaseLogo3";
import {
  header_background_colour4,
  landing_page_background_colour,
} from "../components/styles/materialUIStyles";

const Popover = styled(MuiPopover)(({ theme }) => ({
  [`& .${popoverClasses["paper"]}`]: {
    borderRadius: 0,
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: `10%`,

  width: `100%`,
  zIndex: theme.zIndex.appBar + 10,
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "70vh",
  borderRadius: 0,
  backgroundColor: "white",
}));

const BoxDesktopContent = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  padding: theme.spacing(6, 3),
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
  textAlign: "center",
  "& *": {
    margin: theme.spacing(1, 0),
  },
}));

const BoxSearch = styled(Box)(({ theme }) => ({}));

const BoxSearchIcon = styled(Box)(({ theme }) => ({
  marginTop: "6px",
  color: "#A2A2A2",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
}));

const DesktopInput = styled(InputBase)(({ theme }) => ({
  [`& .${inputBaseClasses["input"]}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    fontSize: "6rem",
  },
}));

const StyledMatterBaseLogo = styled(MatterBaseLogo3)(({ theme }) => ({
  width: theme.spacing(4),
  verticalAlign: "text-bottom",
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: 0,
  display: "flex",
  marginLeft: theme.spacing(7),
  alignItems: "center",
  marginLeft: 0,

  [theme.breakpoints.up("sm")]: {
    position: "absolute",
    marginLeft: theme.spacing(3),
    width: "none",
  },
  [theme.breakpoints.only("sm")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default function SearchPopover() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const sectionDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const categoryListStore = useSelector(selectCategoryList);
  const { payload: category } = categoryListStore;
  const catalogListBrandStore = useSelector(selectCatalogListBrand);
  const { payload: brand } = catalogListBrandStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const [text, setText] = useState("");
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setText(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setText("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && Boolean(text) === true) {
      handleClose();
      history.push(`/search/keyword/${text}`);
    }
  };
  const onRedireactClick = () => {
    history.push("/");
  };
  const open = Boolean(anchorEl);
  const id = open ? "search-popover" : undefined;

  useEffect(() => {
    dispatch(categoryList());
    dispatch(catalogListBrand());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: "center",
          ml: 2,
          display: sm ? "none" : "flex",
        }}
      >
        <BoxSearchIcon>
          <SearchIcon />
        </BoxSearchIcon>
        <StyledInputBase
          aria-describedby={id}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={text}
          onChange={handleClick}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
        />
      </Box>
      {/* 
      <BoxHeader>
        <IconButton
          sx={{
            display: open ? "" : "flex",
            transition: "1s ease all",
            justifyContent: open ? "flex-start" : "center",
            ml: 0.5,
          }}
        >
          <MatterBaseLogo3 width="25px" />
        </IconButton>
        <Typography
          onClick={onRedireactClick}
          sx={{
            color: "#000",
            transition: "1s ease all",
            fontSize: "20px",
            mr: 3,
            fontWeight: 600,
          }}
        >
          Matterbase
        </Typography>
      </BoxHeader> */}

      {/* <BoxHeader>
        <p style={{ opacity: "0" }}>emptyspace</p>
      </BoxHeader> */}

      {sectionDesktop === true && (
        <React.Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open} timeout={350}>
              <SearchBox>
                <Paper
                  elevation={4}
                  sx={{ backgroundColor: landing_page_background_colour }}
                >
                  <BoxDesktopContent>
                    <Typography variant="h4">
                      What are you looking for?
                    </Typography>
                    <DesktopInput
                      aria-describedby={id}
                      autoFocus
                      fullWidth
                      inputProps={{ "aria-label": "search" }}
                      value={text}
                      onChange={handleClick}
                      onClick={handleClick}
                      onKeyPress={handleKeyPress}
                    />
                    {text && (
                      <Typography variant="h5">
                        <Link
                          color="inherit"
                          href={`/search/keyword/${text}`}
                          underline="hover"
                        >
                          Search for the keyword "{text}"
                        </Link>
                      </Typography>
                    )}
                    {text.length > 2 &&
                      brand
                        .filter((x) =>
                          x.name.toLowerCase().includes(text.toLowerCase())
                        )
                        .map((item, i) => (
                          <Typography key={i} variant="h5">
                            <Link
                              color="inherit"
                              href={`/search/brand/${item.name}`}
                              underline="hover"
                            >
                              <Box component="span">
                                {item.name}
                                {"\u2122"}
                              </Box>
                            </Link>
                          </Typography>
                        ))}
                    {text.length > 2 &&
                      category
                        .filter((x) =>
                          x.name.toLowerCase().includes(text.toLowerCase())
                        )
                        .map((item, i) => (
                          <Typography key={i} variant="h5">
                            <Link
                              color="inherit"
                              href={`/search/category/${item.fullname}`}
                              underline="hover"
                            >
                              <Box component="span">
                                {"\u0040"}
                                &nbsp;
                                {item.fullname
                                  .split("->")
                                  .map((word, index, array) => {
                                    return index + 1 < array.length ? (
                                      <React.Fragment>
                                        {word}&nbsp;{"\u25B8"}&nbsp;
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>{word}</React.Fragment>
                                    );
                                  })}
                              </Box>
                            </Link>
                          </Typography>
                        ))}
                    {
                      <Typography variant="h5">
                        <Link
                          color="inherit"
                          href={`/search/image`}
                          underline="hover"
                          onClick={() => dispatch(imageSearchReset())}
                        >
                          Search by uploading an image
                        </Link>
                      </Typography>
                    }
                  </BoxDesktopContent>
                </Paper>
              </SearchBox>
            </Fade>
          </Modal>
        </React.Fragment>
      )}
      {sectionDesktop === false && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          disableAutoFocus
          disableEnforceFocus
        >
          {text && (
            <Typography sx={{ p: 2 }}>
              <Link
                color="inherit"
                href={`/search/keyword/${text}`}
                underline="hover"
              >
                Search for the keyword "{text}"
              </Link>
            </Typography>
          )}
          {text.length > 2 &&
            brand
              .filter((x) => x.name.toLowerCase().includes(text.toLowerCase()))
              .map((item, i) => (
                <Typography key={i} sx={{ p: 2 }}>
                  <Link
                    color="inherit"
                    href={`/search/brand/${item.name}`}
                    underline="hover"
                  >
                    <Box component="span">
                      {item.name}
                      {"\u2122"}
                    </Box>
                  </Link>
                </Typography>
              ))}
          {text.length > 2 &&
            category
              .filter((x) => x.name.toLowerCase().includes(text.toLowerCase()))
              .map((item, i) => (
                <Typography key={i} sx={{ p: 2 }}>
                  <Link
                    color="inherit"
                    href={`/search/category/${item.fullname}`}
                    underline="hover"
                  >
                    <Box component="span">
                      {"\u0040"}
                      &nbsp;
                      {item.fullname.split("->").map((word, index, array) => {
                        return index + 1 < array.length ? (
                          <React.Fragment>
                            {word}&nbsp;{"\u25B8"}&nbsp;
                          </React.Fragment>
                        ) : (
                          <React.Fragment>{word}</React.Fragment>
                        );
                      })}
                    </Box>
                  </Link>
                </Typography>
              ))}
          {Boolean(text) === false && (
            <React.Fragment>
              <Typography sx={{ p: 2 }}>Enter a keyword</Typography>
            </React.Fragment>
          )}
          {
            <Typography sx={{ p: 2 }}>
              <Link
                color="inherit"
                href={`/search/image`}
                underline="hover"
                onClick={() => dispatch(imageSearchReset())}
              >
                Or search by uploading an image
              </Link>
            </Typography>
          }
        </Popover>
      )}
    </React.Fragment>
  );
}
