import { createSlice } from '@reduxjs/toolkit';
import {
  favoriteCreate, favoriteCreateBulk, favoriteCreateReset,
  favoriteLocalCreateStart, favoriteLocalCreateEnd,
} from './favoriteAsyncThunk';

const favoriteCreateSlice = createSlice({
  name: "favorite",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFail: false,
    isBulkCreateSuccess: false,
  },
  reducers: {},
  extraReducers: {
    [favoriteCreate.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
      state.isBulkCreateSuccess = false;
    },
    [favoriteCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [favoriteCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
    [favoriteCreateBulk.pending]: (state, action) => {
      state.isBulkCreateSuccess = false;
    },
    [favoriteCreateBulk.fulfilled]: (state, action) => {
      state.isBulkCreateSuccess = true;
    },
    [favoriteCreateBulk.rejected]: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
    },
    [favoriteLocalCreateStart]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFail = false;
    },
    [favoriteLocalCreateEnd]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [favoriteCreateReset]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isFail = false;
      state.isBulkCreateSuccess = false;
    },
  },
});

export default favoriteCreateSlice.reducer;