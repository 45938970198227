import React from "react";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { bodyFont } from "../components/styles/materialUIStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NotFoundLogo from "../components/img/figure_korobu.png";

const StyledImg = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  objectFit: "cover",
}));

export default function Error404() {
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <Container maxWidth="sm">
        <Box sx={{ width: "100%", maxWidth: 500, my: { xs: 20, md: 15 } }}>
          <StyledImg src={NotFoundLogo} alt="Not Found" />
          <Typography variant="h5" gutterBottom>
            404
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            The Page You Are Looking For Was Not Found
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Link href="/" color="inherit" underline="hover">
              Back to Home
            </Link>
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}
