import renderCellExpand from "./renderCellExpand";
import renderIsEOLCell from "./renderIsEOLCell";

const productColumns = [
  { field: "id", headerName: "ID", width: 100, hide: true, renderCell: renderCellExpand, },
  { field: "name", headerName: "Name", width: 200, renderCell: renderCellExpand, },
  { field: "brand", headerName: "Brand", width: 200, renderCell: renderCellExpand, },
  { field: "category", headerName: "Category", width: 300, renderCell: renderCellExpand, },
  { field: "tradePrice", headerName: "Trade Price", width: 150, renderCell: renderCellExpand, },
  { field: "sku", headerName: "SKU", width: 150, renderCell: renderCellExpand, },
  { field: "size", headerName: "Size", width: 150, renderCell: renderCellExpand, },
  {
    field: "isEOL",
    headerName: "Is End-Of-Life?",
    width: 180,
    type: "boolean",
    renderCell: renderIsEOLCell,
  },
  {
    field: "created_at",
    headerName: "Created On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
  },
  {
    field: "updated_at",
    headerName: "Updated On",
    width: 180,
    type: "dateTime",
    renderCell: renderCellExpand,
  },
];

export default productColumns;
