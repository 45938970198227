import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Divider,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";

import { ChromePicker } from "react-color";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
// import XMLParser from "react-xml-parser";
// import { ReactSVG } from "react-svg";
import * as _ from "lodash";
import ImageCreate from "../image/ImageCreate";
import NoImg from "../../components/img/noImg.png";
import { Dialog, StyledTextField } from "../../components/CustomComponents";
import AutoCompleteOption from "../../components/AutoCompleteOption";
const GridImages = styled("img")(({ theme }) => ({
  width: "30%",
  objectFit: "fill",
  height: "15vh",
  overflow: "hidden",
  cursor: "pointer",
}));

const SvgEditor = (props) => {
  const [values, setValues] = useState([
    {
      name: "",
      height: "1000",
      width: "600",
      tooltip: "",
      link: "",
      product: null,
    },
  ]);

  const [basicSettings, setBasicSettings] = useState({
    width: "700",
    height: "600",
  });

  const [path, setPath] = useState(null);
  const [selectedImg, setSelectedIng] = useState("");
  const [imageIds, setImageIds] = useState([]);
  const [id, setId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [title, setTitle] = useState("");
  const [tooltip, setTooltip] = useState("");
  const [mouseX, setMouseX] = useState();
  const [mouseY, setMouseY] = useState();
  const [productImg, setProductImg] = useState("");
  const [color, setColor] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [colorPopup, setColorPopUp] = useState();
  const [open, setOpen] = useState();
  const [redireactLink, setRedireactLink] = useState("");
  const open2 = Boolean(anchorEl);
  const open3 = Boolean(anchorEl2);
  const [product, setProduct] = useState(null);
  const [value, setValue] = React.useState("Basic");
  const handleCloseModal = () => {
    setColorPopUp("");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePopoverHoverOpen = (e) => {
    // setAnchorEl2(e.nativeEvent.path[0]);
  };
  const handleColorPopup = (id) => {
    let index = values.findIndex((item, index) => index === id);
    let isExsit = values.find((item, index) => index === id);
    if (isExsit?.product?.gallery?.length < 1) {
      values[id].product_url = "";
    }
    if (isExsit?.product?.gallery?.length > 0) {
      if (index !== -1) {
        setColorPopUp(index);
      } else {
        setColorPopUp(0);
      }
    }
  };

  const handlePopoverHoverClose = () => {
    setAnchorEl2(null);
  };
  const handleBasicValues = (event) => {
    setBasicSettings({
      ...basicSettings,
      [event.target.name]: event.target.value,
    });
  };
  const handleValues = (event, value, index) => {
    const val = [...values];
    if (value) {
      val[index]["product"] = value;
      val[index]["link"] = value?.url;
    } else {
      val[index][event.target.name] = event.target.value;
      val[index]["product"] = null;
      val[index]["link"] = "";
    }
    setValues(val);
  };

  function getPath(e) {
    const allHTMLTags = document.getElementById("svg-details");
    const fetchPathNodes = allHTMLTags.querySelectorAll("*[id]");
    let selectedPath = e.nativeEvent.srcElement;
    setPath(selectedPath);
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    if (selectedPath.nodeName) {
      setAnchorEl(e.nativeEvent.srcElement);
    }
    setTitle(e.target.getAttribute("name"));
    setProductImg(e.target?.getAttribute("product_url"));
    setRedireactLink(e.target.getAttribute("link"));
    let selectedProduct = e.target.getAttribute("product");
    if (selectedProduct) {
      setProduct(JSON.parse(selectedProduct));
    } else {
      setProduct(null);
    }
  }
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedImg) {
      let allHTMLTagsArr = [];
      const allHTMLTags = document.getElementById("svg-details");
      const fetchPathNodes = allHTMLTags.querySelectorAll("path");
      let ids = allHTMLTags.querySelectorAll("*[id]");
      allHTMLTagsArr = [...allHTMLTagsArr, ids];
      let newArr = [];
      for (let i = 0; i < Object.keys(allHTMLTagsArr[0])?.length; i++) {
        let news = allHTMLTagsArr.map((item) => item[i].id);
        newArr = [...newArr, news[0]];
      }
      setImageIds(newArr);
    }
  }, [selectedImg]);

  const getId = (val, id) => {
    setId(val);
    let index = values.findIndex((item, index) => index === id);
    if (index !== -1) {
      setOpen(index);
    } else {
      setOpen(0);
    }
    let paths = document.getElementById(val);

    setPath(paths);
  };
  useEffect(() => {
    if (selectedImg) {
      let allHTMLTagsArr = [];
      const allHTMLTags = document.getElementById("svg-details");
      const fetchPathNodes = allHTMLTags.querySelectorAll("path");
      let ids = allHTMLTags.querySelectorAll("*[id]");

      allHTMLTagsArr = [...allHTMLTagsArr, ids];
      let initalValues = [];
      ids.forEach(function (item) {
        let selectedStringProduct = item.getAttribute("product");
        let selectedProduct = selectedStringProduct
          ? JSON.parse(selectedStringProduct)
          : null;
        initalValues = [
          ...initalValues,
          {
            name: item?.getAttribute("name") ?? "",
            tooltip: item?.getAttribute("tooltip") ?? "",
            link: item?.getAttribute("link") ?? "https://",
            product: selectedProduct ? selectedProduct : null,
            product_url: item?.getAttribute("product_url") ?? "",
          },
        ];
      });
      setValues(initalValues);
    }
  }, [selectedImg]);

  function hoverChange(v) {
    let allHTMLTagsArr = [];
    const allHTMLTags = document.getElementById("svg-details");
    // const allHTMLTags = document.querySelectorAll("path");
    let ids = allHTMLTags.querySelectorAll("*[id]");

    allHTMLTagsArr = [...allHTMLTagsArr, ids];

    ids.forEach(function (item, index) {
      if (item.id === v.target.id) {
        let paths = document.getElementById(v.target.id);

        paths?.setAttribute(
          "filter",
          "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
        );
      } else if (item.id !== v.target.id) {
        item.removeAttribute("filter");
      }
    });
    setTooltip(v.target.getAttribute("tooltip"));
    if (v.target.getAttribute("title")) {
      handlePopoverHoverOpen(v);
    } else {
      handlePopoverHoverClose();
    }
  }
  useEffect(() => {
    if (props.isSubmitting === true) {
      let productIds = values?.map((item) => item?.product?.id);
      props.getProductIds(productIds);
    } else {
      props.getProductIds([]);
    }
  }, [props.isSubmitting]);

  const setProductImage = (index, img) => {
    const val = [...values];
    if (img) {
      val[index]["product_url"] = img;
    }
    setValues(val);
    setColorPopUp("");
  };
  // console.log("values", values);
  useEffect(() => {
    if (id && selectedImg) {
      const allHTMLTags = document.getElementById("svg-details");
      const fetchPathNodes = allHTMLTags.querySelectorAll("path");
      let ids = allHTMLTags.querySelectorAll("*[id]");

      ids.forEach(function (item, index) {
        if (item.id === id) {
          // let paths = allHTMLTags.getElementById(id);
          // console.log("yess==================>", paths);
          item.setAttribute("name", values[index]?.name);
          item.setAttribute("tooltip", values[index]?.tooltip);
          item.setAttribute("link", values[index]?.link);
          item.setAttribute("product_url", values[index]?.product_url);
          item.setAttribute(
            "product",
            values[index]?.product ? JSON.stringify(values[index]?.product) : ""
          );
          item?.setAttribute(
            "filter",
            "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
          );
        } else if (item.id !== id) {
          item.removeAttribute("filter");
        }
      });
    }
  }, [path, id, values, selectedImg]);

  useEffect(() => {
    if (props.file) {
      setSelectedIng(props.file);
      if (selectedImg && props.file) {
        const allHTMLTags = document.getElementById("svg-details");
        const fetchPathNodes = allHTMLTags.querySelector("svg");

        fetchPathNodes?.setAttribute("width", basicSettings.width);
        fetchPathNodes?.setAttribute("height", basicSettings.height);
      }
    }
  }, [props, selectedImg, basicSettings]);

  // useEffect(() => {
  //   if (props.file && selectedImg) {
  //     const allHTMLTags = document.getElementById("svg-details");
  //     const fetchPathNodes = allHTMLTags.querySelector("svg");

  //     let IntialWidth = fetchPathNodes?.getAttribute("width");
  //     let InitialHeight = fetchPathNodes?.getAttribute("height");
  //     setBasicSettings({
  //       width: IntialWidth,
  //       height: InitialHeight,
  //     });
  //   }
  // }, [props, selectedImg]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6.5} sx={{ overflow: "hidden", height: "90vh", mr: 3 }}>
        <Popover
          id={id}
          anchorPosition={{ x: mouseX, y: mouseY }}
          open={open2}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Card sx={{ minWidth: 300, maxWidth: 310 }}>
            {product ? (
              <>
                <CardMedia
                  component="img"
                  height="140"
                  image={productImg ? productImg : NoImg}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    {product?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product?.brand}
                  </Typography>
                </CardContent>
                <CardActions>
                  {redireactLink && (
                    <Button
                      sx={{ size: "10px" }}
                      href={redireactLink}
                      target="_blank"
                      size="small"
                    >
                      Search more about this Product
                    </Button>
                  )}
                </CardActions>
              </>
            ) : (
              <Box sx={{ p: 3 }}>
                <Typography variant="body2">No Product Added !</Typography>
              </Box>
            )}
          </Card>
        </Popover>

        {/* <Popover
          id={id}
          sx={{
            pointerEvents: "none",
          }}
          open={open3}
          anchorEl={anchorEl2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{tooltip ? tooltip : "No Tags"}</Typography>
        </Popover> */}

        <Box
          onMouseOverCapture={hoverChange}
          onClick={getPath}
          sx={{ overflow: "hidden", ml: 3 }}
        >
          {selectedImg ? (
            //   <Dragable>
            <div
              id="svg-details"
              style={{ overflow: "hidden", width: "100%" }}
              dangerouslySetInnerHTML={{ __html: selectedImg }}
            />
          ) : (
            //   </Dragable>
            ""
          )}
        </Box>
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ mt: 3 }} />
      <Grid item xs={5}>
        <TabContext value={value}>
          <TabList
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            textColor="secondary"
            aria-label="Vertical tabs example"
          >
            <Tab label="Basic Settings" color="primary" value={"Basic"} />
            <Tab label="Components" color="primary" value={"Components"} />
          </TabList>
          <TabPanel value="Basic">
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ width: "10%" }}>
                  <Typography sx={{ fontSize: "16px" }}>Width</Typography>
                </Box>
                <StyledTextField
                  // onChange={handleValues("name")}
                  onChange={(event) => handleBasicValues(event)}
                  variant="outlined"
                  margin="dense"
                  onKeyPress={(e) => e.preventDefault()}
                  value={basicSettings.width}
                  size="small"
                  type="number"
                  onKeyDown={true}
                  name="width"
                  placeholder="Width"
                  fullWidth
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ width: "10%" }}>
                  <Typography sx={{ fontSize: "16px" }}>Height</Typography>
                </Box>
                <StyledTextField
                  // onChange={handleValues("name")}
                  onChange={(event) => handleBasicValues(event)}
                  variant="outlined"
                  margin="dense"
                  value={basicSettings.height}
                  size="small"
                  onKeyPress={(e) => e.preventDefault()}
                  type="number"
                  name="height"
                  placeholder="Height"
                  fullWidth
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel sx={{ width: "100%" }} value="Components">
            <Box
              sx={{
                height: "70vh",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {imageIds.map((item, index) => (
                <>
                  <Dialog
                    hideBackdrop
                    open={colorPopup === index ? true : false}
                    onClose={handleCloseModal}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        border: "solid 1px #e2e2e2 ",
                        background: "#f4f4f4",
                        borderRadius: "12px",
                        width: "100%",
                        maxWidth: "50%",
                        overflowY: "auto",
                      },
                    }}
                    closeAfterTransition
                  >
                    <Box
                      sx={{
                        p: "10px 20px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: "600", fontSize: "17px" }}
                      >
                        Select Image :-
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "center",
                          flexWrap: "wrap",
                          mt: 2,
                        }}
                      >
                        {values[index]?.product?.gallery?.length > 0 ? (
                          <>
                            {values[index]?.product?.gallery.map((item) => (
                              <GridImages
                                onClick={(e) =>
                                  setProductImage(index, item?.url)
                                }
                                src={item?.url}
                              />
                            ))}
                          </>
                        ) : (
                          <Box height={"10vh"}>
                            <Typography variant="body2">
                              No Images Found !
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Dialog>

                  <Box>
                    <Typography
                      key={index}
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        getId(item, index);
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                  <Collapse in={open == index ? true : false}>
                    <Box sx={{ pl: 5, mr: 5 }} key={index}>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Box sx={{ width: "13%" }}>
                          <Typography sx={{ fontSize: "16px" }}>
                            Name
                          </Typography>
                        </Box>
                        <StyledTextField
                          // onChange={handleValues("name")}
                          onChange={(event) => handleValues(event, null, index)}
                          variant="outlined"
                          margin="dense"
                          value={values[index]?.name}
                          size="small"
                          name="name"
                          label="Name"
                          fullWidth
                        />
                      </Box> */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Box sx={{ width: "13%" }}>
                          <Typography sx={{ fontSize: "16px" }}>
                            Tooltip
                          </Typography>
                        </Box>
                        <StyledTextField
                          onChange={(event) => handleValues(event, null, index)}
                          variant="outlined"
                          margin="dense"
                          value={values[index]?.tooltip}
                          size="small"
                          name="tooltip"
                          label="Tooltip"
                          fullWidth
                        />
                      </Box> */}

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Box sx={{ width: "13%" }}>
                          <Typography sx={{ fontSize: "16px" }}>
                            Product
                          </Typography>
                        </Box>
                        <Autocomplete
                          size="small"
                          sx={{ width: "100%", mt: 1 }}
                          value={values[index]?.product}
                          options={props?.products ?? []}
                          onChange={(event, value) => {
                            handleValues(event, value, index);
                            handleColorPopup(index); // setSelectedRoleData(value)
                            // setFieldValue('fkoutletid', value?.id)
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option) => {
                            return (
                              <AutoCompleteOption
                                {...props}
                                name={option?.name}
                              />
                            );
                          }}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              name="product"
                              placeholder="Select Outlet"
                            />
                          )}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Box sx={{ width: "13%" }}>
                          <Typography sx={{ fontSize: "16px" }}>
                            Link{" "}
                          </Typography>
                        </Box>
                        <StyledTextField
                          // onChange={(event) => handleValues(event, null, index)}
                          variant="outlined"
                          margin="dense"
                          value={values[index]?.link}
                          size="small"
                          name="link"
                          placeholder="Link"
                          fullWidth
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          width: "100%",
                          mt: 3,
                        }}
                      >
                        <Button
                          sx={{
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                          onClick={() => setOpen()}
                          variant="contained"
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                  <Divider sx={{ mb: 3, mt: 2 }} />
                </>
              ))}
            </Box>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default SvgEditor;
