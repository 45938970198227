export const ORDER_STATUS = Object.freeze({
  PREPARING: "Preparing",
  SHIPPED: "Shipped",
  DELIVERED: "Delivered",
});

export const ORDER_OPTIONS = [
  "Preparing Matterbox",
  "Matterbox Shipped",
  "Matterbox Delivered",
];
