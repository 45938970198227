import { createTheme } from "@mui/material/styles";

export const header_background_colour = "#FFFFFF";
export const header_background_colour3 = "rgba(242, 242, 242, 0.6)";
export const header_background_colour4 = "rgba(242, 242, 242, 0.8)";
export const header_background_colour2 = "#000000";
export const header_title_colour = "#000000";
export const highlight_colour = "#D1493B";
export const footer_background_colour = "#282828";
export const footer_title_colour = "#FFFFFF";
export const footer_link_colour = "#CCCCCC";
export const footer_secondary_colour = "#AAAAAA";
export const modal_background_colour = "#FFFFFF";
export const subscriber_background_colour = "#D1BB93";
export const landing_page_background_colour = "#F6F6F6";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    title_super: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: "48px",
      fontWeight: "700",
      color: "rgba(0, 0, 0, 0.88)",
    },
    title_primary_black: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: "24px",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.88)",
    },
    title_primary_grey: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: "24px",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.56)",
    },
    title_secondary_black: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: "20px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.88)",
    },
    title_secondary_grey: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: "20px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.56)",
    },
    body_secondary_grey: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: "20px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.75)",
    },
  },
  palette: {
    background: {
      default: "landing_page_background_colour",
    },
    primary: {
      main: "#282828",
      primary: "#383838",
      secondary: "#0000008f",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.88)",
      secondary: "rgba(0, 0, 0, 0.56)",
      disabled: "rgba(24, 24, 24, 0.54)",
      hint: "rgba(24, 24, 24, 0.54)",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          width: 6,
          height: 4,
          position: "absolute",
        },
        "*::-webkit-scrollbar-thumb": {
          borderRadius: 10,
          background: "#888",
        },
        "*::-webkit-scrollbar-track": {
          background: "landing_page_background_colour",
          borderRadius: 10,
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555", // Set the background color of the scrollbar thumb when hovered
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            opacity: 0,
            background: "rgba(0, 0, 0, 0.45)",
            // backdropFilter: "blur(5px)",
            // WebkitBackdropFilter: "blur(5px)",
          },
        },
      },
    },
  },
});
