import React, { useRef } from "react";

import { Box, Typography, Card, CardMedia } from "@mui/material";
import { useDispatch } from "react-redux";

import {
  orderCancel,
  orderProductRemove,
} from "../features/order/orderAsyncThunk";

const SampleOrderProductCard = ({
  product,
  orderId,
  productCheck,
  orderIdCheck,
}) => {
  const cardRef = useRef(null);
  const dispatch = useDispatch();

  return (
    <Box sx={{ backgroundColor: "#fff", p: 3, mb: 1.5, borderRadius: "12px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
        >
          {product?.imageURL ? (
            <Box
              sx={{
                width: { xs: 140, sm: 112 },
                height: 112,
                borderRadius: "8px",
              }}
            >
              <Card
                ref={cardRef}
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <CardMedia
                  height={"100%"}
                  component="img"
                  alt={"Product Image"}
                  image={product?.imageURL}
                  title={"Product Image"}
                  sx={{ height: 112, borderRadius: "8px" }}
                />
              </Card>
            </Box>
          ) : (
            <Box
              sx={{
                width: { xs: 140, sm: 112 },
                height: 112,
                backgroundColor: "#C4C4C4",
                borderRadius: "8px",
              }}
            />
          )}
          <Box
            sx={{
              ml: { xs: 0, sm: 1.5 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              my: { xs: 1.5, sm: 0 },
            }}
          >
            <Box sx={{ width: { sm: "200px" } }}>
              <Typography sx={{ fontSize: 15 }} fontWeight={600} noWrap>
                {product?.product_name}
              </Typography>
              <Typography fontSize={12} fontWeight={300} noWrap>
                Product Name
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 15 }} fontWeight={600} noWrap>
                {product.brand_name}
              </Typography>
              <Typography fontSize={12} fontWeight={300} noWrap>
                Brand Name
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderLeft: { xs: 0, sm: 1 },
              borderLeftColor: "#181818",
              pl: { xs: 0, sm: 1.5 },
              mb: { xs: 1.5, sm: 0 },
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 15 }} fontWeight={600}>
                {product?.product_variation_name}
              </Typography>
              <Typography fontSize={12} fontWeight={300}>
                Variation Name
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: 15, textAlign: "right", marginRight: 6 }}
              fontWeight={300}
            >
              Quantity: {product?.quantity}
            </Typography>
            <Typography
              sx={{ fontSize: 15, textAlign: "right" }}
              fontWeight={300}
            >
              Sample Size: {product?.product_size}
            </Typography>
          </Box>
          {orderIdCheck && (
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", sm: "flex-end" },
              }}
              onClick={() => {
                if (productCheck) {
                  dispatch(
                    orderProductRemove({
                      orderId,
                      productVariationId: product?.id,
                    })
                  );
                  window.location.href = `/account`;
                  return;
                }
                dispatch(orderCancel(orderId));
                window.location.href = `/account`;
              }}
            >
              <Typography
                sx={{
                  borderBottom: 1,
                  borderBottomColor: "#181818",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                fontSize={12}
                noWrap
                fontWeight={300}
              >
                Remove from Matterbox
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SampleOrderProductCard;
